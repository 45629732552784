import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Link, withRouter } from "react-router-dom"

import SidebarLink from "components/SidebarLink/SidebarLink"
import Expand from "components/Expand/Expand"
import Icon from "components/Icon/Icon"
import NotificationContainer from "components/Layout/containers/Notification"
import { handleLogout, hasPermissions, getAuthInfo } from "apps/auth/auth"
import { REQUIRE_HORIZONTAL_SCROLL } from "utils/constants"
import { getLocalStorageObj, setLocalStorageObj } from "utils/fs"
import { reverse } from "utils/urls"

import Logo from "assets/img/logo.svg"

import "./MainLayout.scss"

const SIDEBAR_TOGGLED = "sidebar-toggled"

class MainLayout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isToggled: getLocalStorageObj(SIDEBAR_TOGGLED),
    }
  }

  toggleSidebar = () =>
    this.setState(
      prevState => ({
        isToggled: !prevState.isToggled,
      }),
      () => setLocalStorageObj(SIDEBAR_TOGGLED, this.state.isToggled)
    )

  render() {
    const authInfo = getAuthInfo()
    const { t, location } = this.props

    return (
      <div id="app">
        <div
          className={`d-flex ${this.state.isToggled ? "toggled" : ""}`.trim()}
          id="wrapper"
          {...(REQUIRE_HORIZONTAL_SCROLL.some(path =>
            location.pathname.includes(path)
          )
            ? { style: { overflowX: "visible" } }
            : {})}>
          <div id="sidebar-wrapper">
            <nav className="navbar navbar-expand-lg navbar-dark bg-blue-dark shadow">
              <div className="container">
                <Link className="navbar-brand" to={reverse("home")}>
                  <img src={Logo} width="200" alt={t("Logo")} />
                </Link>
              </div>
            </nav>

            {hasPermissions(
              ["view_working_sheet", "view_invoice_working_sheet"],
              "some"
            ) && (
              <div className="sidebar-items">
                <h5>{t("Sheets")}</h5>
                {hasPermissions(
                  ["view_working_sheet", "view_invoice_working_sheet"],
                  "some"
                ) && (
                  <div className="list-group">
                    <div className="list-group-item">
                      <div className="row">
                        <div className="col">{t("Working Sheets")}</div>
                        <div className="col-auto pr-4">
                          <Expand color="white" target="#sheets-working" />
                        </div>
                      </div>
                    </div>
                    <div className="collapse" id="sheets-working">
                      {hasPermissions(["view_working_sheet"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("workingSheet")}>
                          {t("Working Sheet")}
                        </SidebarLink>
                      )}
                      {hasPermissions(["view_invoice_working_sheet"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("invoiceWorkingSheet")}>
                          {t("Invoice Working Sheet")}
                        </SidebarLink>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            {hasPermissions(
              [
                "view_airports_airport",
                "view_core_city",
                "view_core_country",
                "view_core_daterange",
                "view_routings_routing",
                "view_flights_flight",
                "add_flights_flight",
                "view_airlines_airline",
                "add_bookings_airlinebooking",
                "view_bookings_airlinebooking",
                "view_fares_airlinebookingfares",
                "view_fares_airlineinfantbookingfares",
                "view_fees_airlinefee",
                "view_tour_operators_touroperator",
                "add_bookings_touroperatorbooking",
                "view_bookings_touroperatorbooking",
                "view_fares_touroperatorbookingfare",
                "view_fares_touroperatorinfantbookingfare",
                "view_fees_touroperatorfee",
                "view_tickets_ticket",
                "add_tickets_ticket",
                "view_tickets_ticketdefault",
                "view_invoices_touroperatorinvoice",
              ],
              "some"
            ) && (
              <div className="sidebar-items">
                <h5>{t("General")}</h5>
                {hasPermissions(
                  [
                    "view_airports_airport",
                    "view_core_city",
                    "view_core_country",
                    "view_core_daterange",
                    "view_routings_routing",
                  ],
                  "some"
                ) && (
                  <div className="list-group">
                    <div className="list-group-item">
                      <div className="row">
                        <div className="col">{t("Misc.")}</div>
                        <div className="col-auto pr-4">
                          <Expand color="white" target="#general-misc" />
                        </div>
                      </div>
                    </div>
                    <div className="collapse" id="general-misc">
                      {hasPermissions(["view_airports_airport"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("airportList")}>
                          {t("Airports")}
                        </SidebarLink>
                      )}
                      {hasPermissions(["view_core_city"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("cityList")}>
                          {t("Cities")}
                        </SidebarLink>
                      )}
                      {hasPermissions(["view_core_country"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("countryList")}>
                          {t("Countries")}
                        </SidebarLink>
                      )}
                      {hasPermissions(["view_core_daterange"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("dateRangeList")}>
                          {t("Date Ranges")}
                        </SidebarLink>
                      )}
                      {hasPermissions(["view_routings_routing"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("routingList")}>
                          {t("Routings")}
                        </SidebarLink>
                      )}
                    </div>
                  </div>
                )}

                {hasPermissions(
                  ["view_flights_flight", "add_flights_flight"],
                  "some"
                ) && (
                  <div className="list-group">
                    <div className="list-group-item">
                      <div className="row">
                        <div className="col">{t("Flights")}</div>
                        <div className="col-auto pr-4">
                          <Expand color="white" target="#general-flights" />
                        </div>
                      </div>
                    </div>
                    <div className="collapse" id="general-flights">
                      {hasPermissions(["view_flights_flight"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("flightSeriesList")}>
                          {t("Flight Schedule")}
                        </SidebarLink>
                      )}
                      {hasPermissions(["add_flights_flight"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("flightCreate")}>
                          {t("Create Flight")}
                        </SidebarLink>
                      )}
                    </div>
                  </div>
                )}

                {hasPermissions(
                  [
                    "view_airlines_airline",
                    "add_bookings_airlinebooking",
                    "view_bookings_airlinebooking",
                    "view_fares_airlinebookingfare",
                    "view_fares_airlineinfantbookingfare",
                    "view_fees_airlinefee",
                  ],
                  "some"
                ) && (
                  <div className="list-group">
                    <div className="list-group-item">
                      <div className="row">
                        <div className="col">{t("Airlines")}</div>
                        <div className="col-auto pr-4">
                          <Expand color="white" target="#general-airlines" />
                        </div>
                      </div>
                    </div>
                    <div className="collapse" id="general-airlines">
                      {hasPermissions(["view_airlines_airline"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("airlineList")}>
                          {t("Airline List")}
                        </SidebarLink>
                      )}
                      {hasPermissions(["add_bookings_airlinebooking"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("airlineBookingCreateStep1")}
                          activeStateRoutes={[
                            reverse("airlineBookingCreateStep1"),
                            reverse("airlineBookingCreateStep2"),
                          ]}>
                          {t("Create Airline Booking")}
                        </SidebarLink>
                      )}
                      {hasPermissions(["view_bookings_airlinebooking"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("airlineBookingList")}>
                          {t("Airline Booking List")}
                        </SidebarLink>
                      )}
                      {hasPermissions(["view_fares_airlinebookingfare"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("airlineBookingFareList")}>
                          {t("Airline Fares")}
                        </SidebarLink>
                      )}
                      {hasPermissions([
                        "view_fares_airlineinfantbookingfare",
                      ]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("airlineInfantBookingFareList")}>
                          {t("Airline Infant Fares")}
                        </SidebarLink>
                      )}
                      {hasPermissions(["view_fees_airlinefee"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("airlineFeeList")}>
                          {t("Airline Fees")}
                        </SidebarLink>
                      )}
                    </div>
                  </div>
                )}

                {hasPermissions(
                  [
                    "view_tour_operators_touroperator",
                    "add_bookings_touroperatorbooking",
                    "view_bookings_touroperatorbooking",
                    "view_fares_touroperatorbookingfare",
                    "view_fares_touroperatorinfantbookingfare",
                    "view_fees_touroperatorfee",
                  ],
                  "some"
                ) && (
                  <div className="list-group">
                    <div className="list-group-item">
                      <div className="row">
                        <div className="col">{t("Tour Operators")}</div>
                        <div className="col-auto pr-4">
                          <Expand
                            color="white"
                            target="#general-tour-operators"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="collapse" id="general-tour-operators">
                      {hasPermissions(["view_tour_operators_touroperator"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("tourOperatorList")}>
                          {t("TO List")}
                        </SidebarLink>
                      )}
                      {hasPermissions(["add_bookings_touroperatorbooking"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("tourOperatorBookingCreateStep1")}
                          activeStateRoutes={[
                            reverse("tourOperatorBookingCreateStep1"),
                            reverse("tourOperatorBookingCreateStep2"),
                          ]}>
                          {t("Create TO Booking")}
                        </SidebarLink>
                      )}
                      {hasPermissions([
                        "view_bookings_touroperatorbooking",
                      ]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("tourOperatorBookingList")}>
                          {t("TO Booking List")}
                        </SidebarLink>
                      )}
                      {hasPermissions([
                        "view_fares_touroperatorbookingfare",
                      ]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("tourOperatorBookingFareList")}>
                          {t("TO Fares")}
                        </SidebarLink>
                      )}
                      {hasPermissions([
                        "view_fares_touroperatorinfantbookingfare",
                      ]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("tourOperatorInfantBookingFareList")}>
                          {t("TO Infant Fares")}
                        </SidebarLink>
                      )}
                      {hasPermissions(["view_fees_touroperatorfee"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("tourOperatorFeeList")}>
                          {t("TO Fees")}
                        </SidebarLink>
                      )}
                    </div>
                  </div>
                )}

                {hasPermissions(
                  [
                    "view_tickets_ticket",
                    "add_tickets_ticket",
                    "view_tickets_ticketdefault",
                  ],
                  "some"
                ) && (
                  <div className="list-group">
                    <div className="list-group-item">
                      <div className="row">
                        <div className="col">{t("Tickets")}</div>
                        <div className="col-auto pr-4">
                          <Expand color="white" target="#general-tickets" />
                        </div>
                      </div>
                    </div>
                    <div className="collapse" id="general-tickets">
                      {hasPermissions(["view_tickets_ticket"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("ticketList")}>
                          {t("Ticket List")}
                        </SidebarLink>
                      )}
                      {hasPermissions(["add_tickets_ticket"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("ticketCreateStep1")}
                          activeStateRoutes={[
                            reverse("ticketCreateStep1"),
                            reverse("ticketCreateStep2"),
                          ]}>
                          {t("Create Ticket")}
                        </SidebarLink>
                      )}
                      {hasPermissions(["view_tickets_ticketdefault"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("ticketDefaultList")}>
                          {t("Ticket Defaults")}
                        </SidebarLink>
                      )}
                    </div>
                  </div>
                )}

                {hasPermissions(
                  ["view_invoices_touroperatorinvoice"],
                  "some"
                ) && (
                  <div className="list-group">
                    <div className="list-group-item">
                      <div className="row">
                        <div className="col">{t("Invoices")}</div>
                        <div className="col-auto pr-4">
                          <Expand color="white" target="#general-invoices" />
                        </div>
                      </div>
                    </div>
                    <div className="collapse" id="general-invoices">
                      {hasPermissions([
                        "view_invoices_touroperatorinvoice",
                      ]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("tourOperatorInvoiceList")}>
                          {t("TO Invoice List")}
                        </SidebarLink>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            {hasPermissions(
              [
                "view_users_user",
                "view_core_appsettings",
                "view_core_ownercompany",
                "view_fees_feecategory",
                "view_currencies_currency",
                "view_currencies_exchangerate",
              ],
              "some"
            ) && (
              <div className="sidebar-items">
                <h5>{t("Admin")}</h5>
                {hasPermissions(
                  [
                    "view_users_user",
                    "view_core_appsettings",
                    "view_core_ownercompany",
                  ],
                  "some"
                ) && (
                  <div className="list-group">
                    <div className="list-group-item">
                      <div className="row">
                        <div className="col">{t("Admin")}</div>
                        <div className="col-auto pr-4">
                          <Expand color="white" target="#admin-admin" />
                        </div>
                      </div>
                    </div>
                    <div className="collapse" id="admin-admin">
                      {hasPermissions(["view_users_user"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("userList")}>
                          {t("Users")}
                        </SidebarLink>
                      )}
                      {hasPermissions(["view_core_appsettings"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("appSettingsDetail")}>
                          {t("App Settings")}
                        </SidebarLink>
                      )}
                      {hasPermissions(["view_core_ownercompany"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("ownerCompanyDetail")}>
                          {t("Owner Company Details")}
                        </SidebarLink>
                      )}
                    </div>
                  </div>
                )}

                {hasPermissions(
                  [
                    "view_fees_feecategory",
                    "view_currencies_currency",
                    "view_currencies_exchangerate",
                  ],
                  "some"
                ) && (
                  <div className="list-group">
                    <div className="list-group-item">
                      <div className="row">
                        <div className="col">{t("Finance")}</div>
                        <div className="col-auto pr-4">
                          <Expand color="white" target="#admin-finance" />
                        </div>
                      </div>
                    </div>
                    <div className="collapse" id="admin-finance">
                      {hasPermissions(["view_fees_feecategory"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("feeCategoryList")}>
                          {t("Fee Categories")}
                        </SidebarLink>
                      )}
                      {hasPermissions(["view_currencies_currency"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("currencyList")}>
                          {t("Currencies")}
                        </SidebarLink>
                      )}
                      {hasPermissions(["view_currencies_exchangerate"]) && (
                        <SidebarLink
                          className="list-group-item sub-item"
                          to={reverse("exchangeRateList")}>
                          {t("Exchange Rates")}
                        </SidebarLink>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="list-group list-group-flush"></div>
          </div>

          <div id="page-content-wrapper">
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary shadow">
              <div className="container-fluid">
                <button
                  className="btn mr-3"
                  id="menu-toggle"
                  onClick={this.toggleSidebar}>
                  <Icon name="bars" size="xl" color="white" />
                </button>

                <form className="d-flex w-50">
                  <input
                    className="form-control mr-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                </form>

                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        id="auth-dropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-expanded="false">
                        {authInfo.name ? authInfo.name : authInfo.email}
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="auth-dropdown">
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => handleLogout()}>
                            {this.props.t("Logout")}
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>

            <NotificationContainer />

            <div className="main-content container-fluid">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(withRouter(MainLayout))

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
}
