import React from "react"
import PropTypes from "prop-types"

import TicketDefaultForm from "apps/tickets/TicketDefault/TicketDefaultForm"
import { reverse } from "utils/urls"

export default function TicketDefaultUpdate(props) {
  return (
    <TicketDefaultForm
      {...props}
      isUpdate
      actionEndpoint={`/tickets/ticket-defaults/${props.match.params.id}/`}
      successUrl={reverse("ticketDefaultDetail", { id: props.match.params.id })}
    />
  )
}

TicketDefaultUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
