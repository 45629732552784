import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { startCase, isObject } from "lodash"

import { getBoolDisplay } from "../utils"
import Button from "components/Button/Button"
import { apiGET } from "utils/api"
import { reverse } from "utils/urls"

export default function History(props) {
  const params = props.match.params
  const [history, setHistory] = useState({
    data: [],
    columns: [],
  })

  const { t } = useTranslation()

  useEffect(
    () =>
      apiGET({
        path: `/${params.model}/${params.id}/history/`,
        onSuccess: response => {
          if (response.length) {
            const dataColumns = Object.keys(
              response[response.length - 1]
            ).filter(f => !["created", "updated", "user"].includes(f))

            setHistory({
              data: response,
              columns: ["created", "user", ...dataColumns],
            })
          } else {
            setHistory({
              data: [],
              columns: [],
            })
          }
        },
        onError: () => props.history.push(reverse("home")),
      }),
    [params.model, params.id, props.history]
  )

  const renderCol = (col, entry) => {
    if (col === "created") {
      return entry.updated
    } else if (col === "user") {
      if (entry[col]) {
        return entry[col].email
      } else {
        return t("System")
      }
    } else if (entry[col] === "True") {
      return getBoolDisplay(true)
    } else if (entry[col] === "False") {
      return getBoolDisplay(false)
    } else if (entry[col] === "None") {
      return "-"
    } else if (isObject(entry[col])) {
      return entry[col].name || "-"
    }

    return entry[col]
  }

  return (
    <div>
      <h2>{t("History")}</h2>
      {history.data.length ? (
        <div className="my-2">
          <table className="table">
            <thead>
              <tr>
                {history.columns.map((col, i) => (
                  <th key={`th-${i}`}>{startCase(col)}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {history.data.map((entry, i) => (
                <tr key={`tr-${i}`}>
                  {history.columns.map((col, j) => (
                    <td key={`td-${i}-${j}`}>{renderCol(col, entry)}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>{t("No history recorded for selected object.")}</div>
      )}
      <div className="my-2">
        <Button
          icon="chevron-left-double"
          onClick={() => props.history.goBack()}>
          {t("Back")}
        </Button>
      </div>
    </div>
  )
}

History.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      model: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
