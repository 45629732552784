import React from "react"
import { useTranslation } from "react-i18next"

import InfantBookingFareForm from "apps/fares/InfantBookingFare/InfantBookingFareForm"
import { reverse } from "utils/urls"

export default function TourOperatorInfantBookingFareCreate(props) {
  const { t } = useTranslation()

  return (
    <InfantBookingFareForm
      {...props}
      actionEndpoint="/fares/tour-operator-infant-fares/"
      label={t("Add tour operator infant booking fare")}
      successMessage={t(
        "Tour Operator Infant Booking Fare was successfully saved."
      )}
      successUrl={reverse("tourOperatorInfantBookingFareList")}
    />
  )
}
