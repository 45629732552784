import React, { useState } from "react"
import PropTypes from "prop-types"
import { Popover as TinyPopover } from "react-tiny-popover"

import Card from "components/Card/Card"

import styles from "./Popover.module.scss"

export default function Popover({
  trigger,
  header,
  headerClassName,
  content,
  contentClassName,
  children,
  ...rest
}) {
  const [isOpen, setIsOpen] = useState(false)

  const getTrigger = () => {
    switch (trigger) {
      case "click":
        return {
          onClick: () => setIsOpen(!isOpen),
        }
      case "hover":
        return {
          onMouseEnter: () => setIsOpen(true),
          onMouseLeave: () => setIsOpen(false),
        }
      default:
        throw new Error()
    }
  }

  return (
    <TinyPopover
      isOpen={isOpen}
      positions={["top", "bottom", "left", "right"]}
      onClickOutside={() => setIsOpen(false)}
      padding={5}
      containerStyle={{
        // there are some elements with high z-index on some pages so this has to be set
        zIndex: 9999,
      }}
      content={
        <div className={styles["popover-container"]}>
          {header && (
            <div className={`row ${headerClassName}`}>
              <div className="col-12">
                <div
                  className={`px-3 py-2 bg-gray-light ${styles["header-container"]}`}>
                  <h4 className="mb-0 text-center">{header}</h4>
                </div>
              </div>
            </div>
          )}
          <Card className={`text-nowrap ${contentClassName}`}>{content}</Card>
        </div>
      }
      {...rest}>
      {React.cloneElement(children, { ...getTrigger() })}
    </TinyPopover>
  )
}

Popover.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  trigger: PropTypes.oneOf(["click", "hover"]),
  header: PropTypes.string,
  headerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
}

Popover.defaultProps = {
  trigger: "click",
  header: null,
  headerClassName: "",
  contentClassName: "",
}
