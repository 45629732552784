import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import it from "./locale/it"
import fr from "./locale/fr"

const resources = {
  it: { translation: it },
  fr: { translation: fr },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,

    lng: "en",
    fallbackLng: "en",
    supportedLngs: ["en", "it", "fr"],

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
