import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { handleLogout } from "apps/auth/auth"
import Button from "components/Button/Button"
import { Form, FormField } from "components/Form"
import { apiPOST } from "utils/api"
import { reverse } from "utils/urls"

export default function PasswordReset(props) {
  const { t } = useTranslation()

  return (
    <div className="container vh-100 d-flex align-items-center">
      <div className="row h-75">
        <div className="col-12 col-md-6 offset-md-3">
          <Form
            endpoint={`/auth/users/password-reset/${props.match.params.uid}/${props.match.params.token}/`}
            apiFunction={apiPOST}
            successUrl={reverse("login")}
            successMessage={t("Password was successfully reset.")}
            successFunction={() => handleLogout()}
            title={t("Reset password")}
            showBack={false}
            showSubmit={false}>
            <div className="my-2">
              <FormField
                name="newPassword"
                fieldType="input"
                type="password"
                label={t("New password")}
                required
              />
            </div>
            <div className="my-2">
              <FormField
                name="reNewPassword"
                fieldType="input"
                type="password"
                label={t("New password (again)")}
                required
              />
            </div>
            <div className="mt-4">
              <Button icon="save" submit>
                {t("Save")}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

PasswordReset.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      uid: PropTypes.string,
      token: PropTypes.string,
    }),
  }).isRequired,
}
