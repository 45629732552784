import React from "react"

import { useTranslation } from "react-i18next"

import Detail from "components/GenericViews/Detail"
import { reverse } from "utils/urls"

export default function OwnerCompanyDetail(props) {
  const { t } = useTranslation()

  return (
    <Detail
      title={t("Owner company details")}
      detailEndpoint={`/owner-company/detail/`}
      listRoute={reverse("home")}
      changePermission={["change_core_ownercompany"]}
      changeRoute={reverse("ownerCompanyUpdate")}
      fields={[
        [
          {
            label: t("Name"),
            key: "name",
            size: "col-4",
          },
          {
            label: t("General Information"),
            key: "note",
            type: "multiLineText",
            size: "col-4",
          },
        ],
        [
          {
            label: t("VAT Identification Number"),
            key: "vatNumber",
            size: "col-4",
          },
          {
            label: t("Domiciliation"),
            key: "domiciliation",
            type: "singleSelect",
            size: "col-4",
          },
        ],
        [
          {
            label: t("Bank Account Number"),
            key: "bankAccount",
            type: "multiLineText",
            size: "col-4",
          },
          {
            label: t("Vat Amount"),
            key: "vat",
            size: "col-4",
          },
        ],
        [
          {
            label: t("Fiscal Year Start"),
            key: "fiscalYearStart",
            size: "col-4",
          },
        ],
      ]}
    />
  )
}
