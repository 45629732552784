import React from "react"
import { useTranslation } from "react-i18next"

import FeeForm from "apps/fees/Fee/FeeForm"
import { reverse } from "utils/urls"

export default function TourOperatorFeeCreate(props) {
  const { t } = useTranslation()

  return (
    <FeeForm
      {...props}
      actionEndpoint="/fees/tour-operator-fees/"
      label={t("Add tour operator fee")}
      successMessage={t("Tour Operator Fee was successfully saved.")}
      successUrl={reverse("tourOperatorFeeList")}
      isTourOperator
    />
  )
}
