import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import ItemsInfantBookingFareFormSet from "apps/fares/InfantBookingFare/ItemsInfantBookingFareFormSet"
import { Form, FormField } from "components/Form"
import { apiPUT, apiGET, apiPOST } from "utils/api"

export default function InfantBookingFareForm(props) {
  const [formData, setFormData] = useState({})

  const { t } = useTranslation()

  useEffect(
    () =>
      props.isUpdate
        ? apiGET({
            path: props.detailEndpoint,
            onSuccess: response => setFormData(response),
            onError: () => props.history.push(props.errorUrl),
          })
        : () => {},
    [
      props.isUpdate,
      props.match.params.id,
      props.history,
      props.detailEndpoint,
      props.errorUrl,
    ]
  )

  return (
    <div>
      <Form
        endpoint={props.actionEndpoint}
        apiFunction={props.isUpdate ? apiPUT : apiPOST}
        defaultValues={formData}
        successUrl={props.successUrl}
        successMessage={props.successMessage}
        title={props.label}>
        <div>
          <h5>{t("Infant Booking Fare creation")}</h5>
          <div className="row">
            <div className="col-4">
              <FormField
                name="airline"
                fieldType="asyncSelect"
                label={t("Carrier")}
                endpoint="/airlines/"
                icon="airplane"
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <FormField
                name={["startDate", "endDate"]}
                fieldType="dateRange"
                label={t("Start date - End date")}
                required
              />
            </div>
            <div className="col-4">
              <FormField
                name="value"
                fieldType="price"
                label={t("Value")}
                required
              />
            </div>
          </div>
        </div>
        <div className="mt-4 mb-5">
          <h5>{t("Routing Specific")}</h5>
          <ItemsInfantBookingFareFormSet />
        </div>
      </Form>
    </div>
  )
}

InfantBookingFareForm.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string,
    }),
  }).isRequired,
  isUpdate: PropTypes.bool,
  actionEndpoint: PropTypes.string.isRequired,
  detailEndpoint: PropTypes.string,
  errorUrl: PropTypes.string,
  successUrl: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
}

InfantBookingFareForm.defaultProps = {
  isUpdate: false,
  detailEndpoint: "",
  errorUrl: "",
}
