import React from "react"
import { Link, Redirect } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { isLoggedIn, setAuthCookie } from "apps/auth/auth"
import Button from "components/Button/Button"
import { Form, FormField } from "components/Form"
import { apiPOST } from "utils/api"
import { reverse } from "utils/urls"

export default function Login(props) {
  const { t } = useTranslation()

  return isLoggedIn() ? (
    <Redirect to={reverse("home")} />
  ) : (
    <div className="container vh-100 d-flex align-items-center">
      <div className="row h-75">
        <div className="col-12 col-md-6 offset-md-3">
          <h1 className="mb-3">{t("Log in")} &mdash; Sedes</h1>
          <Form
            endpoint={`/auth/jwt/create/`}
            apiFunction={apiPOST}
            successUrl={reverse("home")}
            successFunction={response => setAuthCookie(response.access)}
            showBack={false}
            showSubmit={false}>
            <div className="row">
              <div className="col-12">
                <FormField
                  name="email"
                  fieldType="input"
                  type="text"
                  label={t("Email")}
                  required
                />
              </div>
              <div className="col-12">
                <FormField
                  name="password"
                  fieldType="input"
                  type="password"
                  label={t("Password")}
                  required
                />
              </div>
              <div className="col-auto">
                <Link to={reverse("forgotPassword")}>
                  <p>{t("Forgot password?")}</p>
                </Link>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-auto">
                    <Button submit>{t("Login")}</Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}
