import React from "react"
import PropTypes from "prop-types"

import { FormField } from "components/Form"
import Icon from "components/Icon/Icon"

import styles from "./AirportSelectField.module.scss"

export default function AirportSelectField({ withCities, ...rest }) {
  return (
    <FormField
      {...rest}
      fieldType="asyncSelect"
      endpoint={withCities ? "/airports/cities/" : "/airports/"}
      data-select-type="airports"
      formatOptionLabel={(option, optionState) => {
        if (optionState.selectValue.includes(option)) {
          return option.displayName
        }

        const isAirport = !withCities || option.type === "airport"
        return (
          <div className={`${styles.container} d-flex`}>
            <div className={styles["icon-container"]}>
              <Icon
                name={isAirport ? "airplane-horizontal" : "city"}
                size="md"
              />
            </div>
            <div className={styles["code-iata-container"]}>
              <b className="text-magenta">
                {isAirport ? option.codeIata : option.code}
              </b>
            </div>
            <div className={styles["dash-container"]}>
              {isAirport || option.code ? "-" : ""}
            </div>
            <div className={styles["name-container"]}>
              <div>
                <b>{isAirport ? option.city.name : option.name}</b>
              </div>
              <div>
                {isAirport
                  ? option.city.country.displayName
                  : option.country.displayName}
              </div>
            </div>
            <div className={styles["code-icao-container"]}>
              {isAirport && <b>[{option.codeIcao}]</b>}
            </div>
          </div>
        )
      }}
    />
  )
}

AirportSelectField.propTypes = {
  withCities: PropTypes.bool,
}

AirportSelectField.defaultProps = {
  withCities: false,
}
