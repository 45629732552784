import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

import Delete from "components/GenericViews/Delete"
import { reverse } from "utils/urls"

export default function AirlineFeeDelete(props) {
  const { t } = useTranslation()

  return (
    <Delete
      modelName={t("Airline Fee")}
      endpoint={`/fees/airline-fees/${props.match.params.id}/`}
      successRoute={reverse("airlineFeeList")}
      errorRoute={reverse("airlineFeeList")}
    />
  )
}

AirlineFeeDelete.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
