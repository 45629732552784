import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { getAppInfo } from "apps/core/utils"
import Icon from "components/Icon/Icon"
import { FormField } from "components/Form"

export default function PetCPassenger({ data, index, remove }) {
  const { t } = useTranslation()

  const appInfo = getAppInfo()

  return (
    <div className="row">
      <FormField
        fieldType="input"
        type="hidden"
        name={`passengers[${index}].passengerType`}
        label=""
        defaultValue="PETC"
      />
      <FormField
        fieldType="input"
        type="hidden"
        name={`passengers[${index}].passengerId`}
        label=""
        defaultValue={data.id || data.passengerId}
      />
      <div className="col-12 col-xl-4">
        <div className="row">
          <div className="col-1 my-auto text-center">
            <b>{`${index + 1}.`}</b>
          </div>
          <div className="col-6">
            <FormField
              fieldType="input"
              type="text"
              name={`passengers[${index}].familyReference`}
              label={t("Family reference")}
              defaultValue={data.familyReference}
              required
            />
          </div>
          <div className="col-5">
            <FormField
              fieldType="select"
              name={`passengers[${index}].type`}
              label={t("Passenger type")}
              options={[{ id: "PETC", displayName: t("PetC") }]}
              disabled
              defaultValue={{ id: "PETC", displayName: t("PetC") }}
            />
          </div>
        </div>
      </div>
      <div className="col-12 col-xl-8">
        <div className="row">
          <div className="col-3">
            <FormField
              fieldType="select"
              name={`passengers[${index}].petType`}
              label={t("Pet type")}
              options={[
                { id: "DOG", displayName: t("Dog") },
                { id: "CAT", displayName: t("Cat") },
              ]}
              required
              defaultValue={data.petType}
            />
          </div>
          <div className="col-3">
            <FormField
              fieldType="input"
              type="number"
              min={0.01}
              step={0.01}
              name={`passengers[${index}].weight`}
              label={t("Weight")}
              required
              defaultValue={data.weight}
              inputGroupAppend={
                <span className="input-group-text">
                  {appInfo.massUnit.toLowerCase()}
                </span>
              }
            />
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-5">
                <FormField
                  fieldType="input"
                  type="text"
                  name={`passengers[${index}].description`}
                  label={t("Description")}
                  defaultValue={data.description}
                />
              </div>
              <div className="offset-5 col-auto my-auto text-center pt-1">
                <Icon
                  color="danger"
                  name="remove"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    remove(index)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

PetCPassenger.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]).isRequired,
  remove: PropTypes.func.isRequired,
}
