import React from "react"

import TourOperatorForm from "apps/tourOperators/TourOperator/TourOperatorForm"
import { reverse } from "utils/urls"

export default function TourOperatorCreate(props) {
  return (
    <TourOperatorForm
      {...props}
      actionEndpoint="/tour-operators/"
      successUrl={reverse("tourOperatorList")}
    />
  )
}
