import React from "react"
import { useTranslation } from "react-i18next"

import InfantBookingFareForm from "apps/fares/InfantBookingFare/InfantBookingFareForm"
import { reverse } from "utils/urls"

export default function AirlineInfantBookingFareCreate(props) {
  const { t } = useTranslation()

  return (
    <InfantBookingFareForm
      {...props}
      actionEndpoint="/fares/airline-infant-fares/"
      label={t("Add airline infant booking fare")}
      successMessage={t("Airline Infant Booking Fare was successfully saved.")}
      successUrl={reverse("airlineInfantBookingFareList")}
    />
  )
}
