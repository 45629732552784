import React from "react"
import { useFormContext, useFieldArray } from "react-hook-form"
import { useTranslation } from "react-i18next"

import Button from "components/Button/Button"
import { FormField } from "components/Form"

export default function ItemsInfantBookingFareFormSet(props) {
  const { getValues, setValue, control, watch } = useFormContext()
  const { fields, remove } = useFieldArray({
    control,
    name: "items",
    keyName: "fieldArrayId",
  })
  const fares = watch("items", [])

  const { t } = useTranslation()

  const addFare = () => {
    const newFares = [...(getValues().items || [])]
    newFares.push({})
    setValue("items", newFares)
  }

  return (
    <>
      {fields.map((field, i) => (
        <div
          className={`row ${i !== 0 ? "mt-3" : ""}`}
          key={field.fieldArrayId}>
          <FormField
            name={`items[${i}].item_id`}
            fieldType="input"
            containerClassName="mb-0 mt-0"
            type="hidden"
            label=""
            defaultValue={field.id || field.item_id}
          />
          <div className="col-4">
            <FormField
              name={`items[${i}].value`}
              currencyName={`items[${i}].currency`}
              fieldType="price"
              label={t("Value")}
              defaultValue={field.value}
              currencyDefaultValue={field.currency}
              required
            />
          </div>
          <div className="col-4">
            <FormField
              name={`items[${i}].routing`}
              fieldType="asyncSelect"
              label={t("Routing")}
              endpoint="/routings/"
              defaultValue={field.routing}
              filterOption={
                fares.length
                  ? option =>
                      !fares.map(fare => fare.routing.id).includes(option.value)
                  : undefined
              }
              required
            />
          </div>
          <div className="col-auto my-auto">
            <Button
              type="danger"
              outline
              onClick={() => {
                remove(i)
              }}>
              {t("Delete")}
            </Button>
          </div>
          <div className="col-auto my-auto">
            <Button type="success" onClick={addFare}>
              {t("Add")}
            </Button>
          </div>
        </div>
      ))}
      {!fields.length && (
        <div className="row mt-4">
          <div className="col-auto">
            <Button type="success" onClick={addFare}>
              {t("Add")}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}
