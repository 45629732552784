import React from "react"
import PropTypes from "prop-types"
import { useFormContext, useFieldArray } from "react-hook-form"
import { useTranslation } from "react-i18next"

import AdultPassenger from "./AdultPassenger"
import ChildPassenger from "./ChildPassenger"
import InfantPassenger from "./InfantPassenger"
import PetCPassenger from "./PetCPassenger"
import Button from "components/Button/Button"

import "./PassengersFormSet.scss"

export default function PassengersFormSet(props) {
  const { control, getValues, setValue } = useFormContext()
  const { fields, remove } = useFieldArray({
    control,
    name: "passengers",
    keyName: "fieldArrayId",
  })
  const { t } = useTranslation()

  const append = type => {
    const passengers = [...(getValues().passengers || [])]
    passengers.push({ passengerType: type })
    setValue("passengers", passengers)
  }

  return (
    <>
      {fields.map((field, i) => (
        <div className="row" key={field.fieldArrayId}>
          <div className="col-12">
            {field.passengerType === "ADULT" && (
              <AdultPassenger data={field} index={i} remove={remove} />
            )}
            {field.passengerType === "CHILD" && (
              <ChildPassenger
                data={field}
                index={i}
                remove={remove}
                maxChildAge={props.maxChildAge}
                operatingDates={props.operatingDates}
              />
            )}
            {field.passengerType === "INFANT" && (
              <InfantPassenger
                data={field}
                index={i}
                remove={remove}
                operatingDates={props.operatingDates}
              />
            )}
            {field.passengerType === "PETC" && (
              <PetCPassenger data={field} index={i} remove={remove} />
            )}
          </div>
        </div>
      ))}
      <div className="row mt-3">
        <div className="col-auto add-buttons">
          <Button type="secondary" icon="plus" onClick={() => append("ADULT")}>
            {t("Add ADT")}
          </Button>
          <Button
            type="secondary"
            className="ml-4"
            icon="plus"
            onClick={() => append("CHILD")}>
            {t("Add CHD")}
          </Button>
          <Button
            type="secondary"
            className="ml-4"
            icon="plus"
            onClick={() => append("INFANT")}>
            {t("Add INF")}
          </Button>
          <Button
            type="secondary"
            className="ml-4"
            icon="plus"
            onClick={() => append("PETC")}>
            {t("Add PetC")}
          </Button>
        </div>
      </div>
    </>
  )
}

PassengersFormSet.propTypes = {
  maxChildAge: PropTypes.number.isRequired,
  operatingDates: PropTypes.array.isRequired,
}
