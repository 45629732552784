import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

import Icon from "components/Icon/Icon"
import Popover from "components/Popover/Popover"
import { SubTable, TableWithFilters } from "components/Table"
import { DATE_FORMAT } from "utils/constants"

const getConfig = (t, tableConfig, actionsColumn) => ({
  ...tableConfig,
  columns: [
    {
      Header: t("Airline"),
      id: "airline",
      accessor: f => f.active.airline.displayName,
    },
    {
      Header: t("Period"),
      id: "period",
      accessor: f => `${f.active.startDate} - ${f.active.endDate}`,
    },
    {
      Header: t("Value"),
      id: "value",
      accessor: f => f.active.fare,
      className: "text-right",
    },
    actionsColumn,
  ],
  SubComponent: row => (
    <SubTable
      data={row.original.fares}
      columns={[
        {
          Header: t("Period"),
          accessor: "startDate",
          Cell: subRow =>
            subRow.original.id === row.original.active.id ? (
              <span className="text-danger">{`${subRow.original.startDate} - ${subRow.original.endDate}`}</span>
            ) : (
              <>{`${subRow.original.startDate} - ${subRow.original.endDate}`}</>
            ),
          sortMethod: (a, b, desc) => {
            a = moment(a, DATE_FORMAT)
            b = moment(b, DATE_FORMAT)

            if (a.isBefore(b)) {
              return 1
            } else if (a.isAfter(b)) {
              return -1
            }

            return 0
          },
          parentColumnIndex: 1,
        },
        {
          Header: t("Value"),
          accessor: "value",
          className: "text-right",
          Cell: subRow =>
            subRow.original.items.length ? (
              <>
                <span>{subRow.original.fare}</span>
                <Popover
                  header={t("Routing Specific")}
                  trigger="hover"
                  content={
                    <div>
                      <div className="row text-danger">
                        <div className="col-6 text-center">{t("General")}</div>
                        <div className="col-6 text-center">
                          {subRow.original.fare}
                        </div>
                      </div>
                      {subRow.original.items.map((fare, i) => (
                        <div className="row" key={i}>
                          <div className="col-6 text-center">
                            {fare.routing.displayName}
                          </div>
                          <div className="col-6 text-center">{fare.fare}</div>
                        </div>
                      ))}
                    </div>
                  }>
                  <span>
                    <Icon name="info-circle" size="sm" className="ml-2 mb-1" />
                  </span>
                </Popover>
              </>
            ) : (
              subRow.original.fare
            ),
          parentColumnIndex: 2,
        },
        actionsColumn,
      ]}
      diagonalColumnWidth={[0]}
    />
  ),
  filters: [
    [
      {
        size: "col",
        children: [
          [
            {
              name: "airline",
              fieldType: "asyncSelect",
              endpoint: "/airlines/",
              icon: "airplane",
              label: t("Carrier"),
              size: "col-3",
            },
            {
              fieldType: "applyButton",
              size: "col-auto mt-2",
            },
            {
              fieldType: "clearButton",
              size: "col-auto mt-2",
            },
          ],
        ],
      },
    ],
  ],
})

export default function InfantBookingFareList(props) {
  const { t } = useTranslation()

  return (
    <TableWithFilters
      config={getConfig(t, props.tableConfig, props.actionsColumn)}
    />
  )
}

InfantBookingFareList.propTypes = {
  tableConfig: PropTypes.object,
  actionsColumn: PropTypes.object,
}

InfantBookingFareList.defaultProps = {
  tableConfig: {},
  actionsColumn: null,
}
