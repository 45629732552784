import React from "react"

import { notificationRef } from "components/Layout/containers/Notification"

import "./Notification.scss"

class Notification extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      notifications: [],
    }
  }

  notify = (type, message) => {
    const types = ["success", "error", "info", "warning"]

    if (!types.includes(type)) {
      throw new TypeError(type + " is an invalid Notification type")
    }

    this.setState({
      notifications: this.state.notifications.concat({ type, message }),
    })
  }

  dismissAllNotifications = () => this.setState({ notifications: [] })

  dismissMessage = index => {
    const { notifications } = this.state
    notifications.splice(index, 1)
    this.setState({ notifications })
  }

  render() {
    const { notifications } = this.state

    return (
      <div className="notifications">
        {notifications.map((notification, index) => (
          <div
            key={index}
            className={`notification alert alert-${notification.type}`}
            role="alert">
            {notification.message}
            <button
              type="button"
              className="btn btn-sm dismiss-message"
              aria-label="Close"
              onClick={event => this.dismissMessage(index)}>
              x
            </button>
          </div>
        ))}
      </div>
    )
  }
}

export const _notify = (type, message) => {
  if (notificationRef && notificationRef.current) {
    notificationRef.current.notify(type, message)
  }
}

export const _dismissAllNotifications = () => {
  if (notificationRef && notificationRef.current) {
    notificationRef.current.dismissAllNotifications()
  }
}

export default Notification
