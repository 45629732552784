import { isObject } from "lodash"

import airlinesRoutes from "utils/routes/airlines"
import airportsRoutes from "utils/routes/airports"
import authRoutes from "utils/routes/auth"
import bookingsRoutes from "utils/routes/bookings"
import coreRoutes from "utils/routes/core"
import currenciesRoutes from "utils/routes/currencies"
import faresRoutes from "utils/routes/fares"
import feesRoutes from "utils/routes/fees"
import flightsRoutes from "utils/routes/flights"
import invoicesRoutes from "utils/routes/invoices"
import routingsRoutes from "utils/routes/routings"
import sheetsRoutes from "utils/routes/sheets"
import ticketsRoutes from "utils/routes/tickets"
import tourOperatorsRoutes from "utils/routes/tourOperators"

export const reverse = (routeObj, baseParams, queryParams) => {
  if (typeof routeObj === "string") {
    routeObj = {
      name: routeObj,
    }

    if (baseParams) {
      routeObj.baseParams = baseParams
    }

    if (queryParams) {
      routeObj.queryParams = queryParams
    }
  }

  const routes = {
    ...airlinesRoutes,
    ...airportsRoutes,
    ...authRoutes,
    ...bookingsRoutes,
    ...coreRoutes,
    ...currenciesRoutes,
    ...faresRoutes,
    ...feesRoutes,
    ...flightsRoutes,
    ...invoicesRoutes,
    ...routingsRoutes,
    ...sheetsRoutes,
    ...ticketsRoutes,
    ...tourOperatorsRoutes,
  }

  let routing = routes[routeObj.name]

  if (routeObj.baseParams) {
    Object.keys(routeObj.baseParams).forEach(param => {
      routing = routing.replace(":" + param, routeObj.baseParams[param])
    })
  }

  if (routeObj.queryParams) {
    Object.entries(routeObj.queryParams).forEach(([key, value], i) => {
      if (isObject(value)) {
        value = JSON.stringify(value)
      }
      routing = `${routing}${i === 0 ? "?" : "&"}${key}=${value}`
    })
  }

  return routing
}

export const route = (path, component, permissions = [], exact = false) => {
  return { path: reverse(path), component, permissions, exact }
}

export const buildRouteFromLocation = l =>
  encodeURIComponent(l.pathname + l.search + l.hash)

export const getPathnameFromString = string => {
  const url = document.createElement("a")
  url.href = string
  return url.pathname
}
