import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { uniqueId } from "lodash"

import BSTooltip from "assets/vendor/bootstrap/js/tooltip"

export default function Tooltip(props) {
  const elementId = `tooltip-${uniqueId()}`

  useEffect(() => {
    const el = [].slice.call(
      document.querySelectorAll(`[data-id="${elementId}"]`)
    )[0]
    const tooltip = new BSTooltip(el, { boundary: "window" })

    return () => {
      if (tooltip.tip) {
        tooltip.tip.remove()
      }
      if (tooltip._popper) {
        tooltip._popper.destroy()
      }
    }
  }, [elementId])

  return React.cloneElement(props.children, {
    "data-id": elementId,
    "data-bs-toggle": "tooltip",
    "data-placement": props.placement,
    title: props.title,
  })
}

Tooltip.propTypes = {
  placement: PropTypes.oneOf(["auto", "top", "bottom", "left", "right"]),
  title: PropTypes.string.isRequired,
}

Tooltip.defaultProps = {
  placement: "top",
}
