import {
  FlightSeriesDelete,
  FlightSeriesDetail,
  FlightSeriesList,
} from "apps/flights/FlightSeries"
import SegmentObjectsList from "./Segment/SegmentObjectsList"
import { FlightCreate, FlightDetail } from "apps/flights/Flight"
import { route } from "utils/urls"

export const PROTECTED_ROUTES = [
  route("segmentObjectsList", SegmentObjectsList, ["view_flights_flight"]),
  route("flightSeriesDelete", FlightSeriesDelete, ["delete_flights_flight"]),
  route("flightSeriesDetail", FlightSeriesDetail, ["view_flights_flight"]),
  route("flightSeriesList", FlightSeriesList, ["view_flights_flight"]),
  route("flightCreate", FlightCreate, ["add_flights_flight"]),
  route("flightDetail", FlightDetail, ["view_flights_flight"]),
]
