import React from "react"
import PropTypes from "prop-types"
import { useFormContext, useWatch } from "react-hook-form"

import Button from "components/Button/Button"

export default function GroupFineTypeSelect({ types, name, labels }) {
  const { setValue } = useFormContext()
  const value = useWatch({ name, defaultValue: types[0] })

  return (
    <div className="btn-group d-flex" role="group">
      <Button
        className="w-100 text-nowrap"
        outline={value !== types[0]}
        onClick={() => setValue(name, types[0])}>
        {labels[0]}
      </Button>
      <Button
        className="w-100 text-nowrap"
        outline={value !== types[1]}
        onClick={() => setValue(name, types[1])}>
        {labels[1]}
      </Button>
    </div>
  )
}

GroupFineTypeSelect.propTypes = {
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
}
