import React from "react"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { hasPermissions } from "apps/auth/auth"
import Button from "components/Button/Button"
import { SubTable, TableWithFilters } from "components/Table"
import { DATE_FORMAT } from "utils/constants"
import { reverse } from "utils/urls"

const getConfig = t => ({
  endpoint: "/currencies/exchange-rates",
  title: t("Exchange Rates"),
  tableName: "exchange-rates-table-config",
  columns: [
    {
      Header: t("From"),
      id: "fromCurrency",
      accessor: f => f.active.fromCurrency,
    },
    {
      Header: t("To"),
      id: "toCurrency",
      accessor: f => f.active.toCurrency,
    },
    {
      Header: t("Period"),
      id: "period",
      accessor: f =>
        `${f.active.startDate} - ${f.active.endDate || t("Unlimited")}`,
      sortable: false,
    },
    {
      Header: t("Rate"),
      id: "rate",
      accessor: f => f.active.rate,
      sortable: false,
    },
  ],
  SubComponent: row => (
    <SubTable
      data={row.original.rates}
      columns={[
        {
          Header: t("Period"),
          accessor: "startDate",
          Cell: subRow =>
            subRow.original.id === row.original.active.id ? (
              <span className="text-danger">{`${subRow.original.startDate} - ${
                subRow.original.endDate || t("Unlimited")
              }`}</span>
            ) : (
              <>{`${subRow.original.startDate} - ${
                subRow.original.endDate || t("Unlimited")
              }`}</>
            ),
          sortMethod: (a, b, desc) => {
            a = moment(a, DATE_FORMAT)
            b = moment(b, DATE_FORMAT)

            if (a.isBefore(b)) {
              return 1
            } else if (a.isAfter(b)) {
              return -1
            }

            return 0
          },
          parentColumnIndex: 2,
        },
        {
          Header: t("Rate"),
          accessor: "rate",
        },
      ]}
      diagonalColumnWidth={[0, 1]}
    />
  ),
  filters: [],
  buttons: (
    <>
      {hasPermissions(["add_currencies_exchangerate"]) && (
        <Button icon="plus" to={reverse("exchangeRateCreate")}>
          {t("Add Exchange Rate")}
        </Button>
      )}
    </>
  ),
})

export default function AirportList(props) {
  const { t } = useTranslation()

  return <TableWithFilters config={getConfig(t)} />
}
