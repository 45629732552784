import Home from "apps/core/Home/Home"
import History from "apps/core/History/History"
import AppSettingsDetail from "apps/core/AppSettings/AppSettingsDetail"
import AppSettingsUpdate from "apps/core/AppSettings/AppSettingsUpdate"
import {
  CityCreate,
  CityUpdate,
  CityDelete,
  CityDetail,
  CityList,
} from "apps/core/City"
import CountryList from "apps/core/Country/CountryList"
import {
  DateRangeCreate,
  DateRangeUpdate,
  DateRangeDelete,
  DateRangeDetail,
  DateRangeList,
} from "apps/core/DateRange"
import OwnerCompanyUpdate from "apps/core/OwnerCompany/OwnerCompanyUpdate"
import OwnerCompanyDetail from "apps/core/OwnerCompany/OwnerCompanyDetail"
import { route } from "utils/urls"

export const PROTECTED_ROUTES = [
  route("home", Home, [], true),
  route("appSettingsUpdate", AppSettingsUpdate, ["change_core_appsettings"]),
  route("appSettingsDetail", AppSettingsDetail, ["view_core_appsettings"]),
  route("cityCreate", CityCreate, ["add_core_city"]),
  route("cityUpdate", CityUpdate, ["change_core_city"]),
  route("cityDelete", CityDelete, ["delete_core_city"]),
  route("cityDetail", CityDetail, ["view_core_city"]),
  route("cityList", CityList, ["view_core_city"]),
  route("countryList", CountryList, ["view_core_country"]),
  route("dateRangeCreate", DateRangeCreate, ["add_core_daterange"]),
  route("dateRangeUpdate", DateRangeUpdate, ["change_core_daterange"]),
  route("dateRangeDelete", DateRangeDelete, ["delete_core_daterange"]),
  route("dateRangeDetail", DateRangeDetail, ["view_core_daterange"]),
  route("dateRangeList", DateRangeList, ["view_core_daterange"]),
  route("ownerCompanyUpdate", OwnerCompanyUpdate, ["change_core_ownercompany"]),
  route("ownerCompanyDetail", OwnerCompanyDetail, ["view_core_ownercompany"]),
  route("history", History, ["view_history"]),
]
