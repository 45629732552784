import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { isEqual } from "lodash"
import ReactTable from "react-table-6"

import TdComponent from "components/Table/TdComponent"
import ThComponent from "components/Table/ThComponent"
import Checkbox from "components/Checkbox/Checkbox"
import { apiGET } from "utils/api"
import { PAGE_SIZE_QUERY_PARAM, ORDERING_QUERY_PARAM } from "utils/constants"

class BasicTable extends React.Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    endpoint: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    const endpoint = this.prepareEndpoint(props.endpoint)

    this.state = {
      endpoint,
      data: [],
      loading: true,
      selectedRows: new Set(),
    }
  }

  prepareEndpoint = endpoint => {
    const searchParams = new URLSearchParams(endpoint.split("?")[1])
    let newEndpoint = endpoint
    let updateParams = false

    if (!searchParams.get(PAGE_SIZE_QUERY_PARAM)) {
      searchParams.set(PAGE_SIZE_QUERY_PARAM, "none")
      updateParams = true
    }

    if (updateParams) {
      newEndpoint = `${endpoint.split("?")[0]}?${searchParams.toString()}`
    }

    return newEndpoint
  }

  componentDidMount() {
    this.setState({ loading: true }, () => this.fetchResults())
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.endpoint !== prevState.endpoint ||
      this.props.endpoint !== prevProps.endpoint
    ) {
      this.setState({ loading: true }, () => this.fetchResults())
    }
  }

  getSearchParams = () => new URLSearchParams(this.state.endpoint.split("?")[1])

  pushToHistory = searchParams => {
    const currentSearchParams = this.getSearchParams()
    if (searchParams.toString() !== currentSearchParams.toString()) {
      const base = this.state.endpoint.split("?")[0]
      this.setState({ endpoint: `${base}?${searchParams.toString()}` })
    }
  }

  fetchResults = () =>
    apiGET({
      path: this.state.endpoint,
      onSuccess: response =>
        this.setState({
          data: response,
          loading: false,
          selectedRows: new Set(),
        }),
    })

  pushTableDataToHistory = data => {
    const searchParams = this.getSearchParams()

    if (data.sorted.length) {
      searchParams.set(
        ORDERING_QUERY_PARAM,
        data.sorted.map(s => `${s.desc ? "-" : ""}${s.id}`)
      )
    }

    this.pushToHistory(searchParams)
  }

  fetchData = data => {
    const tableData = {
      sorted: data.sorted,
    }

    if (isEqual(this.state.tableData, tableData)) {
      return
    }

    this.setState({ tableData }, () => {
      this.pushTableDataToHistory(data)
    })
  }

  addSelectColumn = columns => {
    const { data, selectedRows } = this.state

    return [
      {
        Header: () => (
          <Checkbox
            id="rt-th-select-all"
            onChange={e => {
              const rows = new Set()
              e.target.checked
                ? data.forEach(d => (d.id ? rows.add(d.id) : d))
                : rows.clear()

              this.setState({ selectedRows: rows })
            }}
            checked={selectedRows.size === data.filter(d => d.id).length}
            style={{ cursor: "pointer" }}
          />
        ),
        Cell: row =>
          row.original.id ? (
            <Checkbox
              id={`rt-td-select-row-${row.original.id}`}
              onChange={e => {
                const rows = new Set(selectedRows)
                if (e.target.checked) {
                  rows.add(row.original.id)
                } else {
                  rows.delete(row.original.id)
                }
                this.setState({ selectedRows: rows })
              }}
              checked={selectedRows.has(row.original.id)}
              style={{ cursor: "pointer" }}
            />
          ) : null,
        sortable: false,
        resizable: false,
        width: 50,
        className: "rt-select",
        headerClassName: "rt-select-all-header",
        style: { textAlign: "center" },
      },
      ...columns,
    ]
  }

  render() {
    const { data, loading, selectedRows } = this.state
    const { t } = this.props

    const searchParams = this.getSearchParams()

    let { columns } = this.props
    columns = this.addSelectColumn(columns)

    return (
      <ReactTable
        columns={columns}
        manual
        minRows={0}
        data={data}
        loading={loading}
        filterable={false}
        resizable={false}
        showPageSizeOptions={false}
        showPageJump={false}
        showPagination={false}
        onFetchData={this.fetchData}
        defaultSorted={
          searchParams.get(ORDERING_QUERY_PARAM)
            ? searchParams
                .get(ORDERING_QUERY_PARAM)
                .split(",")
                .map(s => ({
                  id: s.replace("-", ""),
                  desc: s.includes("-"),
                }))
            : undefined
        }
        NoDataComponent={() => (
          <div>{!loading ? t("No results found.") : ""}</div>
        )}
        getTdProps={(_, rowInfo, column) => {
          let className = "text-left"

          if (selectedRows.has(rowInfo.original.id)) {
            className = `${className} rt-td-selected`
          }

          return {
            className,
            width: column.width,
          }
        }}
        getTheadThProps={(state, rowInfo, column) => ({
          alignment: column.headerAlignment || "justify-content-start",
          sortable: column.sortable,
          renderHeader: column.renderHeader,
          width: column.width,
        })}
        TdComponent={TdComponent}
        ThComponent={ThComponent}
      />
    )
  }
}

export default withTranslation()(BasicTable)
