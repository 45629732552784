import React, { useState } from "react"
import PropTypes from "prop-types"
import { useFormContext, Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import InputMask from "react-input-mask"
import { get } from "lodash"

export default function InputField({
  onChange: onChangeProp,
  fieldType,
  className,
  name,
  registerProps,
  defaultValue,
  required,
  showError,
  ...rest
}) {
  const { errors, control } = useFormContext()
  const [focused, setFocused] = useState(false)

  const { t } = useTranslation()

  const renderField = props => {
    switch (fieldType) {
      case "input":
        return <input {...props} />
      case "masked":
        return <InputMask {...props} />
      case "textarea":
        return <textarea {...props} />
      default:
        throw new Error()
    }
  }

  return (
    <Controller
      render={({ onChange, onBlur, ...restRender }) => {
        const error = get(errors, name)

        const props = {
          ...restRender,
          ...rest,
          onChange: e => {
            onChangeProp(e.target.value)
            onChange(e.target.value)
          },
          onFocus: e => setFocused(true),
          onBlur: e => {
            setFocused(false)
            onBlur(e)
          },
          className: `${className} form-control form-field ${
            focused || restRender.value ? "has-floating-label" : ""
          } ${error ? "is-invalid" : ""}`,
        }

        return (
          <>
            {renderField(props)}
            {showError && error ? (
              <div className="invalid-feedback d-block">{error.message}</div>
            ) : null}
          </>
        )
      }}
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{
        ...registerProps,
        validate: value => {
          if (required && !value) {
            return t("This field is required.")
          }

          return registerProps.validate ? registerProps.validate(value) : true
        },
      }}
    />
  )
}

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  fieldType: PropTypes.oneOf(["input", "textarea", "masked"]).isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  registerProps: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool.isRequired,
  showError: PropTypes.bool,
}

InputField.defaultProps = {
  className: "",
  onChange: () => {},
  registerProps: {},
  defaultValue: "",
  showError: true,
}
