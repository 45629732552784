import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { isEmpty } from "lodash"

import { hasPermissions } from "apps/auth/auth"
import { getBoolDisplay } from "apps/core/utils"
import Button from "components/Button/Button"
import FieldDetail from "components/FieldDetail/FieldDetail"
import { apiGET } from "utils/api"
import { LOGO_CROP_SIZE } from "utils/constants"

import "./Detail.scss"

export default function Detail(props) {
  const [data, setData] = useState({})
  const { t } = useTranslation()
  let history = useHistory()

  useEffect(
    () =>
      apiGET({
        path: props.detailEndpoint,
        onSuccess: response => setData(response),
        onError: () => history.push(props.listRoute),
      }),
    [props.detailEndpoint, history, props.listRoute]
  )

  const renderValue = field => {
    let val = data[field.key]

    if (["", null, undefined].includes(val)) {
      return "-"
    } else if (typeof val === "boolean") {
      return getBoolDisplay(val)
    }

    switch (field.type) {
      case "logo":
        return (
          <img
            src={val}
            width={LOGO_CROP_SIZE.width}
            height={LOGO_CROP_SIZE.height}
            alt=""
            className="border"
          />
        )
      case "color":
        return (
          <>
            <span
              className="border border-dark color-block mr-1"
              style={{ backgroundColor: val }}
            />
            {val}
          </>
        )
      case "singleSelect":
        return val.displayName
      case "multiSelect":
        return val.map(d => d.displayName).join(", ")
      case "multiLineText":
        return val.split("\n").map((item, i, array) => (
          <p key={item} className={`${i === array.length - 1 ? "mb-0" : ""}`}>
            {item}
          </p>
        ))
      default:
        return val
    }
  }

  return !isEmpty(data) ? (
    <div>
      <div className="row">
        <div className="col">
          <h1>{props.title}</h1>
        </div>
        <div className="col-auto">
          {props.changePermission.length !== 0 &&
            hasPermissions(props.changePermission) &&
            props.changeCheck(data) && (
              <Button icon="edit" to={props.changeRoute}>
                {t("Update")}
              </Button>
            )}
          {props.deletePermission.length !== 0 &&
            hasPermissions(props.deletePermission) &&
            props.deleteCheck(data) && (
              <Button
                type="danger"
                icon="remove"
                className="ml-2"
                to={props.deleteRoute}>
                {t("Delete")}
              </Button>
            )}
          {props.historyRoute !== "" && hasPermissions(["view_history"]) && (
            <Button icon="history" className="ml-2" to={props.historyRoute}>
              {t("History")}
            </Button>
          )}
          {props.additionalButtons.map(
            (btn, i) =>
              hasPermissions(btn.permission) &&
              (btn.check === undefined || btn.check(data)) && (
                <Button
                  className={`ml-2 ${btn.className || ""}`}
                  to={btn.route}
                  key={i}>
                  {btn.label}
                </Button>
              )
          )}
        </div>
      </div>
      {props.fields.map((fieldsets, i) => (
        <div className="row my-2" key={i}>
          {fieldsets.map((field, j) => (
            <div className={field.size} key={j}>
              {field.type === "logo" ? (
                <div>
                  <div className="px-2 mb-2">{field.label}</div>
                  <div>{renderValue(field)}</div>
                </div>
              ) : (
                <FieldDetail
                  label={field.label}
                  value={renderValue(field)}
                  type={field.type}
                />
              )}
            </div>
          ))}
        </div>
      ))}
      <div className="mt-4">
        <Button icon="chevron-left-double" onClick={() => history.goBack()}>
          {t("Back")}
        </Button>
      </div>
    </div>
  ) : null
}

Detail.propTypes = {
  detailEndpoint: PropTypes.string.isRequired,
  listRoute: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.exact({
        label: PropTypes.node.isRequired,
        key: PropTypes.string.isRequired,
        type: PropTypes.string,
        size: PropTypes.string,
      })
    )
  ).isRequired,
  changePermission: PropTypes.array,
  changeRoute: PropTypes.string,
  changeCheck: PropTypes.func,
  deletePermission: PropTypes.array,
  deleteRoute: PropTypes.string,
  deleteCheck: PropTypes.func,
  additionalButtons: PropTypes.arrayOf(
    PropTypes.exact({
      permission: PropTypes.array.isRequired,
      route: PropTypes.string.isRequired,
      check: PropTypes.func,
      className: PropTypes.string,
      label: PropTypes.node.isRequired,
    })
  ),
  historyRoute: PropTypes.string,
  title: PropTypes.string.isRequired,
}

Detail.defaultProps = {
  changePermission: [],
  changeRoute: "",
  changeCheck: () => true,
  deletePermission: [],
  deleteRoute: "",
  deleteCheck: () => true,
  additionalButtons: [],
  historyRoute: "",
}
