import React from "react"
import { useTranslation } from "react-i18next"

import BookingList from "./BookingList"

export default function TourOperatorBookingList(props) {
  const { t } = useTranslation()

  return (
    <BookingList
      tableConfig={{
        endpoint: "/bookings/tour-operator-bookings",
        title: t("Tour Operator Booking"),
        tableName: "tour-operator-booking-table-config",
      }}
    />
  )
}
