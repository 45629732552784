import React from "react"

import Icon from "components/Icon/Icon"
import Tooltip from "components/Tooltip/Tooltip"
import { actionsColumn as tableActionsColumn } from "components/Table"

import { reverse } from "utils/urls"

export const mealTypeColumn = t => ({
  Header: t("Catering"),
  id: "mealType",
  accessor: td => td.mealType.displayName,
})

export const maxChildAgeColumn = t => ({
  Header: t("Max CHD Age"),
  accessor: "maxChildAge",
  className: "text-center",
  headerAlignment: "justify-content-center",
})

export const cabinBaggageColumn = t => ({
  Header: t("Cabin Baggage"),
  id: "cabinBaggage",
  accessor: td => (
    <div className="row text-center">
      <div className="col-6 text-center">
        {td?.cabinbaggage?.pieces ? (
          <>
            {td.cabinbaggage.pieces}
            {t("pc(s)")}
          </>
        ) : (
          "-"
        )}
      </div>
      <div className="col-6 text-center">
        {td?.cabinbaggage?.weight ? <>{td.cabinbaggage.weight}kg</> : "-"}
      </div>
    </div>
  ),
  sortable: false,
  headerAlignment: "justify-content-center",
})

export const cabinAccessoryColumn = t => ({
  Header: t("Cabin Accessory"),
  id: "cabinAccessory",
  accessor: td => (
    <div className="row text-center">
      <div className="col-6 text-center">
        {td?.cabinaccessory?.pieces ? (
          <>
            {td.cabinaccessory.pieces}
            {t("pc(s)")}
          </>
        ) : (
          "-"
        )}
      </div>
      <div className="col-6 text-center">
        {td?.cabinaccessory?.weight ? <>{td.cabinaccessory.weight}kg</> : "-"}
      </div>
    </div>
  ),
  sortable: false,
  headerAlignment: "justify-content-center",
})

export const totalCabinWeightColumn = t => ({
  Header: t("Total Cabin Weight"),
  id: "totalCabinWeight",
  accessor: td => (
    <div className="text-center">
      {td.totalCabinWeight ? `${td.totalCabinWeight}kg` : "-"}
    </div>
  ),
  headerAlignment: "justify-content-center",
})

export const checkedBaggageColumn = t => ({
  Header: t("Checked Baggage"),
  id: "checkedBaggage",
  accessor: td => (
    <div className="row text-center">
      <div className="col-6 text-center">
        {td?.checkedbaggage?.pieces ? (
          <>
            {td.checkedbaggage.pieces}
            {t("pc(s)")}
          </>
        ) : (
          "-"
        )}
      </div>
      <div className="col-6 text-center">
        {td?.checkedbaggage?.weight ? <>{td.checkedbaggage.weight}kg</> : "-"}
      </div>
    </div>
  ),
  sortable: false,
  headerAlignment: "justify-content-center",
})

export const actionsColumn = t =>
  tableActionsColumn(obj => (
    <>
      <Tooltip title={t("Ticket Default Details")}>
        <Icon.Link
          name="eye"
          color="gray"
          to={reverse("ticketDefaultDetail", {
            id: obj.id,
          })}
        />
      </Tooltip>
      <Tooltip title={t("Ticket Default History")}>
        <Icon.Link
          name="history"
          color="gray"
          linkClassName="ml-3"
          to={reverse("history", {
            id: obj.id,
            model: encodeURIComponent("tickets/ticket-defaults"),
          })}
        />
      </Tooltip>
      <Tooltip title={t("Edit Ticket Default")}>
        <Icon.Link
          name="edit"
          to={reverse("ticketDefaultUpdate", { id: obj.id })}
          linkClassName="ml-3"
        />
      </Tooltip>
      <Tooltip title={t("Delete Ticket Default")}>
        <Icon.Link
          name="remove"
          color="danger"
          to={reverse("ticketDefaultDelete", { id: obj.id })}
          linkClassName="ml-3"
        />
      </Tooltip>
    </>
  ))
