import React, { useCallback, useEffect, useState } from "react"
import { setGlobal, getGlobal } from "reactn"
import PropTypes from "prop-types"
import { useFormContext, Controller, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import AsyncSelect from "react-select/async"
import { debounce, get } from "lodash"

import { getAuthHeaders } from "apps/auth/auth"
import { apiGET, checkStatus, buildApiUrl, parseJSON } from "utils/api"

import { commonStyles, commonComponents } from "./common"
import "./SelectField.scss"

const DEBOUNCE_TIME = 500

export default function AsyncSelectField({
  onChange: onChangeProp,
  className,
  name,
  registerProps,
  defaultValue,
  endpoint,
  required,
  storeChoices,
  showError,
  ...rest
}) {
  const { errors, control, setValue } = useFormContext()
  const selectValue = useWatch({
    control,
    name,
    defaultValue,
  })
  const [defaultOptions, setDefaultOptions] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [focused, setFocused] = useState(false)
  const [hasInitialValue, setHasInitialValue] = useState(false)

  const { t } = useTranslation()
  let location = useLocation()

  useEffect(() => {
    // This hook is in charge of setting the default value from url search param.
    // Complicated `if` statement is there to ensure that is it triggered only on
    // the initial page load
    const searchParams = new URLSearchParams(location.search)
    const paramsValue = searchParams.get(name)

    if (paramsValue && !selectValue && !hasInitialValue && !isLoading) {
      setIsLoading(true)
      apiGET({
        path: `${endpoint}${paramsValue}/`,
        onSuccess: response => {
          const selected = {
            ...response,
            id: `${response.id}`,
          }
          setIsLoading(false)
          setHasInitialValue(true)
          setValue(name, selected)
          onChangeProp(selected)
        },
      })
    }
  }, [
    location.search,
    name,
    selectValue,
    isLoading,
    hasInitialValue,
    endpoint,
    setValue,
    onChangeProp,
  ])

  const promiseOptions = inputValue => {
    setIsLoading(true)

    const apiStore = { ...getGlobal().apiStore }
    const url = `${buildApiUrl(endpoint)}${
      endpoint.includes("?") ? "&" : "?"
    }q=${inputValue}`

    if (url in apiStore && storeChoices) {
      return new Promise(resolve => {
        resolve(apiStore[url])
      })
    } else {
      const options = {
        ...{
          headers: {
            "Content-Type": "application/json",
            ...getAuthHeaders(),
          },
        },
      }
      return fetch(url, options)
        .then(checkStatus)
        .then(parseJSON)
        .then(json => {
          const results = json.results.map(result => ({
            ...result,
            id: `${result.id}`,
          }))

          if (storeChoices) {
            apiStore[url] = results
            setGlobal({ apiStore })
          }

          setIsLoading(false)
          return results
        })
    }
  }

  const debouncedOptions = useCallback(
    debounce((inputValue, cb) => {
      promiseOptions(inputValue).then(options => cb(options))
    }, DEBOUNCE_TIME),
    []
  )

  return (
    <Controller
      render={({ onChange, ...restRender }) => {
        const error = get(errors, name)

        return (
          <>
            <AsyncSelect
              isClearable
              hideSelectedOptions
              blurInputOnSelect
              openMenuOnFocus
              cacheOptions
              defaultOptions={defaultOptions}
              placeholder=""
              getOptionLabel={option => option.displayName}
              getOptionValue={option => `${option.id}`}
              {...restRender}
              {...rest}
              className={`${className} select-field ${
                focused || restRender.value ? "has-floating-label" : ""
              } ${error ? "is-invalid" : ""}`}
              onChange={selected => {
                if (!hasInitialValue) {
                  setHasInitialValue(true)
                }

                if (!selected) {
                  setFocused(false)
                }
                onChangeProp(selected)
                onChange(selected === null ? "" : selected)
              }}
              onMenuOpen={() => {
                if (!defaultOptions) {
                  setIsLoading(true)
                  debouncedOptions("", response => setDefaultOptions(response))
                }

                setFocused(true)
              }}
              onMenuClose={() => setFocused(false)}
              onInputChange={inputValue => {
                if (inputValue && selectValue) {
                  setValue(name, null)
                }
              }}
              loadOptions={debouncedOptions}
              isLoading={isLoading}
              styles={commonStyles(rest.disabled)}
              components={commonComponents(t, rest, true)}
            />
            {showError && error ? (
              <div className="invalid-feedback d-block">{error.message}</div>
            ) : null}
          </>
        )
      }}
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{
        ...registerProps,
        validate: value => {
          if (required && !value) {
            return t("This field is required.")
          }

          return registerProps.validate ? registerProps.validate(value) : true
        },
      }}
    />
  )
}

AsyncSelectField.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  registerProps: PropTypes.object,
  defaultValue: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  endpoint: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  storeChoices: PropTypes.bool,
  filterOption: PropTypes.func,
  showError: PropTypes.bool,
}

AsyncSelectField.defaultProps = {
  onChange: () => {},
  className: "",
  registerProps: {},
  defaultValue: "",
  disabled: false,
  storeChoices: false,
  filterOption: undefined,
  showError: true,
}
