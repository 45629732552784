import React from "react"

import CityForm from "apps/core/City/CityForm"
import { reverse } from "utils/urls"

export default function CityCreate(props) {
  return (
    <CityForm
      {...props}
      actionEndpoint="/cities/"
      successUrl={reverse("cityList")}
    />
  )
}
