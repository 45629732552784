const routes = {
  ticketDefaultCreate: "/tickets/ticket-defaults/create",
  ticketDefaultUpdate: "/tickets/ticket-defaults/:id/update",
  ticketDefaultDelete: "/tickets/ticket-defaults/:id/delete",
  ticketDefaultDetail: "/tickets/ticket-defaults/:id",
  ticketDefaultList: "/tickets/ticket-defaults",
  ticketCreateStep1: "/tickets/create/step-1",
  ticketCreateStep2: "/tickets/create/step-2",
  ticketHistory: "/tickets/:id/history",
  ticketUpdate: "/tickets/:id/update",
  ticketDelete: "/tickets/:id/delete",
  ticketDetail: "/tickets/:id",
  ticketList: "/tickets",
}

export default routes
