import React from "react"
import PropTypes from "prop-types"

import BookingUpdate from "./BookingUpdate"
import { reverse } from "utils/urls"

export default function TourOperatorBookingUpdate(props) {
  return (
    <BookingUpdate
      detailEndpoint={`/bookings/tour-operator-bookings/${props.match.params.id}/?edit=true`}
      listRoute={reverse("tourOperatorBookingList")}
      successUrl={reverse("tourOperatorBookingDetail", {
        id: props.match.params.id,
      })}
    />
  )
}

TourOperatorBookingUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
