import React from "react"
import PropTypes from "prop-types"
import { useFormContext, Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { get, uniqueId } from "lodash"

import Button from "components/Button/Button"
import { readFile } from "utils/fs"

export default function FileField({
  name,
  registerProps,
  defaultValue,
  required,
  className,
  label,
  onFileChange: onFileChangeProp,
  showClear,
  inputProps,
  ...rest
}) {
  const { errors, control, setValue } = useFormContext()
  const { t } = useTranslation()
  const id = `id_file-${uniqueId()}`

  const onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let base64 = await readFile(file)
      setValue(name, base64)
      setValue(`${name}Filename`, file.name)
      onFileChangeProp(file)
    }
  }

  return (
    <>
      <Controller
        render={() => <></>}
        name={name}
        defaultValue={defaultValue}
        control={control}
        rules={{
          ...registerProps,
          validate: value => {
            if (required && !value) {
              return t("This field is required.")
            }

            return registerProps.validate ? registerProps.validate(value) : true
          },
        }}
      />
      <Controller
        name={`${name}Filename`}
        defaultValue=""
        control={control}
        render={renderProps => {
          const error = get(errors, name)
          return (
            <div className={`${className} file-field`}>
              <h6>{label}</h6>
              <label htmlFor={id} className="p-3">
                <div className="mb-2">
                  <b>{renderProps.value || t("No file selected")}</b>
                </div>
                <div>
                  <Button onClick={() => document.getElementById(id).click()}>
                    {t("Browse")}
                  </Button>
                  {renderProps.value && showClear && (
                    <Button
                      type="warning"
                      className="ml-3"
                      onClick={e => {
                        e.preventDefault()
                        setValue(name, "")
                        setValue(`${name}Filename`, "")

                        const input = document.getElementById(id)
                        if (input) {
                          input.value = ""
                        }
                      }}>
                      {t("Clear")}
                    </Button>
                  )}
                </div>
              </label>
              <input
                id={id}
                type="file"
                className="d-none"
                onChange={onFileChange}
                {...inputProps}
              />
              {error ? (
                <div className="invalid-feedback d-block">{error.message}</div>
              ) : null}
            </div>
          )
        }}
      />
    </>
  )
}

FileField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  registerProps: PropTypes.object,
  required: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onFileChange: PropTypes.func,
  inputProps: PropTypes.object,
  showClear: PropTypes.bool,
}

FileField.defaultProps = {
  defaultValue: "",
  registerProps: {},
  className: "",
  onFileChange: () => {},
  inputProps: {},
  showClear: true,
}
