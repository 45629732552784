import React, { useContext } from "react"
import { useHistory } from "react-router"

import Checkbox from "components/Checkbox/Checkbox"
import { getLocalStorageObj, setLocalStorageObj } from "utils/fs"
import { TrComponentContext } from "./TrComponent"

export default function SelectCell(props) {
  const { isSelected, setIsSelected, rowInfo } = useContext(TrComponentContext)
  let history = useHistory()

  return (
    <Checkbox
      onChange={e => {
        setIsSelected(e.target.checked)

        const storageName = `${history.location.pathname}-sheet`
        const sheetData = getLocalStorageObj(storageName) || {}
        const selected = sheetData.selected || {}
        selected[rowInfo.original.id] = e.target.checked
        sheetData.selected = selected
        setLocalStorageObj(storageName, sheetData)
      }}
      checked={isSelected}
      style={{ cursor: "pointer" }}
    />
  )
}
