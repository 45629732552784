import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import Cropper from "react-easy-crop"
import { useFormContext, Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { get } from "lodash"

import Button from "components/Button/Button"
import { readFile } from "utils/fs"
import getCroppedImg from "utils/image"

export default function ImageField({
  name,
  registerProps,
  defaultValue,
  cropSize,
  required,
  ...rest
}) {
  const [state, setState] = useState({
    image: defaultValue,
    crop: { x: 0, y: 0 },
    zoom: 0.75,
  })
  const { errors, control, setValue } = useFormContext()
  const { t } = useTranslation()

  const onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let base64 = await readFile(file)
      setState(prevState => ({ ...prevState, image: base64 }))
      setValue(name, base64)
    }
  }

  const onCropComplete = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      if (state.image) {
        const base64 = await getCroppedImg(state.image, croppedAreaPixels)
        setValue(name, base64)
      }
    },
    [state.image, name, setValue]
  )

  return (
    <Controller
      render={renderProps => {
        const error = get(errors, name)

        return (
          <>
            <div className="image-field has-floating-label">
              <div
                className="border border-teal-light rounded"
                style={{
                  position: "relative",
                  height: `${cropSize.height * 1.5}px`,
                  width: "100%",
                }}>
                <Cropper
                  image={state.image || renderProps.value}
                  crop={state.crop}
                  cropSize={cropSize}
                  zoom={state.zoom}
                  minZoom={0.25}
                  zoomSpeed={0.1}
                  showGrid={false}
                  restrictPosition={false}
                  onCropChange={crop =>
                    setState(prevState => ({ ...prevState, crop }))
                  }
                  onCropComplete={onCropComplete}
                  onZoomChange={zoom =>
                    setState(prevState => ({ ...prevState, zoom }))
                  }
                />
              </div>
              <input type="file" onChange={onFileChange} accept="image/*" />
              <Button
                onClick={() => {
                  setState(prevState => ({ ...prevState, image: "" }))
                  setValue(name, null)
                }}>
                {t("Clear")}
              </Button>
            </div>
            {error ? (
              <div className="invalid-feedback d-block">{error.message}</div>
            ) : null}
          </>
        )
      }}
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{
        ...registerProps,
        validate: value => {
          if (required && !value) {
            return t("This field is required.")
          }

          return registerProps.validate ? registerProps.validate(value) : true
        },
      }}
    />
  )
}

ImageField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  registerProps: PropTypes.object,
  cropSize: PropTypes.exact({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  required: PropTypes.bool.isRequired,
}

ImageField.defaultProps = {
  defaultValue: "",
  registerProps: {},
}
