import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

import Detail from "components/GenericViews/Detail"
import { reverse } from "utils/urls"

export default function CityDetail(props) {
  const { t } = useTranslation()

  return (
    <Detail
      title={t("City details")}
      detailEndpoint={`/cities/${props.match.params.id}/`}
      listRoute={reverse("cityList")}
      changePermission={["change_core_city"]}
      changeRoute={reverse("cityUpdate", { id: props.match.params.id })}
      deletePermission={["delete_core_city"]}
      deleteRoute={reverse("cityDelete", { id: props.match.params.id })}
      historyRoute={reverse("history", {
        id: props.match.params.id,
        model: "cities",
      })}
      fields={[
        [
          { label: t("Name"), key: "name", size: "col-4" },
          { label: t("Code"), key: "code", size: "col-4" },
        ],
        [
          {
            label: t("Country"),
            key: "country",
            type: "singleSelect",
            size: "col-4",
          },
        ],
        [{ label: t("Timezone"), key: "timezone", size: "col-4" }],
      ]}
    />
  )
}

CityDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
