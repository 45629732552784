import {
  AirlineBookingFareCreate,
  AirlineBookingFareUpdate,
  AirlineBookingFareDelete,
  AirlineBookingFareList,
  TourOperatorBookingFareCreate,
  TourOperatorBookingFareUpdate,
  TourOperatorBookingFareDelete,
  TourOperatorBookingFareList,
} from "apps/fares/BookingFare"
import {
  AirlineInfantBookingFareCreate,
  AirlineInfantBookingFareUpdate,
  AirlineInfantBookingFareDelete,
  AirlineInfantBookingFareList,
  TourOperatorInfantBookingFareCreate,
  TourOperatorInfantBookingFareUpdate,
  TourOperatorInfantBookingFareDelete,
  TourOperatorInfantBookingFareList,
} from "apps/fares/InfantBookingFare"
import { route } from "utils/urls"

export const PROTECTED_ROUTES = [
  route("airlineBookingFareCreate", AirlineBookingFareCreate, [
    "add_fares_airlinebookingfare",
  ]),
  route("airlineBookingFareUpdate", AirlineBookingFareUpdate, [
    "change_fares_airlinebookingfare",
  ]),
  route("airlineBookingFareDelete", AirlineBookingFareDelete, [
    "delete_fares_airlinebookingfare",
  ]),
  route("airlineBookingFareList", AirlineBookingFareList, [
    "view_fares_airlinebookingfare",
  ]),
  route("airlineInfantBookingFareCreate", AirlineInfantBookingFareCreate, [
    "add_fares_airlineinfantbookingfare",
  ]),
  route("airlineInfantBookingFareUpdate", AirlineInfantBookingFareUpdate, [
    "change_fares_airlineinfantbookingfare",
  ]),
  route("airlineInfantBookingFareDelete", AirlineInfantBookingFareDelete, [
    "delete_fares_airlineinfantbookingfare",
  ]),
  route("airlineInfantBookingFareList", AirlineInfantBookingFareList, [
    "view_fares_airlineinfantbookingfare",
  ]),
  route("tourOperatorBookingFareCreate", TourOperatorBookingFareCreate, [
    "add_fares_touroperatorbookingfare",
  ]),
  route("tourOperatorBookingFareUpdate", TourOperatorBookingFareUpdate, [
    "change_fares_touroperatorbookingfare",
  ]),
  route("tourOperatorBookingFareDelete", TourOperatorBookingFareDelete, [
    "delete_fares_touroperatorbookingfare",
  ]),
  route("tourOperatorBookingFareList", TourOperatorBookingFareList, [
    "view_fares_touroperatorbookingfare",
  ]),
  route(
    "tourOperatorInfantBookingFareCreate",
    TourOperatorInfantBookingFareCreate,
    ["add_fares_touroperatorinfantbookingfare"]
  ),
  route(
    "tourOperatorInfantBookingFareUpdate",
    TourOperatorInfantBookingFareUpdate,
    ["change_fares_touroperatorinfantbookingfare"]
  ),
  route(
    "tourOperatorInfantBookingFareDelete",
    TourOperatorInfantBookingFareDelete,
    ["delete_fares_touroperatorinfantbookingfare"]
  ),
  route(
    "tourOperatorInfantBookingFareList",
    TourOperatorInfantBookingFareList,
    ["view_fares_touroperatorinfantbookingfare"]
  ),
]
