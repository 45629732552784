import React from "react"
import { components } from "react-select"

import Icon from "components/Icon/Icon"

import CSS from "../../../../../assets/scss/react-import.scss"

export const commonStyles = disabled => ({
  placeholder: (provided, selectState) => ({
    ...provided,
    color: CSS.colorSecondary,
  }),
  control: (provided, selectState) => ({
    ...provided,
    borderColor: selectState.selectProps.className.includes("is-invalid")
      ? "red"
      : selectState.isFocused
      ? CSS.inputFocusBorderColor
      : CSS.inputBorderColor,
    borderWidth: CSS.borderWidth,
    boxShadow: selectState.isFocused
      ? "0 0 0 0.2rem rgba(23, 162, 184, 0.25)"
      : "none",
    height:
      selectState.isMulti && selectState.hasValue ? "3.75rem" : "2.9375rem",
    ...(disabled && {
      pointerEvents: "none",
      backgroundColor: "hsl(0, 0%, 95%)",
    }),
    /* TODO: transitions doesn't seem to work like this */
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    "&:hover": {
      borderColor: selectState.isFocused
        ? CSS.inputFocusBorderColor
        : CSS.inputBorderColor,
    },
  }),
  valueContainer: (provided, selectState) => ({
    ...provided,
    padding: `${
      selectState.isMulti && selectState.hasValue ? "1.125rem" : "0.75rem"
    } 0.5625rem`,
  }),
  indicatorsContainer: (provided, selectState) => ({
    ...provided,
    paddingBottom: "0.6rem",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  clearIndicator: () => ({
    cursor: "pointer",
    padding: "0.5rem 0.25rem 0.25rem 0.25rem",
  }),
  dropdownIndicator: () => ({
    padding: "0.5rem 0.5rem 0.25rem 0.25rem",
  }),
  // there are some elements with high z-index on some pages so this has to be set
  menu: (provided, selectState) => {
    const style = { ...provided, zIndex: 9999 }

    // change width for `AirportSelectField`
    if (selectState.selectProps?.["data-select-type"] === "airports") {
      style.minWidth = "400px"
    }

    return style
  },
})

/* eslint-disable react/prop-types */
export const commonComponents = (t, props, isAsync = false) => ({
  NoOptionsMessage: innerProps => (
    <components.NoOptionsMessage {...innerProps}>
      {props.noOptionsMessage
        ? props.noOptionsMessage(innerProps.selectProps.inputValue)
        : t("No options")}
    </components.NoOptionsMessage>
  ),
  DropdownIndicator: innerProps =>
    props.disabled ? null : (
      <div
        {...innerProps.restInnerProps}
        style={innerProps.getStyles("dropdownIndicator", innerProps)}>
        <Icon name={isAsync ? "search" : "chevron-down"} color="magenta" />
      </div>
    ),
  ClearIndicator: innerProps => {
    const {
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = innerProps

    return props.disabled ? null : (
      <div
        {...restInnerProps}
        ref={ref}
        style={getStyles("clearIndicator", innerProps)}>
        <Icon name="remove" />
      </div>
    )
  },
})
/* eslint-enable react/prop-types */
