import {
  TicketSegmentSelection,
  TicketCreate,
  TicketHistory,
  TicketUpdate,
  TicketDelete,
  TicketDetail,
  TicketList,
} from "apps/tickets/Ticket"
import {
  TicketDefaultCreate,
  TicketDefaultUpdate,
  TicketDefaultDelete,
  TicketDefaultDetail,
  TicketDefaultList,
} from "apps/tickets/TicketDefault"
import { route } from "utils/urls"

export const PROTECTED_ROUTES = [
  route("ticketDefaultCreate", TicketDefaultCreate, [
    "add_tickets_ticketdefault",
  ]),
  route("ticketDefaultUpdate", TicketDefaultUpdate, [
    "change_tickets_ticketdefault",
  ]),
  route("ticketDefaultDelete", TicketDefaultDelete, [
    "delete_tickets_ticketdefault",
  ]),
  route("ticketDefaultDetail", TicketDefaultDetail, [
    "view_tickets_ticketdefault",
  ]),
  route("ticketDefaultList", TicketDefaultList, ["view_tickets_ticketdefault"]),
  route("ticketCreateStep1", TicketSegmentSelection, ["add_tickets_ticket"]),
  route("ticketCreateStep2", TicketCreate, ["add_tickets_ticket"]),
  route("ticketHistory", TicketHistory, ["view_history"]),
  route("ticketUpdate", TicketUpdate, ["change_tickets_ticket"]),
  route("ticketDelete", TicketDelete, ["delete_tickets_ticket"]),
  route("ticketDetail", TicketDetail, ["view_tickets_ticket"]),
  route("ticketList", TicketList, ["view_tickets_ticket"]),
]
