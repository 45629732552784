import React from "react"

import AirportForm from "apps/airports/Airport/AirportForm"
import { reverse } from "utils/urls"

export default function AirportCreate(props) {
  return (
    <AirportForm
      {...props}
      actionEndpoint="/airports/"
      successUrl={reverse("airportList")}
    />
  )
}
