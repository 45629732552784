const routes = {
  airlineBookingFareCreate: "/fares/airline-fares/create",
  airlineBookingFareUpdate: "/fares/airline-fares/:id/update",
  airlineBookingFareDelete: "/fares/airline-fares/:id/delete",
  airlineBookingFareList: "/fares/airline-fares",
  airlineInfantBookingFareCreate: "/fares/airline-infant-fares/create",
  airlineInfantBookingFareUpdate: "/fares/airline-infant-fares/:id/update",
  airlineInfantBookingFareDelete: "/fares/airline-infant-fares/:id/delete",
  airlineInfantBookingFareList: "/fares/airline-infant-fares",
  tourOperatorBookingFareCreate: "/fares/tour-operator-fares/create",
  tourOperatorBookingFareUpdate: "/fares/tour-operator-fares/:id/update",
  tourOperatorBookingFareDelete: "/fares/tour-operator-fares/:id/delete",
  tourOperatorBookingFareList: "/fares/tour-operator-fares",
  tourOperatorInfantBookingFareCreate:
    "/fares/tour-operator-infant-fares/create",
  tourOperatorInfantBookingFareUpdate:
    "/fares/tour-operator-infant-fares/:id/update",
  tourOperatorInfantBookingFareDelete:
    "/fares/tour-operator-infant-fares/:id/delete",
  tourOperatorInfantBookingFareList: "/fares/tour-operator-infant-fares",
}

export default routes
