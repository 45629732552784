import React from "react"
import ReactDOM from "react-dom"
import { Router, Route, Switch, Redirect } from "react-router-dom"
import { LastLocationProvider } from "react-router-last-location"
import "./i18n"

import "assets/scss/bootstrap.scss"
import "assets/vendor/bootstrap/js/bootstrap"

import { PROTECTED_ROUTES as AIRLINES_PROTECTED_ROUTES } from "apps/airlines/Routes"
import { PROTECTED_ROUTES as AIRPORTS_PROTECTED_ROUTES } from "apps/airports/Routes"
import {
  ROUTES as AUTH_ROUTES,
  PROTECTED_ROUTES as AUTH_PROTECTED_ROUTES,
} from "apps/auth/Routes"
import { PROTECTED_ROUTES as BOOKINGS_PROTECTED_ROUTES } from "apps/bookings/Routes"
import { PROTECTED_ROUTES as CORE_PROTECTED_ROUTES } from "apps/core/Routes"
import { PROTECTED_ROUTES as CURRENCIES_PROTECTED_ROUTES } from "apps/currencies/Routes"
import { PROTECTED_ROUTES as FARES_PROTECTED_ROUTES } from "apps/fares/Routes"
import { PROTECTED_ROUTES as FEES_PROTECTED_ROUTES } from "apps/fees/Routes"
import { PROTECTED_ROUTES as FLIGHTS_PROTECTED_ROUTES } from "apps/flights/Routes"
import { PROTECTED_ROUTES as INVOICES_PROTECTED_ROUTES } from "apps/invoices/Routes"
import { PROTECTED_ROUTES as ROUTINGS_PROTECTED_ROUTES } from "apps/routings/Routes"
import { PROTECTED_ROUTES as SHEETS_PROTECTED_ROUTES } from "apps/sheets/Routes"
import { PROTECTED_ROUTES as TICKETS_PROTECTED_ROUTES } from "apps/tickets/Routes"
import { PROTECTED_ROUTES as TOUR_OPERATORS_PROTECTED_ROUTES } from "apps/tourOperators/Routes"
import NotificationContainer from "components/Layout/containers/Notification"
import ProtectedRoute from "components/Route/ProtectedRoute"
import LoadingOverlayContainer from "./components/LoadingOverlay/LoadingOverlay"
import { reverse } from "utils/urls"
import { initGlobal } from "utils/store"
import history from "utils/history"

initGlobal()

const ROUTES = [...AUTH_ROUTES]

const PROTECTED_ROUTES = [
  ...AIRLINES_PROTECTED_ROUTES,
  ...AIRPORTS_PROTECTED_ROUTES,
  ...AUTH_PROTECTED_ROUTES,
  ...BOOKINGS_PROTECTED_ROUTES,
  ...CORE_PROTECTED_ROUTES,
  ...CURRENCIES_PROTECTED_ROUTES,
  ...FARES_PROTECTED_ROUTES,
  ...FEES_PROTECTED_ROUTES,
  ...FLIGHTS_PROTECTED_ROUTES,
  ...INVOICES_PROTECTED_ROUTES,
  ...ROUTINGS_PROTECTED_ROUTES,
  ...SHEETS_PROTECTED_ROUTES,
  ...TICKETS_PROTECTED_ROUTES,
  ...TOUR_OPERATORS_PROTECTED_ROUTES,
]

ReactDOM.render(
  <Router history={history}>
    <LastLocationProvider>
      <LoadingOverlayContainer />
      <Route />
      <Switch>
        {ROUTES.map(({ component: Component, ...rest }) => (
          <Route
            {...rest}
            key={rest}
            render={props => (
              <>
                <NotificationContainer />
                <Component {...props} />
              </>
            )}
          />
        ))}
        {PROTECTED_ROUTES.map(protectedRoute => (
          <ProtectedRoute {...protectedRoute} key={protectedRoute} />
        ))}
        <Redirect to={reverse("home")} />
      </Switch>
    </LastLocationProvider>
  </Router>,
  document.getElementById("root")
)
