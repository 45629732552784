import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"

import { actionsColumn } from "components/Table"
import { hasPermissions } from "apps/auth/auth"
import { getReleaseDate, getSeats } from "apps/bookings/utils"
import { getEnum } from "apps/core/utils"
import Avatar from "components/Avatar/Avatar"
import Button from "components/Button/Button"
import Tooltip from "components/Tooltip/Tooltip"
import Icon from "components/Icon/Icon"
import { TableWithFilters } from "components/Table"
import { reverse, buildRouteFromLocation } from "utils/urls"

export const getConfig = (t, tableConfig, location) => {
  const BOOKING_TYPE_ENUM = getEnum("bookingType")

  return {
    ...tableConfig,
    columns: [
      {
        Header: t("Reference"),
        accessor: "reference",
        width: "10%",
      },
      ...(tableConfig.tableName.includes("airline")
        ? [
            {
              Header: t("Airline"),
              id: "airline",
              accessor: b => (
                <Avatar
                  image={b.airline.logo}
                  title={b.airline.displayName}
                  alt={b.airline.code}
                />
              ),
              width: "6%",
            },
          ]
        : []),
      ...(tableConfig.tableName.includes("tour-operator")
        ? [
            {
              Header: t("TO"),
              id: "tourOperator",
              accessor: b => (
                <Avatar
                  image={b.tourOperator.logo}
                  title={b.tourOperator.displayName}
                  alt={b.tourOperator.code}
                />
              ),
              width: "5%",
            },
          ]
        : []),
      { Header: t("Operating date"), accessor: "operatingDate", width: "10%" },
      {
        Header: (
          <>
            D<sup>o</sup>
          </>
        ),
        accessor: "operatingDay",
        width: "5%",
      },
      {
        Header: (
          <>
            {t("Flight")} N<sup>o</sup>
          </>
        ),
        accessor: "flightNo",
        width: "7%",
      },
      {
        Header: t("Route"),
        id: "segment",
        accessor: b => b.segment.displayName,
        width: "8%",
      },
      {
        Header: t("Type"),
        id: "type",
        accessor: b => b.type.id,
        width: "6%",
      },
      {
        Header: t("Release date"),
        id: "releaseDate",
        accessor: b => getReleaseDate(b.releaseDate),
      },
      {
        Header: t("Seats"),
        id: "seats",
        accessor: b => getSeats(b, BOOKING_TYPE_ENUM),
        width: "8%",
      },
      {
        Header: t("Fare"),
        accessor: "totalFare",
        className: "text-right",
      },
      actionsColumn(obj => (
        <>
          <Tooltip title={t("Booking Details")}>
            <Icon.Link
              name="eye"
              color="gray"
              to={reverse(
                tableConfig.tableName.includes("airline")
                  ? "airlineBookingDetail"
                  : "tourOperatorBookingDetail",
                { id: obj.id }
              )}
            />
          </Tooltip>
          <Tooltip title={t("Edit Booking")}>
            <Icon.Link
              name="edit"
              to={reverse(
                tableConfig.tableName.includes("airline")
                  ? "airlineBookingUpdate"
                  : "tourOperatorBookingUpdate",
                { id: obj.id },
                { next: buildRouteFromLocation(location) }
              )}
              linkClassName="ml-3"
            />
          </Tooltip>
          {!obj.isInvoiced ? (
            <Tooltip title={t("Delete Booking")}>
              <Icon.Link
                name="remove"
                color="danger"
                to={reverse(
                  tableConfig.tableName.includes("airline")
                    ? "airlineBookingDelete"
                    : "tourOperatorBookingDelete",
                  { id: obj.id },
                  { next: buildRouteFromLocation(location) }
                )}
                linkClassName="ml-3"
              />
            </Tooltip>
          ) : (
            <span style={{ marginLeft: "2.25rem" }} />
          )}
        </>
      )),
    ],
    filters: [
      [
        {
          size: "col",
          children: [
            [
              {
                name: "reference",
                fieldType: "input",
                type: "text",
                label: t("Booking reference"),
                size: "col-2",
              },
              ...(tableConfig.tableName.includes("airline")
                ? [
                    {
                      name: "airline",
                      fieldType: "asyncSelect",
                      endpoint: "/airlines/",
                      icon: "airplane",
                      label: t("Carrier"),
                      size: "col-3",
                    },
                  ]
                : []),
              ...(tableConfig.tableName.includes("tour-operator")
                ? [
                    {
                      name: "tourOperator",
                      fieldType: "asyncSelect",
                      endpoint: "/tour-operators/",
                      label: t("Tour Operator"),
                      size: "col-3",
                    },
                  ]
                : []),
              {
                name: "route",
                fieldType: "asyncSelect",
                endpoint: "/routings/routes/",
                label: t("Segment"),
                size: "col-2",
              },
              {
                name: "addReturn",
                fieldType: "checkbox",
                label: t("Add return"),
                size: "col-auto",
                align: "center",
              },
              {
                name: "type",
                fieldType: "select",
                endpoint: "/bookings/booking-types/",
                label: t("Booking Type"),
                size: "col-2",
              },
            ],
            [
              {
                fieldType: "selectDateRange",
                dateFromName: "dateFrom",
                dateToName: "dateTo",
                size: "col-6",
              },
              {
                fieldType: "operatingDays",
                name: "operatingDays",
                size: "col-auto text-center",
              },
            ],
          ],
        },
        {
          size: "col-auto",
          children: [
            [
              {
                fieldType: "applyButton",
                size: "col-12 mt-1",
              },
            ],
            [
              {
                fieldType: "clearButton",
                size: "col-12 mt-3",
              },
            ],
          ],
        },
      ],
    ],
    buttons: tableConfig.tableName.includes("airline") ? (
      <>
        {hasPermissions(["add_bookings_airlinebooking"]) && (
          <Button icon="plus" to={reverse("airlineBookingCreateStep1")}>
            {t("Add Booking")}
          </Button>
        )}
      </>
    ) : (
      <>
        {hasPermissions(["add_bookings_touroperatorbooking"]) && (
          <Button icon="plus" to={reverse("tourOperatorBookingCreateStep1")}>
            {t("Add Booking")}
          </Button>
        )}
      </>
    ),
  }
}

export default function BookingList(props) {
  const { t } = useTranslation()
  let history = useHistory()

  return (
    <TableWithFilters
      config={getConfig(t, props.tableConfig, history.location)}
    />
  )
}

BookingList.propTypes = {
  tableConfig: PropTypes.object,
}

BookingList.defaultProps = {
  tableConfig: {},
}
