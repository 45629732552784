import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useHistory, Link } from "react-router-dom"
import { isEmpty, isObject } from "lodash"

import { hasPermissions } from "apps/auth/auth"
import Button from "components/Button/Button"
import FieldDetail from "components/FieldDetail/FieldDetail"
import Icon from "components/Icon/Icon"
import Tooltip from "components/Tooltip/Tooltip"
import { apiGET, fileGET } from "utils/api"
import { reverse, buildRouteFromLocation } from "utils/urls"
import BasicTable from "components/Table/BasicTable"
import { actionsColumn } from "components/Table"

import styles from "./TourOperatorInvoiceDetail.module.scss"
import FeeCell from "apps/sheets/InvoiceWorkingSheet/FeeCell"
import FareCell from "apps/sheets/InvoiceWorkingSheet/FareCell"

export default function TourOperatorInvoiceDetail(props) {
  const [data, setData] = useState({})
  const { t } = useTranslation()
  let history = useHistory()

  useEffect(
    () =>
      apiGET({
        path: `/invoices/tour-operator-invoices/${props.match.params.id}/`,
        onSuccess: response => setData(response),
        onError: () => history.push(reverse("tourOperatorInvoiceList")),
      }),
    [props.match.params.id, history]
  )

  return !isEmpty(data) ? (
    <div>
      <div className="row">
        <div className="col">
          <h1>
            {t("TO")} {data.isCreditNote ? t("Credit note") : "Invoice"}
            {" - "}
            {data.reference}
            {data.isSent ? (
              <span className="text-danger"> - [{t("Sent")}]</span>
            ) : (
              <span className="text-blue-light"> - [{t("Not sent")}]</span>
            )}
          </h1>
        </div>
        <div className="col-auto">
          <Button
            icon="pdf"
            onClick={() =>
              fileGET(`/invoices/tour-operator-invoices/${data.id}/pdf/`)
            }
            type="info"
            style={{ cursor: "pointer" }}>
            {t("PDF")}
          </Button>
          {!data.isSent &&
            hasPermissions(["send_invoices_touroperatorinvoice"]) && (
              <Button
                icon="send"
                className="ml-2"
                to={reverse(
                  "tourOperatorInvoiceSend",
                  { id: data.id },
                  { next: buildRouteFromLocation(history.location) }
                )}>
                {t("Send")}
              </Button>
            )}
          {!data.isNullified &&
            !data.isSent &&
            hasPermissions(["change_invoices_touroperatorinvoice"]) &&
            (data.hasBookingsInvoicedWithTaxes ||
              data.hasBookingsInvoicedWithoutTaxes) && (
              <Button
                icon="invert"
                className="ml-2"
                to={reverse(
                  "tourOperatorInvoiceInvertTaxes",
                  { id: data.id },
                  { next: buildRouteFromLocation(history.location) }
                )}>
                {t("Invert Taxes")}
              </Button>
            )}
          {!data.isNullified &&
            !data.isSent &&
            data.isLast &&
            hasPermissions(["delete_invoices_touroperatorinvoice"]) && (
              <Button
                type="danger"
                icon="remove"
                className="ml-2"
                to={reverse(
                  "tourOperatorInvoiceDelete",
                  { id: data.id },
                  { next: buildRouteFromLocation(history.location) }
                )}>
                {t("Delete")}
              </Button>
            )}
          {!data.isNullified &&
            hasPermissions(["nullify_invoices_touroperatorinvoice"]) && (
              <Button
                icon="nullify"
                className="ml-2"
                to={reverse(
                  "tourOperatorInvoiceNullify",
                  { id: data.id },
                  { next: buildRouteFromLocation(history.location) }
                )}>
                {t("Nullify")}
              </Button>
            )}
          {!data.isNullified &&
            hasPermissions([
              "nullify_invoices_touroperatorinvoice",
              "add_invoices_touroperatorinvoice",
            ]) && (
              <Button
                icon="nullify-recreate"
                className="ml-2"
                to={reverse(
                  "tourOperatorInvoiceNullifyRecreate",
                  { id: data.id },
                  { next: buildRouteFromLocation(history.location) }
                )}>
                {t("Nullify and Recreate")}
              </Button>
            )}
        </div>
      </div>
      <div className="row mt-4 border-bottom border-blue-light">
        <div className="col-12 mb-2">
          <div className="row">
            <div className="col-3 border-right border-blue-light">
              <div className="row">
                <div className="col-12">
                  <FieldDetail
                    label={t("Tour Operator")}
                    value={data.tourOperator.displayName}
                  />
                </div>
                <div className="col-12">
                  <FieldDetail
                    label={t("Invoice reference")}
                    value={data.shortReference}
                  />
                </div>
                <div className="col-12">
                  <FieldDetail
                    label={t("Related invoice")}
                    value={
                      data.relatedInvoice ? (
                        <Link
                          to={reverse("tourOperatorInvoiceDetail", {
                            id: data.relatedInvoice.id,
                          })}>
                          {data.relatedInvoice.reference}
                        </Link>
                      ) : (
                        "-"
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-6 border-right border-blue-light">
              <div className="row border-bottom border-blue-light">
                <div className="col-6">
                  <div className="row">
                    <div className="col-12">
                      <FieldDetail
                        label={t("Seat Quantity")}
                        value={data.paxSeats}
                      />
                    </div>
                    <div className="col-12">
                      <FieldDetail
                        label={t("SUR Quantity")}
                        value={data.paxSeats}
                      />
                    </div>
                    <div className="col-12">
                      <FieldDetail
                        label={t("TAX Quantity")}
                        value={data.taxSeats}
                      />
                    </div>
                    <div className="col-12">
                      <FieldDetail
                        label={t("INF Quantity")}
                        value={data.infSeats}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-12">
                      <FieldDetail
                        label={t("Total Seats")}
                        value={data.paxCost || "-"}
                        valueClassName="text-right"
                      />
                    </div>
                    <div className="col-12">
                      <FieldDetail
                        label={t("Total SUR")}
                        value={
                          data.surCost ? (
                            <FeeCell
                              total={data.surCost}
                              header={t("Surcharges")}
                              objects={data.surcharges}
                            />
                          ) : (
                            "-"
                          )
                        }
                        valueClassName="text-right"
                      />
                    </div>
                    <div className="col-12">
                      <FieldDetail
                        label={t("Total TAX")}
                        value={
                          data.taxCost ? (
                            <FeeCell
                              total={data.taxCost}
                              header={t("Taxes")}
                              objects={data.taxes}
                            />
                          ) : (
                            "-"
                          )
                        }
                        valueClassName="text-right"
                      />
                    </div>
                    <div className="col-12">
                      <FieldDetail
                        label={t("Total INF")}
                        value={data.infCost || "-"}
                        valueClassName="text-right"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="offset-6 col-6">
                      <FieldDetail
                        label={t("Total ex. VAT")}
                        value={data.totalCost}
                        valueClassName="text-right"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <FieldDetail
                        label={t("VAT Rate")}
                        value={`${data.vat}%`}
                      />
                    </div>
                    <div className="col-6">
                      <FieldDetail
                        label={t("VAT")}
                        value={data.totalVat || "-"}
                        valueClassName="text-right"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="offset-6 col-6">
                      <FieldDetail
                        label={t("Total incl. VAT")}
                        valueClassName="text-right"
                        value={
                          <span className="text-magenta">
                            {data.totalInvoice}
                          </span>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <BasicTable
          columns={[
            {
              Header: t("Reference"),
              id: "reference",
              accessor: item =>
                item.type.id === "PAX" ? (
                  item.booking !== null ? (
                    <Link
                      to={reverse(
                        "tourOperatorBookingDetail",
                        {
                          id: item.booking,
                        },
                        { next: buildRouteFromLocation(history.location) }
                      )}>
                      {item.bookingReference}
                    </Link>
                  ) : (
                    <>{item.bookingReference}</>
                  )
                ) : item.type.id === "INF" ? (
                  item.ticket !== null ? (
                    <Link
                      to={reverse(
                        "ticketDetail",
                        {
                          id: item.ticket,
                        },
                        { next: buildRouteFromLocation(history.location) }
                      )}>
                      {item.ticketReference}
                    </Link>
                  ) : (
                    <>{item.ticketReference}</>
                  )
                ) : (
                  ""
                ),
              sortable: false,
            },
            {
              Header: t("Operating date"),
              id: "operatingDate",
              accessor: item =>
                item.type.id !== "ITM" && item.segment.operatingDate,
              sortable: false,
            },
            {
              Header: (
                <>
                  D<sup>o</sup>
                </>
              ),
              id: "operatingDay",
              accessor: item =>
                item.type.id !== "ITM" && item.segment.operatingDay,
              sortable: false,
              width: "5%",
            },
            {
              Header: (
                <>
                  {t("Flight ")} N<sup>o</sup>
                </>
              ),
              id: "flightNo",
              accessor: item => item.type.id !== "ITM" && item.segment.flightNo,
              sortable: false,
            },
            {
              Header: t("Route"),
              id: "route",
              accessor: item =>
                item.type.id !== "ITM" &&
                `${item.segment.departureIata}-${item.segment.arrivalIata}`,
              sortable: false,
            },
            {
              Header: t("Type"),
              id: "type",
              accessor: item => item.type.displayName,
              sortable: false,
              getProps: (_, row) =>
                row.original.type.id === "ITM"
                  ? { className: styles["bg-gray-very-light"] }
                  : {},
            },
            {
              Header: t("Quantity"),
              id: "quantity",
              accessor: item => item.seats,
              sortable: false,
              getProps: (_, row) =>
                row.original.type.id === "ITM"
                  ? { className: styles["bg-gray-very-light"] }
                  : {},
            },
            {
              Header: t("Price"),
              id: "price",
              accessor: item =>
                item.fees ? (
                  <FeeCell
                    total={item.costs.price}
                    header={t("Surcharges")}
                    objects={item.fees.fees}
                    isDetailed
                  />
                ) : isObject(item.costs.price) ? (
                  <FareCell
                    type={
                      item.type.id === "ITM"
                        ? "invoiced_booking"
                        : "invoiced_infant_booking"
                    }
                    fare={item.costs.price}
                    status={item.costs.priceStatus}
                  />
                ) : (
                  item.costs.price
                ),
              sortable: false,
              getProps: (_, row) => {
                const rowProps = { className: "" }

                if (row.original.type.id === "ITM") {
                  rowProps["className"] += `${styles["bg-gray-very-light"]} `
                }

                if (
                  row.original.costs?.priceStatus === "no" ||
                  row.original.fees?.status === "no"
                ) {
                  rowProps["className"] += "text-danger bg-red-light"
                } else if (
                  row.original.costs?.priceStatus === "over" ||
                  row.original.fees?.status === "over"
                ) {
                  rowProps["className"] += "text-warning bg-orange-light"
                }

                return rowProps.className !== "" ? rowProps : {}
              },
              className: "text-right",
            },
            {
              Header: t("Total ex. VAT"),
              id: "totalAmount",
              accessor: item => item.costs.cost,
              sortable: false,
              getProps: (_, row) =>
                row.original.type.id === "ITM"
                  ? { className: styles["bg-gray-very-light"] }
                  : {},
              className: "text-right",
            },
            {
              Header: t("VAT"),
              id: "vat",
              accessor: item => item.costs.vat,
              sortable: false,
              getProps: (_, row) =>
                row.original.type.id === "ITM"
                  ? { className: styles["bg-gray-very-light"] }
                  : {},
              className: "text-right",
            },
            {
              Header: t("Total incl. VAT"),
              id: "total",
              accessor: item =>
                item.type.id !== "ITM" ? (
                  <b>{item.costs.total}</b>
                ) : (
                  item.costs.total
                ),
              sortable: false,
              getProps: (_, row) =>
                row.original.type.id === "ITM"
                  ? { className: styles["bg-gray-very-light"] }
                  : {},
              className: "text-right",
            },
            actionsColumn(item => (
              <>
                {item.type?.id !== "ITM" &&
                data.itemsErasable &&
                hasPermissions(["change_invoices_touroperatorinvoice"]) ? (
                  <Tooltip title={t("Delete Invoice Item")}>
                    <Icon.Link
                      name="remove"
                      color="danger"
                      to={reverse(
                        "tourOperatorInvoiceDeleteInvoiceItem",
                        {
                          id: data.id,
                          itemId: item.id,
                          itemType: item.type?.id,
                        },
                        { next: buildRouteFromLocation(history.location) }
                      )}
                      linkClassName="ml-3"
                    />
                  </Tooltip>
                ) : (
                  <></>
                )}
              </>
            )),
          ]}
          endpoint={`/invoices/tour-operator-invoices/${data.id}/get-invoice-items`}
        />
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <Button
            icon="chevron-left-double"
            onClick={
              history.location.search.includes("next")
                ? () =>
                    history.push(
                      new URLSearchParams(history.location.search).get("next")
                    )
                : () => history.goBack()
            }>
            {t("Back")}
          </Button>
        </div>
      </div>
    </div>
  ) : null
}

TourOperatorInvoiceDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
