import React, { useState } from "react"
import PropTypes from "prop-types"

import Icon from "components/Icon/Icon"

export default function Expand(props) {
  const [expanded, setExpanded] = useState(props.defaultExpanded)

  return (
    <div
      className="ml-auto p-1 d-flex align-items-center"
      role="button"
      onClick={() => setExpanded(prevState => !prevState)}
      data-toggle="collapse"
      data-target={props.target}>
      <Icon
        color={props.color}
        name={expanded ? "chevron-up" : "chevron-down"}
        size={props.size}
      />
    </div>
  )
}

Expand.propTypes = {
  target: PropTypes.string.isRequired,
  defaultExpanded: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
}

Expand.defaultProps = {
  defaultExpanded: false,
  color: "teal",
  size: "md",
}
