import React from "react"
import PropTypes from "prop-types"
import { useFormContext } from "react-hook-form"
import { uniqueId } from "lodash"

import "./Checkbox.scss"

export default function Checkbox({
  label,
  className,
  align,
  error,
  registerProps,
  ...rest
}) {
  const formContext = useFormContext()
  const register = formContext ? formContext.register : null
  const id = `id_checkbox-${uniqueId()}`

  return (
    <>
      <div
        className={`checkbox-container align-${align} ${className} ${
          rest.disabled ? "disabled" : ""
        }`.trim()}>
        {label ? (
          <label htmlFor={id} className="label">
            {label}
          </label>
        ) : null}
        <div className={`checkbox ${rest.error ? "is-invalid" : ""}`}>
          <input
            type="checkbox"
            ref={
              rest.name && register ? register({ ...registerProps }) : undefined
            }
            {...rest}
            id={id}
          />
          <label htmlFor={id}></label>
        </div>
      </div>
      {error ? <div className="invalid-feedback d-block">{error}</div> : null}
    </>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  align: PropTypes.string,
  error: PropTypes.string,
  registerProps: PropTypes.object,
}

Checkbox.defaultProps = {
  name: undefined,
  label: "",
  className: "",
  align: "left",
  error: "",
  registerProps: {},
}
