import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { isString } from "lodash"

import Icon from "components/Icon/Icon"

import "./Button.scss"

export default function Button({
  children,
  type,
  outline,
  size,
  icon,
  modalTarget,
  ...rest
}) {
  const iconProps = isString(icon) ? { name: icon } : icon

  if (iconProps) {
    if (!iconProps.hasOwnProperty("color")) {
      iconProps.color = outline ? type : "white"
    }

    if (!iconProps.hasOwnProperty("size")) {
      iconProps.size = size
    }
  }

  const commonProps = {
    disabled: rest.disabled,
    className: `btn btn${outline ? "-outline" : ""}-${type} ${`btn-${size}`} ${
      iconProps && children ? "has-icon" : ""
    } ${rest.className}`.trim(),
  }

  const renderButton = () => (
    <div className="btn-wrapper">
      {iconProps ? <Icon {...iconProps} /> : null}
      {children !== null && <span className="button-text">{children}</span>}
    </div>
  )

  return rest.to ? (
    <Link to={rest.to} {...commonProps}>
      {renderButton()}
    </Link>
  ) : (
    <button
      type={rest.submit ? "submit" : "button"}
      onClick={rest.onClick}
      {...(modalTarget
        ? { "data-toggle": "modal", "data-target": modalTarget }
        : {})}
      {...commonProps}>
      {renderButton()}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  type: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "light",
    "dark",
  ]).isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  size: PropTypes.string,
  modalTarget: PropTypes.string,
}

Button.defaultProps = {
  children: null,
  type: "primary",
  to: undefined,
  icon: undefined,
  className: "",
  disabled: false,
  outline: false,
  submit: false,
  size: "md",
  modalTarget: undefined,
}
