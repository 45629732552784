import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Form, FormField } from "components/Form"
import { apiGET, apiPOST } from "utils/api"
import { reverse } from "utils/urls"

export default function ExchangeRateCreate(props) {
  const [baseCurrency, setBaseCurrency] = useState()
  const [currencies, setCurrencies] = useState()

  const { t } = useTranslation()

  useEffect(
    () =>
      !baseCurrency
        ? apiGET({
            path: "/app-settings/detail/",
            onSuccess: response => setBaseCurrency(response.currency),
            onError: () => props.history.push(reverse("home")),
          })
        : () => {},
    [props.history, baseCurrency]
  )

  useEffect(
    () =>
      !currencies && baseCurrency
        ? apiGET({
            path: "/currencies/?ordering=code",
            onSuccess: response =>
              setCurrencies(
                response.results.filter(
                  currency => currency.id !== baseCurrency.id
                )
              ),
            onError: () => props.history.push(reverse("home")),
          })
        : () => {},
    [props.history, currencies, baseCurrency]
  )

  return baseCurrency !== undefined && currencies !== undefined ? (
    <Form
      endpoint="/currencies/exchange-rates/"
      apiFunction={apiPOST}
      successUrl={reverse("exchangeRateList")}
      successMessage={t("Exchange Rates were successfully saved.")}
      title={t("Create Exchange Rates")}>
      {currencies.map((currency, i) => (
        <div className="row" key={i}>
          <div className="col-8">
            <div className="row">
              <div className="col-4">
                <FormField
                  name={`rates[${i}].fromCurrency`}
                  fieldType="input"
                  type="hidden"
                  label=""
                  defaultValue={baseCurrency.id}
                />
                <FormField
                  name={`rates[${i}].fromCurrencyCode`}
                  fieldType="input"
                  type="text"
                  label={t("From")}
                  disabled
                  defaultValue={baseCurrency.displayName}
                />
              </div>
              <div className="col-4">
                <FormField
                  name={`rates[${i}].toCurrency`}
                  fieldType="input"
                  type="hidden"
                  label=""
                  defaultValue={currency.id}
                />
                <FormField
                  name={`rates[${i}].toCurrencyCode`}
                  fieldType="input"
                  type="text"
                  label={t("To")}
                  disabled
                  defaultValue={currency.displayName}
                />
              </div>
              <div className="col-4">
                <FormField
                  name={`rates[${i}].rate`}
                  fieldType="input"
                  type="number"
                  min={0}
                  step={0.01}
                  label={t("Rate")}
                  required
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </Form>
  ) : (
    <></>
  )
}

ExchangeRateCreate.propTypes = {
  history: PropTypes.object.isRequired,
}
