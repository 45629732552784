import React from "react"

import CurrencyForm from "apps/currencies/Currency/CurrencyForm"
import { reverse } from "utils/urls"

export default function CurrencyCreate(props) {
  return (
    <CurrencyForm
      {...props}
      actionEndpoint="/currencies/"
      successUrl={reverse("currencyList")}
    />
  )
}
