import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useGlobal } from "reactn"
import { useTranslation } from "react-i18next"
import { useLastLocation } from "react-router-last-location"
import { isEmpty } from "lodash"

import BookingTypeForm from "./BookingTypeForm"
import GroupFineFormSet from "./GroupFineFormSet"
import GroupFineTypeSelect from "./GroupFineTypeSelect"
import { useSegmentSelectionType } from "apps/wizardForms/SegmentSelection"
import Steps from "apps/wizardForms/Steps/Steps"
import FlightSummary from "apps/flights/FlightSummary/FlightSummary"
import { getEnum } from "apps/core/utils"
import { validateBookingForm } from "apps/bookings/utils"
import Card from "components/Card/Card"
import { Form, FormField } from "components/Form"
import Button from "components/Button/Button"
import { apiStoreGET, apiPOST } from "utils/api"
import { notify } from "utils/notifications"
import { reverse } from "utils/urls"

import "./BookingCreate.scss"

export default function BookingCreate(props) {
  const segmentSelectionType = useSegmentSelectionType()
  const isAirline = segmentSelectionType === "airlineBooking"
  const [data, setData] = useGlobal(`${segmentSelectionType}FormData`)

  const bookingFormFlightType = useGlobal(
    `${segmentSelectionType}FormFlightType`
  )
  const flightType = bookingFormFlightType[0] || "single"
  const setFlightType = bookingFormFlightType[1]

  const bookingFormSegments = useGlobal(`${segmentSelectionType}FormSegments`)
  const setSegments = bookingFormSegments[1]

  const BOOKING_TYPE_ENUM = getEnum("bookingType")

  const [legFares, setLegFares] = useState({})

  const { t } = useTranslation()

  const lastLocation = useLastLocation()

  useEffect(() => {
    return props.history.listen(location => {
      if (location.pathname !== reverse(`${segmentSelectionType}CreateStep1`)) {
        setData(undefined)
        setFlightType(undefined)
        setSegments(undefined)
      }
    })
  }, [props.history, setData, setFlightType, segmentSelectionType, setSegments])

  useEffect(() => {
    if (isEmpty(data) || !data.segments) {
      props.history.push(reverse(`${segmentSelectionType}CreateStep1`))
    }
  }, [data, props.history, segmentSelectionType])

  const getFare = useCallback(
    fareData => {
      apiPOST({
        path: isAirline
          ? "/fares/airline-fares/booking-creation-fares/"
          : "/fares/tour-operator-fares/booking-creation-fares/",
        opts: {
          body: fareData,
        },
        onSuccess: response => setLegFares(response),
        onError: () =>
          notify(
            "error",
            t("There were some errors while trying to get fares.")
          ),
      })
    },
    [t, isAirline]
  )

  useEffect(() => {
    if (isEmpty(legFares) && !isEmpty(data)) {
      const flyingSubject = isAirline
        ? { airline: data.airline.id }
        : { tourOperator: data.tourOperator.id }
      const departureAirport = data.firstDepartureAirport.id
      const arrivalAirport = data.firstArrivalAirport.id

      const segments = Object.entries(data.segments)
        .map(([k, v]) => (v === "true" ? k.split("-")[1] : null))
        .filter(s => s !== null)

      const fareData = {
        ...flyingSubject,
        departureAirport,
        arrivalAirport,
        segments,
      }

      if (["return", "multi"].includes(flightType)) {
        if (flightType === "return") {
          fareData["secondDepartureAirport"] = data.firstArrivalAirport.id
          fareData["secondArrivalAirport"] = data.firstDepartureAirport.id
        } else if (flightType === "multi") {
          fareData["secondDepartureAirport"] = data.secondDepartureAirport.id
          fareData["secondArrivalAirport"] = data.secondArrivalAirport.id
        }
      }

      getFare(fareData)
    }
  }, [legFares, data, getFare, t, flightType, isAirline])

  // cache currency choices on load
  useEffect(() => {
    apiStoreGET({
      path: `/currencies/?page_size=none`,
      onSuccess: () => {},
    })
  })

  const [datesData, setDatesData] = useState()

  const getSelectedSegments = useCallback(
    () =>
      Object.entries(data.segments || {})
        .map(([k, v]) => (v === "true" ? k.split("-")[1] : null))
        .filter(s => s !== null),
    [data.segments]
  )

  useEffect(() => {
    const segments = getSelectedSegments()
    if (!datesData && segments.length) {
      apiPOST({
        path: "/flights/segments/segments-dates-data/",
        opts: {
          body: { segments },
        },
        onSuccess: response => setDatesData(response),
        onError: () =>
          notify(
            "error",
            t(
              "There were some errors while trying to get information about flight dates."
            )
          ),
      })
    }
  }, [datesData, setDatesData, t, getSelectedSegments])

  return (
    <>
      <Steps step="step2" />
      <h1>
        {isAirline ? t("Airline") : t("Tour Operator")} {t("Booking creation")}
      </h1>
      <Form
        showBack={false}
        showSubmit={false}
        apiFunction={apiPOST}
        endpoint={
          isAirline
            ? "/bookings/airline-bookings/"
            : "/bookings/tour-operator-bookings/"
        }
        defaultValues={{
          [`${BOOKING_TYPE_ENUM.GROUP}.fines`]: [
            { date: "0", penalty: "100", freeCancelation: "0" },
          ],
        }}
        successUrl={
          data.source === "sheet"
            ? `${lastLocation.pathname}${lastLocation.search}`
            : isAirline
            ? reverse("airlineBookingList")
            : reverse("tourOperatorBookingList")
        }
        successMessage={t("Bookings were successfully saved.")}
        processData={d => ({
          ...data,
          ...d,
        })}
        validateForm={values =>
          validateBookingForm(values, BOOKING_TYPE_ENUM, legFares, t)
        }>
        <>
          <FlightSummary
            datesData={datesData}
            getSelectedSegments={getSelectedSegments}
          />
          <h5 className="mt-4">{t("Add booking info")}</h5>
          <Card className="p-2">
            <div className="row">
              <div className="col-3">
                <FormField
                  name="receivedDate"
                  fieldType="date"
                  label={t("Received date")}
                  required
                />
              </div>
              {getSelectedSegments().length !== 1 ? (
                <div className="offset-1 col-3">
                  <FormField
                    fieldType="input"
                    name="invoiceDateOffset"
                    type="number"
                    min={0}
                    label={t("Invoice date")}
                    required
                    inputGroupPrepend={
                      <span className="input-group-text">D-</span>
                    }
                  />
                </div>
              ) : datesData !== undefined ? (
                <div className="offset-1 col-4">
                  <FormField
                    fieldType="dateAndOffset"
                    name="invoiceDate"
                    label={t("Invoice date")}
                    offsetName="invoiceDateOffset"
                    offsetLabel={t("Invoice date")}
                    referenceDate={datesData.startDate}
                    required
                  />
                </div>
              ) : null}
              <div className="offset-1 col-3">
                <FormField
                  name="requestReference"
                  fieldType="file"
                  className="text-center"
                  label={t("Add request reference")}
                />
              </div>
            </div>
          </Card>
          <h5 className="mt-4">{t("Select booking types")}</h5>
          <BookingTypeForm
            fareType={BOOKING_TYPE_ENUM.BLOCK}
            title={t("Block")}
            legFares={legFares[BOOKING_TYPE_ENUM.BLOCK]}
            leftChildren={
              <>
                <FormField
                  fieldType="input"
                  type="hidden"
                  name="flightType"
                  label=""
                  defaultValue={flightType}
                />
                <FormField
                  fieldType="input"
                  type="number"
                  name={`${BOOKING_TYPE_ENUM.BLOCK}.seats`}
                  label={t("Seats")}
                  min={1}
                />
                <FormField
                  fieldType="input"
                  type="text"
                  name={`${BOOKING_TYPE_ENUM.BLOCK}.name`}
                  label={t("Block name")}
                />
                <FormField
                  fieldType="input"
                  type="text"
                  name={`${BOOKING_TYPE_ENUM.BLOCK}.note`}
                  label={t("Notes")}
                />
              </>
            }
          />
          <BookingTypeForm
            fareType={BOOKING_TYPE_ENUM.ALLOTMENT}
            title={t("Allotment")}
            legFares={legFares[BOOKING_TYPE_ENUM.ALLOTMENT]}
            leftChildren={
              <>
                <FormField
                  fieldType="input"
                  type="number"
                  name={`${BOOKING_TYPE_ENUM.ALLOTMENT}.seats`}
                  label={t("Seats")}
                  min={1}
                />
                <FormField
                  fieldType="input"
                  type="text"
                  name={`${BOOKING_TYPE_ENUM.ALLOTMENT}.name`}
                  label={t("Allotment name")}
                />
                <FormField
                  fieldType="input"
                  type="text"
                  name={`${BOOKING_TYPE_ENUM.ALLOTMENT}.note`}
                  label={t("Notes")}
                />
              </>
            }
            rightChildren={
              <div className="row">
                <h6 className="mb-2">{t("Conditions")}</h6>
                <div className="col-6">
                  <FormField
                    fieldType="input"
                    type="number"
                    name={`${BOOKING_TYPE_ENUM.ALLOTMENT}.releaseDate1`}
                    label={t("Allotment type release date")}
                    min={0}
                    inputGroupPrepend={
                      <span className="input-group-text">D-</span>
                    }
                  />
                </div>
              </div>
            }
          />
          <BookingTypeForm
            fareType={BOOKING_TYPE_ENUM.GIR}
            title={t("GIR")}
            legFares={legFares[BOOKING_TYPE_ENUM.GIR]}
            leftChildren={
              <>
                <FormField
                  fieldType="input"
                  type="number"
                  name={`${BOOKING_TYPE_ENUM.GIR}.seats`}
                  label={t("Seats")}
                  min={1}
                />
                <FormField
                  fieldType="input"
                  type="text"
                  name={`${BOOKING_TYPE_ENUM.GIR}.name`}
                  label={t("GIR name")}
                />
                <FormField
                  fieldType="input"
                  type="text"
                  name={`${BOOKING_TYPE_ENUM.GIR}.note`}
                  label={t("Notes")}
                />
              </>
            }
            rightChildren={
              <div className="row">
                <h6 className="mb-2">{t("Conditions")}</h6>
                <div className="col-6">
                  <FormField
                    fieldType="input"
                    type="number"
                    name={`${BOOKING_TYPE_ENUM.GIR}.releaseDate1`}
                    label={t("GIR type 1st release date")}
                    min={0}
                    inputGroupPrepend={
                      <span className="input-group-text">D-</span>
                    }
                  />
                </div>
                <div className="col-6">
                  <FormField
                    fieldType="input"
                    type="number"
                    name={`${BOOKING_TYPE_ENUM.GIR}.releasePercentage`}
                    label={t("GIR seat release percentage")}
                    min={1}
                    max={100}
                    inputGroupAppend={
                      <span className="input-group-text">%</span>
                    }
                  />
                </div>
                <div className="col-6">
                  <FormField
                    fieldType="input"
                    type="number"
                    name={`${BOOKING_TYPE_ENUM.GIR}.releaseDate2`}
                    label={t("GIR type 2nd release date")}
                    min={0}
                    inputGroupPrepend={
                      <span className="input-group-text">D-</span>
                    }
                  />
                </div>
              </div>
            }
          />
          <BookingTypeForm
            fareType={BOOKING_TYPE_ENUM.GROUP}
            title={t("Group")}
            legFares={legFares[BOOKING_TYPE_ENUM.GROUP]}
            leftChildren={
              <>
                <FormField
                  fieldType="input"
                  type="hidden"
                  name={`${BOOKING_TYPE_ENUM.GROUP}.fineFreeCancelationType`}
                  label=""
                  defaultValue="percentage"
                />
                <FormField
                  fieldType="input"
                  type="hidden"
                  name={`${BOOKING_TYPE_ENUM.GROUP}.finePenaltyType`}
                  label=""
                  defaultValue="percentage"
                />
                <FormField
                  fieldType="input"
                  type="number"
                  name={`${BOOKING_TYPE_ENUM.GROUP}.seats`}
                  label={t("Seats")}
                  min={1}
                />
                <FormField
                  fieldType="input"
                  type="text"
                  name={`${BOOKING_TYPE_ENUM.GROUP}.name`}
                  label={t("Group name")}
                />
                <FormField
                  fieldType="input"
                  type="text"
                  name={`${BOOKING_TYPE_ENUM.GROUP}.note`}
                  label={t("Notes")}
                />
              </>
            }
            rightChildren={
              <div className="row">
                <h6 className="mb-2">{t("Conditions")}</h6>
                <div className="col-10">
                  <div className="row my-3">
                    <div className="offset-4 col-4">
                      <GroupFineTypeSelect
                        types={["percentage", "fixed"]}
                        name={`${BOOKING_TYPE_ENUM.GROUP}.finePenaltyType`}
                        labels={[t("%"), t("Fixed")]}
                      />
                    </div>
                    <div className="col-4">
                      <GroupFineTypeSelect
                        types={["percentage", "seats"]}
                        name={`${BOOKING_TYPE_ENUM.GROUP}.fineFreeCancelationType`}
                        labels={[t("%"), t("Seats")]}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <GroupFineFormSet name={`${BOOKING_TYPE_ENUM.GROUP}.fines`} />
                </div>
              </div>
            }
          />
          <BookingTypeForm
            fareType={BOOKING_TYPE_ENUM.OVERFLOW}
            title={t("Overflow")}
            legFares={legFares[BOOKING_TYPE_ENUM.OVERFLOW]}
            leftChildren={
              <>
                <FormField
                  fieldType="input"
                  type="number"
                  name={`${BOOKING_TYPE_ENUM.OVERFLOW}.seats`}
                  label={t("Seats")}
                  min={1}
                />
                <FormField
                  fieldType="input"
                  type="text"
                  name={`${BOOKING_TYPE_ENUM.OVERFLOW}.name`}
                  label={t("Overflow name")}
                />
                <FormField
                  fieldType="input"
                  type="text"
                  name={`${BOOKING_TYPE_ENUM.OVERFLOW}.note`}
                  label={t("Notes")}
                />
              </>
            }
          />
          <div className="row mt-5">
            <div className="col-auto">
              <Button
                {...(data.source === "sheet"
                  ? { icon: "chevron-left-double" }
                  : {})}
                onClick={() =>
                  data.source === "sheet"
                    ? // use lastlocation instead of goBack to reset global data
                      props.history.push(
                        `${lastLocation.pathname}${lastLocation.search}`
                      )
                    : props.history.push(
                        reverse(`${segmentSelectionType}CreateStep1`)
                      )
                }>
                {data.source === "sheet" ? t("Back") : t("Previous step")}
              </Button>
            </div>
            <div className="ml-auto col-auto">
              <Button submit>{t("Finish")}</Button>
            </div>
          </div>
        </>
      </Form>
    </>
  )
}

BookingCreate.propTypes = {
  history: PropTypes.object.isRequired,
}
