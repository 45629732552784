import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

import Detail from "components/GenericViews/Detail"
import { reverse } from "utils/urls"

export default function DateRangeDetail(props) {
  const { t } = useTranslation()

  return (
    <Detail
      title={t("Date range details")}
      detailEndpoint={`/date-ranges/${props.match.params.id}/`}
      listRoute={reverse("dateRangeList")}
      changePermission={["change_core_daterange"]}
      changeRoute={reverse("dateRangeUpdate", { id: props.match.params.id })}
      deletePermission={["delete_core_daterange"]}
      deleteRoute={reverse("dateRangeDelete", { id: props.match.params.id })}
      historyRoute={reverse("history", {
        id: props.match.params.id,
        model: "date-ranges",
      })}
      fields={[
        [
          { label: t("Name"), key: "name", size: "col-4" },
          { label: t("Date from"), key: "dateFrom", size: "col-4" },
          { label: t("Date to"), key: "dateTo", size: "col-4" },
        ],
      ]}
    />
  )
}

DateRangeDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
