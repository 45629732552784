import React from "react"
import { withRouter } from "react-router-dom"

import "./LoadingOverlay.scss"

const ref = React.createRef()

class LoadingOverlayContainer extends React.Component {
  render = () => <LoadingOverlay ref={ref} />
}

class LoadingOverlay extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showLoading: false,
    }
  }

  showLoadingOverlay = () =>
    this.setState({
      showLoading: true,
    })

  hideLoadingOverlay = () => this.setState({ showLoading: false })

  render() {
    return this.state.showLoading ? (
      <div className="spinner">
        <div className="bounce"></div>
        <div className="bounce-next"></div>
        <div></div>
      </div>
    ) : null
  }
}

export const showLoadingOverlay = () => {
  if (ref && ref.current) {
    ref.current.showLoadingOverlay()
  }
}

export const hideLoadingOverlay = () => {
  if (ref && ref.current) {
    ref.current.hideLoadingOverlay()
  }
}

export default withRouter(LoadingOverlayContainer)
