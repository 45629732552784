import React from "react"
import { setGlobal, getGlobal } from "reactn"
import { Trans } from "react-i18next"

import {
  showLoadingOverlay,
  hideLoadingOverlay,
} from "../components/LoadingOverlay/LoadingOverlay"
import { API_URL } from "./constants"
import { getAuthHeaders } from "apps/auth/auth"
import { notify } from "utils/notifications"
import history from "utils/history"
import { route } from "utils/urls"

const api = params => {
  showLoadingOverlay()

  if (
    "body" in params.opts &&
    typeof params.opts.body === "object" &&
    params.opts.body !== null
  ) {
    params.opts.body = JSON.stringify(params.opts.body)
  }

  const options = {
    ...{
      headers: {
        "Content-Type": "application/json",
        ...getAuthHeaders(),
      },
    },
    ...params.opts,
  }

  fetch(buildApiUrl(params.path), options)
    .then(checkStatus)
    .then(parseJSON)
    .then(response => {
      hideLoadingOverlay()
      return params.onSuccess(response)
    })
    .catch(error => {
      hideLoadingOverlay()

      if (!error.response) {
        notify(
          "error",
          <Trans i18nKey="500errorMessage">
            There were some errors while trying to process your request.
            Administrators will be notified about it.
          </Trans>
        )
        window.scroll({ top: 0, left: 0, behavior: "smooth" })
      }

      console.error(error)
      if (params.onError) {
        return params.onError(error)
      }
    })
}

export const parseJSON = response =>
  response.status === 204 ? {} : response.json()

export const checkStatus = async response => {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    if (response.status === 403) {
      history.push(route("home"))
    }

    let res = await parseJSON(response)
    const errorMessage = res.error || res.message || res.statusText
    let error = new Error(errorMessage)

    res.errorMessage = errorMessage
    error.response = res
    error.status = response.status

    throw error
  }
}

export const buildApiUrl = path => (API_URL ? API_URL + path : path)

export const apiGET = params => {
  params.opts = params.opts || {}
  params.opts.method = "GET"
  api(params)
}

export const apiPOST = params => {
  params.opts = params.opts || {}
  params.opts.method = "POST"
  return api(params)
}

export const apiPUT = params => {
  params.opts = params.opts || {}
  params.opts.method = "PUT"
  return api(params)
}

export const apiDELETE = params => {
  params.opts = params.opts || {}
  params.opts.method = "DELETE"
  return api(params)
}

/*
  Special superset function to the apiGET which knows how to cache specific
  path request responses to global state and return cached response if the
  same path has been encountered subsequently.
*/
export const apiStoreGET = params => {
  const apiStore = getGlobal().apiStore

  if (
    params.namespace &&
    params.namespace in apiStore &&
    params.path in apiStore[params.namespace]
  ) {
    params.onSuccess(apiStore[params.namespace][params.path])
  } else if (params.path in apiStore) {
    params.onSuccess(apiStore[params.path])
  } else {
    apiGET({
      path: params.path,
      onSuccess: response => {
        if (params.namespace) {
          const namespace = { ...(apiStore[params.namespace] || {}) }
          namespace[params.path] = response
          apiStore[params.namespace] = namespace
        } else {
          apiStore[params.path] = response
        }

        setGlobal({ apiStore })
        params.onSuccess(response)
      },
      onError: params.onError,
    })
  }
}

export const fileGET = path => {
  showLoadingOverlay()
  const options = {
    headers: {
      ...getAuthHeaders(),
    },
    method: "GET",
  }
  fetch(buildApiUrl(path), options)
    .then(checkStatus)
    .then(response =>
      Promise.all([
        response.blob(),
        response.headers.get("content-disposition").split('"')[1],
      ])
    )
    .then(([blob, filename]) => {
      hideLoadingOverlay()
      const href = window.URL.createObjectURL(blob)
      const link = document.createElement("a")

      link.href = href
      link.setAttribute("download", filename)

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
    .catch(() => {
      hideLoadingOverlay()
      notify(
        "error",
        <Trans i18nKey="500errorMessage">
          There were some errors while trying to process your request.
          Administrators will be notified about it.
        </Trans>
      )
      window.scroll({ top: 0, left: 0, behavior: "smooth" })
    })
}
