import React from "react"
import PropTypes from "prop-types"
import { useFormContext, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { FormField } from "components/Form"
import SectionHeader from "components/SectionHeader/SectionHeader"

export default function Summary({ flightType }) {
  const { control } = useFormContext()
  const passengers = useWatch({
    control,
    name: "passengers",
    defaultValue: [],
  })
  const { t } = useTranslation()

  const adults = passengers.filter(p => p.passengerType === "ADULT").length
  const children = passengers.filter(p => p.passengerType === "CHILD").length
  const infants = passengers.filter(p => p.passengerType === "INFANT").length
  const petc = passengers.filter(p => p.passengerType === "PETC").length

  return (
    <div className="row mt-4">
      <div className="col-12">
        <SectionHeader header={t("Summary")} />
      </div>
      <div className="col-12 mt-3">
        <div className="row">
          <div className="col-3">
            <FormField
              fieldType="input"
              type="text"
              name="departurePNR"
              label={
                flightType === "single"
                  ? t("Airline PNR")
                  : t("Departure airline PNR")
              }
              icon="airplane"
              defaultValue={control.defaultValuesRef?.current?.airlinePnr || ""}
            />
          </div>
          <div className="col-6">
            <div className="d-flex align-items-center py-2 px-3 bg-light mt-2 mb-3">
              <div className="row">
                <div className="col text-center">
                  <b>{t("Total")}</b>
                </div>
                <div className="col text-center">
                  <b>{t("ADT")}</b>
                </div>
                <div className="col text-center">
                  <b>{t("CHD")}</b>
                </div>
                <div className="col text-center">
                  <b>{t("INF")}</b>
                </div>
                <div className="col text-center">
                  <b>{t("PetC")}</b>
                </div>
              </div>
            </div>
            <div className="d-flex px-3 pb-2 border-bottom border-light">
              <div className="row">
                <div className="col text-center">
                  {`${adults + children}${infants ? ` + ${infants}` : ""}${
                    petc ? ` + ${petc}` : ""
                  }`}
                </div>
                <div className="col text-center">{adults}</div>
                <div className="col text-center">{children}</div>
                <div className="col text-center">{infants}</div>
                <div className="col text-center">{petc}</div>
              </div>
            </div>
          </div>
          {flightType !== "single" && (
            <div className="col-3">
              <FormField
                fieldType="input"
                type="text"
                name="returnPNR"
                label={t("Return airline PNR")}
                icon="airplane"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

Summary.propTypes = {
  flightType: PropTypes.oneOf(["single", "return"]).isRequired,
}
