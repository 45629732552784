import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

export default function TdComponent({
  className,
  children,
  width,
  isResized,
  ...rest
}) {
  if ((typeof width === "string" || width instanceof String) && !isResized) {
    rest.style = {
      flex: `${width.replace(/\D/g, "")} 0 auto`,
      width,
      maxWidth: width,
    }
  }

  return (
    <div className={classnames("rt-td", className)} role="gridcell" {...rest}>
      {children}
    </div>
  )
}

TdComponent.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isResized: PropTypes.bool,
}

TdComponent.defaultProps = {
  width: undefined,
  children: null,
  isResized: false,
}
