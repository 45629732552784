import React from "react"

import TicketDefaultForm from "apps/tickets/TicketDefault/TicketDefaultForm"
import { reverse } from "utils/urls"

export default function TicketDefaultCreate(props) {
  return (
    <TicketDefaultForm
      {...props}
      actionEndpoint="/tickets/ticket-defaults/"
      successUrl={reverse("ticketDefaultList")}
    />
  )
}
