const routes = {
  segmentObjectsList: "/flights/segment/:id/:objType/:objId",
  flightSeriesUpdate: "/flights/series/:id/update",
  flightSeriesDelete: "/flights/series/:id/delete",
  flightSeriesDetail: "/flights/series/:id",
  flightSeriesList: "/flights/series",
  flightCreate: "/flights/create",
  flightDetail: "/flights/:id",
}

export default routes
