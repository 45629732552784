import { isArray } from "lodash"

export const getParentColumnWidth = indexes => {
  const headers = document.getElementsByClassName("rt-th rt-resizable-header")
  let width = 0

  if (!isArray(indexes)) {
    indexes = [indexes]
  }

  indexes.forEach(idx => {
    width += headers[idx].offsetWidth
  })

  return width
}
