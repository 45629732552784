import {
  RoutingCreate,
  RoutingUpdate,
  RoutingDelete,
  RoutingDetail,
  RoutingList,
} from "apps/routings/Routing"
import { route } from "utils/urls"

export const PROTECTED_ROUTES = [
  route("routingCreate", RoutingCreate, ["add_routings_routing"]),
  route("routingUpdate", RoutingUpdate, ["change_routings_routing"]),
  route("routingDelete", RoutingDelete, ["delete_routings_routing"]),
  route("routingDetail", RoutingDetail, ["view_routings_routing"]),
  route("routingList", RoutingList, ["view_routings_routing"]),
]
