import React, { useState } from "react"
import PropTypes from "prop-types"
import { useFormContext, Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { get } from "lodash"
import { ConfigProvider, DatePicker } from "antd"
import en_GB from "antd/lib/locale-provider/en_GB"
import moment from "moment"
import "moment/locale/en-gb"

import { getAppInfo } from "apps/core/utils"
import Icon from "components/Icon/Icon"
import { DATE_FORMAT } from "utils/constants"

export default function DateField({
  onChange: onChangeProp,
  className,
  name,
  registerProps,
  defaultValue,
  required,
  format,
  ...rest
}) {
  const { errors, control } = useFormContext()
  const [focused, setFocused] = useState(false)

  const { t } = useTranslation()

  return (
    <Controller
      render={({ onChange, onBlur, value, ...restRender }) => {
        const error = get(errors, name)

        return (
          <ConfigProvider
            locale={getAppInfo().weekdays[0].id === "MON" ? en_GB : undefined}>
            <DatePicker
              format={format}
              suffixIcon={<Icon name="calendar" color="magenta" />}
              clearIcon={<Icon name="remove" />}
              placeholder=""
              {...restRender}
              {...rest}
              value={value ? moment(value, format) : null}
              className={`${className} date-field form-control form-field ${
                focused || value ? "has-floating-label" : ""
              } ${error ? "is-invalid" : ""}`}
              onChange={(date, dateString) => {
                if (!date) {
                  setFocused(false)
                  restRender.ref.current.blur()
                }
                onChangeProp(dateString)
                onChange(dateString || null)
              }}
              onSelect={date => {
                const val = date ? date.format(format) : null
                onChangeProp(val)
                onChange(val || null)
              }}
              onFocus={() => setFocused(true)}
              onBlur={e => {
                setFocused(false)
                onBlur(e)
              }}
            />
            {error ? (
              <div className="invalid-feedback d-block">{error.message}</div>
            ) : null}
          </ConfigProvider>
        )
      }}
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{
        ...registerProps,
        validate: value => {
          if (required && !value) {
            return t("This field is required.")
          }

          return registerProps.validate ? registerProps.validate(value) : true
        },
      }}
    />
  )
}

DateField.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  registerProps: PropTypes.object,
  defaultValue: PropTypes.string,
  required: PropTypes.bool.isRequired,
  format: PropTypes.string,
}

DateField.defaultProps = {
  onChange: () => {},
  className: "",
  registerProps: {},
  defaultValue: "",
  format: DATE_FORMAT,
}
