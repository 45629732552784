import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { isEmpty } from "lodash"

import { getAppInfo } from "apps/core/utils"
import { apiPUT, apiGET, apiPOST } from "utils/api"
import { Form, FormField } from "components/Form"
import SectionHeader from "components/SectionHeader/SectionHeader"
import { reverse } from "utils/urls"

export default function TicketDefaultForm(props) {
  const [formData, setFormData] = useState({})
  const [generalDefaultData, setGeneralDefaultData] = useState({})
  const [selectedAirline, setSelectedAirline] = useState()

  const { t } = useTranslation()

  const appInfo = getAppInfo()

  useEffect(
    () =>
      props.isUpdate
        ? apiGET({
            path: `/tickets/ticket-defaults/${props.match.params.id}/`,
            onSuccess: response => {
              setFormData(response)
              setSelectedAirline(response.airline)
            },
            onError: () => props.history.push(reverse("ticketDefaultList")),
          })
        : () => {},
    [props.isUpdate, props.match.params.id, props.history]
  )

  useEffect(
    () =>
      selectedAirline
        ? apiGET({
            path: `/tickets/ticket-defaults/default-info/?airline=${selectedAirline.id}`,
            onSuccess: response => setGeneralDefaultData(response),
            onError: () => props.history.push(reverse("ticketDefaultList")),
          })
        : () => {},
    [selectedAirline, props.history]
  )

  return (
    <div>
      <Form
        endpoint={props.actionEndpoint}
        apiFunction={props.isUpdate ? apiPUT : apiPOST}
        defaultValues={isEmpty(formData) ? generalDefaultData : formData}
        successUrl={props.successUrl}
        validateForm={values => {
          const errors = []

          const cabinBaggage = Object.values(values.cabinbaggage)
          const cabinAccessory = Object.values(values.cabinaccessory)
          const checkedBaggage = Object.values(values.checkedbaggage)

          if (
            ![
              values.totalCabinWeight,
              ...cabinBaggage,
              ...cabinAccessory,
              ...checkedBaggage,
            ].some(el => el)
          ) {
            errors.push(t("At least one type of baggage must be entered."))
          }

          if (
            values.totalCabinWeight &&
            ![...cabinBaggage, ...cabinAccessory].some(el => el)
          ) {
            errors.push(
              t(
                "When setting `Total cabin weight` both Cabin Accessory and Cabin Baggage must be set."
              )
            )
          }

          ;[
            { values: values.cabinbaggage, name: t("Cabin Baggage") },
            { values: values.cabinaccessory, name: t("Cabin Accessory") },
            { values: values.checkedbaggage, name: t("Checked Baggage") },
          ].forEach(data => {
            if (Object.values(data.values).some(el => el)) {
              const hwl = [
                data.values.height,
                data.values.width,
                data.values["length"],
              ]

              if (
                (!data.values.dimensions && !hwl.every(el => el)) ||
                (data.values.dimensions && hwl.some(el => el))
              ) {
                errors.push(
                  data.name +
                    " - " +
                    t(
                      "Either `L + H + W` or `Length`, `Height` and `Width` must be defined."
                    )
                )
              }
            }
          })

          if (checkedBaggage.some(el => el) && !values.checkedbaggage.weight) {
            errors.push(
              t("Checked Baggage") + " - " + t("`Weight` is required field.")
            )
          }

          if (
            cabinBaggage.some(el => el) &&
            !values.cabinbaggage.weight &&
            !values.totalCabinWeight
          ) {
            errors.push(
              t("Cabin Baggage") +
                " - " +
                t("Either `Weight` or `Total Cabin Weight` must be defined.")
            )
          }

          if (
            cabinAccessory.some(el => el) &&
            !values.cabinaccessory.weight &&
            !values.totalCabinWeight
          ) {
            errors.push(
              t("Cabin Accessory") +
                " - " +
                t("Either `Weight` or `Total Cabin Weight` must be defined.")
            )
          }

          return errors.length ? errors : true
        }}
        successMessage={t("Ticket Default Values were successfully saved.")}
        title={
          props.isUpdate
            ? t("Edit ticket default values")
            : t("Add ticket default values")
        }>
        <div className="row my-4">
          <div className="col-12">
            <div className="row">
              <div className="col-4 col-xxl-3">
                <FormField
                  name="airline"
                  fieldType="asyncSelect"
                  label={t("Airline")}
                  endpoint="/airlines/"
                  required
                  onChange={selected => setSelectedAirline(selected)}
                  disabled={props.isUpdate}
                  icon="airplane"
                />
              </div>
              {(!isEmpty(formData) && formData.routing !== null) ||
              (isEmpty(formData) && generalDefaultData.id) ? (
                <div className="col-4 col-xxl-3">
                  <FormField
                    name="routing"
                    fieldType="asyncSelect"
                    label={t("Routing")}
                    endpoint="/routings/"
                    required
                  />
                </div>
              ) : null}
            </div>
          </div>
          {selectedAirline ? (
            <>
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-xxl-6 mt-3">
                    <div className="row">
                      <div className="col-6">
                        <SectionHeader header={t("Catering")} />
                        <FormField
                          name="mealType"
                          fieldType="select"
                          label={t("Meal type")}
                          endpoint="/tickets/ticket-defaults/meal-types/"
                          storeChoices
                          required
                        />
                      </div>
                      <div className="col-6">
                        <SectionHeader header={t("CHD Age")} />
                        <FormField
                          name="maxChildAge"
                          fieldType="input"
                          type="number"
                          min="1"
                          max="18"
                          label={t("Maximum CHD Age")}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xxl-6 mt-3">
                    <SectionHeader header={t("Checked baggage")} />
                    <div className="row">
                      <div className="col-6">
                        <FormField
                          name="checkedbaggage.pieces"
                          fieldType="input"
                          type="number"
                          min="1"
                          label={t("Piece(s)")}
                        />
                      </div>
                      <div className="col-6">
                        <FormField
                          name="checkedbaggage.weight"
                          fieldType="input"
                          type="number"
                          min="0.01"
                          step="0.01"
                          label={t("Weight")}
                          inputGroupAppend={
                            <span className="input-group-text">
                              {appInfo.massUnit.toLowerCase()}
                            </span>
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="row">
                          <div className="col-6">
                            <FormField
                              name="checkedbaggage.dimensions"
                              fieldType="input"
                              type="number"
                              min="0.1"
                              step="0.1"
                              label={t("L + H + W")}
                              inputGroupAppend={
                                <span className="input-group-text">
                                  {appInfo.lengthUnit.toLowerCase()}
                                </span>
                              }
                            />
                          </div>
                          <div className="col-6">
                            <FormField
                              name="checkedbaggage.length"
                              fieldType="input"
                              type="number"
                              min="0.01"
                              step="0.01"
                              label={t("Length")}
                              inputGroupAppend={
                                <span className="input-group-text">
                                  {appInfo.lengthUnit.toLowerCase()}
                                </span>
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="row">
                          <div className="col-6">
                            <FormField
                              name="checkedbaggage.height"
                              fieldType="input"
                              type="number"
                              min="0.01"
                              step="0.01"
                              label={t("Height")}
                              inputGroupAppend={
                                <span className="input-group-text">
                                  {appInfo.lengthUnit.toLowerCase()}
                                </span>
                              }
                            />
                          </div>
                          <div className="col-6">
                            <FormField
                              name="checkedbaggage.width"
                              fieldType="input"
                              type="number"
                              min="0.01"
                              step="0.01"
                              label={t("Width")}
                              inputGroupAppend={
                                <span className="input-group-text">
                                  {appInfo.lengthUnit.toLowerCase()}
                                </span>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 my-4">
                <div className="row">
                  <div className="col-6">
                    <SectionHeader header={t("Cabin baggage")} />
                    <div className="row">
                      <div className="col-6">
                        <FormField
                          name="cabinbaggage.pieces"
                          fieldType="input"
                          type="number"
                          min="1"
                          label={t("Piece(s)")}
                        />
                      </div>
                      <div className="col-6">
                        <FormField
                          name="cabinbaggage.weight"
                          fieldType="input"
                          type="number"
                          min="0.01"
                          step="0.01"
                          label={t("Weight")}
                          inputGroupAppend={
                            <span className="input-group-text">
                              {appInfo.massUnit.toLowerCase()}
                            </span>
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <SectionHeader header={t("Cabin accessory")} />
                    <div className="row">
                      <div className="col-6">
                        <FormField
                          name="cabinaccessory.pieces"
                          fieldType="input"
                          type="number"
                          min="1"
                          label={t("Piece(s)")}
                        />
                      </div>
                      <div className="col-6">
                        <FormField
                          name="cabinaccessory.weight"
                          fieldType="input"
                          type="number"
                          min="0.01"
                          step="0.01"
                          label={t("Weight")}
                          inputGroupAppend={
                            <span className="input-group-text">
                              {appInfo.massUnit.toLowerCase()}
                            </span>
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="offset-3 col-6">
                    <FormField
                      name="totalCabinWeight"
                      fieldType="input"
                      type="number"
                      min="0.01"
                      step="0.01"
                      label={t("Total Cabin Weight")}
                      inputGroupAppend={
                        <span className="input-group-text">
                          {appInfo.massUnit.toLowerCase()}
                        </span>
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6">
                        <div className="row">
                          <div className="col-6">
                            <FormField
                              name="cabinbaggage.dimensions"
                              fieldType="input"
                              type="number"
                              min="0.1"
                              step="0.1"
                              label={t("L + H + W")}
                              inputGroupAppend={
                                <span className="input-group-text">
                                  {appInfo.lengthUnit.toLowerCase()}
                                </span>
                              }
                            />
                          </div>
                          <div className="col-6">
                            <FormField
                              name="cabinbaggage.length"
                              fieldType="input"
                              type="number"
                              min="0.01"
                              step="0.01"
                              label={t("Length")}
                              inputGroupAppend={
                                <span className="input-group-text">
                                  {appInfo.lengthUnit.toLowerCase()}
                                </span>
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="row">
                          <div className="col-6">
                            <FormField
                              name="cabinbaggage.height"
                              fieldType="input"
                              type="number"
                              min="0.01"
                              step="0.01"
                              label={t("Height")}
                              inputGroupAppend={
                                <span className="input-group-text">
                                  {appInfo.lengthUnit.toLowerCase()}
                                </span>
                              }
                            />
                          </div>
                          <div className="col-6">
                            <FormField
                              name="cabinbaggage.width"
                              fieldType="input"
                              type="number"
                              min="0.01"
                              step="0.01"
                              label={t("Width")}
                              inputGroupAppend={
                                <span className="input-group-text">
                                  {appInfo.lengthUnit.toLowerCase()}
                                </span>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6">
                        <div className="row">
                          <div className="col-6">
                            {" "}
                            <FormField
                              name="cabinaccessory.dimensions"
                              fieldType="input"
                              type="number"
                              min="0.1"
                              step="0.1"
                              label={t("L + H + W")}
                              inputGroupAppend={
                                <span className="input-group-text">
                                  {appInfo.lengthUnit.toLowerCase()}
                                </span>
                              }
                            />
                          </div>
                          <div className="col-6">
                            <FormField
                              name="cabinaccessory.length"
                              fieldType="input"
                              type="number"
                              min="0.01"
                              step="0.01"
                              label={t("Length")}
                              inputGroupAppend={
                                <span className="input-group-text">
                                  {appInfo.lengthUnit.toLowerCase()}
                                </span>
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="row">
                          <div className="col-6">
                            <FormField
                              name="cabinaccessory.height"
                              fieldType="input"
                              type="number"
                              min="0.01"
                              step="0.01"
                              label={t("Height")}
                              inputGroupAppend={
                                <span className="input-group-text">
                                  {appInfo.lengthUnit.toLowerCase()}
                                </span>
                              }
                            />
                          </div>
                          <div className="col-6">
                            <FormField
                              name="cabinaccessory.width"
                              fieldType="input"
                              type="number"
                              min="0.01"
                              step="0.01"
                              label={t("Width")}
                              inputGroupAppend={
                                <span className="input-group-text">
                                  {appInfo.lengthUnit.toLowerCase()}
                                </span>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </Form>
    </div>
  )
}

TicketDefaultForm.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string,
    }),
  }).isRequired,
  isUpdate: PropTypes.bool,
  actionEndpoint: PropTypes.string.isRequired,
  successUrl: PropTypes.string.isRequired,
}

TicketDefaultForm.defaultProps = {
  isUpdate: false,
}
