import React from "react"

import UserForm from "apps/auth/User/UserForm"
import { reverse } from "utils/urls"

export default function UserCreate(props) {
  return (
    <UserForm
      {...props}
      actionEndpoint="/auth/users/"
      successUrl={reverse("userList")}
    />
  )
}
