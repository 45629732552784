import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

import Delete from "components/GenericViews/Delete"
import { reverse } from "utils/urls"

export default function DateRangeDelete(props) {
  const { t } = useTranslation()

  return (
    <Delete
      modelName={t("Date Range")}
      endpoint={`/date-ranges/${props.match.params.id}/`}
      successRoute={reverse("dateRangeList")}
      errorRoute={reverse("dateRangeDetail", { id: props.match.params.id })}
    />
  )
}

DateRangeDelete.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
