import React from "react"
import PropTypes from "prop-types"

import "./StatusDot.scss"

export default function StatusDot({ className, ...rest }) {
  return <div className={`status-dot ${className}`} {...rest}></div>
}

StatusDot.propTypes = {
  className: PropTypes.string,
}

StatusDot.defaultProps = {
  className: "",
}
