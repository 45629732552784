import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import Delete from "components/GenericViews/Delete"
import { reverse } from "utils/urls"

export default function TourOperatorInvoiceItemDelete(props) {
  const { t } = useTranslation()

  const { params } = props.match

  return (
    <Delete
      modelName={t("Tour Operator Invoice Item")}
      endpoint={`/invoices/tour-operator-invoices/delete-invoice-item/${params.itemType}/${params.itemId}/`}
      successRoute={reverse("tourOperatorInvoiceDetail", { id: params.id })}
      errorRoute={reverse("tourOperatorInvoiceDetail", { id: params.id })}
    />
  )
}

TourOperatorInvoiceItemDelete.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
      itemId: PropTypes.string.isRequired,
      itemType: PropTypes.oneOf(["PAX", "TAX", "INF"]),
    }),
  }).isRequired,
}
