import React from "react"
import PropTypes from "prop-types"

import TourOperatorForm from "apps/tourOperators/TourOperator/TourOperatorForm"
import { reverse } from "utils/urls"

export default function TourOperatorUpdate(props) {
  return (
    <TourOperatorForm
      {...props}
      isUpdate
      actionEndpoint={`/tour-operators/${props.match.params.id}/`}
      successUrl={reverse("tourOperatorDetail", { id: props.match.params.id })}
    />
  )
}

TourOperatorUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
