import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import InfantBookingFareForm from "apps/fares/InfantBookingFare/InfantBookingFareForm"
import { reverse } from "utils/urls"

export default function AirlineInfantBookingFareUpdate(props) {
  const { t } = useTranslation()

  return (
    <InfantBookingFareForm
      {...props}
      isUpdate
      detailEndpoint={`/fares/airline-infant-fares/${props.match.params.id}/`}
      actionEndpoint={`/fares/airline-infant-fares/${props.match.params.id}/`}
      errorUrl={reverse("airlineInfantBookingFareList")}
      label={t("Edit airline infant booking fare")}
      successMessage={t("Airline Infant Booking Fare was successfully saved.")}
      successUrl={reverse("airlineInfantBookingFareList")}
    />
  )
}

AirlineInfantBookingFareUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
