import React from "react"

import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Button from "components/Button/Button"

export default function ClearAll(props) {
  const { setValue } = useFormContext()
  const { t } = useTranslation()

  return (
    <Button type="danger" onClick={() => setValue("passengers", [])}>
      {t("Clear all")}
    </Button>
  )
}
