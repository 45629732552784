import React from "react"
import { useTranslation } from "react-i18next"

import { hasPermissions } from "apps/auth/auth"
import BookingFareList from "apps/fares/BookingFare/BookingFareList"
import Button from "components/Button/Button"
import Icon from "components/Icon/Icon"
import { actionsColumn } from "components/Table"
import Tooltip from "components/Tooltip/Tooltip"
import { reverse } from "utils/urls"

export default function AirlineBookingFareList(props) {
  const { t } = useTranslation()

  return (
    <BookingFareList
      tableConfig={{
        endpoint: "/fares/airline-fares",
        title: t("Airline Booking Fares"),
        tableName: "airline-booking-fare-table-config",
        buttons: (
          <>
            {hasPermissions(["add_fares_airlinebookingfare"]) && (
              <Button icon="plus" to={reverse("airlineBookingFareCreate")}>
                {t("Add Fare")}
              </Button>
            )}
          </>
        ),
      }}
      actionsColumn={actionsColumn(obj => (
        <>
          <Tooltip title={t("Fare History")}>
            <Icon.Link
              name="history"
              color="gray"
              to={reverse("history", {
                id: obj.id,
                model: encodeURIComponent("fares/airline-fares"),
              })}
            />
          </Tooltip>
          <Tooltip title={t("Edit Fare")}>
            <Icon.Link
              name="edit"
              linkClassName="ml-3"
              to={reverse("airlineBookingFareUpdate", { id: obj.id })}
            />
          </Tooltip>
          <Tooltip title={t("Delete Fare")}>
            <Icon.Link
              name="remove"
              color="danger"
              linkClassName="ml-3"
              to={reverse("airlineBookingFareDelete", { id: obj.id })}
            />
          </Tooltip>
        </>
      ))}
    />
  )
}
