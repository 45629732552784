import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Form, FormField } from "components/Form"
import { apiPUT, apiGET } from "utils/api"
import { reverse } from "utils/urls"

export default function OwnerCompanyForm(props) {
  const [formData, setFormData] = useState({})

  const { t } = useTranslation()

  useEffect(
    () =>
      apiGET({
        path: "/owner-company/detail/",
        onSuccess: response => setFormData(response),
        onError: () => props.history.push(reverse("home")),
      }),
    [props.history]
  )

  return (
    <div>
      <Form
        endpoint="/owner-company/update/"
        apiFunction={apiPUT}
        defaultValues={formData}
        successUrl={reverse("ownerCompanyDetail")}
        successMessage={t("Owner Company was successfully saved.")}
        title={t("Edit owner company details")}>
        <div className="row">
          <div className="col-4">
            <FormField
              name="name"
              fieldType="input"
              label={t("Name")}
              type="text"
              maxLength={100}
              required
            />
          </div>
          <div className="col-4">
            <FormField
              name="note"
              fieldType="textarea"
              label={t("General information")}
              rows={5}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <FormField
              name="vatNumber"
              fieldType="input"
              label={t("VAT identification number")}
              type="text"
              maxLength={25}
            />
          </div>
          <div className="col-4">
            <FormField
              fieldType="asyncSelect"
              name="domiciliation"
              label={t("Domiciliation")}
              endpoint="/countries/"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <FormField
              name="bankAccount"
              fieldType="textarea"
              label={t("Bank account number")}
              rows={5}
            />
          </div>
          <div className="col-4">
            <FormField
              name="vat"
              fieldType="input"
              label={t("VAT amount")}
              type="number"
              step="0.01"
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <FormField
              name="fiscalYearDay"
              fieldType="input"
              label={t("Fiscal year day")}
              type="number"
              min="1"
              max="31"
              required
            />
          </div>
          <div className="col-4">
            <FormField
              name="fiscalYearMonth"
              fieldType="input"
              label={t("Fiscal year month")}
              type="number"
              min="1"
              max="12"
              required
            />
          </div>
        </div>
      </Form>
    </div>
  )
}

OwnerCompanyForm.propTypes = {
  history: PropTypes.object.isRequired,
}
