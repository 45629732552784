export const getLocalStorageObj = k => {
  const val = localStorage.getItem(k)
  return val ? JSON.parse(val) : val
}

export const setLocalStorageObj = (k, v) => {
  localStorage.setItem(k, JSON.stringify(v))
}

export const removeLocalStorageObj = k => {
  localStorage.removeItem(k)
}

export function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.addEventListener("load", () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export const requireImg = path => require(`assets/img/${path}`)

export const csvToArray = (str, delimiter = ",") => {
  const headers = str.slice(0, str.indexOf("\n")).split(delimiter)
  const rows = str.slice(str.indexOf("\n") + 1).split("\n")
  const arr = rows.map(function(row) {
    const values = row.split(delimiter)
    const el = headers.reduce(function(object, header, index) {
      object[header] = values[index]
      return object
    }, {})
    return el
  })

  return arr
}
