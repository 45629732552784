import React from "react"
import PropTypes from "prop-types"
import { useFormContext } from "react-hook-form"
import { get } from "lodash"

import Checkbox from "components/Checkbox/Checkbox"

export default function CheckboxField(props) {
  const { errors } = useFormContext()
  const error = get(errors, props.name)

  return <Checkbox {...props} error={error} />
}

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
}
