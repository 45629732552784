import React from "react"
import PropTypes from "prop-types"
import { useGlobal } from "reactn"
import { useTranslation } from "react-i18next"
import { isEmpty } from "lodash"

import { useSegmentSelectionType } from "apps/wizardForms/SegmentSelection"
import Avatar from "components/Avatar/Avatar"
import Icon from "components/Icon/Icon"
import { DATE_FORMAT } from "utils/constants"

export default function FlightSummary({ datesData, getSelectedSegments }) {
  const segmentSelectionType = useSegmentSelectionType()
  const isAirline = segmentSelectionType === "airlineBooking"
  const data = useGlobal(`${segmentSelectionType}FormData`)[0] || {}
  const flightType =
    useGlobal(`${segmentSelectionType}FormFlightType`)[0] || "single"

  const { t } = useTranslation()

  return !isEmpty(data) ? (
    <>
      <h5 className="mt-4">{t("Flight summary")}</h5>
      <hr className="my-1" />
      <div className="d-flex justify-content-between align-items-center px-4 py-3">
        <div>
          <Avatar
            className="mx-auto mt-2"
            size={80}
            image={isAirline ? data.airline.logo : data.tourOperator.logo}
            alt={isAirline ? data.airline.code : data.tourOperator.code}
          />
          <small>
            {isAirline
              ? data.airline.displayName
              : data.tourOperator.displayName}
          </small>
        </div>
        <div>
          <div className="d-flex align-items-center">
            <Icon name="airplane-departure" size="xl" />
            <b className="ml-2">{data.firstDepartureAirport.displayName}</b>
          </div>
          {flightType === "return" && (
            <div className="mt-2 d-flex align-items-center">
              <Icon name="airplane-departure" color="magenta" size="xl" />
              <b className="ml-2">{data.firstArrivalAirport.displayName}</b>
            </div>
          )}
          {flightType === "multi" && (
            <div className="mt-2 d-flex align-items-center">
              <Icon name="airplane-departure" color="magenta" size="xl" />
              <b className="ml-2">{data.secondDepartureAirport.displayName}</b>
            </div>
          )}
        </div>
        <div>
          <div className="d-flex d-flex align-items-center">
            <Icon name="airplane-arrival" size="xl" />
            <b className="ml-2">{data.firstArrivalAirport.displayName}</b>
          </div>
          {flightType === "return" && (
            <div className="mt-2 d-flex align-items-center">
              <Icon name="airplane-arrival" color="magenta" size="xl" />
              <b className="ml-2">{data.firstDepartureAirport.displayName}</b>
            </div>
          )}
          {flightType === "multi" && (
            <div className="mt-2 d-flex align-items-center">
              <Icon name="airplane-arrival" color="magenta" size="xl" />
              <b className="ml-2">{data.secondArrivalAirport.displayName}</b>
            </div>
          )}
        </div>
        <div className="d-flex align-items-center">
          <Icon name="calendar-circle" size="xl" />
          {datesData ? (
            <b className="ml-2">
              {datesData.startDate} - {datesData.endDate}
            </b>
          ) : (
            <b className="ml-2">
              {DATE_FORMAT} - {DATE_FORMAT}
            </b>
          )}
        </div>
        <div>
          <b className="text-circle">D</b>
          <b className="ml-2">
            {datesData && datesData.operatingDays.join("")}
          </b>
        </div>
        <div>
          <b className="text-circle">{getSelectedSegments().length}</b>
          <b className="ml-2">{t("Segments selected")}</b>
        </div>
      </div>
      <hr className="my-1" />
    </>
  ) : null
}

FlightSummary.propTypes = {
  datesData: PropTypes.object,
  getSelectedSegments: PropTypes.func.isRequired,
}

FlightSummary.defaultProps = {
  datesData: undefined,
}
