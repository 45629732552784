import React, { useEffect } from "react"
import PropTypes from "prop-types"

import ListFilters from "components/ListFilters/ListFilters"
import { Table } from "components/Table"

export default function TableWithFilters(props) {
  useEffect(() => {
    document.body.style.overflowY = "scroll"

    return () => {
      document.body.style.overflowY = "visible"
    }
  }, [])

  return (
    <>
      <div className="row">
        <div className="col">
          <h1>{props.config.title}</h1>
        </div>
        <div className="col-auto">{props.config.buttons}</div>
      </div>
      {props.config.filters.length !== 0 && (
        <ListFilters items={props.config.filters} />
      )}
      <Table
        tableName={props.config.tableName}
        columns={props.config.columns}
        endpoint={props.config.endpoint}
        showOptionalHeaders={
          props.config.showOptionalHeaders !== undefined
            ? props.config.showOptionalHeaders
            : false
        }
        showSelectColumn={
          props.config.showSelectColumn !== undefined
            ? props.config.showSelectColumn
            : true
        }
        SubComponent={props.config.SubComponent}
        showExpander={props.config.showExpander}
      />
    </>
  )
}

TableWithFilters.propTypes = {
  config: PropTypes.exact({
    tableName: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    endpoint: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    buttons: PropTypes.node,
    showOptionalHeaders: PropTypes.bool,
    showSelectColumn: PropTypes.bool,
    SubComponent: PropTypes.func,
    showExpander: PropTypes.func,
  }).isRequired,
}
