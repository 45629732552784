import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

import Detail from "components/GenericViews/Detail"
import { reverse } from "utils/urls"

export default function TourOperatorDetail(props) {
  const { t } = useTranslation()

  return (
    <Detail
      title={t("Tour operator details")}
      detailEndpoint={`/tour-operators/${props.match.params.id}/`}
      listRoute={reverse("tourOperatorList")}
      changePermission={["change_tour_operators_touroperator"]}
      changeRoute={reverse("tourOperatorUpdate", { id: props.match.params.id })}
      deletePermission={["delete_tour_operators_touroperator"]}
      deleteRoute={reverse("tourOperatorDelete", { id: props.match.params.id })}
      historyRoute={reverse("history", {
        id: props.match.params.id,
        model: "tour-operators",
      })}
      fields={[
        [
          { label: t("Name"), key: "name", size: "col-4" },
          { label: t("VAT identification number"), key: "vat", size: "col-4" },
        ],
        [
          { label: t("Code"), key: "code", size: "col-4" },
          { label: t("Address"), key: "address", size: "col-4" },
        ],
        [
          { label: t("Code AS400"), key: "codeAs400", size: "col-4" },
          {
            label: t("City"),
            key: "city",
            type: "singleSelect",
            size: "col-4",
          },
        ],
        [
          { label: t("Zip code"), key: "zipCode", size: "col-4" },
          {
            label: t("Logo"),
            key: "logo",
            type: "logo",
            size: "col-4",
          },
        ],
        [{ label: t("Web address"), key: "url", size: "offset-4 col-4" }],
      ]}
    />
  )
}

TourOperatorDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
