import React, { useState } from "react"
import { useGlobal } from "reactn"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"

import { hasPermissions } from "apps/auth/auth"
import { emptyColumn, COLUMN_WIDTHS } from "apps/sheets/columns"
import Button from "components/Button/Button"
import Icon from "components/Icon/Icon"
import Sheet from "components/Sheet/Sheet"
import { getLocalStorageObj, setLocalStorageObj } from "utils/fs"
import WorkingSheetSegmentDetails from "./WorkingSheetSegmentDetails"
import { reverse, buildRouteFromLocation } from "utils/urls"

import styles from "apps/sheets/Sheet.module.scss"

const getSeatsSum = (state, field) =>
  state.data.map(s => s._original.seats[field]).reduce((a, b) => a + b, 0)

const getOptionalSeatsFooter = (state, type) => {
  const seats = getSeatsSum(state, `${type}Seats`)
  const optionalSeats = getSeatsSum(state, `${type}OptionalSeats`)

  return (
    <>
      {seats}
      {optionalSeats > 0 && (
        <span className="text-warning ml-1">({optionalSeats})</span>
      )}
    </>
  )
}

export default function WorkingSheet(props) {
  const { t } = useTranslation()
  let history = useHistory()

  const storageName = `${history.location.pathname}-sheet`
  const storageSheetData = getLocalStorageObj(storageName) || {}

  const [expandFlightDetails, setExpandFlightDetails] = useState(
    storageSheetData.expandedFlightDetails !== undefined
      ? storageSheetData.expandedFlightDetails
      : true
  )
  const [expandAirlines, setExpandAirlines] = useState(
    storageSheetData.expandedAirlines !== undefined
      ? storageSheetData.expandedAirlines
      : true
  )
  const [expandTourOperators, setExpandTourOperators] = useState(
    storageSheetData.expandedTourOperators !== undefined
      ? storageSheetData.expandedTourOperators
      : true
  )
  const [expandTickets, setExpandTickets] = useState(
    storageSheetData.expandedTickets !== undefined
      ? storageSheetData.expandedTickets
      : true
  )

  const airlineBookingFormData = useGlobal("airlineBookingFormData")
  const setAirlineBookingFormData = airlineBookingFormData[1]

  const apiGETNamespace = "working-sheet"

  return (
    <Sheet
      endpoint="/sheets/working-sheet"
      requiredFilters={["dateFrom", "dateTo", "operatingDays"]}
      title={t("Working Sheet")}
      headerLevels={2}
      apiGETNamespace={apiGETNamespace}
      SubComponent={row => (
        <WorkingSheetSegmentDetails
          segment={row.original}
          expandFlightDetails={expandFlightDetails}
          expandAirlines={expandAirlines}
          expandTourOperators={expandTourOperators}
          expandTickets={expandTickets}
          apiGETNamespace={apiGETNamespace}
        />
      )}
      columns={[
        {
          Header: (
            <div className="row">
              <div className="col">{t("Flight details")}</div>
              <div className="col-auto ml-auto">
                <div style={{ cursor: "pointer" }}>
                  <Icon
                    name={`chevron-${
                      expandFlightDetails ? "left" : "right"
                    }-double`}
                    color="white"
                    onClick={() => {
                      setExpandFlightDetails(!expandFlightDetails)

                      const sheetData = getLocalStorageObj(storageName) || {}
                      sheetData.expandedFlightDetails = !expandFlightDetails
                      setLocalStorageObj(storageName, sheetData)
                    }}
                  />
                </div>
              </div>
            </div>
          ),
          headerClassName: styles["th-flight-details"],
          columns: [
            {
              Header: t("Week"),
              accessor: "weekNo",
              width: COLUMN_WIDTHS.weekNo,
              headerClassName: styles["t-gray-light"],
            },
            {
              Header: t("Date"),
              accessor: "localDepartureDate",
              width: COLUMN_WIDTHS.localDepartureDate,
              headerClassName: styles["t-gray-light"],
            },
            {
              Header: (
                <>
                  D<sup>0</sup>
                </>
              ),
              accessor: "operatingDay",
              width: COLUMN_WIDTHS.operatingDay,
              headerClassName: styles["t-gray-light"],
            },
            {
              Header: t("Route"),
              accessor: "displayName",
              width: COLUMN_WIDTHS.route,
              headerClassName: styles["t-gray-light"],
            },
            {
              Header: (
                <>
                  {t("Flight")} N<sup>o</sup>
                </>
              ),
              accessor: "flightNo",
              width: COLUMN_WIDTHS.flightNo,
              headerClassName: styles["t-gray-light"],
            },
            ...(expandFlightDetails
              ? [
                  {
                    Header: t("ETD"),
                    accessor: "etd",
                    width: COLUMN_WIDTHS.etd,
                    headerClassName: styles["t-gray-light"],
                  },
                  {
                    Header: t("ETA"),
                    accessor: "eta",
                    width: COLUMN_WIDTHS.eta,
                    headerClassName: styles["t-gray-light"],
                  },
                ]
              : []),
          ],
        },
        emptyColumn,
        {
          Header: (
            <div className="row">
              <div className="col">{t("Airlines")}</div>
              <div className="col-auto ml-auto">
                <div style={{ cursor: "pointer" }}>
                  <Icon
                    name={`chevron-${expandAirlines ? "left" : "right"}-double`}
                    color="white"
                    onClick={() => {
                      setExpandAirlines(!expandAirlines)

                      const sheetData = getLocalStorageObj(storageName) || {}
                      sheetData.expandedAirlines = !expandAirlines
                      setLocalStorageObj(storageName, sheetData)
                    }}
                  />
                </div>
              </div>
            </div>
          ),
          headerClassName: styles["th-airlines"],
          columns: [
            {
              Header: t("Name"),
              id: "airlineName",
              accessor: s =>
                s.airline.code ? (
                  <Link
                    to={reverse(
                      "segmentObjectsList",
                      {
                        id: s.id,
                        objType: "airlineBookings",
                        objId: s.airline.id,
                      },
                      {
                        fromSheet: "true",
                        next: buildRouteFromLocation(history.location),
                      }
                    )}>
                    {s.airline.code}
                  </Link>
                ) : null,
              headerClassName: styles["th-sub-airlines"],
              className: styles["t-gray-light"],
              getFooterProps: () => ({
                className: styles["tf-white"],
              }),
              width: COLUMN_WIDTHS.basic,
            },
            {
              Header: t("Total"),
              accessor: s => <b>{s.seats.airlineTotalSeats || ""}</b>,
              id: "airlineTotalSeats",
              headerClassName: styles["th-sub-airlines"],
              className: styles["t-gray-light"],
              Footer: state => <b>{getSeatsSum(state, "airlineTotalSeats")}</b>,
              getFooterProps: () => ({
                className: styles["tf-white"],
              }),
              width: COLUMN_WIDTHS.basic,
            },
            ...(expandAirlines
              ? [
                  {
                    Header: "BLK",
                    accessor: s => s.seats.airlineBlkSeats || "",
                    id: "airlineBlkSeats",
                    headerClassName: styles["th-sub-airlines"],
                    Footer: state => getSeatsSum(state, "airlineBlkSeats"),
                    width: COLUMN_WIDTHS.basic,
                  },
                  {
                    Header: "ALT",
                    accessor: s => (
                      <>
                        {s.seats.airlineAltSeats || ""}
                        {s.seats.airlineAltOptionalSeats > 0 && (
                          <span className="text-warning ml-1">
                            ({s.seats.airlineAltOptionalSeats})
                          </span>
                        )}
                      </>
                    ),
                    id: "airlineAltSeats",
                    headerClassName: styles["th-sub-airlines"],
                    Footer: state =>
                      getOptionalSeatsFooter(state, "airlineAlt"),
                    width: COLUMN_WIDTHS.optionalTypes,
                  },
                  {
                    Header: "GRP",
                    accessor: s => s.seats.airlineGrpSeats || "",
                    id: "airlineGrpSeats",
                    headerClassName: styles["th-sub-airlines"],
                    Footer: state => getSeatsSum(state, "airlineGrpSeats"),
                    width: COLUMN_WIDTHS.basic,
                  },
                  {
                    Header: "GIR",
                    accessor: s => (
                      <>
                        {s.seats.airlineGirSeats || ""}
                        {s.seats.airlineGirOptionalSeats > 0 && (
                          <span className="text-warning ml-1">
                            ({s.seats.airlineGirOptionalSeats})
                          </span>
                        )}
                      </>
                    ),
                    id: "airlineGirSeats",
                    headerClassName: styles["th-sub-airlines"],
                    Footer: state =>
                      getOptionalSeatsFooter(state, "airlineGir"),
                    width: COLUMN_WIDTHS.optionalTypes,
                  },
                  {
                    Header: "OVF",
                    accessor: s => s.seats.airlineOvfSeats || "",
                    id: "airlineOvfSeats",
                    headerClassName: styles["th-sub-airlines"],
                    Footer: state => getSeatsSum(state, "airlineOvfSeats"),
                    width: COLUMN_WIDTHS.basic,
                  },
                ]
              : []),
          ],
        },
        {
          width: COLUMN_WIDTHS.empty,
          id: "airlineAddBooking",
          className: styles["td-icon-add"],
          accessor: s =>
            s.airline && hasPermissions(["add_bookings_airlinebooking"]) ? (
              <Button
                size="sm"
                icon="plus"
                onClick={() => {
                  setAirlineBookingFormData({
                    airline: s.airline,
                    segments: {
                      [`segment-${s.id}`]: "true",
                    },
                    firstDepartureAirport: s.departureAirport,
                    firstArrivalAirport: s.arrivalAirport,
                    source: "sheet",
                  })
                  history.push(
                    reverse("airlineBookingCreateStep2", null, {
                      fromSheet: "true",
                    })
                  )
                }}
              />
            ) : (
              ""
            ),
        },
        {
          Header: <div className={styles["th-delta-content"]}>Δ</div>,
          headerClassName: styles["th-delta"],
          columns: [
            {
              Header: <></>,
              id: "deltaSeats",
              className: "position-relative",
              accessor: s => (
                <b
                  className={`${styles["td-delta"]} ${
                    s.seats.deltaSeats > 0 ? "bg-green-light" : ""
                  } ${s.seats.deltaSeats < 0 ? "bg-red-light" : ""}`}>
                  {s.seats.deltaSeats || ""}
                </b>
              ),
              Footer: state => <b>{getSeatsSum(state, "deltaSeats")}</b>,
              width: COLUMN_WIDTHS.delta,
            },
          ],
        },
        emptyColumn,
        {
          Header: (
            <div className="row">
              <div className="col">{t("T.O. Bookings")}</div>
              <div className="col-auto ml-auto">
                <div style={{ cursor: "pointer" }}>
                  <Icon
                    name={`chevron-${
                      expandTourOperators ? "left" : "right"
                    }-double`}
                    color="white"
                    onClick={() => {
                      setExpandTourOperators(!expandTourOperators)

                      const sheetData = getLocalStorageObj(storageName) || {}
                      sheetData.expandedTourOperators = !expandTourOperators
                      setLocalStorageObj(storageName, sheetData)
                    }}
                  />
                </div>
              </div>
            </div>
          ),
          headerClassName: styles["th-tour-operators"],
          columns: [
            {
              Header: t("Name"),
              id: "name",
              headerClassName: styles["th-sub-tour-operators"],
              className: styles["t-gray-light"],
              getFooterProps: () => ({
                className: styles["tf-white"],
              }),
              width: COLUMN_WIDTHS.basic,
            },
            {
              Header: t("Total"),
              accessor: s => <b>{s.seats.tourOperatorTotalSeats || ""}</b>,
              id: "tourOperatorTotalSeats",
              headerClassName: styles["th-sub-tour-operators"],
              className: styles["t-gray-light"],
              Footer: state => (
                <b>{getSeatsSum(state, "tourOperatorTotalSeats")}</b>
              ),
              getFooterProps: () => ({
                className: styles["tf-white"],
              }),
              width: COLUMN_WIDTHS.basic,
            },
            ...(expandTourOperators
              ? [
                  {
                    Header: "BLK",
                    accessor: s => s.seats.tourOperatorBlkSeats || "",
                    id: "tourOperatorBlkSeats",
                    headerClassName: styles["th-sub-tour-operators"],
                    Footer: state => getSeatsSum(state, "tourOperatorBlkSeats"),
                    width: COLUMN_WIDTHS.basic,
                  },
                  {
                    Header: "ALT",
                    accessor: s => (
                      <>
                        {s.seats.tourOperatorAltSeats || ""}
                        {s.seats.tourOperatorAltOptionalSeats > 0 && (
                          <span className="text-warning ml-1">
                            ({s.seats.tourOperatorAltOptionalSeats})
                          </span>
                        )}
                      </>
                    ),
                    id: "tourOperatorAltSeats",
                    headerClassName: styles["th-sub-tour-operators"],
                    Footer: state =>
                      getOptionalSeatsFooter(state, "tourOperatorAlt"),
                    width: COLUMN_WIDTHS.optionalTypes,
                  },
                  {
                    Header: "GRP",
                    accessor: s => s.seats.tourOperatorGrpSeats || "",
                    id: "tourOperatorGrpSeats",
                    headerClassName: styles["th-sub-tour-operators"],
                    Footer: state => getSeatsSum(state, "tourOperatorGrpSeats"),
                    width: COLUMN_WIDTHS.basic,
                  },
                  {
                    Header: "GIR",
                    accessor: s => (
                      <>
                        {s.seats.tourOperatorGirSeats || ""}
                        {s.seats.tourOperatorGirOptionalSeats > 0 && (
                          <span className="text-warning ml-1">
                            ({s.seats.tourOperatorGirOptionalSeats})
                          </span>
                        )}
                      </>
                    ),
                    id: "tourOperatorGirSeats",
                    headerClassName: styles["th-sub-tour-operators"],
                    Footer: state =>
                      getOptionalSeatsFooter(state, "tourOperatorGir"),
                    width: COLUMN_WIDTHS.optionalTypes,
                  },
                  {
                    Header: "OVF",
                    accessor: s => s.seats.tourOperatorOvfSeats || "",
                    id: "tourOperatorOvfSeats",
                    headerClassName: styles["th-sub-tour-operators"],
                    Footer: state => getSeatsSum(state, "tourOperatorOvfSeats"),
                    width: COLUMN_WIDTHS.basic,
                  },
                ]
              : []),
          ],
        },
        emptyColumn,
        {
          Header: (
            <div className="row">
              <div className="col">{t("Tickets")}</div>
              <div className="col-auto ml-auto">
                <div style={{ cursor: "pointer" }}>
                  <Icon
                    name={`chevron-${expandTickets ? "left" : "right"}-double`}
                    color="white"
                    onClick={() => {
                      setExpandTickets(!expandTickets)

                      const sheetData = getLocalStorageObj(storageName) || {}
                      sheetData.expandedTickets = !expandTickets
                      setLocalStorageObj(storageName, sheetData)
                    }}
                  />
                </div>
              </div>
            </div>
          ),
          headerClassName: styles["th-tickets"],
          columns: [
            {
              Header: t("Total"),
              id: "totalPassengers",
              accessor: s =>
                s.seats.adultPassengers ? (
                  <b>
                    {s.seats.adultPassengers + s.seats.childPassengers}
                    {s.seats.infantPassengers > 0 && (
                      <span className="ml-1">+ {s.seats.infantPassengers}</span>
                    )}
                  </b>
                ) : (
                  ""
                ),
              headerClassName: styles["th-sub-tickets"],
              className: styles["t-gray-light"],
              Footer: state => (
                <b>
                  {getSeatsSum(state, "adultPassengers") +
                    getSeatsSum(state, "childPassengers")}
                </b>
              ),
              getFooterProps: () => ({
                className: styles["tf-white"],
              }),
              width: COLUMN_WIDTHS.basic,
            },
            {
              Header: "%",
              id: "passengersPercentage",
              accessor: s =>
                s.seats.adultPassengers > 0
                  ? `${Math.round(
                      ((s.seats.adultPassengers + s.seats.childPassengers) /
                        s.seats.tourOperatorTotalSeats) *
                        100
                    )}%`
                  : "",
              headerClassName: styles["th-sub-tickets"],
              className: styles["t-gray-light"],
              getFooterProps: () => ({
                className: styles["tf-white"],
              }),
              width: COLUMN_WIDTHS.basic,
            },
            ...(expandTickets
              ? [
                  {
                    Header: "ADT",
                    id: "adultPassengers",
                    accessor: s => s.seats.adultPassengers || "",
                    headerClassName: styles["th-sub-tickets"],
                    Footer: state => getSeatsSum(state, "adultPassengers"),
                    width: COLUMN_WIDTHS.basic,
                  },
                  {
                    Header: "CHD",
                    id: "childPassengers",
                    accessor: s => s.seats.childPassengers || "",
                    headerClassName: styles["th-sub-tickets"],
                    Footer: state => getSeatsSum(state, "childPassengers"),
                    width: COLUMN_WIDTHS.basic,
                  },
                  {
                    Header: "INF",
                    id: "infantPassengers",
                    accessor: s => s.seats.infantPassengers || "",
                    headerClassName: styles["th-sub-tickets"],
                    Footer: state => getSeatsSum(state, "infantPassengers"),
                    width: COLUMN_WIDTHS.basic,
                  },
                ]
              : []),
          ],
        },
      ]}
      filters={[
        [
          {
            size: "col",
            children: [
              [
                {
                  name: "departureAirport",
                  fieldType: "airportSelect",
                  withCities: true,
                  icon: "airplane-departure",
                  label: t("Departure airport"),
                  size: "col-4",
                },
                {
                  name: "arrivalAirport",
                  fieldType: "airportSelect",
                  withCities: true,
                  icon: "airplane-arrival",
                  label: t("Arrival airport"),
                  size: "col-4",
                },
                {
                  name: "addReturn",
                  fieldType: "checkbox",
                  label: t("Add return"),
                  size: "col-auto",
                  align: "center",
                },
                {
                  name: "withBookings",
                  fieldType: "checkbox",
                  label: t("Only flights with bookings"),
                  size: "col-auto",
                  align: "center",
                },
                {
                  name: "entireRouting",
                  fieldType: "checkbox",
                  label: t("Entire routing"),
                  size: "col-auto",
                  align: "center",
                },
              ],
              [
                {
                  fieldType: "selectDateRange",
                  dateFromName: "dateFrom",
                  dateToName: "dateTo",
                  size: "col-8",
                  required: true,
                },
                {
                  fieldType: "operatingDays",
                  name: "operatingDays",
                  size: "col-auto text-center",
                  required: true,
                },
              ],
            ],
          },
          {
            size: "col-auto",
            children: [
              [
                {
                  fieldType: "applyButton",
                  size: "col-12 mt-1",
                },
              ],
              [
                {
                  fieldType: "clearButton",
                  size: "col-12 mt-3",
                },
              ],
            ],
          },
        ],
      ]}
    />
  )
}
