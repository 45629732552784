import React from "react"
import PropTypes from "prop-types"
import { useGlobal } from "reactn"
import { useTranslation } from "react-i18next"
import { useWatch } from "react-hook-form"

import { useSegmentSelectionType } from "apps/wizardForms/SegmentSelection"
import { FormField } from "components/Form"

export default function Fares(props) {
  const segmentSelectionType = useSegmentSelectionType()
  const data = useGlobal(`${segmentSelectionType}FormData`)[0]
  const flightType =
    useGlobal(`${segmentSelectionType}FormFlightType`)[0] || "single"

  const { fareType, legFares } = props
  const { t } = useTranslation()

  const type = useWatch({ name: `${fareType}.fareType` })

  return (
    <div className="row">
      <div className="col-6">
        <FormField
          fieldType="select"
          label={t("Fare type")}
          name={`${fareType}.fareType`}
          options={[
            { id: "existing", displayName: t("Existing") },
            { id: "custom", displayName: t("Custom") },
          ]}
        />
      </div>
      {type &&
        type.id === "existing" &&
        ((legFares.firstLeg && legFares.firstLeg.length) ||
        (legFares.secondLeg && legFares.secondLeg.length) ? (
          <>
            {legFares.firstLeg.length !== 0 && (
              <div className="row">
                <div className="col-6">
                  <b>{t("Booking Fares")}:</b>
                </div>
                <div className="col-6">
                  <b>
                    {data.firstDepartureAirport.codeIata} -{" "}
                    {data.firstArrivalAirport.codeIata}
                  </b>
                </div>
                {legFares.firstLeg.map((fare, i) => (
                  <div className="col-12" key={i}>
                    <div className="row">
                      <div className="col-6">
                        {fare.startDate} - {fare.endDate}
                      </div>
                      <div className="col-6">{fare.fare}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {legFares.secondLeg.length !== 0 && (
              <div className="row mt-2">
                <div className="col-6">
                  <b>{t("Booking Fares")}:</b>
                </div>
                <div className="col-6">
                  {flightType === "return" && (
                    <b>
                      {data.firstArrivalAirport.codeIata} -{" "}
                      {data.firstDepartureAirport.codeIata}
                    </b>
                  )}
                  {flightType === "multi" && (
                    <b>
                      {data.secondDepartureAirport.codeIata} -{" "}
                      {data.secondArrivalAirport.codeIata}
                    </b>
                  )}
                </div>
                {legFares.secondLeg.map((fare, i) => (
                  <div className="col-12" key={i}>
                    <div className="row">
                      <div className="col-6">
                        {fare.startDate} - {fare.endDate}
                      </div>
                      <div className="col-6">{fare.fare}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        ) : (
          <p>
            {t(
              "Fares are not defined for the selected segments. " +
                "Please choose `Custom` fare method."
            )}
          </p>
        ))}
      {type && type.id === "custom" && (
        <div className="row">
          <div className="col-12 text-center">
            <b>
              {data.firstDepartureAirport.codeIata} -{" "}
              {data.firstArrivalAirport.codeIata}
            </b>
          </div>
          <div className="col-12">
            <div className="d-flex">
              <FormField
                fieldType="price"
                label={t("Adult fare")}
                name={`${fareType}.firstLegAdultFare`}
                currencyName={`${fareType}.firstLegCurrency`}
                currencyPosition="prepend"
              />
              <FormField
                fieldType="input"
                type="number"
                min={0}
                step={0.01}
                name={`${fareType}.firstLegChildFare`}
                label={t("Child fare")}
              />
            </div>
          </div>
          {["return", "multi"].includes(flightType) && (
            <>
              <div className="col-12 text-center">
                {flightType === "return" && (
                  <b>
                    {data.firstArrivalAirport.codeIata} -{" "}
                    {data.firstDepartureAirport.codeIata}
                  </b>
                )}
                {flightType === "multi" && (
                  <b>
                    {data.secondDepartureAirport.codeIata} -{" "}
                    {data.secondArrivalAirport.codeIata}
                  </b>
                )}
              </div>
              <div className="col-12">
                <div className="d-flex">
                  <FormField
                    fieldType="price"
                    label={t("Adult fare")}
                    name={`${fareType}.secondLegAdultFare`}
                    currencyName={`${fareType}.secondLegCurrency`}
                    currencyPosition="prepend"
                  />
                  <FormField
                    fieldType="input"
                    type="number"
                    min={0}
                    step={0.01}
                    name={`${fareType}.secondLegChildFare`}
                    label={t("Child fare")}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

Fares.propTypes = {
  fareType: PropTypes.string.isRequired,
  legFares: PropTypes.exact({
    firstLeg: PropTypes.array.isRequired,
    secondLeg: PropTypes.array.isRequired,
  }).isRequired,
}
