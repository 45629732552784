import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Form, FormField } from "components/Form"
import { apiPUT, apiGET, apiPOST } from "utils/api"

export default function BookingFareForm(props) {
  const [formData, setFormData] = useState({})

  const { t } = useTranslation()

  useEffect(
    () =>
      props.isUpdate
        ? apiGET({
            path: props.detailEndpoint,
            onSuccess: response => setFormData(response),
            onError: () => props.history.push(props.errorUrl),
          })
        : () => {},
    [
      props.isUpdate,
      props.match.params.id,
      props.history,
      props.detailEndpoint,
      props.errorUrl,
    ]
  )

  return (
    <div>
      <Form
        endpoint={props.actionEndpoint}
        apiFunction={props.isUpdate ? apiPUT : apiPOST}
        defaultValues={formData}
        successUrl={props.successUrl}
        successMessage={props.successMessage}
        title={props.label}>
        <div className="row">
          {props.isTourOperator ? (
            <div className="col-4">
              <FormField
                name="tourOperator"
                fieldType="asyncSelect"
                label={t("Tour Operator")}
                endpoint="/tour-operators/"
                required
              />
            </div>
          ) : (
            <div className="col-4">
              <FormField
                name="airline"
                fieldType="asyncSelect"
                label={t("Carrier")}
                endpoint="/airlines/"
                icon="airplane"
                required
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-4">
            <FormField
              name="route"
              fieldType="asyncSelect"
              label={t("Segment")}
              endpoint="/routings/routes/"
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <FormField
              name="type"
              fieldType="select"
              label={t("Booking Type")}
              endpoint="/bookings/booking-types/"
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <FormField
              name={["startDate", "endDate"]}
              fieldType="dateRange"
              label={t("Start date - End date")}
              required
            />
          </div>
          <div className="col-4">
            <div className="d-flex">
              <FormField
                name="value"
                fieldType="price"
                label={t("Fare")}
                required
                currencyPosition="prepend"
              />
              <FormField
                name="childValue"
                fieldType="input"
                type="number"
                step="0.01"
                min={0}
                label={t("Child fare")}
                required
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

BookingFareForm.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string,
    }),
  }).isRequired,
  isUpdate: PropTypes.bool,
  actionEndpoint: PropTypes.string.isRequired,
  detailEndpoint: PropTypes.string,
  errorUrl: PropTypes.string,
  successUrl: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  isTourOperator: PropTypes.bool,
}

BookingFareForm.defaultProps = {
  isUpdate: false,
  detailEndpoint: "",
  errorUrl: "",
  isTourOperator: false,
}
