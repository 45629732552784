import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import { useTranslation } from "react-i18next"
import { redirectWithNotify } from "utils/notifications"
import { useHistory } from "react-router-dom"

import Button from "components/Button/Button"
import { apiDELETE, apiPOST, apiGET } from "utils/api"
import { reverse } from "utils/urls"

export default function TourOperatorInvoiceAction(props) {
  const [data, setData] = useState({})
  const { t } = useTranslation()
  let history = useHistory()

  useEffect(
    () =>
      apiGET({
        path: `/invoices/tour-operator-invoices/${props.objId}/`,
        onSuccess: response => setData(response),
        onError: () => history.push(reverse("home")),
      }),
    [props.objId, history]
  )

  const getRedirectUrl = () => {
    const urlParams = new URLSearchParams(history.location.search)
    let redirectUrl = reverse("tourOperatorInvoiceDetail", { id: props.objId })
    if (urlParams.get("next")) {
      redirectUrl = urlParams.get("next")
    }
    return redirectUrl
  }

  const onError = err => {
    const nonFieldErrors = err.response?.nonFieldErrors
    const errorMessage = nonFieldErrors
      ? nonFieldErrors.join("\n")
      : messages.error
    redirectWithNotify(getRedirectUrl(), "error", errorMessage)
  }

  const onSubmit = e => {
    e.preventDefault()

    const apiData = {
      path: props.endpoint,
      onSuccess: () =>
        redirectWithNotify(getRedirectUrl(), "success", messages.success),
      onError: onError,
    }

    if (props.type === "delete") {
      apiDELETE(apiData)
    } else {
      apiPOST(apiData)
    }
  }

  const getMessages = () => {
    if (isEmpty(data)) {
      return null
    }

    const subject = data.isCreditNote
      ? " " + t("Credit note")
      : " " + t("Invoice")

    const getSuccessMessage = message => (
      <>
        {t("Tour Operator")} {subject} <b>{data.displayName}</b> {message}
      </>
    )

    switch (props.type) {
      case "delete":
        return {
          title: t("Delete Tour Operator") + subject,
          confirm: t("Are you sure you want to delete Tour Operator") + subject,
          button: t("Delete"),
          success: getSuccessMessage(t("was successfully deleted.")),
        }
      case "send":
        return {
          title: t("Send Tour Operator") + subject,
          confirm: t("Are you sure you want to send Tour Operator") + subject,
          button: t("Send"),
          success: getSuccessMessage(t("was successfully sent.")),
        }
      case "invertTaxes":
        if (data.hasBookingsInvoicedWithTaxes) {
          return {
            title: t("Delete Taxes Tour Operator") + subject,
            confirm:
              t("Are you sure you want to delete taxes from Tour Operator") +
              subject,
            button: t("Confirm"),
            success: getSuccessMessage(t("taxes was successfully deleted.")),
          }
        } else if (data.hasBookingsInvoicedWithoutTaxes) {
          return {
            title: t("Add Taxes Tour Operator") + subject,
            confirm:
              t("Are you sure you want to add taxes to Tour Operator") +
              subject,
            button: t("Confirm"),
            success: getSuccessMessage(t("taxes was successfully created.")),
          }
        }
        throw new Error("unknown action")
      case "nullify":
        return {
          title: t("Nullify Tour Operator") + subject,
          confirm:
            t("Are you sure you want to nullify Tour Operator") + subject,
          button: t("Nullify"),
          success: getSuccessMessage(t("was successfully nullified.")),
        }
      case "nullifyRecreate":
        return {
          title: t("Nullify And Recreate Tour Operator") + subject,
          confirm:
            t("Are you sure you want to nullify and recreate Tour Operator") +
            subject,
          button: t("Nullify and Recreate"),
          success: getSuccessMessage(
            t("was successfully nullified and recreated.")
          ),
        }
      default:
        throw new Error("unknown action")
    }
  }

  const messages = getMessages()

  return !isEmpty(data) ? (
    <>
      <h1>{messages.title}</h1>
      <form method="post" onSubmit={onSubmit}>
        <p>
          {messages.confirm} <b>{data.displayName}</b>?
        </p>
        <div className="my-2">
          <Button submit>{messages.button}</Button>
        </div>
        <div>
          <Button icon="chevron-left-double" onClick={() => history.goBack()}>
            {t("Back")}
          </Button>
        </div>
      </form>
    </>
  ) : null
}

TourOperatorInvoiceAction.propTypes = {
  objId: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}
