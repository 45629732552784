import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

import Notification from "components/Notification/Notification"
import { dismissAllNotifications } from "utils/notifications"

export const notificationRef = React.createRef()

class NotificationContainer extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.unlisten = this.props.history.listen(() => {
      dismissAllNotifications()
    })
  }

  componentWillUnmount = () => this.unlisten()

  render = () => <Notification ref={notificationRef} />
}

export default withRouter(NotificationContainer)
