import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import TourOperatorsGroupFeeFormSet from "apps/fees/Fee/TourOperatorsGroupFeeFormSet"
import { Form, FormField } from "components/Form"
import { apiPUT, apiGET, apiPOST } from "utils/api"

export default function FeeForm(props) {
  const [formData, setFormData] = useState({})

  const { t } = useTranslation()

  useEffect(
    () =>
      props.isUpdate
        ? apiGET({
            path: props.detailEndpoint,
            onSuccess: response => setFormData(response),
            onError: () => props.history.push(props.errorUrl),
          })
        : () => {},
    [
      props.isUpdate,
      props.match.params.id,
      props.history,
      props.detailEndpoint,
      props.errorUrl,
    ]
  )

  return (
    <div>
      <Form
        endpoint={props.actionEndpoint}
        apiFunction={props.isUpdate ? apiPUT : apiPOST}
        defaultValues={formData}
        successUrl={props.successUrl}
        successMessage={props.successMessage}
        title={props.label}>
        <div>
          {props.isTourOperator && <h5>{t("Charge creation")}</h5>}
          <div className="row">
            <div className="col-4">
              <FormField
                name="category"
                fieldType="asyncSelect"
                label={t("Category")}
                endpoint="/fees/categories/"
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <FormField
                name="route"
                fieldType="asyncSelect"
                label={t("Segment")}
                endpoint="/routings/routes/"
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <FormField
                name="airline"
                fieldType="asyncSelect"
                label={t("Carrier")}
                endpoint="/airlines/"
                icon="airplane"
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <FormField
                name="startDate"
                fieldType="date"
                label={t("Start date")}
                required
              />
            </div>
            <div className="col-4">
              <FormField
                name="value"
                fieldType="price"
                label={t("Value")}
                required
              />
            </div>
          </div>
        </div>
        {props.isTourOperator && (
          <div className="mt-4 mb-5">
            <h5>{t("Tour Operator Specific")}</h5>
            <TourOperatorsGroupFeeFormSet />
          </div>
        )}
      </Form>
    </div>
  )
}

FeeForm.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string,
    }),
  }).isRequired,
  isUpdate: PropTypes.bool,
  actionEndpoint: PropTypes.string.isRequired,
  detailEndpoint: PropTypes.string,
  errorUrl: PropTypes.string,
  successUrl: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  isTourOperator: PropTypes.bool,
}

FeeForm.defaultProps = {
  isUpdate: false,
  detailEndpoint: "",
  errorUrl: "",
  isTourOperator: false,
}
