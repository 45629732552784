import React from "react"

import { useTranslation } from "react-i18next"

import { TableWithFilters } from "components/Table"

const getConfig = t => ({
  endpoint: "/countries",
  title: t("Countries"),
  tableName: "country-table-config",
  columns: [
    { Header: t("Name"), accessor: "name" },
    {
      Header: t("Continent"),
      accessor: c => c.continent.displayName,
      id: "continent",
    },
  ],
  filters: [
    [
      {
        name: "name",
        fieldType: "input",
        type: "text",
        label: t("Name"),
        size: "col-4",
      },
      {
        name: "continent",
        fieldType: "select",
        endpoint: "/countries/continents/",
        label: t("Continent"),
        size: "col-4",
        storeChoices: true,
      },
      {
        fieldType: "applyButton",
        size: "col-auto mt-2",
      },
      {
        fieldType: "clearButton",
        size: "col-auto mt-2",
      },
    ],
  ],
})

export default function CountryList(props) {
  const { t } = useTranslation()

  return <TableWithFilters config={getConfig(t)} />
}
