import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import { useTranslation } from "react-i18next"
import moment from "moment"

import {
  BookingInfo,
  ImportCSV,
  Summary,
  TicketInfo,
  ClearAll,
} from "./includes"
import { validateTicketForm } from "./utils"
import PassengersFormSet from "./Passengers/PassengersFormSet"
import FlightDetails from "apps/flights/FlightDetails/FlightDetails"
import Button from "components/Button/Button"
import { Form } from "components/Form"
import SectionHeader from "components/SectionHeader/SectionHeader"
import { apiGET, apiPUT } from "utils/api"
import { DATE_FORMAT } from "utils/constants"
import { notify } from "utils/notifications"
import { reverse } from "utils/urls"

export default function TicketUpdate(props) {
  const [formData, setFormData] = useState({})
  const [ticketInfo, setTicketInfo] = useState()
  const [saveType, setSaveType] = useState()

  const { t } = useTranslation()

  useEffect(() => {
    if (!ticketInfo && !isEmpty(formData)) {
      apiGET({
        path: `/flights/segments/segments-ticket-info/?segments=${formData.segment.id}&tourOperator=${formData.tourOperator.id}&ticket=${formData.id}`,
        onSuccess: response => setTicketInfo(response),
        onError: () =>
          notify(
            "error",
            t("There were some errors while trying to get segments info.")
          ),
      })
    }
  }, [formData, t, ticketInfo])

  useEffect(
    () =>
      apiGET({
        path: `/tickets/${props.match.params.id}/?edit=true`,
        onSuccess: response => setFormData(response),
        onError: () => props.history.push(reverse("ticketList")),
      }),
    [props.match.params.id, props.history]
  )

  return (
    <>
      {ticketInfo ? (
        <Form
          showBack={false}
          showSubmit={false}
          apiFunction={apiPUT}
          endpoint={`/tickets/${props.match.params.id}/`}
          defaultValues={formData}
          successUrl={reverse("ticketDetail", { id: props.match.params.id })}
          successMessage={t("Ticket was successfully saved.")}
          validateForm={values => validateTicketForm(values, t)}
          processData={d => {
            return {
              ...d,
              ...{
                tourOperator: formData.tourOperator.id,
                segments: [
                  formData.segment.id,
                  saveType === "withRelated"
                    ? formData.relatedTicketSegment
                    : null,
                ].filter(id => id !== null),
              },
            }
          }}>
          <h1>
            {t("Edit ticket")} -{" "}
            <span className="text-magenta">{formData.reference}</span>
          </h1>
          <div className="row mt-4">
            <div className="col-12 col-xxl">
              <div className="row">
                <div className="col-12">
                  <FlightDetails data={ticketInfo.flightDetails[0]} />
                </div>
                <div className="col-12">
                  <BookingInfo
                    seats={ticketInfo.seats[0]}
                    tourOperator={formData.tourOperator}
                  />
                </div>
              </div>
            </div>
            <div className="col-xxl-auto px-0 border-right border-teal-light" />
            <div className="col-12 mt-3 mt-xxl-0 col-xxl">
              <TicketInfo ticketDefault={ticketInfo.ticketDefault[0]} />
            </div>
          </div>
          <Summary flightType="single" />
          <div className="row mt-4">
            <div className="col-12">
              <SectionHeader header={t("Passenger list")} />
            </div>
            <div className="col-12">
              <div className="row justify-content-center mt-3">
                <div className="col-auto">
                  <ImportCSV />
                </div>
              </div>
              <div className="row">
                <div className="ml-auto col-auto">
                  <ClearAll />
                </div>
              </div>
            </div>
          </div>
          <div className="row my-2">
            <div className="col-12">
              <hr className="text-primary mb-1" />
            </div>
          </div>
          <PassengersFormSet
            maxChildAge={Math.min(
              ...ticketInfo.ticketDefault.map(flight =>
                flight !== null ? flight.maxChildAge : 18
              )
            )}
            operatingDates={ticketInfo.flightDetails.map(flight =>
              moment(flight.operatingDate, DATE_FORMAT)
            )}
          />
          <div className="row mt-5">
            <div className="col-auto">
              <Button
                icon="chevron-left-double"
                onClick={() => props.history.goBack()}>
                {t("Back")}
              </Button>
            </div>
            {formData.relatedTicketSegment && (
              <div className="ml-auto col-auto">
                <Button
                  submit
                  type="info"
                  onClick={() => setSaveType("withRelated")}>
                  {t("Save and apply to related ticket")}
                </Button>
              </div>
            )}
            <div
              className={`${
                formData.relatedTicketSegment ? "ml-1" : "ml-auto"
              } col-auto`}>
              <Button onClick={() => setSaveType("basic")} submit>
                {t("Save")}
              </Button>
            </div>
          </div>
        </Form>
      ) : null}
    </>
  )
}

TicketUpdate.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string,
    }),
  }).isRequired,
}
