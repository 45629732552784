import React, { useEffect, useState } from "reactn"
import PropTypes from "prop-types"

import { Route, Redirect } from "react-router-dom"

import MainLayout from "components/Layout/MainLayout"
import { hideLoadingOverlay } from "components/LoadingOverlay/LoadingOverlay"
import { isLoggedIn } from "apps/auth/auth"
import { apiStoreGET } from "utils/api"
import { APP_INFO_URL, AUTH_INFO_URL } from "utils/constants"
import history from "utils/history"
import { reverse, buildRouteFromLocation } from "utils/urls"

export default function ProtectedRoute({ component: Component, ...rest }) {
  const [permissions, setPermissions] = useState([])
  const [isAppInfoLoaded, setIsAppInfoLoaded] = useState(false)

  useEffect(() => {
    if (isLoggedIn()) {
      apiStoreGET({
        path: AUTH_INFO_URL,
        onSuccess: response => setPermissions(response.permissions),
      })
      apiStoreGET({
        path: APP_INFO_URL,
        onSuccess: () => setIsAppInfoLoaded(true),
      })
    }
  }, [])

  useEffect(() => {
    return history.listen(() => {
      hideLoadingOverlay()
    })
  })

  return isLoggedIn() === true ? (
    permissions.length && isAppInfoLoaded ? (
      <Route
        {...rest}
        render={props =>
          rest.permissions.every(p => permissions.includes(p)) ? (
            <MainLayout {...props}>
              <Component {...props} />
            </MainLayout>
          ) : (
            <Redirect to={reverse("home")} />
          )
        }
      />
    ) : null
  ) : (
    <Redirect
      to={reverse({
        name: "login",
        queryParams: {
          next: history
            ? buildRouteFromLocation(history.location)
            : reverse("home"),
        },
      })}
    />
  )
}

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
}
