import React from "react"

import BookingCreate from "./BookingCreate/BookingCreate"
import {
  SegmentSelection,
  SegmentSelectionTypeContext,
} from "apps/wizardForms/SegmentSelection"

function AirlineBookingSegmentSelection(props) {
  return (
    <SegmentSelectionTypeContext.Provider value="airlineBooking">
      <SegmentSelection {...props} />
    </SegmentSelectionTypeContext.Provider>
  )
}

function AirlineBookingCreate(props) {
  return (
    <SegmentSelectionTypeContext.Provider value="airlineBooking">
      <BookingCreate {...props} />
    </SegmentSelectionTypeContext.Provider>
  )
}

function TourOperatorBookingSegmentSelection(props) {
  return (
    <SegmentSelectionTypeContext.Provider value="tourOperatorBooking">
      <SegmentSelection {...props} />
    </SegmentSelectionTypeContext.Provider>
  )
}

function TourOperatorBookingCreate(props) {
  return (
    <SegmentSelectionTypeContext.Provider value="tourOperatorBooking">
      <BookingCreate {...props} />
    </SegmentSelectionTypeContext.Provider>
  )
}

export {
  AirlineBookingSegmentSelection,
  AirlineBookingCreate,
  TourOperatorBookingSegmentSelection,
  TourOperatorBookingCreate,
}

export {
  default as AirlineBookingUpdate,
} from "./BookingUpdate/AirlineBookingUpdate"
export {
  default as AirlineBookingDelete,
} from "./BookingDelete/AirlineBookingDelete"
export {
  default as AirlineBookingDetail,
} from "./BookingDetail/AirlineBookingDetail"
export { default as AirlineBookingList } from "./BookingList/AirlineBookingList"
export {
  default as TourOperatorBookingUpdate,
} from "./BookingUpdate/TourOperatorBookingUpdate"
export {
  default as TourOperatorBookingDelete,
} from "./BookingDelete/TourOperatorBookingDelete"
export {
  default as TourOperatorBookingDetail,
} from "./BookingDetail/TourOperatorBookingDetail"
export {
  default as TourOperatorBookingList,
} from "./BookingList/TourOperatorBookingList"
