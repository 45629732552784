import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import InfantBookingFareForm from "apps/fares/InfantBookingFare/InfantBookingFareForm"
import { reverse } from "utils/urls"

export default function TourOperatorInfantBookingFareUpdate(props) {
  const { t } = useTranslation()

  return (
    <InfantBookingFareForm
      {...props}
      isUpdate
      detailEndpoint={`/fares/tour-operator-infant-fares/${props.match.params.id}/`}
      actionEndpoint={`/fares/tour-operator-infant-fares/${props.match.params.id}/`}
      errorUrl={reverse("tourOperatorInfantBookingFareList")}
      label={t("Edit tour operator infant booking fare")}
      successMessage={t(
        "Tour Operator Infant Booking Fare was successfully saved."
      )}
      successUrl={reverse("tourOperatorInfantBookingFareList")}
    />
  )
}

TourOperatorInfantBookingFareUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
