import React from "react"
import PropTypes from "prop-types"

import "./FieldDetail.scss"

export default function FieldDetail({
  value,
  valueClassName,
  inputGroupPrepend,
  inputGroupAppend,
  label,
  type,
  ...rest
}) {
  return (
    <>
      {label && <div className="px-2 mt-2">{label}</div>}
      <div
        className={`my-2 field-detail-container ${
          inputGroupPrepend || inputGroupAppend ? "input-group" : ""
        }`}>
        {inputGroupPrepend !== null && (
          <div className="input-group-prepend">{inputGroupPrepend}</div>
        )}

        <div
          className={`field-detail bg-light px-2 ${valueClassName} ${
            inputGroupPrepend || inputGroupAppend ? "field-control" : ""
          } ${type}`}>
          <b>{value}</b>
        </div>
        {inputGroupAppend !== null && (
          <div className="input-group-append">{inputGroupAppend}</div>
        )}
      </div>
    </>
  )
}

FieldDetail.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]).isRequired,
  valueClassName: PropTypes.string,
  inputGroupPrepend: PropTypes.node,
  inputGroupAppend: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
}

FieldDetail.defaultProps = {
  valueClassName: "",
  inputGroupPrepend: null,
  inputGroupAppend: null,
  label: null,
  type: "",
}
