import React from "react"

import TicketCreateMixin from "./TicketForm/TicketCreate"
import {
  SegmentSelection,
  SegmentSelectionTypeContext,
} from "apps/wizardForms/SegmentSelection"

function TicketSegmentSelection(props) {
  return (
    <SegmentSelectionTypeContext.Provider value="ticket">
      <SegmentSelection {...props} />
    </SegmentSelectionTypeContext.Provider>
  )
}

function TicketCreate(props) {
  return (
    <SegmentSelectionTypeContext.Provider value="ticket">
      <TicketCreateMixin {...props} />
    </SegmentSelectionTypeContext.Provider>
  )
}

export { default as TicketHistory } from "./TicketHistory"
export { default as TicketUpdate } from "./TicketForm/TicketUpdate"
export { default as TicketDelete } from "./TicketDelete"
export { default as TicketDetail } from "./TicketDetail"
export { default as TicketList } from "./TicketList"
export { TicketSegmentSelection, TicketCreate }
