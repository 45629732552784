const routes = {
  tourOperatorInvoiceInvertTaxes:
    "/invoices/tour-operator-invoices/:id/invert-taxes",
  tourOperatorInvoiceSend: "/invoices/tour-operator-invoices/:id/send",
  tourOperatorInvoiceDelete: "/invoices/tour-operator-invoices/:id/delete",
  tourOperatorInvoiceDeleteInvoiceItem:
    "/invoices/tour-operator-invoices/:id/item/:itemType/:itemId/delete",
  tourOperatorInvoiceNullify: "/invoices/tour-operator-invoices/:id/nullify",
  tourOperatorInvoiceNullifyRecreate:
    "/invoices/tour-operator-invoices/:id/nullify-recreate",
  tourOperatorInvoiceDetail: "/invoices/tour-operator-invoices/:id",
  tourOperatorInvoiceList: "/invoices/tour-operator-invoices",
}

export default routes
