import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Form, FormField } from "components/Form"
import { apiPUT, apiGET } from "utils/api"
import { reverse } from "utils/urls"

export default function AppSettingsUpdate(props) {
  const [formData, setFormData] = useState({})

  const { t } = useTranslation()

  useEffect(
    () =>
      apiGET({
        path: "/app-settings/detail/",
        onSuccess: response => setFormData(response),
        onError: () => props.history.push(reverse("home")),
      }),
    [props.history]
  )

  return (
    <>
      <Form
        endpoint="/app-settings/update/"
        apiFunction={apiPUT}
        defaultValues={formData}
        successUrl={reverse("appSettingsDetail")}
        successMessage={t("App Settings were successfully saved.")}
        title={t("Edit app settings")}>
        <div className="row">
          <div className="col-8">
            <FormField
              name="currency"
              fieldType="select"
              label={t("Currency")}
              endpoint="/currencies/"
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <FormField
              name="startingWeekday"
              fieldType="select"
              label={t("Starting weekday")}
              endpoint="/app-settings/starting-weekday/"
              required
            />
          </div>
          <div className="col-4">
            <FormField
              name="startingWeekdayNo"
              fieldType="input"
              label={t("Starting weekday No")}
              type="number"
              min="0"
              max="1"
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            <FormField
              name="nonWorkingDays"
              fieldType="select"
              label={t("Non Working Days")}
              endpoint="/app-settings/weekdays/"
              isMulti
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <FormField
              name="bookingBlockAccessTime"
              fieldType="time"
              label={t("Booking Block Access Time")}
              required
            />
          </div>
          <div className="col-4">
            <FormField
              name="bookingBlockAccessDayOffset"
              fieldType="input"
              type="number"
              min="0"
              label={t("Booking Block Access Day Offset")}
              required
              inputGroupPrepend={<span className="input-group-text">D-</span>}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <FormField
              name="massUnit"
              fieldType="select"
              label={t("Mass Unit")}
              endpoint="/app-settings/mass-unit/"
              required
            />
          </div>
          <div className="col-4">
            <FormField
              name="lengthUnit"
              fieldType="select"
              label={t("Length Unit")}
              endpoint="/app-settings/length-unit/"
              required
            />
          </div>
        </div>
      </Form>
    </>
  )
}

AppSettingsUpdate.propTypes = {
  history: PropTypes.object.isRequired,
}
