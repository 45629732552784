import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

import Avatar from "components/Avatar/Avatar"
import { SubTable, TableWithFilters } from "components/Table"
import { DATE_FORMAT } from "utils/constants"

const getConfig = (t, tableConfig, actionsColumn) => {
  return {
    ...tableConfig,
    columns: [
      {
        Header: t("Segment"),
        id: "segment",
        accessor: f => f.active.route.displayName,
      },
      ...(tableConfig.tableName.includes("airline")
        ? [
            {
              Header: t("Carrier"),
              id: "carrier",
              accessor: f => (
                <Avatar
                  image={f.active.airline.logo}
                  title={f.active.airline.displayName}
                  alt={f.active.airline.code}
                />
              ),
            },
          ]
        : []),
      ...(tableConfig.tableName.includes("tour-operator")
        ? [
            {
              Header: t("Tour Operator"),
              id: "tourOperator",
              accessor: f => (
                <Avatar
                  image={f.active.tourOperator.logo}
                  title={f.active.tourOperator.displayName}
                  alt={f.active.tourOperator.code}
                />
              ),
            },
          ]
        : []),
      {
        Header: t("Booking Type"),
        id: "type",
        accessor: f => f.active.type.id,
      },
      {
        Header: t("Period"),
        id: "period",
        accessor: f =>
          `${f.active.startDate} - ${f.active.endDate || t("Unlimited")}`,
      },
      {
        Header: t("Value"),
        id: "value",
        accessor: f => f.active.fare,
        className: "text-right",
      },
      actionsColumn,
    ],
    SubComponent: row => {
      return (
        <SubTable
          data={row.original.fares}
          columns={[
            {
              Header: t("Period"),
              accessor: "startDate",
              Cell: subRow =>
                subRow.original.id === row.original.active.id ? (
                  <span className="text-danger">{`${
                    subRow.original.startDate
                  } - ${subRow.original.endDate || t("Unlimited")}`}</span>
                ) : (
                  <>{`${subRow.original.startDate} - ${
                    subRow.original.endDate || t("Unlimited")
                  }`}</>
                ),
              sortMethod: (a, b, desc) => {
                a = moment(a, DATE_FORMAT)
                b = moment(b, DATE_FORMAT)

                if (a.isBefore(b)) {
                  return 1
                } else if (a.isAfter(b)) {
                  return -1
                }

                return 0
              },
              parentColumnIndex: 3,
            },
            {
              Header: t("Value"),
              accessor: "value",
              Cell: subRow => subRow.original.fare,
              className: "text-right",
              parentColumnIndex: 4,
            },
            actionsColumn,
          ]}
          diagonalColumnWidth={[0, 1, 2]}
        />
      )
    },
    filters: [
      [
        {
          size: "col",
          children: [
            [
              {
                name: "route",
                fieldType: "asyncSelect",
                endpoint: "/routings/routes/",
                label: t("Segment"),
                size: "col-3",
              },
              ...(tableConfig.tableName.includes("airline")
                ? [
                    {
                      name: "airline",
                      fieldType: "asyncSelect",
                      endpoint: "/airlines/",
                      icon: "airplane",
                      label: t("Carrier"),
                      size: "col-3",
                    },
                  ]
                : []),
              ...(tableConfig.tableName.includes("tour-operator")
                ? [
                    {
                      name: "tourOperator",
                      fieldType: "asyncSelect",
                      endpoint: "/tour-operators/",
                      label: t("Tour Operator"),
                      size: "col-3",
                    },
                  ]
                : []),
              {
                name: "type",
                fieldType: "select",
                endpoint: "/bookings/booking-types/",
                label: t("Booking Type"),
                size: "col-3",
              },
              {
                fieldType: "applyButton",
                size: "col-auto mt-2",
              },
              {
                fieldType: "clearButton",
                size: "col-auto mt-2",
              },
            ],
          ],
        },
      ],
    ],
  }
}

export default function FareList(props) {
  const { t } = useTranslation()

  return (
    <TableWithFilters
      config={getConfig(t, props.tableConfig, props.actionsColumn)}
    />
  )
}

FareList.propTypes = {
  tableConfig: PropTypes.object,
  actionsColumn: PropTypes.object,
}

FareList.defaultProps = {
  tableConfig: {},
  actionsColumn: null,
}
