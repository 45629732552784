import React from "react"
import { useTranslation } from "react-i18next"

import { hasPermissions } from "apps/auth/auth"
import Button from "components/Button/Button"
import Tooltip from "components/Tooltip/Tooltip"
import Icon from "components/Icon/Icon"
import { actionsColumn, TableWithFilters } from "components/Table"
import { reverse } from "utils/urls"

const getConfig = t => ({
  endpoint: "/currencies",
  title: t("Currencies"),
  tableName: "currency-table-config",
  columns: [
    {
      Header: t("Name"),
      accessor: "name",
    },
    {
      Header: t("Code"),
      accessor: "code",
    },
    actionsColumn(obj => (
      <>
        <Tooltip title={t("Currency History")}>
          <Icon.Link
            name="history"
            color="gray"
            to={reverse("history", { id: obj.id, model: "currencies" })}
          />
        </Tooltip>
        <Tooltip title={t("Edit Currency")}>
          <Icon.Link
            name="edit"
            linkClassName="ml-3"
            to={reverse("currencyUpdate", { id: obj.id })}
          />
        </Tooltip>
      </>
    )),
  ],
  filters: [],
  buttons: (
    <>
      {hasPermissions(["add_currencies_currency"]) && (
        <Button icon="plus" to={reverse("currencyCreate")}>
          {t("Add Currency")}
        </Button>
      )}
    </>
  ),
})

export default function CurrencyList(props) {
  const { t } = useTranslation()

  return <TableWithFilters config={getConfig(t)} />
}
