import React, { useState } from "react"
import PropTypes from "prop-types"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { isString } from "lodash"

import FeeCell from "./FeeCell"
import InvoiceWorkingSheetSegmentDetails from "./InvoiceWorkingSheetSegmentDetails"
import { COLUMN_WIDTHS } from "apps/sheets/columns"
import { COLUMN_WIDTHS as INVOICE_WORKING_SHEET_COLUMN_WIDTHS } from "apps/sheets/InvoiceWorkingSheet/columns"

import { hasPermissions } from "apps/auth/auth"
import Checkbox from "components/Checkbox/Checkbox"
import Icon from "components/Icon/Icon"
import Sheet from "components/Sheet/Sheet"
import { apiStoreGET, apiPOST, fileGET } from "utils/api"
import { getLocalStorageObj, setLocalStorageObj } from "utils/fs"
import { notify } from "utils/notifications"

import styles from "apps/sheets/Sheet.module.scss"
import InvoiceWorkingSheetSubmit from "./InvoiceWorkingSheetSubmit"

const apiGETNamespace = "invoice-working-sheet"

export default function InvoiceWorkingSheet(props) {
  const { t } = useTranslation()
  let history = useHistory()

  const storageName = `${history.location.pathname}-sheet`
  const storageSheetData = getLocalStorageObj(storageName) || {}

  const [expandFlightDetails, setExpandFlightDetails] = useState(
    storageSheetData.expandedFlightDetails !== undefined
      ? storageSheetData.expandedFlightDetails
      : true
  )

  const closeModal = () => {
    const closeEl = [].slice.call(
      document.querySelectorAll(`[data-dismiss="modal"]`)
    )[0]

    if (closeEl) {
      closeEl.dispatchEvent(new Event("click"))
    }
  }

  return (
    <Sheet
      formProps={{
        endpoint: "/invoices/tour-operator-invoices/",
        apiFunction: apiPOST,
        processData: d => {
          // close modal before request is made
          closeModal()

          return {
            items: Object.values(d)
              .filter(item => isString(item) && item.includes("segment-"))
              // it makes sense to join and then split by `,` because each item in itself has
              // data joined by `,` and this will split all items
              .join(",")
              .split(",")
              .filter(item => item !== ""),
            tourOperator: new URLSearchParams(history.location.search).get(
              "tourOperator"
            ),
            invoiceType: d.invoiceType.id,
            currency: d.currency?.id,
            existingInvoice: d.existingInvoice?.id,
          }
        },
        successFunction: () => {
          // this will trigger reloading of the sheet without changing filters
          const searchParams = new URLSearchParams(history.location.search)
          searchParams.set("submitted", Date.now())
          history.push("?" + searchParams.toString())
        },
        successMessage: response => {
          const subject = response.isCreditNote
            ? t("Credit note")
            : t("Invoice")

          const pdfLink = (
            <u
              className="text-underline"
              onClick={() =>
                fileGET(`/invoices/tour-operator-invoices/${response.id}/pdf/`)
              }
              style={{ cursor: "pointer" }}>
              {t("here")}.
            </u>
          )

          return (
            <>
              {subject} {t("was saved. You can download PDF")} {pdfLink}
            </>
          )
        },
        showBack: false,
        showSubmit: false,
        submitComponent: hasPermissions([
          "add_invoices_touroperatorinvoice",
        ]) ? (
          <InvoiceWorkingSheetSubmit />
        ) : (
          <></>
        ),
      }}
      endpoint="/sheets/invoice-working-sheet"
      requiredFilters={["tourOperator", "dateFrom", "dateTo", "operatingDays"]}
      title={t("Invoice Working Sheet")}
      headerLevels={2}
      apiGETNamespace={apiGETNamespace}
      showExpander={row => row.original.hasInvoicingItems}
      SubComponent={row => (
        <InvoiceWorkingSheetSegmentDetails
          id={row.original.id}
          apiGETNamespace={apiGETNamespace}
          expandFlightDetails={expandFlightDetails}
        />
      )}
      columns={[
        {
          Header: (
            <div className="row">
              <div className="col">{t("Flight Details")}</div>
              <div className="col-auto ml-auto">
                <div style={{ cursor: "pointer" }}>
                  <Icon
                    name={`chevron-${
                      expandFlightDetails ? "left" : "right"
                    }-double`}
                    color="white"
                    onClick={() => {
                      setExpandFlightDetails(!expandFlightDetails)

                      const sheetData = getLocalStorageObj(storageName) || {}
                      sheetData.expandedAirlines = !expandFlightDetails
                      setLocalStorageObj(storageName, sheetData)
                    }}
                  />
                </div>
              </div>
            </div>
          ),
          headerClassName: styles["th-flight-details"],
          columns: [
            {
              Header: t("Week"),
              accessor: "weekNo",
              width: COLUMN_WIDTHS.weekNo,
              headerClassName: styles["t-gray-light"],
            },
            {
              Header: t("Date"),
              accessor: "localDepartureDate",
              width: COLUMN_WIDTHS.localDepartureDate,
              headerClassName: styles["t-gray-light"],
            },
            {
              Header: (
                <>
                  D<sup>0</sup>
                </>
              ),
              accessor: "operatingDay",
              width: COLUMN_WIDTHS.operatingDay,
              headerClassName: styles["t-gray-light"],
            },
            {
              Header: t("Route"),
              accessor: "displayName",
              width: COLUMN_WIDTHS.route,
              headerClassName: styles["t-gray-light"],
            },
            {
              Header: (
                <>
                  {t("Flight")} N<sup>o</sup>
                </>
              ),
              accessor: "flightNo",
              width: COLUMN_WIDTHS.flightNo,
              headerClassName: styles["t-gray-light"],
            },
            ...(expandFlightDetails
              ? [
                  {
                    Header: t("ETD"),
                    accessor: "etd",
                    width: COLUMN_WIDTHS.etd,
                    headerClassName: styles["t-gray-light"],
                  },
                  {
                    Header: t("ETA"),
                    accessor: "eta",
                    width: COLUMN_WIDTHS.eta,
                    headerClassName: styles["t-gray-light"],
                  },
                ]
              : []),
          ],
        },
        {
          id: "select",
          accessor: s =>
            (s.hasBookingsToInvoice ||
              s.hasInfantBookingsToInvoice ||
              s.hasTaxesToInvoice) &&
            hasPermissions(["add_invoices_touroperatorinvoice"]) ? (
              <SelectInvoiceSegment segmentId={s.id} />
            ) : null,
          width: COLUMN_WIDTHS.empty,
        },
        {
          Header: headerProps =>
            headerProps.data?.[0]?._original.tourOperatorName,
          headerClassName: styles["th-tour-operators"],
          columns: [
            {
              Header: t("Reference"),
              accessor: "reference",
              headerClassName: styles["th-sub-tour-operators"],
              className: styles["t-gray-light"],
            },
            {
              Header: t("Type"),
              accessor: "type",
              width: INVOICE_WORKING_SHEET_COLUMN_WIDTHS.type,
              headerClassName: styles["th-sub-tour-operators"],
              className: styles["t-gray-light"],
            },
            {
              Header: t("Qty."),
              accessor: "quantity",
              width: INVOICE_WORKING_SHEET_COLUMN_WIDTHS.quantity,
              headerClassName: styles["th-sub-tour-operators"],
              className: styles["t-gray-light"],
            },
            {
              Header: t("Fare"),
              accessor: "fare",
              headerClassName: styles["th-sub-tour-operators"],
              className: styles["t-gray-light"],
            },
            {
              Header: t("Surcharge"),
              id: "surcharge",
              accessor: s => (
                <FeeCell
                  total={s.fees.surchargesTotal}
                  header={t("Surcharges")}
                  objects={s.fees.surcharges}
                />
              ),
              headerClassName: styles["th-sub-tour-operators"],
              className: styles["t-gray-light"],
            },
            {
              Header: t("Tax"),
              id: "tax",
              accessor: s => (
                <FeeCell
                  total={s.fees.taxesTotal}
                  header={t("Taxes")}
                  objects={s.fees.taxes}
                />
              ),
              headerClassName: styles["th-sub-tour-operators"],
              className: styles["t-gray-light"],
            },
            {
              Header: t("Status"),
              id: "status",
              accessor: s =>
                s.status !== null && (
                  <span className="text-white">{s.status.label}</span>
                ),
              getProps: (_, row) => {
                switch (row.original.status?.value) {
                  case "no":
                    return { className: "bg-danger" }
                  case "over":
                    return { className: "bg-warning" }
                  case "invoiced":
                    return { className: "bg-success" }
                  default:
                    return { className: styles["t-gray-light"] }
                }
              },
              headerClassName: styles["th-sub-tour-operators"],
              width: INVOICE_WORKING_SHEET_COLUMN_WIDTHS.status,
            },
          ],
        },
      ]}
      filters={[
        [
          {
            size: "col",
            children: [
              [
                {
                  name: "tourOperator",
                  fieldType: "asyncSelect",
                  endpoint: "/tour-operators/",
                  label: t("Tour Operator"),
                  required: true,
                  size: "col-4",
                },
                {
                  name: "departureAirport",
                  fieldType: "airportSelect",
                  withCities: true,
                  icon: "airplane-departure",
                  label: t("Departure airport"),
                  size: "col-4",
                },
                {
                  name: "arrivalAirport",
                  fieldType: "airportSelect",
                  withCities: true,
                  icon: "airplane-arrival",
                  label: t("Arrival airport"),
                  size: "col-4",
                },
              ],
              [
                {
                  name: "invoicingItems",
                  fieldType: "select",
                  options: [
                    { id: "BKG", displayName: t("Booking") },
                    { id: "INF", displayName: t("INF Booking") },
                    { id: "TAX", displayName: t("TAX") },
                  ],
                  isMulti: true,
                  label: t("Invoicing items"),
                  size: "col-4",
                },
                {
                  name: "addReturn",
                  fieldType: "checkbox",
                  label: t("Add return"),
                  size: "col-auto",
                  align: "center",
                },
                {
                  name: "withBookings",
                  fieldType: "checkbox",
                  label: t("Only flights with bookings"),
                  size: "col-auto",
                  align: "center",
                },
                {
                  name: "entireRouting",
                  fieldType: "checkbox",
                  label: t("Entire routing"),
                  size: "col-auto",
                  align: "center",
                },
              ],
              [
                {
                  fieldType: "selectDateRange",
                  dateFromName: "dateFrom",
                  dateToName: "dateTo",
                  size: "col-8",
                  required: true,
                },
                {
                  fieldType: "operatingDays",
                  name: "operatingDays",
                  size: "col-auto text-center",
                  required: true,
                },
              ],
            ],
          },
          {
            size: "col-auto",
            children: [
              [
                {
                  fieldType: "applyButton",
                  size: "col-12 mt-1",
                },
              ],
              [
                {
                  fieldType: "clearButton",
                  size: "col-12 mt-3",
                },
              ],
            ],
          },
        ],
      ]}
    />
  )
}

function SelectInvoiceSegment({ segmentId }) {
  const { register, setValue } = useFormContext()
  const { t } = useTranslation()
  let history = useHistory()

  const name = `segment-${segmentId}`

  return (
    <>
      <input name={name} ref={register} type="hidden" />
      <Checkbox
        onChange={e => {
          const searchParams = new URLSearchParams(history.location.search)
          const tourOperarorId = searchParams.get("tourOperator")

          const checked = e.target.checked

          apiStoreGET({
            namespace: apiGETNamespace,
            path: `/sheets/invoice-working-sheet/${segmentId}/segment-details/?tourOperator=${tourOperarorId}`,
            onSuccess: response => {
              const fields = response.objects
                .filter(item =>
                  ["booking", "infant_booking", "segment_tax"].includes(
                    item.type
                  )
                )
                .map(
                  item =>
                    `${name}-${item.seats < 0 ? "credit-note" : ""}${
                      item.seats > 0 ? "invoice" : ""
                    }-${item.type}-${item.id}`
                )

              if (checked) {
                setValue(name, fields.join(","))
              } else {
                setValue(name, "")
              }
              window.dispatchEvent(new Event("invoiceitemselected"))
            },
            onError: () =>
              notify(
                "error",
                t("There were some errors while trying to get segment details.")
              ),
          })
        }}
      />
    </>
  )
}

SelectInvoiceSegment.propTypes = {
  segmentId: PropTypes.number.isRequired,
}
