import React from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router"

import BookingDetail from "./BookingDetail"
import { reverse, buildRouteFromLocation } from "utils/urls"

export default function AirlineBookingDetail(props) {
  let history = useHistory()

  return (
    <BookingDetail
      isAirline
      detailEndpoint={`/bookings/airline-bookings/${props.match.params.id}/`}
      listRoute={reverse("airlineBookingList")}
      changeRoute={reverse(
        "airlineBookingUpdate",
        {
          id: props.match.params.id,
        },
        { next: buildRouteFromLocation(history.location) }
      )}
      deleteRoute={reverse(
        "airlineBookingDelete",
        {
          id: props.match.params.id,
        },
        {
          next:
            encodeURIComponent(
              new URLSearchParams(history.location.search).get("next")
            ) || reverse("airlineBookingList"),
        }
      )}
    />
  )
}

AirlineBookingDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
