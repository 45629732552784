import moment from "moment-timezone"

import { DATE_FORMAT, TIME_FORMAT } from "utils/constants"

export const getDurationDiff = (values, startLegIdx, endLegIdx = null) => {
  const start = {},
    end = {}

  const legs = values.legs || []

  // If endLegId is not defined it means that duration of one leg is calculated
  if (legs[startLegIdx] && !endLegIdx) {
    Object.assign(start, {
      airport: legs[startLegIdx].departureAirport,
      time: legs[startLegIdx].departureTime,
      dayOffset: legs[startLegIdx].departureDayOffset,
    })

    Object.assign(end, {
      airport: legs[startLegIdx].arrivalAirport,
      time: legs[startLegIdx].arrivalTime,
      dayOffset: legs[startLegIdx].arrivalDayOffset,
    })
    // If endLegId is defined it means that layover between two legs is calculated
  } else if (legs[startLegIdx] && legs[endLegIdx]) {
    Object.assign(start, {
      airport: legs[startLegIdx].arrivalAirport,
      time: legs[startLegIdx].arrivalTime,
      dayOffset: legs[startLegIdx].arrivalDayOffset,
    })

    Object.assign(end, {
      airport: legs[endLegIdx].departureAirport,
      time: legs[endLegIdx].departureTime,
      dayOffset: legs[endLegIdx].departureDayOffset,
    })
  }

  // check if one of the required fields is missing
  if (!start.airport || !start.time || !end.airport || !end.time) {
    return null
  }

  let startDateTime, endDateTime
  // if startDate is passed set that date as a date for calculation
  if (values.period && values.period.dateFrom) {
    startDateTime = moment(values.period.dateFrom, DATE_FORMAT)
    endDateTime = moment(values.period.dateFrom, DATE_FORMAT)
  } else {
    startDateTime = moment()
    endDateTime = moment()
  }

  if (start.dayOffset) {
    startDateTime.add(start.dayOffset.id, "days")
  }

  if (end.dayOffset) {
    endDateTime.add(end.dayOffset.id, "days")
  }

  startDateTime = moment.tz(
    `${startDateTime.format(DATE_FORMAT)} ${start.time}`,
    `${DATE_FORMAT} ${TIME_FORMAT}`,
    start.airport.city.timezone
  )
  endDateTime = moment.tz(
    `${endDateTime.format(DATE_FORMAT)} ${end.time}`,
    `${DATE_FORMAT} ${TIME_FORMAT}`,
    end.airport.city.timezone
  )

  const hours = endDateTime.diff(startDateTime, "hours")
  const minutes = endDateTime.diff(startDateTime, "minutes") % 60

  if (hours > 0 || (hours === 0 && minutes > 1)) {
    return `${hours}h${minutes}min`
  }
  return false
}

export const getSegments = legs => {
  let airports = []
  legs = legs || []

  const BreakException = {}
  try {
    legs.forEach((leg, i) => {
      const departure = leg.departureAirport
      const arrival = leg.arrivalAirport
      let prevValue = airports[airports.length - 1]

      if (
        legs[i - 1] &&
        ((legs[i - 1].arrivalAirport && !legs[i].departureAirport) ||
          (!legs[i - 1].arrivalAirport && legs[i].departureAirport) ||
          (legs[i - 1].arrivalAirport &&
            legs[i].departureAirport &&
            legs[i - 1].arrivalAirport.id !== legs[i].departureAirport.id))
      ) {
        throw BreakException
      }

      if (
        departure &&
        (!prevValue || (prevValue && prevValue.id !== departure.id))
      ) {
        airports.push(departure)
      }

      prevValue = airports[airports.length - 1]
      if (
        arrival &&
        (!prevValue || (prevValue && prevValue.id !== arrival.id))
      ) {
        airports.push(arrival)
      }
    })
  } catch (e) {
    if (e !== BreakException) throw e
    return false
  }

  const segments = {}
  airports.forEach((airport, x) => {
    for (let i = x + 1; i < airports.length; i++) {
      const key = `${airport.id}-${airports[i].id}`

      if (airport.id !== airports[i].id) {
        let steps = []

        for (let j = x; j < i; j++) {
          steps.push({ departure: airports[j], arrival: airports[j + 1] })
        }

        if (key in segments && steps.length > segments[key].length) {
          steps = segments[key]
        }
        segments[key] = steps
      }
    }
  })

  // Calculate for each segment whether it has at least one carrier
  Object.entries(segments).forEach(([k, segmentLegs]) => {
    // go through all segment's legs and create list of all airlines that operate on them
    let carriers = []
    segmentLegs.forEach(sl => {
      const leg = legs.find(
        l =>
          l.departureAirport.id === sl.departure.id &&
          l.arrivalAirport.id === sl.arrival.id
      )
      carriers = [
        ...carriers,
        ...(leg && leg.carriers
          ? leg.carriers.filter(c => c.airline).map(c => c.airline.id)
          : []),
      ]
    })

    // for each distinct airline find number of its occurrencies on segment's legs and if there is
    // at least one airline that operates on all segment's legs then that segment has carrier
    const hasCarrier =
      [...new Set(carriers)]
        .map(c => carriers.reduce((n, val) => n + (val === c), 0))
        .filter(occ => occ === segmentLegs.length).length > 0

    segments[k] = {
      legs: segmentLegs,
      hasCarrier,
    }
  })

  return Object.values(segments)
}
