import React, { useState } from "react"
import PropTypes from "prop-types"
import { useFormContext, Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { TimePicker } from "antd"
import { get } from "lodash"
import moment from "moment"

import Icon from "components/Icon/Icon"
import { TIME_FORMAT } from "utils/constants"

export default function TimeField({
  onChange: onChangeProp,
  className,
  name,
  registerProps,
  defaultValue,
  required,
  ...rest
}) {
  const { errors, control } = useFormContext()
  const [focused, setFocused] = useState(false)

  const { t } = useTranslation()

  return (
    <Controller
      render={({ onChange, onBlur, value, ...restRender }) => {
        const error = get(errors, name)

        return (
          <>
            <TimePicker
              format={TIME_FORMAT}
              suffixIcon={<Icon name="chevron-down" color="magenta" />}
              clearIcon={<Icon name="remove" />}
              placeholder=""
              {...restRender}
              {...rest}
              value={value ? moment(value, TIME_FORMAT) : null}
              className={`${className} time-field form-control form-field ${
                focused || value ? "has-floating-label" : ""
              } ${error ? "is-invalid" : ""}`}
              onChange={(time, timeString) => {
                if (!time) {
                  setFocused(false)
                  restRender.ref.current.blur()
                }
                onChangeProp(timeString)
                onChange(timeString || null)
              }}
              onSelect={time => {
                const val = time ? time.format(TIME_FORMAT) : null
                onChangeProp(val)
                onChange(val || null)
              }}
              onFocus={() => setFocused(true)}
              onBlur={e => {
                setFocused(false)
                onBlur(e)
              }}
            />
            {error ? (
              <div className="invalid-feedback d-block">{error.message}</div>
            ) : null}
          </>
        )
      }}
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{
        ...registerProps,
        validate: value => {
          if (required && !value) {
            return t("This field is required.")
          }

          return registerProps.validate ? registerProps.validate(value) : true
        },
      }}
    />
  )
}

TimeField.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  registerProps: PropTypes.object,
  defaultValue: PropTypes.string,
  required: PropTypes.bool.isRequired,
}

TimeField.defaultProps = {
  onChange: () => {},
  className: "",
  registerProps: {},
  defaultValue: null,
}
