import React from "react"
import PropTypes from "prop-types"

import Tooltip from "components/Tooltip/Tooltip"

import "./Avatar.scss"

export default function Avatar({ image, title, alt, size, ...rest }) {
  const getBody = () =>
    image ? (
      <img src={image} alt={alt} style={getImageSize()} />
    ) : (
      <span style={getImageSize()}>{alt.substring(0, 3)}</span>
    )

  const getAvatarStyle = () => ({
    fontSize: 0.45 * size,
    width: size,
    minWidth: size,
    height: size,
    ...(rest.style || {}),
  })

  const getImageSize = () => ({
    width: size - 5,
    height: size - 5,
  })

  return (
    <div
      className={`avatar position-relative ${rest.className}`}
      style={getAvatarStyle()}>
      {title ? <Tooltip title={title}>{getBody()}</Tooltip> : <>{getBody()}</>}
    </div>
  )
}

Avatar.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  alt: PropTypes.string,
  size: (props, propName) => {
    const value = props[propName]
    if (value && value <= 5) {
      return new Error("`size` prop must be bigger than 5")
    }
  },
  className: PropTypes.string,
}

Avatar.defaultProps = {
  image: undefined,
  title: undefined,
  alt: "",
  size: 30,
  className: "",
}
