const routes = {
  airlineBookingCreateStep1: "/airline-bookings/create/step-1",
  airlineBookingCreateStep2: "/airline-bookings/create/step-2",
  airlineBookingUpdate: "/airline-bookings/:id/update",
  airlineBookingDelete: "/airline-bookings/:id/delete",
  airlineBookingDetail: "/airline-bookings/:id",
  airlineBookingList: "/airline-bookings",
  tourOperatorBookingCreateStep1: "/tour-operator-bookings/create/step-1",
  tourOperatorBookingCreateStep2: "/tour-operator-bookings/create/step-2",
  tourOperatorBookingUpdate: "/tour-operator-bookings/:id/update",
  tourOperatorBookingDelete: "/tour-operator-bookings/:id/delete",
  tourOperatorBookingDetail: "/tour-operator-bookings/:id",
  tourOperatorBookingList: "/tour-operator-bookings",
}

export default routes
