import {
  CurrencyCreate,
  CurrencyUpdate,
  CurrencyList,
} from "apps/currencies/Currency"
import {
  ExchangeRateCreate,
  ExchangeRateList,
} from "apps/currencies/ExchangeRate"
import { route } from "utils/urls"

export const PROTECTED_ROUTES = [
  route("exchangeRateCreate", ExchangeRateCreate, [
    "add_currencies_exchangerate",
  ]),
  route("exchangeRateList", ExchangeRateList, ["view_currencies_exchangerate"]),
  route("currencyCreate", CurrencyCreate, ["add_currencies_currency"]),
  route("currencyUpdate", CurrencyUpdate, ["change_currencies_currency"]),
  route("currencyList", CurrencyList, ["view_currencies_currency"]),
]
