import React from "react"
import PropTypes from "prop-types"
import { useFormContext, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { get } from "lodash"

import { getAppInfo } from "apps/core/utils"
import Checkbox from "components/Checkbox/Checkbox"

export default function OperatingDaysField(props) {
  const { errors, register, control, setValue } = useFormContext()
  const value = useWatch({
    control,
    name: props.name,
    defaultValue: props.defaultValues,
  })
  const selectedDays = new Set(value.split(",").filter(d => d !== ""))
  const error = get(errors, props.name)
  const { t } = useTranslation()

  const WEEKDAYS = getAppInfo().weekdays

  return (
    <>
      <input
        name={props.name}
        type="hidden"
        ref={register({
          validate: value => {
            if (props.required && !value) {
              return t("At least one operating day must be selected.")
            }

            return true
          },
        })}
        className={`${error ? "is-invalid" : ""}`}
      />
      <Checkbox
        label={t("All")}
        align="center"
        className="px-1"
        onChange={e => {
          const days = new Set()
          e.target.checked
            ? WEEKDAYS.forEach(day => days.add(day.id))
            : days.clear()
          setValue(props.name, Array.from(days).join(","))
          props.onChange({ name: "all", value: e.target.checked })
        }}
        checked={selectedDays.size === WEEKDAYS.length}
      />
      {WEEKDAYS.map((day, i) => (
        <Checkbox
          key={i}
          label={day.displayName}
          align="center"
          className="px-1"
          onChange={e => {
            const days = new Set(selectedDays)
            if (e.target.checked) {
              days.add(day.id)
            } else {
              days.delete(day.id)
            }
            setValue(props.name, Array.from(days).join(","))
            props.onChange({ name: day.id, value: e.target.checked })
          }}
          checked={selectedDays.has(day.id)}
        />
      ))}
      {error ? (
        <div className="invalid-feedback d-block">{error.message}</div>
      ) : null}
    </>
  )
}

OperatingDaysField.propTypes = {
  name: PropTypes.string,
  defaultValues: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
}

OperatingDaysField.defaultProps = {
  name: "days",
  defaultValues: "",
  onChange: () => {},
  required: false,
}
