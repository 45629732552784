import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import Card from "components/Card/Card"
import { getEnum } from "apps/core/utils"

export default function BookingEvolutionModal({ data, ...rest }) {
  const { t } = useTranslation()

  const BOOKING_TYPE_ENUM = getEnum("bookingType")

  return (
    <div className="modal fade" id="evolutionModal" tabIndex="-1" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{t("Booking evolution")}</h5>
            <button type="button" className="close" data-dismiss="modal">
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">{t("Date")}</th>
                  <th scope="col">{t("Action")}</th>
                  <th scope="col">{t("Seats")}</th>
                  <th scope="col">{t("User")}</th>
                  {data.type.id === BOOKING_TYPE_ENUM.GROUP && (
                    <th scope="col">{t("Penalty")}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {data.evolution.map((event, i) => (
                  <tr key={i}>
                    <td>{event.date}</td>
                    <td>{event.status}</td>
                    <td>{event.seats}</td>
                    <td>{event.user}</td>
                    {data.type.id === BOOKING_TYPE_ENUM.GROUP && (
                      <td>{event.penalty}</td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="row">
              <div className="col">
                <Card
                  className="h-100"
                  bodyClassName="text-center p-2 d-flex flex-column justify-content-center">
                  <div>
                    <b>{t("Total seats")}</b>
                  </div>
                  <div>
                    <b>{data.seats.totalSeats}</b>
                  </div>
                </Card>
              </div>
              <div className="col">
                <Card
                  className="h-100"
                  bodyClassName="text-center p-2 d-flex flex-column justify-content-center">
                  <div>
                    <b>{t("Confirmed seats")}</b>
                  </div>
                  <div>
                    <b>{data.seats.confirmedSeats}</b>
                  </div>
                </Card>
              </div>
              <div className="col">
                <Card
                  className="h-100"
                  bodyClassName="text-center p-2 d-flex flex-column justify-content-center">
                  <div>
                    <b>{t("Canceled seats")}</b>
                  </div>
                  <div>
                    <b>{data.seats.canceledSeats}</b>
                  </div>
                </Card>
              </div>
              {[BOOKING_TYPE_ENUM.ALLOTMENT, BOOKING_TYPE_ENUM.GIR].includes(
                data.type.id
              ) && (
                <div className="col">
                  <Card
                    className="h-100"
                    bodyClassName="text-center p-2 d-flex flex-column justify-content-center">
                    <div>
                      <b>{t("Optional seats")}</b>
                    </div>
                    <div>
                      <b>{data.seats.optionalSeats}</b>
                    </div>
                  </Card>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

BookingEvolutionModal.propTypes = {
  data: PropTypes.object.isRequired,
}
