import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Form, FormField } from "components/Form"
import { apiPUT, apiGET, apiPOST } from "utils/api"
import { reverse } from "utils/urls"

export default function AirportForm(props) {
  const [formData, setFormData] = useState({})

  const { t } = useTranslation()

  useEffect(
    () =>
      props.isUpdate
        ? apiGET({
            path: `/airports/${props.match.params.id}/`,
            onSuccess: response => setFormData(response),
            onError: () => props.history.push(reverse("airportList")),
          })
        : () => {},
    [props.isUpdate, props.match.params.id, props.history]
  )

  return (
    <div>
      <Form
        endpoint={props.actionEndpoint}
        apiFunction={props.isUpdate ? apiPUT : apiPOST}
        defaultValues={formData}
        successUrl={props.successUrl}
        successMessage={t("Airport was successfully saved.")}
        title={props.isUpdate ? t("Edit airport") : t("Add airport")}>
        <div className="row">
          <div className="col-4">
            <FormField
              name="name"
              fieldType="input"
              type="text"
              label={t("Name")}
              maxLength={100}
              required
            />
          </div>
          <div className="col-4">
            <FormField
              name="codeIata"
              fieldType="input"
              type="text"
              label={t("IATA Code")}
              maxLength={3}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <FormField
              name="city"
              fieldType="selectWithAdd"
              selectFieldType="asyncSelect"
              label={t("City")}
              endpoint="/cities/"
              required
              addRoute="cityCreate"
            />
          </div>
          <div className="col-4">
            <FormField
              fieldType="input"
              name="codeIcao"
              type="text"
              label={t("ICAO Code")}
              maxLength={4}
              required
            />
          </div>
        </div>
      </Form>
    </div>
  )
}

AirportForm.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string,
    }),
  }).isRequired,
  isUpdate: PropTypes.bool,
  actionEndpoint: PropTypes.string.isRequired,
  successUrl: PropTypes.string.isRequired,
}

AirportForm.defaultProps = {
  isUpdate: false,
}
