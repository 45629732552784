import React from "react"
import PropTypes from "prop-types"

import { Link, useLocation } from "react-router-dom"

export default function SidebarLink({ children, activeStateRoutes, ...rest }) {
  let location = useLocation()

  return (
    <Link
      {...rest}
      className={`${rest.className} ${
        (activeStateRoutes && activeStateRoutes.includes(location.pathname)) ||
        location.pathname === rest.to
          ? "active"
          : ""
      }`}>
      {children}
    </Link>
  )
}

SidebarLink.propTypes = {
  children: PropTypes.node.isRequired,
  activeStateRoutes: PropTypes.array,
}

SidebarLink.defaultProps = {
  activeStateRoutes: undefined,
}
