import React from "react"
import PropTypes from "prop-types"
import { useFormContext } from "react-hook-form"
import moment from "moment"

import { FormField } from "components/Form"
import { DATE_FORMAT } from "utils/constants"

export default function DateAndOffsetField(props) {
  const { setValue } = useFormContext()

  return (
    <div className="row">
      <div className="col">
        <FormField
          name={props.name}
          fieldType="date"
          label={props.label}
          disabledDate={current =>
            current
              ? current.isAfter(moment(props.referenceDate, DATE_FORMAT), "day")
              : false
          }
          defaultPickerValue={moment(props.referenceDate, DATE_FORMAT)}
          onChange={date =>
            setValue(
              props.offsetName,
              moment(props.referenceDate, DATE_FORMAT)
                .diff(moment(date, DATE_FORMAT), "days")
                .toString()
            )
          }
          required={props.required}
        />
      </div>
      <div className="col-auto my-auto px-0">
        <b className="text-magneta">—</b>
      </div>
      <div className="col">
        <FormField
          name={props.offsetName}
          fieldType="input"
          type="number"
          min={0}
          label={props.offsetLabel}
          required={props.required}
          onChange={value =>
            setValue(
              props.name,
              moment(props.referenceDate, DATE_FORMAT)
                .subtract(value, "days")
                .format(DATE_FORMAT)
            )
          }
          inputGroupPrepend={<span className="input-group-text">D-</span>}
        />
      </div>
    </div>
  )
}

DateAndOffsetField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  offsetName: PropTypes.string.isRequired,
  offsetLabel: PropTypes.string.isRequired,
  referenceDate: PropTypes.string.isRequired,
  required: PropTypes.bool,
}

DateAndOffsetField.defaultProps = {
  required: false,
}
