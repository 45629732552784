import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

import { getConfig as getBookingListConfig } from "apps/bookings/Booking/BookingList/BookingList"
import { getConfig as getTicketListConfig } from "apps/tickets/Ticket/TicketList"
import FlightDetails from "apps/flights/FlightDetails/FlightDetails"
import SectionHeader from "components/SectionHeader/SectionHeader"
import { apiGET } from "utils/api"
import { notify } from "utils/notifications"
import BasicTable from "components/Table/BasicTable"
import Button from "components/Button/Button"
import { useHistory } from "react-router"

export default function SegmentObjectsList(props) {
  const [flightDetails, setFlightDetails] = useState()
  const { t } = useTranslation()
  let history = useHistory()

  const segmentId = props.match.params.id
  const objType = props.match.params.objType
  const objId = props.match.params.objId

  useEffect(
    () =>
      apiGET({
        path: `/flights/segments/${segmentId}/flight-details/`,
        onSuccess: response => setFlightDetails(response),
        onError: () =>
          notify(
            "error",
            t("There were some errors while trying to get flight details.")
          ),
      }),
    [segmentId, t]
  )

  const getHeader = () => {
    switch (objType) {
      case "airlineBookings":
        return t("Airline Bookings")
      case "tourOperatorBookings":
        return t("Tour Operator Bookings")
      case "tickets":
        return t("Tickets")
      default:
        break
    }
  }

  const getTableConfig = () => {
    switch (objType) {
      case "airlineBookings":
        return getBookingListConfig(
          t,
          {
            tableName: `segment-airline-bookings`,
          },
          history.location
        )
      case "tourOperatorBookings":
        return getBookingListConfig(
          t,
          {
            tableName: `segment-tour-operator-bookings`,
          },
          history.location
        )
      case "tickets":
        return getTicketListConfig(t, history.location)
      default:
        break
    }
  }

  const getEndpoint = () => {
    switch (objType) {
      case "airlineBookings":
        return `/bookings/airline-bookings/?segment=${segmentId}&airline=${objId}`
      case "tourOperatorBookings":
        return `/bookings/tour-operator-bookings/?segment=${segmentId}&tour_operator=${objId}`
      case "tickets":
        return `/tickets/?segment=${segmentId}&tour_operator=${objId}`
      default:
        break
    }
  }

  const searchParams = new URLSearchParams(props.location.search)

  return (
    <>
      <div className="row mb-4">
        <div className="col">
          <h1>{t("Segment details")}</h1>
        </div>
        {searchParams.get("next") !== null && (
          <div className="col-auto">
            <Button
              icon="chevron-left-double"
              onClick={() => props.history.push(searchParams.get("next"))}>
              {t("Back to Sheet")}
            </Button>
          </div>
        )}
      </div>
      {flightDetails !== undefined ? (
        <>
          <FlightDetails data={flightDetails} />
          <div className="mt-5">
            <SectionHeader header={getHeader()} />
          </div>
          <BasicTable {...getTableConfig()} endpoint={getEndpoint()} />
        </>
      ) : (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </>
  )
}

SegmentObjectsList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
      objType: PropTypes.oneOf([
        "airlineBookings",
        "tourOperatorBookings",
        "tickets",
      ]).isRequired,
      objId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}
