import React from "react"
import { useTranslation } from "react-i18next"

import { hasPermissions } from "apps/auth/auth"
import Button from "components/Button/Button"
import Tooltip from "components/Tooltip/Tooltip"
import Icon from "components/Icon/Icon"
import { actionsColumn, TableWithFilters } from "components/Table"
import { reverse } from "utils/urls"

const getConfig = t => ({
  endpoint: "/tour-operators",
  title: t("Tour Operators"),
  tableName: "tour-operators-table-config",
  columns: [
    {
      Header: t("Name"),
      accessor: "name",
    },
    {
      Header: t("City"),
      id: "city",
      accessor: t => t.city.displayName,
    },
    { Header: t("Code"), accessor: "code" },
    { Header: t("Code AS400"), accessor: "codeAs400" },
    { Header: t("VAT identification number"), accessor: "vat" },
    { Header: t("Slug"), accessor: "slug" },
    actionsColumn(obj => (
      <>
        <Tooltip title={t("Tour Operator Details")}>
          <Icon.Link
            name="eye"
            color="gray"
            to={reverse("tourOperatorDetail", { id: obj.id })}
          />
        </Tooltip>
        <Tooltip title={t("Tour Operator History")}>
          <Icon.Link
            name="history"
            color="gray"
            to={reverse("history", { id: obj.id, model: "tour-operators" })}
            linkClassName="ml-3"
          />
        </Tooltip>
        <Tooltip title={t("Edit Tour Operator")}>
          <Icon.Link
            name="edit"
            linkClassName="ml-3"
            to={reverse("tourOperatorUpdate", { id: obj.id })}
          />
        </Tooltip>
        <Tooltip title={t("Delete Tour Operator")}>
          <Icon.Link
            name="remove"
            color="danger"
            linkClassName="ml-3"
            to={reverse("tourOperatorDelete", { id: obj.id })}
          />
        </Tooltip>
      </>
    )),
  ],
  filters: [
    [
      {
        name: "id",
        fieldType: "asyncSelect",
        endpoint: "/tour-operators/",
        label: t("Tour Operator"),
        size: "col-4",
      },
      {
        fieldType: "applyButton",
        size: "col-auto mt-2",
      },
      {
        fieldType: "clearButton",
        size: "col-auto mt-2",
      },
    ],
  ],
  buttons: (
    <>
      {hasPermissions(["add_tour_operators_touroperator"]) && (
        <Button icon="plus" to={reverse("tourOperatorCreate")}>
          {t("Add Tour Operator")}
        </Button>
      )}
    </>
  ),
})

export default function TourOperatorList(props) {
  const { t } = useTranslation()

  return <TableWithFilters config={getConfig(t)} />
}
