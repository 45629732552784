import React from "react"
import { useTranslation } from "react-i18next"

import BookingList from "./BookingList"

export default function AirlineBookingList(props) {
  const { t } = useTranslation()

  return (
    <BookingList
      tableConfig={{
        endpoint: "/bookings/airline-bookings",
        title: t("Airline Booking"),
        tableName: "airline-booking-table-config",
      }}
    />
  )
}
