import React, { useEffect, useState } from "react"
import { useGlobal } from "reactn"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import ReactTable from "react-table-6"

import { hasPermissions } from "apps/auth/auth"
import Button from "components/Button/Button"
import { COLUMN_WIDTHS } from "apps/sheets/columns"
import {
  SELECT_COLUMN_WIDTH,
  EXPAND_COLUMN_WIDTH,
} from "components/Sheet/Sheet"
import { apiStoreGET } from "utils/api"
import { notify } from "utils/notifications"
import { reverse, buildRouteFromLocation } from "utils/urls"

import styles from "./WorkingSheetSegmentDetails.module.scss"
import AddBookingTourOperator from "./AddBookingTourOperator"

const getOptionalSeats = (segment, subject, type) => {
  const seats = segment[subject]?.seats[`${type}Seats`]
  const optionalSeats = segment[subject]?.seats[`${type}OptionalSeats`]

  return (
    <>
      {seats || ""}
      {optionalSeats > 0 && (
        <span className="text-warning ml-1">({optionalSeats})</span>
      )}
    </>
  )
}

export default function WorkingSheetSegmentDetails(props) {
  const [data, setData] = useState()
  const { t } = useTranslation()
  let history = useHistory()

  const airlineBookingFormData = useGlobal("airlineBookingFormData")
  const setAirlineBookingFormData = airlineBookingFormData[1]

  const tourOperatorBookingFormData = useGlobal("tourOperatorBookingFormData")
  const setTourOperatorBookingFormData = tourOperatorBookingFormData[1]

  const ticketFormData = useGlobal("ticketFormData")
  const setTicketFormData = ticketFormData[1]

  useEffect(() => {
    if (data === undefined) {
      let isSubscribed = true

      apiStoreGET({
        ...(props.apiGETNamespace ? { namespace: props.apiGETNamespace } : {}),
        path: `/sheets/working-sheet/${props.segment.id}/segment-details/`,
        onSuccess: response => {
          if (isSubscribed) {
            setData(response)
          }
        },
        onError: () =>
          notify(
            "error",
            t("There were some errors while trying to get segment details.")
          ),
      })

      return () => (isSubscribed = false)
    }
  }, [data, props.segment.id, t, props.apiGETNamespace])

  return data ? (
    <ReactTable
      minRows={0}
      showPagination={false}
      filterable={false}
      resizable={false}
      sortable={false}
      noDataText=""
      data={data}
      pageSize={data.length}
      style={{ marginTop: 0 }}
      getTableProps={() => ({ style: { overflow: "visible" } })}
      getTbodyProps={() => ({ style: { overflow: "visible" } })}
      getTheadProps={() => ({ style: { display: "none" } })}
      getTdProps={() => ({ style: { textAlign: "center" } })}
      columns={[
        { width: SELECT_COLUMN_WIDTH },
        { width: COLUMN_WIDTHS.weekNo },
        { width: COLUMN_WIDTHS.localDepartureDate },
        { width: COLUMN_WIDTHS.operatingDay },
        { width: COLUMN_WIDTHS.route },
        { width: COLUMN_WIDTHS.flightNo },
        ...(props.expandFlightDetails
          ? [{ width: COLUMN_WIDTHS.etd }, { width: COLUMN_WIDTHS.eta }]
          : []),
        { width: COLUMN_WIDTHS.empty },
        {
          id: "airlineCode",
          accessor: s =>
            s.airline ? (
              <Link
                to={reverse(
                  "segmentObjectsList",
                  {
                    id: props.segment.id,
                    objType: "airlineBookings",
                    objId: s.airline.id,
                  },
                  {
                    fromSheet: "true",
                    next: buildRouteFromLocation(history.location),
                  }
                )}>
                {s.airline.code}
              </Link>
            ) : null,
          width: COLUMN_WIDTHS.basic,
        },
        {
          id: "airlineTotalSeats",
          accessor: s => <b>{s.airline?.seats.totalSeats}</b>,
          width: COLUMN_WIDTHS.basic,
        },
        ...(props.expandAirlines
          ? [
              {
                id: "airlineBlkSeats",
                accessor: s => s.airline?.seats.blkSeats || "",
                width: COLUMN_WIDTHS.basic,
              },
              {
                id: "airlineAltSeats",
                accessor: s => getOptionalSeats(s, "airline", "alt"),
                width: COLUMN_WIDTHS.optionalTypes,
              },
              {
                id: "airlineGrpSeats",
                accessor: s => s.airline?.seats.grpSeats || "",
                width: COLUMN_WIDTHS.basic,
              },
              {
                id: "airlineGirSeats",
                accessor: s => getOptionalSeats(s, "airline", "gir"),
                width: COLUMN_WIDTHS.optionalTypes,
              },
              {
                id: "airlineOvfSeats",
                accessor: s => s.airline?.seats.ovfSeats || "",
                width: COLUMN_WIDTHS.basic,
              },
            ]
          : []),
        {
          width: COLUMN_WIDTHS.empty,
          id: "airlineAddBooking",
          className: styles["td-icon-add"],
          accessor: s =>
            s.airline && hasPermissions(["add_bookings_airlinebooking"]) ? (
              <Button
                size="sm"
                icon="plus"
                onClick={() => {
                  setAirlineBookingFormData({
                    airline: s.airline,
                    segments: {
                      [`segment-${props.segment.id}`]: "true",
                    },
                    firstDepartureAirport: s.other.firstDepartureAirport,
                    firstArrivalAirport: s.other.firstArrivalAirport,
                    source: "sheet",
                  })
                  history.push(
                    reverse("airlineBookingCreateStep2", null, {
                      fromSheet: "true",
                    })
                  )
                }}
              />
            ) : (
              ""
            ),
        },
        { width: COLUMN_WIDTHS.delta },
        { width: COLUMN_WIDTHS.empty },
        {
          id: "tourOperatorCode",
          accessor: s =>
            s.tourOperator ? (
              <Link
                to={reverse(
                  "segmentObjectsList",
                  {
                    id: props.segment.id,
                    objType: "tourOperatorBookings",
                    objId: s.tourOperator.id,
                  },
                  {
                    fromSheet: "true",
                    next: buildRouteFromLocation(history.location),
                  }
                )}>
                {s.tourOperator.code}
              </Link>
            ) : null,
          width: COLUMN_WIDTHS.basic,
          className: styles["td-add-booking"],
          Footer: row => (
            <AddBookingTourOperator rows={row.data} segment={props.segment} />
          ),
        },
        {
          id: "tourOperatorTotalSeats",
          accessor: s => <b>{s.tourOperator?.seats.totalSeats}</b>,
          width: COLUMN_WIDTHS.basic,
        },
        ...(props.expandTourOperators
          ? [
              {
                id: "tourOperatorBlkSeats",
                accessor: s => s.tourOperator?.seats.blkSeats || "",
                width: COLUMN_WIDTHS.basic,
              },
              {
                id: "tourOperatorAltSeats",
                accessor: s => getOptionalSeats(s, "tourOperator", "alt"),
                width: COLUMN_WIDTHS.optionalTypes,
              },
              {
                id: "tourOperatorGrpSeats",
                accessor: s => s.tourOperator?.seats.grpSeats || "",
                width: COLUMN_WIDTHS.basic,
              },
              {
                id: "tourOperatorGirSeats",
                accessor: s => getOptionalSeats(s, "tourOperator", "gir"),
                width: COLUMN_WIDTHS.optionalTypes,
              },
              {
                id: "tourOperatorOvfSeats",
                accessor: s => s.tourOperator?.seats.ovfSeats || "",
                width: COLUMN_WIDTHS.basic,
              },
            ]
          : []),
        {
          width: COLUMN_WIDTHS.empty,
          id: "tourOperatorAddBooking",
          className: styles["td-icon-add"],
          accessor: s =>
            s.tourOperator &&
            hasPermissions(["add_bookings_touroperatorbooking"]) ? (
              <Button
                size="sm"
                icon="plus"
                onClick={() => {
                  setTourOperatorBookingFormData({
                    tourOperator: s.tourOperator,
                    segments: {
                      [`segment-${props.segment.id}`]: "true",
                    },
                    firstDepartureAirport: s.other.firstDepartureAirport,
                    firstArrivalAirport: s.other.firstArrivalAirport,
                    source: "sheet",
                  })
                  history.push(
                    reverse("tourOperatorBookingCreateStep2", null, {
                      fromSheet: "true",
                    })
                  )
                }}
              />
            ) : (
              ""
            ),
        },
        {
          id: "ticketTotal",
          accessor: s =>
            s.tourOperator?.seats.adultPassengers ? (
              <Link
                to={reverse(
                  "segmentObjectsList",
                  {
                    id: props.segment.id,
                    objType: "tickets",
                    objId: s.tourOperator.id,
                  },
                  {
                    fromSheet: "true",
                    next: buildRouteFromLocation(history.location),
                  }
                )}>
                {s.tourOperator?.seats.adultPassengers +
                  s.tourOperator?.seats.childPassengers}
                {s.tourOperator?.seats.infantPassengers > 0 && (
                  <> + {s.tourOperator?.seats.infantPassengers}</>
                )}
              </Link>
            ) : null,
          width: COLUMN_WIDTHS.basic,
        },
        {
          id: "ticketPercentage",
          accessor: s =>
            s.tourOperator?.seats.adultPassengers > 0
              ? `${Math.round(
                  ((s.tourOperator?.seats.adultPassengers +
                    s.tourOperator?.seats.childPassengers) /
                    s.tourOperator?.seats.totalSeats) *
                    100
                )}%`
              : "",
          width: COLUMN_WIDTHS.basic,
        },
        ...(props.expandTickets
          ? [
              {
                id: "ticketAdultSeats",
                accessor: s => s.tourOperator?.seats.adultPassengers || "",
                width: COLUMN_WIDTHS.basic,
              },
              {
                id: "ticketChildSeats",
                accessor: s => s.tourOperator?.seats.childPassengers || "",
                width: COLUMN_WIDTHS.basic,
              },
              {
                id: "ticketInfantSeats",
                accessor: s => s.tourOperator?.seats.infantPassengers || "",
                width: COLUMN_WIDTHS.basic,
              },
            ]
          : []),
        {
          width: EXPAND_COLUMN_WIDTH,
          id: "ticketAdd",
          className: styles["td-icon-add"],
          accessor: s =>
            s.tourOperator &&
            s.tourOperator.seats.adultPassengers +
              s.tourOperator.seats.childPassengers <
              s.tourOperator.seats.totalSeats &&
            hasPermissions(["add_tickets_ticket"]) ? (
              <Button
                size="sm"
                icon="plus"
                onClick={() => {
                  setTicketFormData({
                    tourOperator: s.tourOperator,
                    segments: {
                      [`segment-${props.segment.id}`]: "true",
                    },
                    source: "sheet",
                  })
                  history.push(
                    reverse("ticketCreateStep2", null, {
                      fromSheet: "true",
                    })
                  )
                }}
              />
            ) : (
              ""
            ),
        },
      ]}
    />
  ) : null
}

WorkingSheetSegmentDetails.propTypes = {
  segment: PropTypes.object.isRequired,
  expandFlightDetails: PropTypes.bool.isRequired,
  expandAirlines: PropTypes.bool.isRequired,
  expandTourOperators: PropTypes.bool.isRequired,
  expandTickets: PropTypes.bool.isRequired,
  apiGETNamespace: PropTypes.string,
}

WorkingSheetSegmentDetails.defaultProps = {
  apiGETNamespace: undefined,
}
