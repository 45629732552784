import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

import Icon from "components/Icon/Icon"
import { FormField } from "components/Form"

export default function InfantPassenger({
  data,
  index,
  remove,
  operatingDates,
}) {
  const { t } = useTranslation()

  const minOperatingDate = moment.min(operatingDates)
  const maxOperatingDate = moment.max(operatingDates)

  return (
    <div className="row">
      <FormField
        fieldType="input"
        type="hidden"
        name={`passengers[${index}].passengerType`}
        label=""
        defaultValue="INFANT"
      />
      <FormField
        fieldType="input"
        type="hidden"
        name={`passengers[${index}].passengerId`}
        label=""
        defaultValue={data.id || data.passengerId}
      />
      <div className="col-12 col-xl-4">
        <div className="row">
          <div className="col-1 my-auto text-center">
            <b>{`${index + 1}.`}</b>
          </div>
          <div className="col-6">
            <FormField
              fieldType="input"
              type="text"
              name={`passengers[${index}].familyReference`}
              label={t("Family reference")}
              defaultValue={data.familyReference}
              required
            />
          </div>
          <div className="col-5">
            <FormField
              fieldType="select"
              name={`passengers[${index}].type`}
              label={t("Passenger type")}
              options={[{ id: "INFANT", displayName: t("Infant") }]}
              disabled
              defaultValue={{ id: "INFANT", displayName: t("Infant") }}
            />
          </div>
        </div>
      </div>
      <div className="col-12 col-xl-8">
        <div className="row">
          <div className="col-3">
            <FormField
              fieldType="input"
              type="text"
              name={`passengers[${index}].firstName`}
              label={t("First name")}
              required
              defaultValue={data.firstName}
            />
          </div>
          <div className="col-3">
            <FormField
              fieldType="input"
              type="text"
              name={`passengers[${index}].lastName`}
              label={t("Last name")}
              required
              defaultValue={data.lastName}
            />
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-5">
                <FormField
                  fieldType="select"
                  name={`passengers[${index}].gender`}
                  label={t("Gender")}
                  options={[
                    { id: "F", displayName: t("Female") },
                    { id: "M", displayName: t("Male") },
                  ]}
                  required
                  defaultValue={data.gender}
                />
              </div>
              <div className="col-5">
                <FormField
                  fieldType="date"
                  name={`passengers[${index}].dateOfBirth`}
                  label={t("Date of birth")}
                  required
                  defaultValue={data.dateOfBirth}
                  disabledDate={current =>
                    current
                      ? current.isSameOrBefore(
                          moment(minOperatingDate).subtract(2, "years"),
                          "day"
                        ) || current.isAfter(moment(maxOperatingDate), "day")
                      : false
                  }
                  defaultPickerValue={moment(maxOperatingDate)}
                />
              </div>
              <div className="col-auto my-auto text-center pt-1">
                <Icon
                  color="danger"
                  name="remove"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    remove(index)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

InfantPassenger.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]).isRequired,
  remove: PropTypes.func.isRequired,
  operatingDates: PropTypes.array.isRequired,
}
