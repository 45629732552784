import React, { useEffect, useRef, useState } from "react"
import { useGlobal } from "reactn"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"

import Button from "components/Button/Button"
import { Form, FormField } from "components/Form"
import { reverse } from "utils/urls"

import styles from "./WorkingSheetSegmentDetails.module.scss"
import popoverStyles from "components/Popover/Popover.module.scss"
import Card from "components/Card/Card"

export default function AddBookingTourOperator(props) {
  const [showSelect, setShowSelect] = useState(false)
  const wrapperRef = useRef(null)
  const { t } = useTranslation()
  let history = useHistory()

  const tourOperatorBookingFormData = useGlobal("tourOperatorBookingFormData")
  const setTourOperatorBookingFormData = tourOperatorBookingFormData[1]

  const tourOperatorIds = props.rows
    .map(row => row._original.tourOperator?.id)
    .filter(id => id)

  useEffect(() => {
    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSelect(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [wrapperRef])

  return (
    <div ref={wrapperRef}>
      <Button
        size="sm"
        icon="plus"
        className={styles["tf-add-booking"]}
        onClick={() => setShowSelect(!showSelect)}>
        {t("Add Booking")}
      </Button>
      {showSelect && (
        <div className={styles["td-select-tour-operator"]}>
          <div className={popoverStyles["popover-container"]}>
            <div className="row">
              <div className="col-12">
                <div
                  className={`px-3 py-2 bg-gray-light ${popoverStyles["header-container"]}`}>
                  <h4 className="mb-0 text-center">{t("Select T.O.")}</h4>
                </div>
              </div>
            </div>
            <Card>
              <Form showBack={false} showSubmit={false}>
                <FormField
                  name="tourOperator"
                  fieldType="asyncSelect"
                  label={t("Tour Operator")}
                  required
                  endpoint="/tour-operators/"
                  containerClassName="mb-0 mt-0"
                  onChange={selected => {
                    if (selected !== undefined) {
                      setTourOperatorBookingFormData({
                        tourOperator: selected,
                        segments: {
                          [`segment-${props.segment.id}`]: "true",
                        },
                        firstDepartureAirport: props.segment.departureAirport,
                        firstArrivalAirport: props.segment.arrivalAirport,
                        source: "sheet",
                      })
                      history.push(
                        reverse("tourOperatorBookingCreateStep2", null, {
                          fromSheet: "true",
                        })
                      )
                    }
                  }}
                  filterOption={option =>
                    !tourOperatorIds.includes(parseInt(option.value))
                  }
                />
              </Form>
            </Card>
          </div>
        </div>
      )}
    </div>
  )
}

AddBookingTourOperator.propTypes = {
  rows: PropTypes.array.isRequired,
  segment: PropTypes.object.isRequired,
}
