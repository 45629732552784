import React from "react"
import PropTypes from "prop-types"

import { FormField } from "components/Form"
import Icon from "components/Icon/Icon"
import { reverse, buildRouteFromLocation } from "utils/urls"

export default function SelectFieldWithAdd({
  addRoute,
  selectFieldType,
  ...rest
}) {
  return (
    <div className="row">
      <div className="col">
        <FormField {...rest} fieldType={selectFieldType} />
      </div>
      {!rest.isDisabled && (
        <div className="col-auto mt-3">
          <Icon.Link
            to={reverse({
              name: addRoute,
              queryParams: {
                next: buildRouteFromLocation(window.location),
                addRelatedField: rest.name,
              },
            })}
            name="plus"
            size="lg"
          />
        </div>
      )}
    </div>
  )
}

SelectFieldWithAdd.propTypes = {
  name: PropTypes.string.isRequired,
  addRoute: PropTypes.string.isRequired,
  selectFieldType: PropTypes.string.isRequired,
}
