import React from "react"
import PropTypes from "prop-types"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { FormField } from "components/Form"

export default function SelectDateRangeField(props) {
  const { getValues, setValue } = useFormContext()
  const { t } = useTranslation()

  const getFullName = name =>
    props.namePrefix ? `${props.namePrefix}[${name}]` : name

  return (
    <div className="row">
      <div className={`col-12 col-lg-6 ${props.rangeContainerClassName}`}>
        <FormField
          name={getFullName(props.selectRangeName)}
          fieldType="asyncSelect"
          icon="calendar-circle"
          endpoint="/date-ranges/"
          label={t("Select date range")}
          onChange={selected => {
            if (selected) {
              setValue(getFullName(props.dateFromName), selected.dateFrom)
              setValue(getFullName(props.dateToName), selected.dateTo)
            } else {
              setValue(getFullName(props.dateFromName), "")
              setValue(getFullName(props.dateToName), "")
            }
            props.onRangeChange(selected)
          }}
        />
      </div>
      <div className={`col-12 col-lg-6 ${props.datesContainerClassName}`}>
        <FormField
          name={[
            getFullName(props.dateFromName),
            getFullName(props.dateToName),
          ]}
          fieldType="dateRange"
          icon="calendar-circle"
          label={t("Date from - Date to")}
          onChange={([dateFrom, dateTo]) => {
            const dateRange = getValues(getFullName(props.selectRangeName))
            if (
              dateRange &&
              (dateRange.dateFrom !== dateFrom || dateRange.dateTo !== dateTo)
            ) {
              setValue(getFullName(props.selectRangeName), "")
            }
            props.onDateChange([dateFrom, dateTo])
          }}
          required={props.required}
        />
      </div>
    </div>
  )
}

SelectDateRangeField.propTypes = {
  namePrefix: PropTypes.string,
  selectRangeName: PropTypes.string,
  dateFromName: PropTypes.string,
  dateToName: PropTypes.string,
  required: PropTypes.bool,
  onRangeChange: PropTypes.func,
  onDateChange: PropTypes.func,
  rangeContainerClassName: PropTypes.string,
  datesContainerClassName: PropTypes.string,
}

SelectDateRangeField.defaultProps = {
  namePrefix: "",
  selectRangeName: "dateRange",
  dateFromName: "dateFrom",
  dateToName: "dateTo",
  required: false,
  onRangeChange: () => {},
  onDateChange: () => {},
  rangeContainerClassName: "",
  datesContainerClassName: "",
}
