import React from "react"
import { useTranslation } from "react-i18next"

import { hasPermissions } from "apps/auth/auth"
import Button from "components/Button/Button"
import Tooltip from "components/Tooltip/Tooltip"
import Icon from "components/Icon/Icon"
import { actionsColumn, TableWithFilters } from "components/Table"
import { reverse } from "utils/urls"

const getConfig = t => ({
  endpoint: "/fees/categories",
  title: t("Fee Categories"),
  tableName: "fee-category-table-config",
  columns: [
    {
      Header: t("Name"),
      accessor: "name",
    },
    {
      Header: t("Type"),
      accessor: c => c.type.displayName,
      id: "type",
    },
    actionsColumn(obj => (
      <>
        <Tooltip title={t("Fee Category History")}>
          <Icon.Link
            name="history"
            color="gray"
            to={reverse("history", {
              id: obj.id,
              model: encodeURIComponent("fees/categories"),
            })}
          />
        </Tooltip>
        <Tooltip title={t("Edit Fee Category")}>
          <Icon.Link
            name="edit"
            linkClassName="ml-3"
            to={reverse("feeCategoryUpdate", { id: obj.id })}
          />
        </Tooltip>
      </>
    )),
  ],
  filters: [],
  buttons: (
    <>
      {hasPermissions(["add_fees_feecategory"]) && (
        <Button icon="plus" to={reverse("feeCategoryCreate")}>
          {t("Add Fee Category")}
        </Button>
      )}
    </>
  ),
})

export default function FeeCategoryList(props) {
  const { t } = useTranslation()

  return <TableWithFilters config={getConfig(t)} />
}
