import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { has, isEmpty } from "lodash"

import GroupFineFormSet from "../BookingCreate/GroupFineFormSet"
import { hasPermissions } from "apps/auth/auth"
import { getReleaseDate } from "apps/bookings/utils"
import { getEnum } from "apps/core/utils"
import FlightDetails from "apps/flights/FlightDetails/FlightDetails"
import Avatar from "components/Avatar/Avatar"
import FieldDetail from "components/FieldDetail/FieldDetail"
import SectionHeader from "components/SectionHeader/SectionHeader"
import { Form, FormField } from "components/Form"
import { apiGET, apiPUT } from "utils/api"

export default function BookingUpdate({
  isAirline,
  detailEndpoint,
  listRoute,
  successUrl,
  ...rest
}) {
  const [formData, setFormData] = useState({})
  let history = useHistory()
  const { t } = useTranslation()

  const BOOKING_TYPE_ENUM = getEnum("bookingType")

  const hasSuperUserPermission = hasPermissions([
    isAirline
      ? "superuser_change_bookings_airlinebooking"
      : "superuser_change_bookings_touroperatorbooking",
  ])

  useEffect(
    () =>
      apiGET({
        path: detailEndpoint,
        onSuccess: response => setFormData(response),
        onError: () => history.push(listRoute),
      }),
    [detailEndpoint, history, listRoute]
  )

  return !isEmpty(formData) ? (
    <div>
      <Form
        endpoint={detailEndpoint}
        apiFunction={apiPUT}
        defaultValues={formData}
        successUrl={successUrl}
        processData={d => {
          // there are some problems with package which translates camel case to
          // snake case so we have to do this (number in the end is the problem)
          if (has(d, "releaseDate1")) {
            d["release_date_1"] = d["releaseDate1"]
            delete d["releaseDate1"]
          }

          if (has(d, "releaseDate2")) {
            d["release_date_2"] = d["releaseDate2"]
            delete d["releaseDate2"]
          }

          return d
        }}
        successMessage={t(
          `${
            isAirline ? t("Airline") : "Tour Operator"
          } Booking was successfully saved.`
        )}
        title={
          <>
            {isAirline ? t("Airline") : "Tour Operator"} {t("Booking edit")} -{" "}
            <span className="text-magenta">{formData.reference}</span>
          </>
        }>
        <div className="row">
          <div className="col-7">
            <FlightDetails data={formData.flightDetails} />
          </div>
          <div className="col-5">
            <SectionHeader header={t("Booking info")} />
            <div className="row mt-4">
              {isAirline && (
                <div className="col-6">
                  <FormField
                    name="reference"
                    fieldType="input"
                    type="text"
                    label={t("Reference")}
                    required
                  />
                </div>
              )}
              <div className="col-6">
                {formData.validations.hasInvoiceDatePassed ? (
                  <FieldDetail
                    label={t("Received date")}
                    value={formData.receivedDate}
                  />
                ) : (
                  <FormField
                    name="receivedDate"
                    fieldType="date"
                    label={t("Received date")}
                    required
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {formData.validations.hasInvoiceDatePassed ? (
                  <FieldDetail
                    label={t("Invoice date")}
                    value={
                      <>
                        {formData.details.invoiceDate.date}{" "}
                        <span className="text-magenta">
                          (D-{formData.details.invoiceDate.dateOffset})
                        </span>
                      </>
                    }
                  />
                ) : (
                  <FormField
                    fieldType="dateAndOffset"
                    name="invoiceDate"
                    label={t("Invoice date")}
                    offsetName="invoiceDateOffset"
                    offsetLabel={t("Invoice date")}
                    referenceDate={formData.operatingDate}
                    required
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-7">
            <SectionHeader header={t("Booking details")} />
            <div className="row mt-4">
              <div className="col-6">
                <div className="row mt-3">
                  <div className="col-5 text-center">
                    <Avatar
                      image={
                        isAirline
                          ? formData.airline.logo
                          : formData.tourOperator.logo
                      }
                      title={
                        isAirline
                          ? formData.airline.displayName
                          : formData.tourOperator.displayName
                      }
                      alt={
                        isAirline
                          ? formData.airline.code
                          : formData.tourOperator.code
                      }
                      size={80}
                      className="mx-auto"
                    />
                    <small>
                      {isAirline
                        ? formData.airline.displayName
                        : formData.tourOperator.displayName}
                    </small>
                  </div>
                  <div className="col-5">
                    <FieldDetail
                      label={t("Booking Type")}
                      value={
                        <b className="text-uppercase text-magenta">
                          {formData.type.displayName}
                        </b>
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-3">
                    <FormField
                      name="name"
                      fieldType="input"
                      type="text"
                      label={t("Name")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <FormField
                      name="note"
                      fieldType="input"
                      type="text"
                      label={t("Note")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-12">
                    {formData.validations.isSeatsEditable &&
                    hasSuperUserPermission ? (
                      <FormField
                        name="totalSeats"
                        fieldType="input"
                        type="number"
                        min={0}
                        label={t("Seats")}
                      />
                    ) : (
                      <FieldDetail
                        label={t("Seats")}
                        value={formData.totalSeats}
                      />
                    )}
                  </div>
                  <div className="col-12">
                    {hasSuperUserPermission ? (
                      <div className="d-flex">
                        <FormField
                          name="fare"
                          fieldType="price"
                          label={t("Fare")}
                          required
                          currencyPosition="prepend"
                        />
                        <FormField
                          name="childFare"
                          fieldType="input"
                          type="number"
                          step="0.01"
                          min={0}
                          label={t("Child fare")}
                          required
                        />
                      </div>
                    ) : (
                      <FieldDetail
                        label={t("Fare")}
                        value={formData.details.totalFare}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-5">
            {[
              BOOKING_TYPE_ENUM.ALLOTMENT,
              BOOKING_TYPE_ENUM.GIR,
              BOOKING_TYPE_ENUM.GROUP,
            ].includes(formData.type.id) && (
              <div className="col-12">
                <SectionHeader header={t("Conditions")} />
                {formData.type.id === BOOKING_TYPE_ENUM.ALLOTMENT && (
                  <div className="row mt-4">
                    <div className="col-12">
                      {formData.validations.isReleaseDate1Editable ? (
                        <FormField
                          fieldType="dateAndOffset"
                          name="releaseDate1"
                          label={t("Allotment type release date")}
                          offsetName="releaseDate1Offset"
                          offsetLabel={t("Allotment type release date")}
                          referenceDate={formData.operatingDate}
                          required
                        />
                      ) : (
                        <FieldDetail
                          label={t("Allotment type release date")}
                          value={getReleaseDate(formData.details.releaseDate1)}
                        />
                      )}
                    </div>
                  </div>
                )}
                {formData.type.id === BOOKING_TYPE_ENUM.GIR && (
                  <>
                    <div className="row mt-4">
                      <div className="col-12">
                        {formData.validations.isReleaseDate1Editable ? (
                          <FormField
                            fieldType="dateAndOffset"
                            name="releaseDate1"
                            label={t("GIR type 1st release date")}
                            offsetName="releaseDate1Offset"
                            offsetLabel={t("GIR type 1st release date")}
                            referenceDate={formData.operatingDate}
                            required
                          />
                        ) : (
                          <FieldDetail
                            label={t("GIR type 1st release date")}
                            value={getReleaseDate(
                              formData.details.releaseDate1
                            )}
                          />
                        )}
                      </div>
                      <div className="col-6">
                        {formData.validations.isReleaseDate1Editable ? (
                          <FormField
                            name="releasePercentage"
                            fieldType="input"
                            type="number"
                            label={t("GIR seat release percentage")}
                            required
                            inputGroupAppend={
                              <span className="input-group-text">%</span>
                            }
                          />
                        ) : (
                          <FieldDetail
                            label={t("GIR seat release percentage")}
                            value={formData.releasePercentage}
                            inputGroupAppend={<span>%</span>}
                          />
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {formData.validations.isReleaseDate2Editable ? (
                          <FormField
                            fieldType="dateAndOffset"
                            name="releaseDate2"
                            label={t("GIR type 2nd release date")}
                            offsetName="releaseDate2Offset"
                            offsetLabel={t("GIR type 2nd release date")}
                            referenceDate={formData.operatingDate}
                            required
                          />
                        ) : (
                          <FieldDetail
                            label={t("GIR type 2nd release date")}
                            value={getReleaseDate(
                              formData.details.releaseDate2
                            )}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
                {formData.type.id === BOOKING_TYPE_ENUM.GROUP &&
                  (formData.validations.finesHavePassed ? (
                    <>
                      <div className="row mt-4">
                        <div className="col-6">
                          <span className="px-2">{t("Days")}</span>
                        </div>
                        <div className="col-3">
                          <span className="px-2">{t("Penalty")}</span>
                        </div>
                        <div className="col-3">
                          <span className="px-2">{t("Free CNX")}</span>
                        </div>
                      </div>
                      {formData.fines.map(fine => (
                        <div className="row" key={fine.id}>
                          <div className="col-6">
                            <FieldDetail value={fine.applicableDatetime} />
                          </div>
                          <div className="col-3">
                            <FieldDetail value={fine.penalty} />
                          </div>
                          <div className="col-3">
                            <FieldDetail
                              value={`${fine.freeCancelationSeats} ${t(
                                "Seats"
                              )}`}
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="row mt-4">
                      <GroupFineFormSet name="fines" isUpdate />
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </Form>
    </div>
  ) : null
}

BookingUpdate.propTypes = {
  detailEndpoint: PropTypes.string.isRequired,
  listRoute: PropTypes.string.isRequired,
  successUrl: PropTypes.string.isRequired,
  isAirline: PropTypes.bool,
}

BookingUpdate.defaultProps = {
  isAirline: false,
}
