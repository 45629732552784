import React from "react"
import { useTranslation } from "react-i18next"

import { hasPermissions } from "apps/auth/auth"
import { getBoolDisplay } from "apps/core/utils"
import Button from "components/Button/Button"
import Icon from "components/Icon/Icon"
import Tooltip from "components/Tooltip/Tooltip"
import { actionsColumn, TableWithFilters } from "components/Table"
import { reverse } from "utils/urls"

const getConfig = t => ({
  endpoint: "/auth/users",
  title: t("Users"),
  tableName: "users-table-config",
  columns: [
    {
      Header: t("Email"),
      accessor: "email",
      width: "15%",
    },
    { Header: t("Name"), accessor: "name", width: "10%" },
    {
      Header: t("Owner"),
      id: "isOwner",
      accessor: u => getBoolDisplay(u.isOwner),
      width: "10%",
    },
    {
      Header: t("Group"),
      id: "group",
      accessor: u => u.group.displayName,
      width: "15%",
    },
    {
      Header: t("Tour Operator"),
      id: "tourOperator",
      accessor: u => (u.tourOperator ? u.tourOperator.displayName : "-"),
      width: "15%",
    },
    {
      Header: t("Active"),
      id: "isActive",
      accessor: u => getBoolDisplay(u.isActive),
      width: "10%",
    },
    { Header: t("Date joined"), accessor: "dateJoined", width: "15%" },
    actionsColumn(obj => (
      <>
        <Tooltip title={t("User Details")}>
          <Icon.Link
            name="eye"
            color="gray"
            to={reverse("userDetail", { id: obj.id })}
          />
        </Tooltip>
      </>
    )),
  ],
  filters: [],
  buttons: (
    <>
      {hasPermissions(["add_users_user"]) && (
        <Button icon="plus" to={reverse("userCreate")}>
          {t("Add User")}
        </Button>
      )}
    </>
  ),
  showSelectColumn: false,
})

export default function UserList(props) {
  const { t } = useTranslation()

  return <TableWithFilters config={getConfig(t)} />
}
