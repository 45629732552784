import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import moment from "moment"

import { DATE_FORMAT } from "utils/constants"
import {
  operatingDaysColumn,
  SubTable,
  TableWithFilters,
} from "components/Table"
import Avatar from "components/Avatar/Avatar"
import { reverse } from "utils/urls"

const getConfig = t => ({
  endpoint: "/flights/series",
  title: t("Flight Schedule"),
  tableName: "flight-series-table-config",
  columns: [
    {
      Header: t("Carrier(s)"),
      id: "carriers",
      accessor: f => (
        <div className="d-flex">
          {f.carriers.map((carrier, i, carriers) => (
            <Avatar
              key={i}
              className={`${i !== 0 ? "ml-2" : ""} ${
                i !== carriers.length - 1 ? "mr-2" : ""
              }`}
              image={carrier.logo}
              title={carrier.displayName}
              alt={carrier.code}
            />
          ))}
        </div>
      ),
      sortable: false,
    },
    {
      Header: (
        <>
          {t("Flight")} N<sup>o</sup>
        </>
      ),
      accessor: "flightNo",
      Cell: row => (
        <Link to={reverse("flightSeriesDetail", { id: row.original.id })}>
          {row.value}
        </Link>
      ),
    },
    { Header: t("Routings"), accessor: "name" },
    { Header: t("Start Date"), accessor: "periodStart" },
    { Header: t("End Date"), accessor: "periodEnd" },
    operatingDaysColumn("daysOfOperations"),
    { Header: t("Flight count"), accessor: "flightCount" },
  ],
  SubComponent: row => {
    const flights = [...row.original.flights].map(flight => {
      flight.flightNo = row.original.flightNo
      flight.name = row.original.name
      return flight
    })

    return (
      <SubTable
        data={flights}
        columns={[
          {
            Header: (
              <>
                {t("Flight")} N<sup>o</sup>
              </>
            ),
            accessor: "flightNo",
            Cell: subRow => (
              <Link to={reverse("flightDetail", { id: subRow.original.id })}>
                {subRow.value}
              </Link>
            ),
            parentColumnIndex: 1,
          },
          {
            Header: t("Routings"),
            accessor: "name",
            parentColumnIndex: 2,
          },
          {
            Header: t("Op. date"),
            accessor: "operatingDate",
            sortMethod: (a, b, desc) => {
              a = moment(a, DATE_FORMAT)
              b = moment(b, DATE_FORMAT)

              if (a.isBefore(b)) {
                return 1
              } else if (a.isAfter(b)) {
                return -1
              }

              return 0
            },
            parentColumnIndex: [3, 4],
          },
          operatingDaysColumn("operatingDow"),
          {
            Header: "",
            parentColumnIndex: [5],
            sortable: false,
          },
        ]}
      />
    )
  },
  filters: [
    [
      {
        size: "col",
        children: [
          [
            {
              fieldType: "selectDateRange",
              dateFromName: "periodStart",
              dateToName: "periodEnd",
              size: "col-6",
            },
            {
              fieldType: "flightNo",
              skipValidation: true,
              name: "flightNo",
              size: "col-3",
            },
            {
              fieldType: "operatingDays",
              name: "daysOfOperations",
              size: "col-auto text-center",
            },
          ],
          [
            {
              name: "departureAirport",
              fieldType: "airportSelect",
              withCities: true,
              icon: "airplane-departure",
              label: t("Departure airport"),
              size: "col-3",
            },
            {
              name: "arrivalAirport",
              fieldType: "airportSelect",
              withCities: true,
              icon: "airplane-arrival",
              label: t("Arrival airport"),
              size: "col-3",
            },
            {
              name: "carrier",
              fieldType: "asyncSelect",
              icon: "airplane",
              endpoint: "/airlines/",
              label: t("Carrier"),
              size: "col-3",
            },
          ],
        ],
      },
      {
        size: "col-auto",
        children: [
          [
            {
              fieldType: "applyButton",
              size: "col-12 mt-1",
            },
          ],
          [
            {
              fieldType: "clearButton",
              size: "col-12 mt-3",
            },
          ],
        ],
      },
    ],
  ],
})

export default function FlightSeriesList(props) {
  const { t } = useTranslation()

  return <TableWithFilters config={getConfig(t)} />
}
