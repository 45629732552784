import React from "react"
import { useHistory } from "react-router"
import { useTranslation } from "react-i18next"

import { hasPermissions, isOwner } from "apps/auth/auth"
import Avatar from "components/Avatar/Avatar"
import Button from "components/Button/Button"
import Tooltip from "components/Tooltip/Tooltip"
import Icon from "components/Icon/Icon"
import { actionsColumn, TableWithFilters } from "components/Table"
import { fileGET } from "utils/api"
import { reverse, buildRouteFromLocation } from "utils/urls"

export const getConfig = (t, location) => ({
  endpoint: "/tickets",
  title: t("Tickets"),
  tableName: "ticket-table-config",
  columns: [
    { Header: t("Reference"), accessor: "reference" },
    {
      Header: t("Tour Operator"),
      id: "tourOperator",
      accessor: t => (
        <Avatar
          image={t.tourOperator.logo}
          title={t.tourOperator.displayName}
          alt={t.tourOperator.code}
        />
      ),
    },
    {
      Header: t("Segment"),
      accessor: t => t.segment.displayName,
      id: "segment",
    },
    {
      Header: t("Airline"),
      id: "airline",
      accessor: t => (
        <Avatar
          image={t.airline.logo}
          title={t.airline.displayName}
          alt={t.airline.code}
        />
      ),
    },
    { Header: t("Adults"), accessor: "adultPassengerCount" },
    { Header: t("Children"), accessor: "childPassengerCount" },
    { Header: t("Infants"), accessor: "infantPassengerCount" },
    { Header: t("Pets"), accessor: "petPassengerCount" },
    actionsColumn(obj => (
      <>
        <Tooltip title={t("Ticket Details")}>
          <Icon.Link
            name="eye"
            color="gray"
            to={reverse("ticketDetail", { id: obj.id })}
          />
        </Tooltip>
        <Tooltip title={t("Ticket History")}>
          <Icon.Link
            name="history"
            color="gray"
            to={reverse("ticketHistory", { id: obj.id })}
            linkClassName="ml-3"
          />
        </Tooltip>
        {obj.isEditable || isOwner() ? (
          <Tooltip title={t("Edit Ticket")}>
            <Icon.Link
              name="edit"
              to={reverse(
                "ticketUpdate",
                { id: obj.id },
                { next: buildRouteFromLocation(location) }
              )}
              linkClassName="ml-3"
            />
          </Tooltip>
        ) : (
          <span style={{ marginLeft: "2.25rem" }} />
        )}
        <Tooltip title={t("Download Ticket PDF")}>
          <Icon
            name="pdf"
            onClick={() => fileGET(`/tickets/${obj.id}/pdf/`)}
            className="ml-3"
            type="info"
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
        {(obj.isEditable || isOwner()) &&
        (!obj.infantBookingsInvoiced || isOwner()) ? (
          <Tooltip title={t("Delete Ticket")}>
            <Icon.Link
              name="remove"
              color="danger"
              to={reverse(
                "ticketDelete",
                { id: obj.id },
                { next: buildRouteFromLocation(location) }
              )}
              linkClassName="ml-3"
            />
          </Tooltip>
        ) : (
          <span style={{ marginLeft: "2.25rem" }} />
        )}
      </>
    )),
  ],
  filters: [
    [
      {
        name: "reference",
        fieldType: "input",
        type: "text",
        label: t("Reference"),
        size: "col-2",
      },
      {
        name: "tourOperator",
        fieldType: "asyncSelect",
        endpoint: "/tour-operators/",
        label: t("Tour Operator"),
        size: "col-2",
      },
      {
        name: "route",
        fieldType: "asyncSelect",
        endpoint: "/routings/routes/",
        label: t("Segment"),
        size: "col-2",
      },
      {
        name: "addReturn",
        fieldType: "checkbox",
        label: t("Add return"),
        size: "col-auto",
        align: "center",
      },
      {
        name: "airline",
        fieldType: "asyncSelect",
        endpoint: "/airlines/",
        label: t("Airline"),
        size: "col-2",
      },
      {
        fieldType: "applyButton",
        size: "col-auto mt-2",
      },
      {
        fieldType: "clearButton",
        size: "col-auto mt-2",
      },
    ],
  ],
  buttons: (
    <>
      {hasPermissions(["add_tickets_ticket"]) && (
        <Button icon="plus" to={reverse("ticketCreateStep1")}>
          {t("Add Ticket")}
        </Button>
      )}
    </>
  ),
})

export default function TicketList(props) {
  const { t } = useTranslation()
  const history = useHistory()

  return <TableWithFilters config={getConfig(t, history.location)} />
}
