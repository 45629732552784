import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { isEmpty } from "lodash"

import { BookingInfo, TicketInfo } from "./TicketForm/includes"
import { hasPermissions, isOwner } from "apps/auth/auth"
import FlightDetails from "apps/flights/FlightDetails/FlightDetails"
import Button from "components/Button/Button"
import FieldDetail from "components/FieldDetail/FieldDetail"
import SectionHeader from "components/SectionHeader/SectionHeader"
import { apiGET, fileGET } from "utils/api"
import { notify } from "utils/notifications"
import { reverse, buildRouteFromLocation } from "utils/urls"

export default function TicketDetail(props) {
  const [data, setData] = useState({})
  const [ticketInfo, setTicketInfo] = useState()
  const { t } = useTranslation()
  let history = useHistory()

  useEffect(() => {
    if (!ticketInfo && !isEmpty(data)) {
      apiGET({
        path: `/flights/segments/segments-ticket-info/?segments=${data.segment.id}&tourOperator=${data.tourOperator.id}&ticket=${data.id}`,
        onSuccess: response => setTicketInfo(response),
        onError: () =>
          notify(
            "error",
            t("There were some errors while trying to get segments info.")
          ),
      })
    }
  }, [data, t, ticketInfo])

  useEffect(
    () =>
      apiGET({
        path: `/tickets/${props.match.params.id}/`,
        onSuccess: response => setData(response),
        onError: () => props.history.push(reverse("ticketList")),
      }),
    [props.match.params.id, props.history]
  )

  return ticketInfo ? (
    <div>
      <div className="row">
        <div className="col">
          <h1>
            {t("Ticket details")}
            {" - "}
            <span className="text-magenta">{data.reference}</span>
          </h1>
        </div>
        <div className="col-auto">
          {(data.isEditable || isOwner()) &&
            hasPermissions(["change_tickets_ticket"]) && (
              <Button
                icon="edit"
                to={reverse(
                  "ticketUpdate",
                  { id: data.id },
                  { next: buildRouteFromLocation(history.location) }
                )}>
                {t("Update")}
              </Button>
            )}
          <Button
            icon="pdf"
            type="info"
            className="ml-2"
            onClick={() => fileGET(`/tickets/${data.id}/pdf/`)}>
            {t("PDF")}
          </Button>
          {hasPermissions(["delete_tickets_ticket"]) &&
            (data.isEditable || isOwner()) &&
            (!data.infantBookingsInvoiced || isOwner()) && (
              <Button
                type="danger"
                icon="remove"
                className="ml-2"
                to={reverse(
                  "ticketDelete",
                  { id: data.id },
                  {
                    next:
                      encodeURIComponent(
                        new URLSearchParams(history.location.search).get("next")
                      ) || reverse("ticketList"),
                  }
                )}>
                {t("Delete")}
              </Button>
            )}
          {hasPermissions(["view_history"]) && (
            <Button
              icon="history"
              className="ml-2"
              to={reverse(
                "ticketHistory",
                { id: data.id },
                { next: buildRouteFromLocation(history.location) }
              )}>
              {t("History")}
            </Button>
          )}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 col-xxl">
          <div className="row">
            <div className="col-12">
              <FlightDetails data={ticketInfo.flightDetails[0]} />
            </div>
            <div className="col-12">
              <BookingInfo
                seats={ticketInfo.seats[0]}
                tourOperator={data.tourOperator}
              />
            </div>
          </div>
        </div>
        <div className="col-xxl-auto px-0 border-right border-teal-light" />
        <div className="col-12 mt-3 mt-xxl-0 col-xxl">
          <TicketInfo ticketDefault={ticketInfo.ticketDefault[0]} />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <SectionHeader header={t("Summary")} />
        </div>
        <div className="col-12 mt-3">
          <div className="row">
            <div className="col-3">
              <FieldDetail
                label={t("Airline PNR")}
                value={data.airlinePNR || "-"}
              />
            </div>
            <div className="col-6">
              <div className="d-flex align-items-center py-2 px-3 bg-light mt-2 mb-3">
                <div className="row">
                  <div className="col text-center">
                    <b>{t("Total")}</b>
                  </div>
                  <div className="col text-center">
                    <b>{t("ADT")}</b>
                  </div>
                  <div className="col text-center">
                    <b>{t("CHD")}</b>
                  </div>
                  <div className="col text-center">
                    <b>{t("INF")}</b>
                  </div>
                  <div className="col text-center">
                    <b>{t("PetC")}</b>
                  </div>
                </div>
              </div>
              <div className="d-flex px-3 pb-2 border-bottom border-light">
                <div className="row">
                  <div className="col text-center">
                    {`${data.adultPassengerCount + data.childPassengerCount}${
                      data.infantPassengerCount
                        ? ` + ${data.infantPassengerCount}`
                        : ""
                    }${
                      data.petPassengerCount
                        ? ` + ${data.petPassengerCount}`
                        : ""
                    }`}
                  </div>
                  <div className="col text-center">
                    {data.adultPassengerCount}
                  </div>
                  <div className="col text-center">
                    {data.childPassengerCount}
                  </div>
                  <div className="col text-center">
                    {data.infantPassengerCount}
                  </div>
                  <div className="col text-center">
                    {data.petPassengerCount}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <SectionHeader header={t("Passenger list")} />
        </div>
      </div>
      <table className="table mt-2">
        <thead>
          <tr>
            <th>{t("Gender")}</th>
            <th>{t("Last name")}</th>
            <th>{t("Name")}</th>
            <th>{t("Date of birth")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.passengers.map((family, i) => (
            <Fragment key={i}>
              {family.members.map((passenger, j) => (
                <tr className={`${i % 2 !== 0 ? "bg-light" : ""}`} key={j}>
                  <td>{passenger.gender.id}</td>
                  <td className="text-uppercase">{passenger.lastName}</td>
                  <td className="text-uppercase">{passenger.firstName}</td>
                  <td>{passenger.dateOfBirth || ""}</td>
                  <td>
                    {passenger.passengerType === "ADULT" &&
                      passenger.pets.length !== 0 && (
                        <div>PETC {passenger.pets.join(", ")}</div>
                      )}
                    {passenger.passengerType === "CHILD" && <>CHD</>}
                    {passenger.passengerType === "INFANT" && <>INF</>}
                  </td>
                </tr>
              ))}
            </Fragment>
          ))}
        </tbody>
      </table>
      <div className="my-4">
        <Button
          icon="chevron-left-double"
          onClick={
            history.location.search.includes("next")
              ? () =>
                  history.push(
                    new URLSearchParams(history.location.search).get("next")
                  )
              : () => history.goBack()
          }>
          {t("Back")}
        </Button>
      </div>
    </div>
  ) : null
}

TicketDetail.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string,
    }),
  }).isRequired,
}
