const routes = {
  home: "/",
  history: "/history/:model/:id",
  appSettingsDetail: "/app-settings/detail",
  appSettingsUpdate: "/app-settings/update",
  cityCreate: "/cities/create",
  cityUpdate: "/cities/:id/update",
  cityDelete: "/cities/:id/delete",
  cityDetail: "/cities/:id",
  cityList: "/cities",
  countryList: "/countries",
  dateRangeCreate: "/date-ranges/create",
  dateRangeUpdate: "/date-ranges/:id/update",
  dateRangeDelete: "/date-ranges/:id/delete",
  dateRangeDetail: "/date-ranges/:id",
  dateRangeList: "/date-ranges",
  ownerCompanyDetail: "/owner-company/detail",
  ownerCompanyUpdate: "/owner-company/update",
}

export default routes
