import history from "utils/history"
import {
  _dismissAllNotifications,
  _notify,
} from "components/Notification/Notification"

export const dismissAllNotifications = () => _dismissAllNotifications()

export const notify = (type, message) => _notify(type, message)

export const redirectWithNotify = (url, type, message) => {
  history.push(url)
  notify(type, message)
}
