import React from "react"
import { isEqual } from "lodash"

export const validateBookingForm = (values, BOOKING_TYPE_ENUM, legFares, t) => {
  const GROUP_EMPTY_FORM = {
    seats: "",
    name: "",
    note: "",
    fineFreeCancelationType: "percentage",
    finePenaltyType: "percentage",
    fines: [
      {
        date: "0",
        penalty: "100",
        freeCancelation: "0",
      },
    ],
    fareType: "",
  }

  const TYPE_REQUIRED_FIELDS = {
    [BOOKING_TYPE_ENUM.ALLOTMENT]: [
      { name: "seats", label: t("Seats") },
      { name: "releaseDate1", label: t("Allotment type release date") },
    ],
    [BOOKING_TYPE_ENUM.BLOCK]: [{ name: "seats", label: t("Seats") }],
    [BOOKING_TYPE_ENUM.GIR]: [
      { name: "seats", label: t("Seats") },
      { name: "releaseDate1", label: t("GIR type 1st release date") },
      { name: "releaseDate2", label: t("GIR type 2nd release date") },
      { name: "releasePercentage", label: t("GIR seat release percentage") },
    ],
    [BOOKING_TYPE_ENUM.GROUP]: [
      { name: "seats", label: t("Seats") },
      { name: "fines", label: t("Fines") },
    ],
    [BOOKING_TYPE_ENUM.OVERFLOW]: [{ name: "seats", label: t("Seats") }],
  }

  const FARE_TYPE_FIELD = [{ name: "fareType", label: t("Fare type") }]

  const SINGLE_LEG_FARE_FIELDS = [
    { name: "firstLegAdultFare", label: t("Adult fare for the first leg") },
    { name: "firstLegChildFare", label: t("Child fare for the first leg") },
  ]

  const MULTI_LEG_FARE_FIELDS = [
    { name: "secondLegAdultFare", label: t("Adult fare for the second leg") },
    { name: "secondLegChildFare", label: t("Child fare for the second leg") },
  ]

  const bookingTypes = []
  const errors = []
  Object.entries(BOOKING_TYPE_ENUM).forEach(([typeName, typeValue]) => {
    let requiredFields = [
      ...TYPE_REQUIRED_FIELDS[typeValue],
      ...FARE_TYPE_FIELD,
    ]
    const bookingData = values[typeValue]
    const bookingTypeValues = requiredFields.map(
      field => bookingData[field.name]
    )

    if (
      (typeValue !== BOOKING_TYPE_ENUM.GROUP &&
        bookingTypeValues.some(v => v)) ||
      (typeValue === BOOKING_TYPE_ENUM.GROUP &&
        !isEqual(bookingData, GROUP_EMPTY_FORM))
    ) {
      const fareType = bookingData.fareType
      if (fareType && fareType.id === "custom") {
        bookingTypeValues.push(
          bookingData.firstLegAdultFare,
          bookingData.firstLegChildFare
        )
        requiredFields = [...requiredFields, ...SINGLE_LEG_FARE_FIELDS]

        if (["return", "multi"].includes(values.flightType)) {
          bookingTypeValues.push(
            bookingData.secondLegAdultFare,
            bookingData.secondLegChildFare
          )
          requiredFields = [...requiredFields, ...MULTI_LEG_FARE_FIELDS]
        }
      } else if (fareType && fareType.id === "existing") {
        if (!legFares[typeValue].firstLeg.length) {
          errors.push(
            `${typeName} - ${t(
              "Fares for the first leg are not defined. Please choose 'Custom' fare type."
            )}`
          )
        } else if (
          ["return", "multi"].includes(values.flightType) &&
          !legFares[typeValue].secondLeg.length
        ) {
          errors.push(
            `${typeName} - ${t(
              "Fares for the second leg are not defined. Please choose 'Custom' fare type."
            )}`
          )
        }
      } else if (fareType && fareType.id !== "") {
        errors.push(`${typeName} - ${t("Fare type unknown.")}`)
      }

      if (!bookingTypeValues.every(v => v)) {
        requiredFields.forEach(field => {
          if (!bookingData[field.name]) {
            errors.push(`${typeName} - ${field.label} ${t("is required.")}`)
          }
        })
      }

      if (
        typeValue === BOOKING_TYPE_ENUM.GROUP &&
        bookingData.fines.length > 1
      ) {
        const finesDates = bookingData.fines.map(fine => fine.date)
        if (finesDates.length !== new Set(finesDates).size) {
          errors.push(
            `${typeName} - ${t("Every fine must have unique date value.")}`
          )
        }

        const penalties = bookingData.fines
          .sort((a, b) => (parseInt(a.date) > parseInt(b.date) ? -1 : 1))
          .map(fine => parseFloat(fine.penalty))
        if (arrayIsDecreasing(penalties)) {
          errors.push(
            `${typeName} - ${t(
              "Penalties must increase (or stay the same) with decreasing date value."
            )}`
          )
        }

        const freeCancelationSeats = bookingData.fines
          .sort((a, b) => (parseInt(a.date) > parseInt(b.date) ? -1 : 1))
          .map(fine => parseFloat(fine.freeCancelation))
        if (arrayIsIncreasing(freeCancelationSeats)) {
          errors.push(
            `${typeName} - ${t(
              "Free cancelation seats must deacrease (or stay the same) with decreasing date value."
            )}`
          )
        }
      }

      if (
        typeValue === BOOKING_TYPE_ENUM.GIR &&
        bookingData.releaseDate1 &&
        bookingData.releaseDate2 &&
        parseInt(bookingData.releaseDate2) >= parseInt(bookingData.releaseDate1)
      ) {
        errors.push(
          `${typeName} - ${t(
            "2nd release date must be after 1st release date."
          )}`
        )
      }
    }

    if (
      (typeValue !== BOOKING_TYPE_ENUM.GROUP &&
        bookingTypeValues.some(v => v)) ||
      (typeValue === BOOKING_TYPE_ENUM.GROUP &&
        !isEqual(bookingData, GROUP_EMPTY_FORM))
    ) {
      bookingTypes.push(typeValue)
    }
  })

  if (!bookingTypes.length) {
    errors.push(t("At least one booking type must be populated."))
  }

  return errors.length ? errors : true
}

const arrayIsIncreasing = array => {
  for (let i = 0; i < array.length; i++) {
    if (i && array[i] < array[i - 1]) {
      return false
    }
  }
  return true
}

const arrayIsDecreasing = array => {
  for (let i = 0; i < array.length; i++) {
    if (i && array[i] > array[i - 1]) {
      return false
    }
  }
  return true
}

export const getReleaseDate = releaseDate =>
  releaseDate ? (
    releaseDate.hasPassed ? (
      <span className="text-danger">
        {releaseDate.date}{" "}
        <span className="text-magenta">(D-{releaseDate.dateOffset})</span>
      </span>
    ) : (
      <>
        {releaseDate.date}{" "}
        <span className="text-magenta">(D-{releaseDate.dateOffset})</span>
      </>
    )
  ) : (
    "-"
  )

export const getSeats = (booking, BOOKING_TYPE_ENUM) =>
  (booking.type.id === BOOKING_TYPE_ENUM.ALLOTMENT && !booking.split1Done) ||
  (booking.type.id === BOOKING_TYPE_ENUM.GIR && !booking.split2Done) ? (
    <>
      {booking.seats.totalSeats}
      {` (`}
      <span className="text-success">
        {booking.seats.confirmedSeats + booking.seats.tempConfirmedSeats} CNF
      </span>
      {` + `}
      <span className="text-warning">{booking.seats.optionalSeats} OPT</span>)
    </>
  ) : (
    booking.seats.totalSeats
  )
