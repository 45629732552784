import {
  AirlineFeeCreate,
  AirlineFeeUpdate,
  AirlineFeeDelete,
  AirlineFeeList,
  TourOperatorFeeCreate,
  TourOperatorFeeUpdate,
  TourOperatorFeeDelete,
  TourOperatorFeeList,
} from "apps/fees/Fee"
import {
  FeeCategoryCreate,
  FeeCategoryUpdate,
  FeeCategoryList,
} from "apps/fees/FeeCategory"
import { route } from "utils/urls"

export const PROTECTED_ROUTES = [
  route("feeCategoryCreate", FeeCategoryCreate, ["add_fees_feecategory"]),
  route("feeCategoryUpdate", FeeCategoryUpdate, ["change_fees_feecategory"]),
  route("feeCategoryList", FeeCategoryList, ["view_fees_feecategory"]),
  route("airlineFeeCreate", AirlineFeeCreate, ["add_fees_airlinefee"]),
  route("airlineFeeUpdate", AirlineFeeUpdate, ["change_fees_airlinefee"]),
  route("airlineFeeDelete", AirlineFeeDelete, ["delete_fees_airlinefee"]),
  route("airlineFeeList", AirlineFeeList, ["view_fees_airlinefee"]),
  route("tourOperatorFeeCreate", TourOperatorFeeCreate, [
    "add_fees_touroperatorfee",
  ]),
  route("tourOperatorFeeUpdate", TourOperatorFeeUpdate, [
    "change_fees_touroperatorfee",
  ]),
  route("tourOperatorFeeDelete", TourOperatorFeeDelete, [
    "delete_fees_touroperatorfee",
  ]),
  route("tourOperatorFeeList", TourOperatorFeeList, [
    "view_fees_touroperatorfee",
  ]),
]
