import React from "react"
import { useTranslation } from "react-i18next"

import { hasPermissions } from "apps/auth/auth"
import InfantBookingFareList from "apps/fares/InfantBookingFare/InfantBookingFareList"
import Button from "components/Button/Button"
import Icon from "components/Icon/Icon"
import { actionsColumn } from "components/Table"
import Tooltip from "components/Tooltip/Tooltip"
import { reverse } from "utils/urls"

export default function AirlineInfantBookingFareList(props) {
  const { t } = useTranslation()

  return (
    <InfantBookingFareList
      tableConfig={{
        endpoint: "/fares/airline-infant-fares",
        title: t("Airline Infant Booking Fares"),
        tableName: "airline-infant-fare-table-config",
        buttons: (
          <>
            {hasPermissions(["add_fares_airlineinfantbookingfare"]) && (
              <Button
                icon="plus"
                to={reverse("airlineInfantBookingFareCreate")}>
                {t("Add Infant Fare")}
              </Button>
            )}
          </>
        ),
      }}
      actionsColumn={actionsColumn(obj => (
        <>
          <Tooltip title={t("Infant Fare History")}>
            <Icon.Link
              name="history"
              color="gray"
              to={reverse("history", {
                id: obj.id,
                model: encodeURIComponent("fares/airline-infant-fares"),
              })}
            />
          </Tooltip>
          <Tooltip title={t("Edit Infant Fare")}>
            <Icon.Link
              name="edit"
              linkClassName="ml-3"
              to={reverse("airlineInfantBookingFareUpdate", { id: obj.id })}
            />
          </Tooltip>
          <Tooltip title={t("Delete Infant Fare")}>
            <Icon.Link
              name="remove"
              color="danger"
              linkClassName="ml-3"
              to={reverse("airlineInfantBookingFareDelete", { id: obj.id })}
            />
          </Tooltip>
        </>
      ))}
    />
  )
}
