import React, { Fragment } from "react"
import { useFormContext, useFieldArray } from "react-hook-form"
import { useTranslation } from "react-i18next"

import CarrierFormSet from "apps/flights/Carrier/CarrierFormSet"
import { getDurationDiff, getSegments } from "apps/flights/utils"
import Card from "components/Card/Card"
import Button from "components/Button/Button"
import FlightDuration from "components/FlightDuration/FlightDuration"
import { FormField } from "components/Form"

export default function LegFormSet(props) {
  const { getValues, setValue, control, watch } = useFormContext()
  const { fields, remove } = useFieldArray({
    control,
    name: `legs`,
  })
  const legsValues = watch("legs", [])

  const { t } = useTranslation()

  const addLeg = () => {
    const legs = [...getValues().legs]
    const lastLeg = legs[legs.length - 1]

    const newLeg = {}

    if (lastLeg) {
      newLeg.carriers = lastLeg.carriers

      if (lastLeg.arrivalAirport) {
        newLeg.departureAirport = lastLeg.arrivalAirport
      }
    }
    legs.push(newLeg)

    setValue("legs", legs)
  }

  const addReturnLegs = () => {
    const oldLegs = [...getValues().legs]
    const newLegs = []

    oldLegs
      .slice()
      .reverse()
      .forEach((leg, i) => {
        newLegs.push({
          arrivalAirport: leg.departureAirport,
          departureAirport: leg.arrivalAirport,
          carriers: leg.carriers,
        })
      })

    setValue("legs", [...oldLegs, ...newLegs])
  }

  const renderDuration = (legIndex, type) => {
    const values = { legs: legsValues }
    let duration

    if (type === "layover") {
      duration = getDurationDiff(values, legIndex - 1, legIndex)
    } else if (type === "leg") {
      duration = getDurationDiff(values, legIndex)
    } else {
      throw TypeError("Invalid type.")
    }

    return duration !== null ? (
      <FlightDuration type={type} duration={duration} />
    ) : null
  }

  const renderSegments = () => {
    const segments = getSegments(legsValues)
    return segments !== false ? (
      segments.length !== 0 ? (
        <div className="d-flex">
          {segments.map((s, i) => (
            <div key={i} className={`${i !== 0 ? "ml-4" : ""}`}>
              <FormField
                name={`segments[${i}].isSaleable`}
                fieldType="checkbox"
                className="align-center"
                disabled={!s.hasCarrier}
                label={`${s.legs[0].departure.codeIata}-${
                  s.legs[s.legs.length - 1].arrival.codeIata
                }`}
              />
              {s.legs.map((leg, k) => (
                <Fragment key={`${leg.departure.id}-${leg.arrival.id}`}>
                  <FormField
                    name={`segments[${i}].legs[${k}].departure`}
                    fieldType="input"
                    label=""
                    type="hidden"
                    defaultValue={leg.departure.id}
                  />
                  <FormField
                    name={`segments[${i}].legs[${k}].arrival`}
                    fieldType="input"
                    label=""
                    type="hidden"
                    defaultValue={leg.arrival.id}
                  />
                </Fragment>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <span>
          {t("Start selecting airports in order to see available segments.")}
        </span>
      )
    ) : (
      <span className="text-danger">
        {t(
          "There is a gap between consecutive legs. Please fix that in order to save segments."
        )}
      </span>
    )
  }

  return (
    <>
      {fields.map((field, i) => (
        <Fragment key={field.id}>
          {renderDuration(i, "layover") || <div className="row mb-3" />}
          <div className="row">
            <div className="col">
              <Card bodyClassName="p-4">
                <div className="row">
                  <div className="col-12 col-xxl-6">
                    <div className="row">
                      <div className="col-6 col-xxl-5">
                        <FormField
                          name={`legs[${i}].departureAirport`}
                          fieldType="airportSelect"
                          icon="airplane-departure"
                          label={t("Departure airport")}
                          required
                          defaultValue={field.departureAirport}
                          filterOption={
                            legsValues[i] && legsValues[i].arrivalAirport
                              ? option =>
                                  option.value !==
                                  legsValues[i].arrivalAirport.id
                              : undefined
                          }
                        />
                      </div>
                      <div className="col-3 col-xxl-4">
                        <FormField
                          name={`legs[${i}].departureTime`}
                          fieldType="time"
                          label={t("Dep. time")}
                          required
                          defaultValue={field.departureTime}
                        />
                      </div>
                      <div className="col-3 col-xxl-3">
                        <FormField
                          name={`legs[${i}].departureDayOffset`}
                          fieldType="select"
                          label={t("Dep. offset")}
                          options={[
                            { id: -1, displayName: "-1" },
                            { id: 0, displayName: "--" },
                            { id: 1, displayName: "+1" },
                            { id: 2, displayName: "+2" },
                          ]}
                          disabled={i === 0}
                          defaultValue={
                            field.departureDayOffset || {
                              id: 0,
                              displayName: "--",
                            }
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xxl-6">
                    <div className="row">
                      <div className="col-6 col-xxl-5">
                        <FormField
                          name={`legs[${i}].arrivalAirport`}
                          fieldType="airportSelect"
                          icon="airplane-arrival"
                          label={t("Arrival airport")}
                          required
                          defaultValue={field.arrivalAirport}
                          filterOption={
                            legsValues[i] && legsValues[i].departureAirport
                              ? option =>
                                  option.value !==
                                  legsValues[i].departureAirport.id
                              : undefined
                          }
                        />
                      </div>
                      <div className="col-3 col-xxl-4">
                        <FormField
                          name={`legs[${i}].arrivalTime`}
                          fieldType="time"
                          label={t("Arr. time")}
                          required
                          defaultValue={field.arrivalTime}
                        />
                      </div>
                      <div className="col-3 col-xxl-3">
                        <FormField
                          name={`legs[${i}].arrivalDayOffset`}
                          fieldType="select"
                          label={t("Arr. offset")}
                          defaultValue={
                            field.arrivalDayOffset || {
                              id: 0,
                              displayName: "--",
                            }
                          }
                          options={[
                            { id: -1, displayName: "-1" },
                            { id: 0, displayName: "--" },
                            { id: 1, displayName: "+1" },
                            { id: 2, displayName: "+2" },
                          ]}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {renderDuration(i, "leg")}
                <CarrierFormSet
                  namePrefix={`legs[${i}]`}
                  carriers={
                    legsValues[i] && legsValues[i].carriers
                      ? legsValues[i].carriers
                      : []
                  }
                />
              </Card>
            </div>
            <div className="col-auto">
              <Button
                disabled={i === 0}
                onClick={() => remove(i)}
                type="danger"
                icon="remove">
                {t("Delete")}
              </Button>
            </div>
          </div>
        </Fragment>
      ))}
      <div className="row mt-4">
        <div className="col">
          <Button icon="plus" type="success" onClick={addLeg}>
            {t("Add leg")}
          </Button>
        </div>
        <div className="col-auto ml-auto">
          <Button icon="return" type="success" onClick={addReturnLegs}>
            {t("Add return legs")}
          </Button>
        </div>
      </div>
      <div className="row my-5">
        <h4>{t("Segments")}</h4>
        <div className="col-12">
          <Card>{renderSegments()}</Card>
        </div>
      </div>
    </>
  )
}
