import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import FeeForm from "apps/fees/Fee/FeeForm"
import { reverse } from "utils/urls"

export default function TourOperatorFeeUpdate(props) {
  const { t } = useTranslation()

  return (
    <FeeForm
      {...props}
      isUpdate
      detailEndpoint={`/fees/tour-operator-fees/${props.match.params.id}/`}
      actionEndpoint={`/fees/tour-operator-fees/${props.match.params.id}/`}
      errorUrl={reverse("touroperatorFeeList")}
      label={t("Edit tour operator fee")}
      successMessage={t("Tour Operator Fee was successfully saved.")}
      successUrl={reverse("tourOperatorFeeList")}
      isTourOperator
    />
  )
}

TourOperatorFeeUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
