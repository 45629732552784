import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import BookingFareForm from "apps/fares/BookingFare/BookingFareForm"
import { reverse } from "utils/urls"

export default function TourOperatorBookingFareUpdate(props) {
  const { t } = useTranslation()

  return (
    <BookingFareForm
      {...props}
      isUpdate
      detailEndpoint={`/fares/tour-operator-fares/${props.match.params.id}/`}
      actionEndpoint={`/fares/tour-operator-fares/${props.match.params.id}/`}
      errorUrl={reverse("tourOperatorBookingFareList")}
      label={t("Edit tour operator booking fare")}
      successMessage={t("Tour Operator Booking Fare was successfully saved.")}
      successUrl={reverse("tourOperatorBookingFareList")}
      isTourOperator
    />
  )
}

TourOperatorBookingFareUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
