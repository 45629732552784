import React from "react"
import PropTypes from "prop-types"
import { isObject } from "lodash"

import {
  AsyncSelectField,
  CheckboxField,
  DateField,
  DateRangeField,
  FileField,
  ImageField,
  InputField,
  SelectField,
  TimeField,
} from "components/Form/fields/simple"
import {
  AirportSelectField,
  DateAndOffsetField,
  FlightNoField,
  OperatingDaysField,
  PriceField,
  SelectDateRangeField,
  SelectFieldWithAdd,
} from "components/Form/fields/composite"
import Icon from "components/Icon/Icon"
import { DATE_TIME_FORMAT, TIME_FORMAT } from "utils/constants"

import "./FormField.scss"

const SIMPLE_FORM_FIELDS = [
  "asyncSelect",
  "checkbox",
  "date",
  "dateTime",
  "dateRange",
  "file",
  "image",
  "input",
  "masked",
  "select",
  "textarea",
  "time",
]
const COMPOSITE_FORM_FIELDS = [
  "airportSelect",
  "flightNo",
  "operatingDays",
  "price",
  "selectDateRange",
  "selectWithAdd",
  "dateAndOffset",
]

/* ------------------------------------- SIMPLE FORM FIELD ------------------------------------- */
function SimpleFormField({
  fieldType,
  helpText,
  containerClassName,
  inputGroupPrepend,
  inputGroupAppend,
  ...rest
}) {
  const renderField = () => {
    switch (fieldType) {
      case "asyncSelect":
        return <AsyncSelectField {...rest} />
      case "checkbox":
        return <CheckboxField {...rest} />
      case "date":
        return <DateField {...rest} />
      case "dateRange":
        return <DateRangeField {...rest} />
      case "dateTime":
        return (
          <DateField
            {...rest}
            format={DATE_TIME_FORMAT}
            showTime={{ format: TIME_FORMAT }}
          />
        )
      case "file":
        return rest.disabled ? (
          <input type="text" disabled className="form-control form-field" />
        ) : (
          <FileField {...rest} />
        )
      case "image":
        return rest.disabled ? (
          <input type="text" disabled className="form-control form-field" />
        ) : (
          <ImageField {...rest} />
        )
      case "input":
      case "masked":
      case "textarea":
        return <InputField fieldType={fieldType} {...rest} />
      case "select":
        return <SelectField {...rest} />
      case "time":
        return <TimeField {...rest} />
      default:
        throw new Error()
    }
  }

  const render = () => (
    <>
      <div
        className={`my-2 form-field-container ${containerClassName} ${
          inputGroupPrepend || inputGroupAppend ? "input-group" : ""
        } ${rest.type === "hidden" ? "d-none" : ""}`}
        style={{ position: "relative" }}>
        {inputGroupPrepend !== null && (
          <div className="input-group-prepend">{inputGroupPrepend}</div>
        )}
        <div
          className={`${
            inputGroupPrepend || inputGroupAppend ? "field-control" : ""
          }`}>
          {renderField()}
          {!["checkbox", "file"].includes(fieldType) ? (
            <label htmlFor={rest.name} className="floating">
              {rest.label}
              {rest.required && <span className="text-danger">*</span>}
            </label>
          ) : null}
          {helpText !== "" && (
            <small className="form-text text-muted d-block mt-2">
              {helpText}
            </small>
          )}
        </div>
        {inputGroupAppend !== null && (
          <div className="input-group-append">{inputGroupAppend}</div>
        )}
      </div>
    </>
  )

  return rest.icon ? (
    <div className="row">
      <div className="col-auto my-auto pr-0 pt-1">
        <Icon
          size="xl"
          {...(isObject(rest.icon) ? rest.icon : { name: rest.icon })}
        />
      </div>
      <div className="col">{render()}</div>
    </div>
  ) : (
    render()
  )
}

SimpleFormField.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  fieldType: PropTypes.oneOf(SIMPLE_FORM_FIELDS).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  containerClassName: PropTypes.string,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  inputGroupPrepend: PropTypes.node,
  inputGroupAppend: PropTypes.node,
}

SimpleFormField.defaultProps = {
  containerClassName: "",
  helpText: "",
  disabled: false,
  required: false,
  inputGroupPrepend: null,
  inputGroupAppend: null,
}
/* ------------------------------------- SIMPLE FORM FIELD ------------------------------------- */

/* ------------------------------------ COMPOSITE FORM FIELD ----------------------------------- */
function CompositeFormField(props) {
  const renderField = () => {
    switch (props.fieldType) {
      case "airportSelect":
        return <AirportSelectField {...props} />
      case "dateAndOffset":
        return <DateAndOffsetField {...props} />
      case "flightNo":
        return <FlightNoField {...props} />
      case "operatingDays":
        return <OperatingDaysField {...props} />
      case "price":
        return <PriceField {...props} />
      case "selectDateRange":
        return <SelectDateRangeField {...props} />
      case "selectWithAdd":
        return <SelectFieldWithAdd {...props} />
      default:
        throw new Error()
    }
  }

  return renderField()
}

CompositeFormField.propTypes = {
  fieldType: PropTypes.oneOf(COMPOSITE_FORM_FIELDS).isRequired,
}
/* ------------------------------------ COMPOSITE FORM FIELD ----------------------------------- */

/* ----------------------------------------- FORM FIELD ---------------------------------------- */
export default function FormField(props) {
  const render = () => {
    if (SIMPLE_FORM_FIELDS.includes(props.fieldType)) {
      return <SimpleFormField {...props} />
    } else if (COMPOSITE_FORM_FIELDS.includes(props.fieldType)) {
      return <CompositeFormField {...props} />
    }
    throw new Error()
  }

  return render()
}

FormField.propTypes = {
  fieldType: PropTypes.oneOf([...SIMPLE_FORM_FIELDS, ...COMPOSITE_FORM_FIELDS])
    .isRequired,
}
/* ----------------------------------------- FORM FIELD ---------------------------------------- */
