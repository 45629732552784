import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { isEmpty } from "lodash"

import Card from "components/Card/Card"
import { Form, FormField } from "components/Form"
import { apiGET, apiPOST } from "utils/api"
import { reverse } from "utils/urls"

export default function ManagePermissions(props) {
  const [formData, setFormData] = useState({})
  const { t } = useTranslation()
  let history = useHistory()

  useEffect(
    () =>
      apiGET({
        path: `/auth/users/${props.match.params.id}/manage-permissions/`,
        onSuccess: response => setFormData(response),
        onError: () => history.push(reverse("home")),
      }),
    [history, props.match.params.id]
  )

  return !isEmpty(formData) ? (
    <div>
      <h1 className="mb-3">{t("Manage Permissions")}</h1>
      <Card className="mt-3 pt-3">
        <Form
          apiFunction={apiPOST}
          endpoint={`/auth/users/${props.match.params.id}/manage-permissions/`}
          defaultValues={formData.reduce(
            (acc, val) => ({ ...acc, [val.name]: val.value }),
            {}
          )}
          successUrl={reverse("userDetail", { id: props.match.params.id })}
          successMessage={t("Permissions were successfully saved.")}>
          {formData.map(perm => (
            <div className="my-2" key={perm.name}>
              <FormField
                name={perm.name}
                fieldType="checkbox"
                label={perm.label}
              />
            </div>
          ))}
        </Form>
      </Card>
    </div>
  ) : null
}

ManagePermissions.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
