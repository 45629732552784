import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { isEmpty } from "lodash"

import { hasPermissions } from "apps/auth/auth"
import { getAppInfo } from "apps/core/utils"
import Button from "components/Button/Button"
import FieldDetail from "components/FieldDetail/FieldDetail"
import Icon from "components/Icon/Icon"
import SectionHeader from "components/SectionHeader/SectionHeader"
import { apiGET } from "utils/api"
import { reverse } from "utils/urls"

export default function TicketDefaultDetail(props) {
  const [data, setData] = useState({})
  const { t } = useTranslation()
  let history = useHistory()

  const appInfo = getAppInfo()

  useEffect(
    () =>
      apiGET({
        path: `/tickets/ticket-defaults/${props.match.params.id}`,
        onSuccess: response => setData(response),
        onError: () => history.push(reverse("ticketDefaultList")),
      }),
    [history, props.match.params.id]
  )

  return !isEmpty(data) ? (
    <div>
      <div className="row">
        <div className="col">
          <h1>{t("Ticket Default details")}</h1>
        </div>
        <div className="col-auto">
          {hasPermissions(["change_tickets_ticketdefault"]) && (
            <Button
              icon="edit"
              to={reverse("ticketDefaultUpdate", {
                id: props.match.params.id,
              })}>
              {t("Update")}
            </Button>
          )}
          {hasPermissions(["delete_tickets_ticketdefault"]) && (
            <Button
              icon="remove"
              type="danger"
              className="ml-2"
              to={reverse("ticketDefaultDelete", {
                id: props.match.params.id,
              })}>
              {t("Delete")}
            </Button>
          )}
          {hasPermissions(["view_history"]) && (
            <Button
              type="secondary"
              icon="history"
              className="ml-2"
              to={reverse("history", {
                id: props.match.params.id,
                model: encodeURIComponent("tickets/ticket-defaults"),
              })}>
              {t("History")}
            </Button>
          )}
        </div>
      </div>
      <div className="row my-auto">
        <div className="col-12">
          <div className="row">
            <div className="col-4 col-xxl-3">
              <FieldDetail
                label={t("Airline")}
                value={data.airline.displayName}
                inputGroupPrepend={
                  <div className="mr-2 pt-1">
                    <Icon name="airplane" size="lg" />
                  </div>
                }
              />
            </div>
            {data.routing !== null && (
              <div className="col-4 col-xxl-3">
                <FieldDetail
                  label={t("Routing")}
                  value={data.routing.displayName}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-xxl-6 mt-3">
              <div className="row">
                <div className="col-6">
                  <SectionHeader header={t("Catering")} />
                  <FieldDetail
                    label={t("Meal type")}
                    value={data.mealType.displayName}
                  />
                </div>
                <div className="col-6">
                  <SectionHeader header={t("CHD Age")} />
                  <FieldDetail
                    label={t("Maximum CHD Age")}
                    value={data.maxChildAge}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-xxl-6 mt-3">
              <SectionHeader header={t("Checked baggage")} />
              <div className="row">
                <div className="col-6">
                  <FieldDetail
                    label={t("Piece(s)")}
                    value={data.checkedbaggage?.pieces || "-"}
                  />
                </div>
                <div className="col-6">
                  <FieldDetail
                    label={t("Weight")}
                    value={data.checkedbaggage?.weight || "-"}
                    inputGroupAppend={
                      <span className="text-magenta">
                        {appInfo.massUnit.toLowerCase()}
                      </span>
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <FieldDetail
                        label={t("L + H + W")}
                        value={data.checkedbaggage?.dimensions || "-"}
                        inputGroupAppend={
                          <span className="text-magenta">
                            {appInfo.lengthUnit.toLowerCase()}
                          </span>
                        }
                      />
                    </div>
                    <div className="col-6">
                      <FieldDetail
                        label={t("Length")}
                        value={data.checkedbaggage?.["length"] || "-"}
                        inputGroupAppend={
                          <span className="text-magenta">
                            {appInfo.lengthUnit.toLowerCase()}
                          </span>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <FieldDetail
                        label={t("Height")}
                        value={data.checkedbaggage?.height || "-"}
                        inputGroupAppend={
                          <span className="text-magenta">
                            {appInfo.lengthUnit.toLowerCase()}
                          </span>
                        }
                      />
                    </div>
                    <div className="col-6">
                      <FieldDetail
                        label={t("Width")}
                        value={data.checkedbaggage?.width || "-"}
                        inputGroupAppend={
                          <span className="text-magenta">
                            {appInfo.lengthUnit.toLowerCase()}
                          </span>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 my-4">
          <div className="row">
            <div className="col-6">
              <SectionHeader header={t("Cabin baggage")} />
              <div className="row">
                <div className="col-6">
                  <FieldDetail
                    label={t("Piece(s)")}
                    value={data.cabinbaggage?.pieces || "-"}
                  />
                </div>
                <div className="col-6">
                  <FieldDetail
                    label={t("Weight")}
                    value={data.cabinbaggage?.weight || "-"}
                    inputGroupAppend={
                      <span className="text-magenta">
                        {appInfo.massUnit.toLowerCase()}
                      </span>
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <SectionHeader header={t("Cabin accessory")} />
              <div className="row">
                <div className="col-6">
                  <FieldDetail
                    label={t("Piece(s)")}
                    value={data.cabinaccessory?.pieces || "-"}
                  />
                </div>
                <div className="col-6">
                  <FieldDetail
                    label={t("Weight")}
                    value={data.cabinaccessory?.weight || "-"}
                    inputGroupAppend={
                      <span className="text-magenta">
                        {appInfo.massUnit.toLowerCase()}
                      </span>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="offset-3 col-6">
              <FieldDetail
                label={t("Total Cabin Weight")}
                value={data.totalCabinWeight || "-"}
                inputGroupAppend={
                  <span className="text-magenta">
                    {appInfo.massUnit.toLowerCase()}
                  </span>
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <FieldDetail
                        label={t("L + H + W")}
                        value={data.cabinbaggage?.dimensions || "-"}
                        inputGroupAppend={
                          <span className="text-magenta">
                            {appInfo.lengthUnit.toLowerCase()}
                          </span>
                        }
                      />
                    </div>
                    <div className="col-6">
                      <FieldDetail
                        label={t("Length")}
                        value={data.cabinbaggage?.["length"] || "-"}
                        inputGroupAppend={
                          <span className="text-magenta">
                            {appInfo.lengthUnit.toLowerCase()}
                          </span>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <FieldDetail
                        label={t("Height")}
                        value={data.cabinbaggage?.height || "-"}
                        inputGroupAppend={
                          <span className="text-magenta">
                            {appInfo.lengthUnit.toLowerCase()}
                          </span>
                        }
                      />
                    </div>
                    <div className="col-6">
                      <FieldDetail
                        label={t("Width")}
                        value={data.cabinbaggage?.width || "-"}
                        inputGroupAppend={
                          <span className="text-magenta">
                            {appInfo.lengthUnit.toLowerCase()}
                          </span>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <FieldDetail
                        label={t("L + H + W")}
                        value={data.cabinaccessory?.dimensions || "-"}
                        inputGroupAppend={
                          <span className="text-magenta">
                            {appInfo.lengthUnit.toLowerCase()}
                          </span>
                        }
                      />
                    </div>
                    <div className="col-6">
                      <FieldDetail
                        label={t("Length")}
                        value={data.cabinaccessory?.["length"] || "-"}
                        inputGroupAppend={
                          <span className="text-magenta">
                            {appInfo.lengthUnit.toLowerCase()}
                          </span>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      <FieldDetail
                        label={t("Height")}
                        value={data.cabinaccessory?.height || "-"}
                        inputGroupAppend={
                          <span className="text-magenta">
                            {appInfo.lengthUnit.toLowerCase()}
                          </span>
                        }
                      />
                    </div>
                    <div className="col-6">
                      <FieldDetail
                        label={t("Width")}
                        value={data.cabinaccessory?.width || "-"}
                        inputGroupAppend={
                          <span className="text-magenta">
                            {appInfo.lengthUnit.toLowerCase()}
                          </span>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <Button icon="chevron-left-double" onClick={() => history.goBack()}>
            {t("Back")}
          </Button>
        </div>
      </div>
    </div>
  ) : null
}

TicketDefaultDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
