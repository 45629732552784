import React from "react"
import PropTypes from "prop-types"

import TourOperatorInvoiceAction from "./TourOperatorInvoiceAction"
import TourOperatorInvoiceItemDelete from "./TourOperatorInvoiceItemDelete"

function TourOperatorInvoiceDelete(props) {
  return (
    <TourOperatorInvoiceAction
      type="delete"
      objId={props.match.params.id}
      endpoint={`/invoices/tour-operator-invoices/${props.match.params.id}/`}
    />
  )
}

function TourOperatorInvoiceSend(props) {
  return (
    <TourOperatorInvoiceAction
      type="send"
      objId={props.match.params.id}
      endpoint={`/invoices/tour-operator-invoices/${props.match.params.id}/send/`}
    />
  )
}

function TourOperatorInvoiceInvertTaxes(props) {
  return (
    <TourOperatorInvoiceAction
      type="invertTaxes"
      objId={props.match.params.id}
      endpoint={`/invoices/tour-operator-invoices/${props.match.params.id}/invert-taxes/`}
    />
  )
}

function TourOperatorInvoiceNullify(props) {
  return (
    <TourOperatorInvoiceAction
      type="nullify"
      objId={props.match.params.id}
      endpoint={`/invoices/tour-operator-invoices/${props.match.params.id}/nullify/`}
    />
  )
}

function TourOperatorInvoiceNullifyRecreate(props) {
  return (
    <TourOperatorInvoiceAction
      type="nullifyRecreate"
      objId={props.match.params.id}
      endpoint={`/invoices/tour-operator-invoices/${props.match.params.id}/nullify-recreate/`}
    />
  )
}

TourOperatorInvoiceDelete.propTypes =
  TourOperatorInvoiceSend.propTypes =
  TourOperatorInvoiceInvertTaxes.propTypes =
  TourOperatorInvoiceNullify.propTypes =
  TourOperatorInvoiceNullifyRecreate.propTypes =
    {
      match: PropTypes.shape({
        params: PropTypes.exact({
          id: PropTypes.string.isRequired,
        }),
      }).isRequired,
    }

export {
  TourOperatorInvoiceDelete,
  TourOperatorInvoiceSend,
  TourOperatorInvoiceInvertTaxes,
  TourOperatorInvoiceNullify,
  TourOperatorInvoiceNullifyRecreate,
  TourOperatorInvoiceItemDelete,
}
export { default as TourOperatorInvoiceDetail } from "./TourOperatorInvoiceDetail"
export { default as TourOperatorInvoiceList } from "./TourOperatorInvoiceList"
