import React from "react"
import { useTranslation } from "react-i18next"

import { hasPermissions } from "apps/auth/auth"
import Button from "components/Button/Button"
import Tooltip from "components/Tooltip/Tooltip"
import Icon from "components/Icon/Icon"
import { actionsColumn, TableWithFilters } from "components/Table"
import { reverse } from "utils/urls"

const getConfig = t => ({
  endpoint: "/date-ranges",
  title: t("Date Ranges"),
  tableName: "date-range-table-config",
  columns: [
    {
      Header: t("Name"),
      accessor: "name",
    },
    { Header: t("Date from"), accessor: "dateFrom" },
    { Header: t("Date to"), accessor: "dateTo" },
    actionsColumn(obj => (
      <>
        <Tooltip title={t("Date Range Details")}>
          <Icon.Link
            name="eye"
            color="gray"
            to={reverse("dateRangeDetail", { id: obj.id })}
          />
        </Tooltip>
        <Tooltip title={t("Date Range History")}>
          <Icon.Link
            name="history"
            to={reverse("history", { id: obj.id, model: "date-ranges" })}
            color="gray"
            linkClassName="ml-3"
          />
        </Tooltip>
        <Tooltip title={t("Edit Date Range")}>
          <Icon.Link
            name="edit"
            linkClassName="ml-3"
            to={reverse("dateRangeUpdate", { id: obj.id })}
          />
        </Tooltip>
        <Tooltip title={t("Delete Date Range")}>
          <Icon.Link
            name="remove"
            color="danger"
            linkClassName="ml-3"
            to={reverse("dateRangeDelete", { id: obj.id })}
          />
        </Tooltip>
      </>
    )),
  ],
  filters: [
    [
      {
        name: "name",
        fieldType: "input",
        type: "text",
        label: t("Name"),
        size: "col-4",
      },
      {
        name: ["dateFrom", "dateTo"],
        fieldType: "dateRange",
        label: t("Date from - Date to"),
        size: "col-auto",
      },
      {
        fieldType: "applyButton",
        size: "col-auto mt-2",
      },
      {
        fieldType: "clearButton",
        size: "col-auto mt-2",
      },
    ],
  ],
  buttons: (
    <>
      {hasPermissions(["add_core_daterange"]) && (
        <Button icon="plus" to={reverse("dateRangeCreate")}>
          {t("Add Date Range")}
        </Button>
      )}
    </>
  ),
})

export default function DateRangeList(props) {
  const { t } = useTranslation()

  return <TableWithFilters config={getConfig(t)} />
}
