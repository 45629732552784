import React from "react"
import { useTranslation } from "react-i18next"

import {
  mealTypeColumn,
  maxChildAgeColumn,
  cabinBaggageColumn,
  cabinAccessoryColumn,
  totalCabinWeightColumn,
  checkedBaggageColumn,
  actionsColumn,
} from "./columns"
import { hasPermissions } from "apps/auth/auth"
import Avatar from "components/Avatar/Avatar"
import Button from "components/Button/Button"
import { SubTable, TableWithFilters } from "components/Table"
import { reverse } from "utils/urls"

const getConfig = t => ({
  endpoint: "/tickets/ticket-defaults",
  title: t("Ticket Defaults"),
  tableName: "ticket-default-table-config",
  columns: [
    {
      Header: t("Carrier"),
      id: "airline",
      accessor: td => (
        <div className="d-flex justify-content-center">
          <Avatar
            image={td.airline.logo}
            title={td.airline.displayName}
            alt={td.airline.code}
          />
        </div>
      ),
      headerAlignment: "justify-content-center",
    },
    mealTypeColumn(t),
    maxChildAgeColumn(t),
    cabinBaggageColumn(t),
    cabinAccessoryColumn(t),
    totalCabinWeightColumn(t),
    checkedBaggageColumn(t),
    actionsColumn(t),
  ],
  SubComponent: row =>
    row.original.subDefaults ? (
      <SubTable
        data={row.original.subDefaults}
        diagonalColumnWidth={null}
        columns={[
          {
            Header: t("Routing"),
            id: "routing",
            accessor: sd => (
              <div className="text-center">{sd.routing.displayName}</div>
            ),
            headerAlignment: "justify-content-center",
            parentColumnIndex: 0,
          },
          { ...mealTypeColumn(t), parentColumnIndex: 1 },
          { ...maxChildAgeColumn(t), parentColumnIndex: 2 },
          { ...cabinBaggageColumn(t), parentColumnIndex: 3 },
          { ...cabinAccessoryColumn(t), parentColumnIndex: 4 },
          { ...totalCabinWeightColumn(t), parentColumnIndex: 5 },
          { ...checkedBaggageColumn(t), parentColumnIndex: 6 },
          { ...actionsColumn(t), parentColumnIndex: 7 },
        ]}
      />
    ) : null,
  showExpander: row => row.original.subDefaults !== null,
  filters: [
    [
      {
        name: "airline",
        fieldType: "asyncSelect",
        endpoint: "/airlines/",
        label: t("Airline"),
        icon: "airplane",
        size: "col-4",
      },
      {
        name: "mealType",
        fieldType: "select",
        endpoint: "/tickets/ticket-defaults/meal-types/",
        label: t("Meal type"),
        size: "col-4",
        storeChoices: true,
      },
      {
        fieldType: "applyButton",
        size: "col-auto mt-2",
      },
      {
        fieldType: "clearButton",
        size: "col-auto mt-2",
      },
    ],
  ],
  buttons: (
    <>
      {hasPermissions(["add_tickets_ticketdefault"]) && (
        <Button icon="plus" to={reverse("ticketDefaultCreate")}>
          {t("Add Ticket Default")}
        </Button>
      )}
    </>
  ),
})

export default function TicketDefaultList(props) {
  const { t } = useTranslation()

  return <TableWithFilters config={getConfig(t)} />
}
