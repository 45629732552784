import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router"
import { ReactTableDefaults } from "react-table-6"

import { getLocalStorageObj } from "utils/fs"

export const TrComponentContext = React.createContext()

export default function TrComponent({ rowInfo, SubComponent, ...rest }) {
  let history = useHistory()

  const storageName = `${history.location.pathname}-sheet`
  const storageSheetData = getLocalStorageObj(storageName) || {}

  const [isSelected, setIsSelected] = useState(
    storageSheetData.selected?.[rowInfo?.original.id] || false
  )
  const [isExpanded, setIsExpanded] = useState(
    storageSheetData.expanded?.[rowInfo?.original.id] || false
  )

  useEffect(() => {
    if (rowInfo) {
      const storageName = `${history.location.pathname}-sheet`
      const sheetData = getLocalStorageObj(storageName) || {}

      if (sheetData.expanded?.[rowInfo.original.id]) {
        setIsExpanded(true)
      }

      if (sheetData.selected?.[rowInfo.original.id]) {
        setIsSelected(true)
      }
    }
  }, [rowInfo, history.location.pathname])

  return (
    <TrComponentContext.Provider
      value={{ isSelected, setIsSelected, isExpanded, setIsExpanded, rowInfo }}>
      <>
        <ReactTableDefaults.TrComponent {...rest} />
        {isExpanded && rowInfo ? SubComponent(rowInfo) : null}
      </>
    </TrComponentContext.Provider>
  )
}

TrComponent.propTypes = {
  rowInfo: PropTypes.object,
  SubComponent: PropTypes.func,
}

TrComponent.defaultProps = {
  rowInfo: undefined,
  SubComponent: () => {},
}
