import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

import Icon from "components/Icon/Icon"
import Popover from "components/Popover/Popover"

export default function FareCell({ type, fare, status }) {
  const { t } = useTranslation()

  return ["invoiced_booking", "invoiced_infant_booking"].includes(type) ? (
    <div className="text-right">
      <span className="mr-2">{fare.invoiced}</span>
      <Popover
        trigger="click"
        header={
          type === "invoiced_booking"
            ? t("Booking Fare")
            : t("Infant Booking Fare")
        }
        content={
          <div>
            <div className="row">
              <div className="col-auto">
                <div className="row">
                  <div className="col-auto">
                    <b>{t("Invoiced")}</b>
                  </div>
                </div>
                <div className="row">
                  <div className="col-auto text-right">{fare.invoiced}</div>
                </div>
              </div>
              <div className="col-auto">
                <div className="row">
                  <div className="col-auto">
                    <b>{t("Initial")}</b>
                  </div>
                </div>
                <div className="row">
                  <div className="col-auto text-right">{fare.initial}</div>
                </div>
              </div>
              <div className="col-auto">
                <div className="row">
                  <div className="col-auto">
                    <b>{t("Current")}</b>
                  </div>
                </div>
                <div className="row">
                  <div
                    className={`col-auto text-right ${
                      status === "no" && "text-danger"
                    } ${status === "over" && "text-warning"}`}>
                    {fare.current}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }>
        <span>
          <Icon
            name="info-circle"
            color="white"
            className="bg-blue rounded-circle"
            size="sm"
          />
        </span>
      </Popover>
    </div>
  ) : (
    <>{fare}</>
  )
}

FareCell.propTypes = {
  type: PropTypes.string,
  fare: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  status: PropTypes.string,
}

FareCell.defaultProps = {
  type: undefined,
  fare: undefined,
  status: "",
}
