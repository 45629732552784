import React from "react"
import PropTypes from "prop-types"
import { useForm, FormProvider } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"
import { isArray, isObject } from "lodash"

import Button from "components/Button/Button"
import Card from "components/Card/Card"
import Expand from "components/Expand/Expand"
import { FormField } from "components/Form"
import {
  PAGE_QUERY_PARAM,
  PAGE_SIZE_QUERY_PARAM,
  ORDERING_QUERY_PARAM,
} from "utils/constants"
import { convertIntegersToStrings } from "utils/forms"

export default function ListFilters(props) {
  const { t } = useTranslation()
  let location = useLocation()
  let history = useHistory()

  const getSearchParams = () => new URLSearchParams(location.search)

  const getDefaultValues = () => {
    const searchParams = getSearchParams()

    const defaults = {}
    searchParams.forEach((value, key) => {
      if (
        ![
          PAGE_QUERY_PARAM,
          PAGE_SIZE_QUERY_PARAM,
          ORDERING_QUERY_PARAM,
        ].includes(key)
      ) {
        if (value === "true") {
          defaults[key] = true
        } else if (value === "false") {
          defaults[key] = false
        } else {
          defaults[key] = value
        }
      }
    })

    return convertIntegersToStrings(defaults)
  }

  const formInstance = useForm({ defaultValues: getDefaultValues() })

  const updateSearchParams = values => {
    const searchParams = getSearchParams()

    Object.entries(values).forEach(([k, v]) => {
      // select fields have object as a value
      if (isArray(v)) {
        searchParams.set(k, v.map(obj => obj.id).join(","))
      } else if (isObject(v) && v.id !== undefined) {
        searchParams.set(k, v.id)
      } else {
        searchParams.set(k, v)
      }
    })

    // when filters change reset page param to default if on lists
    if (!props.isSheet) {
      searchParams.set(PAGE_QUERY_PARAM, 1)
    }

    history.push("?" + searchParams.toString())
  }

  const clearFilters = () => {
    const searchParams = getSearchParams()

    // Delete all filter fields related query params
    for (const [key] of getSearchParams()) {
      if (
        ![
          PAGE_QUERY_PARAM,
          PAGE_SIZE_QUERY_PARAM,
          ORDERING_QUERY_PARAM,
        ].includes(key)
      ) {
        searchParams.delete(key)
      }
    }

    const formValues = Object.entries(formInstance.getValues())
    const values = {}
    formValues.forEach(([k, v]) => {
      if (typeof v == "boolean") {
        values[k] = false
      } else {
        values[k] = ""
      }
    })
    formInstance.reset(values)

    history.push("?" + searchParams.toString())
  }

  // eslint-disable-next-line react/prop-types
  const renderItem = ({ size, ...item }) => {
    switch (item.fieldType) {
      case "applyButton":
        return (
          <Button submit className="mt-2 w-100">
            {item.label || t("Apply filters")}
          </Button>
        )
      case "clearButton":
        return (
          <Button className="mt-2 w-100" onClick={clearFilters}>
            {item.label || t("Clear filters")}
          </Button>
        )
      default:
        return <FormField {...item} />
    }
  }

  const renderFilters = rows =>
    rows.map((row, i) => (
      <div className={`row ${i !== 0 ? "mt-3" : ""}`} key={`row-${i}`}>
        {row.map((item, j) => (
          <div className={item.size} key={`item-${j}`}>
            {item.children ? renderFilters(item.children) : renderItem(item)}
          </div>
        ))}
      </div>
    ))

  return (
    <>
      {props.isSheet ? (
        <div className="row mt-4 mb-2">
          <div className="col">
            <h6>{t("Filters")}</h6>
          </div>
          <div className="ml-auto col-auto">
            <div>
              <Expand target="#filters" />
            </div>
          </div>
        </div>
      ) : (
        <h6 className="mt-5">{t("Filters")}</h6>
      )}
      <Card
        className={`mb-3 p-4 ${props.isSheet ? "collapse show" : ""}`}
        bodyClassName="py-0"
        id="filters">
        <FormProvider {...formInstance}>
          <form
            onSubmit={formInstance.handleSubmit(data =>
              updateSearchParams(data)
            )}>
            {renderFilters(props.items)}
          </form>
        </FormProvider>
      </Card>
    </>
  )
}

ListFilters.propTypes = {
  items: PropTypes.array.isRequired,
  isSheet: PropTypes.bool,
}

ListFilters.defaultProps = {
  isSheet: false,
}
