import React, { Component } from "react"
import classnames from "classnames"
import { withTranslation } from "react-i18next"
import ReactSelect from "react-select"

import Icon from "components/Icon/Icon"
import {
  commonStyles,
  commonComponents,
} from "components/Form/fields/simple/Select/common"

import "./Pagination.scss"

/*
  This component is overriden in order to add first and last page buttons and update the design
*/

/* eslint-disable react/prop-types */
class Pagination extends Component {
  static defaultProps = {
    renderPageJump: ({
      onChange,
      value,
      onBlur,
      onKeyPress,
      inputType,
      pageJumpText,
    }) => (
      <div className="-pageJump">
        <input
          aria-label={pageJumpText}
          type={inputType}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
        />
      </div>
    ),
    renderCurrentPage: page => <span className="-currentPage">{page + 1}</span>,
    renderTotalPagesCount: pages => (
      <span className="-totalPages">{pages || 1}</span>
    ),
    renderPageSizeOptions: ({
      pageSize,
      pageSizeOptions,
      rowsSelectorText,
      onPageSizeChange,
      rowsText,
      t,
    }) => (
      <ReactSelect
        hideSelectedOptions
        blurInputOnSelect
        placeholder=""
        styles={{
          ...commonStyles(),
          control: (provided, selectState) => ({
            ...commonStyles().control(provided, selectState),
            minHeight: "2.45rem",
            height: "2.45rem",
            top: "0.125rem",
          }),
          valueContainer: provided => ({ ...provided, minWidth: "5rem" }),
          indicatorsContainer: undefined,
        }}
        onChange={selected => onPageSizeChange(Number(selected.value))}
        className="d-inline-flex ml-4"
        components={commonComponents(null, {})}
        // here we are using `value` and `label` instead of `id` and `displayName` because
        // this is ReactSelect and not FormField component
        defaultValue={{ value: pageSize, label: `${t("Show")} ${pageSize}` }}
        menuPlacement="top"
        isSearchable={false}
        options={pageSizeOptions.map((option, i) => ({
          value: option,
          label: `${t("Show")} ${option}`,
        }))}
      />
    ),
  }

  constructor(props) {
    super(props)

    this.getSafePage = this.getSafePage.bind(this)
    this.changePage = this.changePage.bind(this)
    this.applyPage = this.applyPage.bind(this)

    this.state = {
      page: props.page,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.page !== this.props.page ||
      prevState.page !== this.state.page
    ) {
      // this is probably safe because we only update when old/new props/state.page are different
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        page: this.props.page,
      })
    }
    /* when the last page from new props is smaller
     than the current page in the page box,
     the current page needs to be the last page. */
    if (
      this.props.pages !== prevProps.pages &&
      this.props.pages <= this.state.page
    ) {
      this.setState({
        page: this.props.pages - 1,
      })
    }
  }

  getSafePage(page) {
    if (Number.isNaN(page)) {
      page = this.props.page
    }
    return Math.min(Math.max(page, 0), this.props.pages - 1)
  }

  changePage(page) {
    page = this.getSafePage(page)
    this.setState({ page })
    if (this.props.page !== page) {
      this.props.onPageChange(page)
    }
  }

  applyPage(e) {
    if (e) {
      e.preventDefault()
    }
    const page = this.state.page
    this.changePage(page === "" ? this.props.page : page)
  }

  getPageJumpProperties() {
    return {
      onKeyPress: e => {
        if (e.which === 13 || e.keyCode === 13) {
          this.applyPage()
        }
      },
      onBlur: this.applyPage,
      value: this.state.page === "" ? "" : this.state.page + 1,
      onChange: e => {
        const val = e.target.value
        const page = val - 1
        if (val === "") {
          return this.setState({ page: val })
        }
        this.setState({ page: this.getSafePage(page) })
      },
      inputType: this.state.page === "" ? "text" : "number",
      pageJumpText: this.props.pageJumpText,
    }
  }

  render() {
    const {
      // Computed
      pages,
      // Props
      page,
      showPageSizeOptions,
      pageSizeOptions,
      pageSize,
      showPageJump,
      canPrevious,
      canNext,
      onPageSizeChange,
      className,
      renderPageJump,
      renderCurrentPage,
      renderTotalPagesCount,
      renderPageSizeOptions,
    } = this.props

    return (
      <div
        className={classnames(
          className,
          "-pagination",
          "justify-content-center",
          "mt-4"
        )}
        style={this.props.style}>
        {canPrevious && this.state.page >= 1 && (
          <div className="text-right my-auto mr-5">
            <Icon
              name="chevron-left-double"
              onClick={() => {
                this.changePage(0)
              }}
            />
            <Icon
              className="ml-3"
              name="chevron-left"
              onClick={() => {
                this.changePage(page - 1)
              }}
            />
          </div>
        )}
        <div>
          <b className="-pageInfo">
            {this.props.pageText}{" "}
            {showPageJump
              ? renderPageJump(this.getPageJumpProperties())
              : renderCurrentPage(page)}{" "}
            {this.props.ofText} {renderTotalPagesCount(pages)}
          </b>
          <div className="d-inline-flex mx-3">
            <input
              key={this.state.page}
              className="form-control"
              type="number"
              min="1"
              max={pages}
              defaultValue={this.state.page + 1}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  this.changePage(e.target.value - 1)
                }
              }}
            />
          </div>
          {showPageSizeOptions &&
            renderPageSizeOptions({
              pageSize,
              rowsSelectorText: this.props.rowsSelectorText,
              pageSizeOptions,
              onPageSizeChange,
              rowsText: this.props.rowsText,
              t: this.props.t,
            })}
        </div>
        {canNext && this.state.page < this.props.pages - 1 && (
          <div className="my-auto ml-5">
            <Icon
              name="chevron-right"
              className="mr-3"
              onClick={() => {
                this.changePage(page + 1)
              }}
            />
            <Icon
              className="ml-3"
              name="chevron-right-double"
              onClick={() => {
                this.changePage(this.props.pages)
              }}
            />
          </div>
        )}
      </div>
    )
  }
}

export default withTranslation()(Pagination)
/* eslint-enable react/prop-types */
