import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useFormContext, Controller, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import ReactSelect from "react-select"
import { get } from "lodash"

import { apiGET, apiStoreGET } from "utils/api"

import { commonStyles, commonComponents } from "./common"
import "./SelectField.scss"

export default function SelectField({
  onChange: onChangeProp,
  className,
  name,
  registerProps,
  defaultValue,
  endpoint,
  required,
  options,
  storeChoices,
  showError,
  ...rest
}) {
  const { errors, control, setValue } = useFormContext()
  const selectValue = useWatch({
    control,
    name,
    defaultValue,
  })
  const [state, setState] = useState({
    options: [],
    loading: false,
  })
  const [focused, setFocused] = useState(false)

  const { t } = useTranslation()
  let location = useLocation()

  useEffect(() => {
    const setPreSelectedValue = options => {
      const searchParams = new URLSearchParams(location.search)
      const paramsValue = searchParams.get(name)

      let selected
      if (paramsValue) {
        if (rest.isMulti) {
          selected = options.filter(o =>
            paramsValue.split(",").includes(`${o.id}`)
          )
        } else {
          selected = options.find(o => `${o.id}` === `${paramsValue}`)
        }

        if (selected) {
          setValue(name, selected)
          onChangeProp(selected)
        }
      }
    }

    if (options.length) {
      setPreSelectedValue(options)
      setState(prevState => ({ ...prevState, options }))
    } else if (!state.options.length && !state.loading) {
      setState(prevState => ({ ...prevState, loading: true }))
      const getFunction = storeChoices ? apiStoreGET : apiGET
      getFunction({
        path: `${endpoint}${endpoint.includes("?") ? "&" : "?"}page_size=none`,
        onSuccess: response => {
          // either `results` key is in the response or the reponse itself represents results
          let options = response.results || response
          options = options.map(option => ({
            ...option,
            id: `${option.id}`,
          }))

          setPreSelectedValue(options)
          setState({ options, loading: false })
        },
      })
    }
  }, [
    options,
    state.options,
    state.loading,
    storeChoices,
    endpoint,
    location.search,
    name,
    setValue,
    onChangeProp,
    rest.isMulti,
  ])

  return (
    <Controller
      render={({ onChange, ...restRender }) => {
        const error = get(errors, name)

        return (
          <>
            <ReactSelect
              isClearable
              hideSelectedOptions
              blurInputOnSelect
              openMenuOnFocus
              placeholder=""
              getOptionLabel={option => option.displayName}
              getOptionValue={option => `${option.id}`}
              {...restRender}
              {...rest}
              className={`${className} select-field ${
                focused || restRender.value ? "has-floating-label" : ""
              } ${error ? "is-invalid" : ""}`}
              onChange={selected => {
                if (!selected) {
                  setFocused(false)
                }
                onChangeProp(selected)
                onChange(selected === null ? "" : selected)
              }}
              onMenuOpen={() => setFocused(true)}
              onMenuClose={() => setFocused(false)}
              onInputChange={inputValue => {
                if (inputValue && selectValue) {
                  setValue(name, null)
                }
              }}
              options={state.options}
              styles={commonStyles(rest.disabled)}
              components={commonComponents(t, rest)}
            />
            {showError && error ? (
              <div className="invalid-feedback d-block">{error.message}</div>
            ) : null}
          </>
        )
      }}
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{
        ...registerProps,
        validate: value => {
          if (required && !value) {
            return t("This field is required.")
          }

          return registerProps.validate ? registerProps.validate(value) : true
        },
      }}
    />
  )
}

SelectField.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  registerProps: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  endpoint: PropTypes.string,
  required: PropTypes.bool.isRequired,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  storeChoices: PropTypes.bool,
  filterOption: PropTypes.func,
  showError: PropTypes.bool,
}

SelectField.defaultProps = {
  onChange: () => {},
  className: "",
  registerProps: {},
  defaultValue: "",
  endpoint: null,
  options: [],
  disabled: false,
  storeChoices: false,
  filterOption: undefined,
  showError: true,
}
