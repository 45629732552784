import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

import Detail from "components/GenericViews/Detail"
import { reverse } from "utils/urls"

export default function AirlineDetail(props) {
  const { t } = useTranslation()

  return (
    <Detail
      title={t("Airline details")}
      detailEndpoint={`/airlines/${props.match.params.id}/`}
      listRoute={reverse("airlineList")}
      changePermission={["change_airlines_airline"]}
      changeRoute={reverse("airlineUpdate", { id: props.match.params.id })}
      deletePermission={["delete_airlines_airline"]}
      deleteRoute={reverse("airlineDelete", { id: props.match.params.id })}
      historyRoute={reverse("history", {
        id: props.match.params.id,
        model: "airlines",
      })}
      fields={[
        [
          { label: t("Name"), key: "name", size: "col-4" },
          { label: t("Address"), key: "address", size: "col-4" },
        ],
        [
          { label: t("Code"), key: "code", size: "col-4" },
          {
            label: t("City"),
            key: "city",
            type: "singleSelect",
            size: "col-4",
          },
        ],
        [
          { label: t("Code AS400"), key: "codeAs400", size: "col-4" },
          { label: t("Zip code"), key: "zipCode", size: "col-4" },
        ],
        [
          { label: t("Color"), key: "colorCode", type: "color", size: "col-4" },
          { label: t("Web address"), key: "url", size: "col-4" },
        ],
        [{ label: t("Logo"), key: "logo", type: "logo", size: "col-4" }],
      ]}
    />
  )
}

AirlineDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
