import { isArray, isObject } from "lodash"

export const reduceSelectValues = data => {
  const reducer = innerData => {
    Object.keys(innerData).forEach(k => {
      if (isArray(innerData[k])) {
        innerData[k].forEach((d, i) => {
          if (d.id !== undefined) {
            innerData[k][i] = d.id
          } else {
            reducer(d)
          }
        })
      } else if (isObject(innerData[k])) {
        if (innerData[k].id !== undefined) {
          innerData[k] = innerData[k].id
        } else {
          reducer(innerData[k])
        }
      }
    })
  }

  const result = JSON.parse(JSON.stringify(data))
  reducer(result)
  return result
}

export const convertIntegersToStrings = data => {
  Object.keys(data).forEach(k => {
    if (typeof data[k] === "number") {
      data[k] = `${data[k]}`
    } else if (isArray(data[k])) {
      data[k].forEach((d, i) => {
        if (typeof d === "number") {
          data[k][i] = `${d}`
        } else {
          convertIntegersToStrings(d)
        }
      })
    } else if (isObject(data[k])) {
      convertIntegersToStrings(data[k])
    }
  })
  return data
}
