import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { getEnum } from "apps/core/utils"
import Avatar from "components/Avatar/Avatar"
import Icon from "components/Icon/Icon"
import Popover from "components/Popover/Popover"
import StatusDot from "components/StatusDot/StatusDot"
import { SubTable, TableWithFilters } from "components/Table"
import { DATE_FORMAT } from "utils/constants"

const getConfig = (t, tableConfig, actionsColumn) => ({
  ...tableConfig,
  columns: [
    {
      Header: t("Segment"),
      id: "segment",
      accessor: f => f.active.route.displayName,
    },
    {
      Header: t("Carrier"),
      id: "carrier",
      accessor: f => (
        <Avatar
          image={f.active.airline.logo}
          title={f.active.airline.displayName}
          alt={f.active.airline.code}
        />
      ),
    },
    {
      Header: () => (
        <div>
          <StatusDot className="d-inline-block bg-primary mr-1" />
          <span>{t("Surcharge")}</span>
          <span className="mx-1">/</span>
          <StatusDot className="d-inline-block bg-magenta mr-1" />
          <span>{t("Tax")}</span>
        </div>
      ),
      id: "category",
      accessor: f => (
        <div>
          {f.active.category.type.id === getEnum("feeType").SURCHARGE ? (
            <StatusDot className="d-inline-block bg-primary mr-1" />
          ) : (
            <StatusDot className="d-inline-block bg-magenta mr-1" />
          )}
          <span className="ml-1">{f.active.category.name}</span>
        </div>
      ),
    },
    {
      Header: t("Period"),
      id: "period",
      accessor: f =>
        `${f.active.startDate} - ${f.active.endDate || t("Unlimited")}`,
      sortable: false,
    },
    {
      Header: t("Value"),
      id: "value",
      accessor: f => f.active.price,
      sortable: false,
      className: "text-right",
    },
    actionsColumn,
  ],
  SubComponent: row => (
    <SubTable
      data={row.original.fees}
      columns={[
        {
          Header: t("Period"),
          accessor: "startDate",
          Cell: subRow =>
            subRow.original.id === row.original.active.id ? (
              <span className="text-danger">{`${subRow.original.startDate} - ${
                subRow.original.endDate || t("Unlimited")
              }`}</span>
            ) : (
              <>{`${subRow.original.startDate} - ${
                subRow.original.endDate || t("Unlimited")
              }`}</>
            ),
          sortMethod: (a, b, desc) => {
            a = moment(a, DATE_FORMAT)
            b = moment(b, DATE_FORMAT)

            if (a.isBefore(b)) {
              return 1
            } else if (a.isAfter(b)) {
              return -1
            }

            return 0
          },
          parentColumnIndex: 3,
        },
        {
          Header: t("Value"),
          accessor: "value",
          className: "text-right",
          Cell: subRow =>
            subRow.original.tourOperatorsGroupFees.length ? (
              <>
                <span>{subRow.original.price}</span>
                <Popover
                  header={t("Tour Operator Specific")}
                  trigger="hover"
                  content={
                    <div>
                      <div className="row text-danger">
                        <div className="col-6 text-center">{t("General")}</div>
                        <div className="col-6 text-center">
                          {subRow.original.price}
                        </div>
                      </div>
                      {subRow.original.tourOperatorsGroupFees.map(fee =>
                        fee.tourOperators.map((tourOperator, i) => (
                          <div className="row" key={i}>
                            <div className="col-6 text-center">
                              {tourOperator.code}
                            </div>
                            <div className="col-6 text-center">{fee.price}</div>
                          </div>
                        ))
                      )}
                    </div>
                  }>
                  <span>
                    <Icon name="info-circle" size="sm" className="ml-2 mb-1" />
                  </span>
                </Popover>
              </>
            ) : (
              subRow.original.price
            ),
          parentColumnIndex: 4,
        },
        actionsColumn,
      ]}
      diagonalColumnWidth={[0, 1, 2]}
    />
  ),
  filters: [
    [
      {
        size: "col",
        children: [
          [
            {
              name: "route",
              fieldType: "asyncSelect",
              endpoint: "/routings/routes/",
              label: t("Segment"),
              size: "col-3",
            },
            {
              name: "airline",
              fieldType: "asyncSelect",
              endpoint: "/airlines/",
              icon: "airplane",
              label: t("Carrier"),
              size: "col-3",
            },
            {
              name: "category",
              fieldType: "asyncSelect",
              endpoint: "/fees/categories/",
              label: t("Category"),
              size: "col-3",
            },
            {
              fieldType: "applyButton",
              size: "col-auto mt-2",
            },
            {
              fieldType: "clearButton",
              size: "col-auto mt-2",
            },
          ],
        ],
      },
    ],
  ],
})

export default function FeeList(props) {
  const { t } = useTranslation()

  return (
    <TableWithFilters
      config={getConfig(t, props.tableConfig, props.actionsColumn)}
    />
  )
}

FeeList.propTypes = {
  tableConfig: PropTypes.object,
  actionsColumn: PropTypes.object,
}

FeeList.defaultProps = {
  tableConfig: {},
  actionsColumn: null,
}
