export const validateTicketForm = (values, t) => {
  const errors = []

  if (!values.passengers || values.passengers.length === 0) {
    errors.push(t("At lease one passenger must be added."))
  } else {
    const adultReferences = values.passengers
      .filter(p => p.passengerType === "ADULT")
      .map(p => p.familyReference)

    values.passengers.forEach(p => {
      if (
        ["CHILD", "INFANT", "PETC"].includes(p.passengerType) &&
        !adultReferences.includes(p.familyReference)
      ) {
        errors.push(
          (["CHILD", "INFANT"].includes(p.passengerType)
            ? `${p.familyReference} ${p.firstName} ${p.lastName} - `
            : `${p.familyReference} ${p.petType.displayName} - `) +
            t("there is no adult with matching family reference.")
        )
      }
    })
  }

  return errors.length ? errors : true
}
