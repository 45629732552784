import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import SectionHeader from "components/SectionHeader/SectionHeader"

export default function TicketInfo({ ticketDefault }) {
  const { t } = useTranslation()

  return (
    <>
      <SectionHeader header={t("Ticket info")} />
      <div className="d-flex align-items-center py-2 px-3 bg-light my-3 justify-content-center">
        <b>{t("Catering")}</b>
      </div>
      <div className="d-flex px-3 pb-2 border-bottom border-light justify-content-center">
        {ticketDefault?.mealType?.displayName || "-"}
      </div>
      <div className="d-flex align-items-center py-2 px-3 bg-light my-3">
        <div className="row">
          <div className="col-4">
            <b>
              {t("Baggage Allowance")} -{" "}
              <span className="text-magenta">{t("Per Pax")}</span>
            </b>
          </div>
          <div className="col-8">
            <div className="row">
              <div className="col-3 text-center">
                <b>{t("Piece(s)")}</b>
              </div>
              <div className="col-3 text-center">
                <b>{t("Weight")}</b>
              </div>
              <div className="col-6 text-center">
                <b>{t("Dimensions")}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex px-3 pb-2 border-bottom border-light">
        <div className="row">
          <div className="col-4">{t("Cabin baggage")}</div>
          <div className="col-8">
            <div className="row">
              <div className="col-3 text-center">
                {ticketDefault?.cabinbaggage?.pieces || "-"}
              </div>
              <div className="col-3 text-center">
                {ticketDefault?.cabinbaggage?.weight
                  ? `${ticketDefault?.cabinbaggage?.weight}kg`
                  : "-"}
              </div>
              <div className="col-6 text-center">
                {ticketDefault?.cabinbaggage?.maxDimensions || "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex px-3 py-2 border-bottom border-light">
        <div className="row">
          <div className="col-4">{t("Cabin accessory")}</div>
          <div className="col-8">
            <div className="row">
              <div className="col-3 text-center">
                {ticketDefault?.cabinaccessory?.pieces || "-"}
              </div>
              <div className="col-3 text-center">
                {ticketDefault?.cabinaccessory?.weight
                  ? `${ticketDefault?.cabinaccessory?.weight}kg`
                  : "-"}
              </div>
              <div className="col-6 text-center">
                {ticketDefault?.cabinaccessory?.maxDimensions || "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
      {ticketDefault?.totalCabinWeight && (
        <div className="d-flex px-3 py-2 border-bottom border-light">
          <div className="row">
            <div className="col-4">{t("Cabin baggage + accessory")}</div>
            <div className="col-8">
              <div className="row">
                <div className="offset-3 col-3 text-center">
                  {ticketDefault.totalCabinWeight}kg
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="d-flex px-3 py-2 border-bottom border-light">
        <div className="row">
          <div className="col-4">{t("Checked baggage")}</div>
          <div className="col-8">
            <div className="row">
              <div className="col-3 text-center">
                {ticketDefault?.checkedbaggage?.pieces || "-"}
              </div>
              <div className="col-3 text-center">
                {ticketDefault?.checkedbaggage?.weight
                  ? `${ticketDefault?.checkedbaggage?.weight}kg`
                  : "-"}
              </div>
              <div className="col-6 text-center">
                {ticketDefault?.checkedbaggage?.maxDimensions || "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

TicketInfo.propTypes = {
  ticketDefault: PropTypes.object,
}

TicketInfo.defaultProps = {
  ticketDefault: {},
}
