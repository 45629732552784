import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext, useWatch } from "react-hook-form"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import ReactTable from "react-table-6"

import { hasPermissions } from "apps/auth/auth"
import FeeCell from "./FeeCell"
import { COLUMN_WIDTHS } from "apps/sheets/columns"
import { COLUMN_WIDTHS as INVOICE_WORKING_SHEET_COLUMN_WIDTHS } from "apps/sheets/InvoiceWorkingSheet/columns"
import Checkbox from "components/Checkbox/Checkbox"
import {
  EXPAND_COLUMN_WIDTH,
  SELECT_COLUMN_WIDTH,
} from "components/Sheet/Sheet"
import { apiStoreGET } from "utils/api"
import { notify } from "utils/notifications"
import { reverse, buildRouteFromLocation } from "utils/urls"
import FareCell from "./FareCell"

export default function InvoiceWorkingSheetSegmentDetails(props) {
  const [data, setData] = useState()
  const { t } = useTranslation()
  let history = useHistory()

  useEffect(() => {
    if (data === undefined) {
      let isSubscribed = true

      const searchParams = new URLSearchParams(history.location.search)
      const tourOperarorId = searchParams.get("tourOperator")

      apiStoreGET({
        ...(props.apiGETNamespace ? { namespace: props.apiGETNamespace } : {}),
        path: `/sheets/invoice-working-sheet/${props.id}/segment-details/?tourOperator=${tourOperarorId}`,
        onSuccess: response => {
          if (isSubscribed) {
            setData(response)
          }
        },
        onError: () =>
          notify(
            "error",
            t("There were some errors while trying to get segment details.")
          ),
      })

      return () => (isSubscribed = false)
    }
  }, [data, props.id, t, props.apiGETNamespace, history.location.search])

  const getStatusClassName = (row, types) => {
    if (types.includes(row.original.type)) {
      const status = row.original.status
      if (status === "no") {
        return { className: "text-danger bg-red-light" }
      } else if (status === "over") {
        return { className: "text-warning bg-orange-light" }
      }
    }
    return {}
  }

  return data ? (
    <ReactTable
      minRows={0}
      showPagination={false}
      filterable={false}
      resizable={false}
      sortable={false}
      noDataText=""
      data={data.objects}
      pageSize={data.objects.length}
      style={{ marginTop: 0 }}
      getTheadProps={() => ({ style: { display: "none" } })}
      getTdProps={() => ({ style: { textAlign: "center" } })}
      columns={[
        { width: SELECT_COLUMN_WIDTH },
        { width: COLUMN_WIDTHS.weekNo },
        { width: COLUMN_WIDTHS.localDepartureDate },
        { width: COLUMN_WIDTHS.operatingDay },
        { width: COLUMN_WIDTHS.route },
        { width: COLUMN_WIDTHS.flightNo },
        ...(props.expandFlightDetails
          ? [{ width: COLUMN_WIDTHS.etd }, { width: COLUMN_WIDTHS.eta }]
          : []),
        {
          id: "select",
          accessor: obj =>
            ["booking", "infant_booking", "segment_tax"].includes(obj.type) &&
            hasPermissions(["add_invoices_touroperatorinvoice"]) ? (
              <SelectInvoiceItem segmentId={props.id} item={obj} />
            ) : null,
          width: COLUMN_WIDTHS.empty,
        },
        {
          id: "reference",
          accessor: obj =>
            [
              "booking",
              "invoiced_booking",
              "infant_booking",
              "invoiced_infant_booking",
            ].includes(obj.type) && (
              <Link
                to={reverse(
                  ["booking", "invoiced_booking"].includes(obj.type)
                    ? "tourOperatorBookingDetail"
                    : "ticketDetail",
                  {
                    id:
                      obj.type === "booking"
                        ? obj.id
                        : obj.type === "invoiced_booking"
                        ? obj.bookingId
                        : obj.ticketId,
                  },
                  {
                    fromSheet: "true",
                    next: buildRouteFromLocation(history.location),
                  }
                )}>
                {obj.reference}
              </Link>
            ),
        },
        {
          id: "type",
          accessor: obj => {
            if (["booking", "invoiced_booking"].includes(obj.type)) {
              return obj.bookingType.id
            } else if (["surcharge", "invoiced_surcharge"].includes(obj.type)) {
              return "SUR"
            } else if (
              ["tax", "segment_tax", "invoiced_tax"].includes(obj.type)
            ) {
              return "TAX"
            } else if (
              ["infant_booking", "invoiced_infant_booking"].includes(obj.type)
            ) {
              return "INF"
            } else {
              throw new Error(`${obj.type} is a unknown type.`)
            }
          },
          width: INVOICE_WORKING_SHEET_COLUMN_WIDTHS.type,
        },
        {
          id: "quantity",
          accessor: obj => obj.seats,
          width: INVOICE_WORKING_SHEET_COLUMN_WIDTHS.quantity,
        },
        {
          id: "fare",
          accessor: obj => (
            <FareCell type={obj.type} fare={obj.fare} status={obj.status} />
          ),
          getProps: (_, row) =>
            getStatusClassName(row, [
              "invoiced_booking",
              "invoiced_infant_booking",
            ]),
          className: "text-right",
        },
        {
          id: "surcharge",
          accessor: obj =>
            ["surcharge", "invoiced_surcharge"].includes(obj.type) && (
              <FeeCell
                total={obj.total}
                header={t("Surcharges")}
                objects={obj.fees}
                isDetailed={obj.type === "invoiced_surcharge"}
              />
            ),
          getProps: (_, row) => getStatusClassName(row, ["invoiced_surcharge"]),
        },
        {
          id: "tax",
          accessor: obj =>
            ["tax", "segment_tax", "invoiced_tax"].includes(obj.type) && (
              <FeeCell
                total={obj.total}
                header={t("Taxes")}
                objects={obj.fees}
                isDetailed={obj.type === "invoiced_tax"}
              />
            ),
          getProps: (_, row) => getStatusClassName(row, ["invoiced_tax"]),
        },
        {
          id: "status",
          accessor: obj =>
            [
              "invoiced_booking",
              "invoiced_surcharge",
              "invoiced_tax",
              "invoiced_infant_booking",
            ].includes(obj.type) && (
              <Link
                to={reverse(
                  "tourOperatorInvoiceDetail",
                  {
                    id: obj.invoiceId,
                  },
                  {
                    fromSheet: "true",
                    next: buildRouteFromLocation(history.location),
                  }
                )}>
                {obj.invoiceReference}
              </Link>
            ),
          width: INVOICE_WORKING_SHEET_COLUMN_WIDTHS.status,
        },
        {
          width: EXPAND_COLUMN_WIDTH,
        },
      ]}
    />
  ) : null
}

InvoiceWorkingSheetSegmentDetails.propTypes = {
  id: PropTypes.number.isRequired,
  apiGETNamespace: PropTypes.string,
  expandFlightDetails: PropTypes.bool.isRequired,
}

InvoiceWorkingSheetSegmentDetails.defaultProps = {
  apiGETNamespace: undefined,
}

function SelectInvoiceItem({ segmentId, item }) {
  const { register, control, setValue, getValues } = useFormContext()

  const selectAllName = `segment-${segmentId}`
  const name = `${selectAllName}-${item.seats < 0 ? "credit-note" : ""}${
    item.seats > 0 ? "invoice" : ""
  }-${item.type}-${item.id}`

  // for some reason, useWatch doesn't return correct values, but getValues does
  // but if I remove useWatch then getValues value is not correctly updated
  // :shrug:
  useWatch({
    control,
    name: selectAllName,
    defaultValue: "",
  })
  const value = getValues()[selectAllName] || ""

  const selectedItems = new Set(value.split(",").filter(item => item !== ""))

  return (
    <>
      <input name={name} ref={register} type="hidden" />
      <Checkbox
        onChange={e => {
          const newSelectedItems = new Set(selectedItems)
          if (e.target.checked) {
            newSelectedItems.add(name)
          } else {
            newSelectedItems.delete(name)
          }
          setValue(selectAllName, Array.from(newSelectedItems).join(","))
          window.dispatchEvent(new Event("invoiceitemselected"))
        }}
        checked={selectedItems.has(name)}
      />
    </>
  )
}

SelectInvoiceItem.propTypes = {
  segmentId: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
}
