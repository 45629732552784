/* API */
export const API_URL = process.env.REACT_APP_API_URL
export const TOKEN_COOKIE = "sedes_auth_token"
export const AUTH_INFO_URL = "/auth/info/"
export const APP_INFO_URL = "/app-info/"

/* Tables */
export const PAGE_QUERY_PARAM = "page"
export const PAGE_SIZE_QUERY_PARAM = "page_size"
export const ORDERING_QUERY_PARAM = "ordering"
export const DEFAULT_PAGE_SIZE = 25
export const PAGE_SIZE_OPTIONS = [25, 50, 100, 200]

/* Filters */
export const EMPTY_CHOICE_LABEL = "---------"

/* Datetime */
export const DATE_FORMAT = "DD MMM YYYY"
export const TIME_FORMAT = "HH:mm"
export const DATE_TIME_FORMAT = "DD MMM YYYY HH:mm"

/* Forms */
export const SHOW_DEV_TOOL = false
export const LOGO_CROP_SIZE = { height: 256, width: 256 }

/* Misc */
export const REQUIRE_HORIZONTAL_SCROLL = ["sheets"]
