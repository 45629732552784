import React, { useEffect, useReducer } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Form, FormField } from "components/Form"
import { apiPUT, apiGET, apiPOST } from "utils/api"
import { reverse } from "utils/urls"

export default function CityForm(props) {
  const reducer = (state, action) => {
    switch (action.type) {
      case "setFormData":
        return {
          ...state,
          formData: action.formData,
          selectedCountry: action.formData.country,
        }
      case "setCountry":
        return { ...state, selectedCountry: action.country }
      case "setCountryCities":
        return {
          ...state,
          countryCities: { loading: action.loading, results: action.results },
        }
      default:
        throw new Error()
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    formData: {},
    countryCities: {
      results: [],
      loading: false,
    },
    selectedCountry: null,
  })

  const { t } = useTranslation()

  useEffect(
    () =>
      props.isUpdate
        ? apiGET({
            path: `/cities/${props.match.params.id}/`,
            onSuccess: response =>
              dispatch({ type: "setFormData", formData: response }),
            onError: () => props.history.push(reverse("cityList")),
          })
        : () => {},
    [props.isUpdate, props.match.params.id, props.history]
  )

  useEffect(() => {
    if (state.selectedCountry) {
      dispatch({ type: "setCountryCities", loading: true, results: [] })
      apiGET({
        path: `/cities/?country=${state.selectedCountry.id}&page_size=none`,
        onSuccess: response =>
          dispatch({
            type: "setCountryCities",
            loading: false,
            results: response,
          }),
      })
    }
  }, [state.selectedCountry])

  return (
    <div>
      <Form
        endpoint={props.actionEndpoint}
        apiFunction={props.isUpdate ? apiPUT : apiPOST}
        defaultValues={state.formData}
        successUrl={props.successUrl}
        successMessage={t("City was successfully saved.")}
        title={props.isUpdate ? t("Edit city") : t("Add city")}>
        <div className="row">
          <div className="col-4">
            <FormField
              name="name"
              fieldType="input"
              type="text"
              label={t("Name")}
              maxLength={50}
              required
            />
          </div>
          <div className="col-4">
            <FormField
              name="code"
              fieldType="input"
              type="text"
              label={t("Code")}
              maxLength={3}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <FormField
              name="country"
              fieldType="asyncSelect"
              label={t("Country")}
              required
              endpoint="/countries/"
              onChange={selected =>
                dispatch({ type: "setCountry", country: selected })
              }
            />
          </div>
          {state.selectedCountry && (
            <div className="col-4">
              <div
                className="alert alert-info overflow-auto my-2"
                style={{ maxHeight: "14rem" }}
                role="alert">
                {state.countryCities.loading ? (
                  <div className="spinner-border" role="status"></div>
                ) : state.countryCities.results.length !== 0 ? (
                  <div>
                    <span>
                      {`${state.countryCities.results.length} ${
                        state.countryCities.results.length === 1
                          ? t("city")
                          : t("cities")
                      } ${t("associated with the selected country")}:`}
                    </span>
                    {state.countryCities.results.map(city => (
                      <div key={city.name}>{city.name}</div>
                    ))}
                  </div>
                ) : (
                  <div>
                    {t("No cities are associated with the selected country.")}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Form>
    </div>
  )
}

CityForm.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string,
    }),
  }).isRequired,
  isUpdate: PropTypes.bool,
  actionEndpoint: PropTypes.string.isRequired,
  successUrl: PropTypes.string.isRequired,
}

CityForm.defaultProps = {
  isUpdate: false,
}
