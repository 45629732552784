import React, { Fragment, useEffect, useState } from "react"
import { getGlobal, setGlobal } from "reactn"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"
import { useLastLocation } from "react-router-last-location"
import ReactTable, { ReactTableDefaults } from "react-table-6"
import { useDeepCompareEffect, useDeepCompareCallback } from "use-deep-compare"

import ListFilters from "components/ListFilters/ListFilters"
import { Form } from "components/Form"
import { apiGET } from "utils/api"
import {
  getLocalStorageObj,
  removeLocalStorageObj,
  setLocalStorageObj,
} from "utils/fs"
import Expander from "./Expander"
import SelectCell from "./SelectCell"
import TdComponent from "./TdComponent"
import TrComponent from "./TrComponent"

export const SELECT_COLUMN_WIDTH = 50
export const EXPAND_COLUMN_WIDTH = 50

export default function Sheet({
  endpoint,
  requiredFilters,
  apiGETNamespace,
  SubComponent,
  title,
  filters,
  headerLevels,
  showExpander,
  formProps,
  ...rest
}) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [collapseRows, setCollapseRows] = useState(false)
  const [storageSheetData, setStorageSheetData] = useState()

  const { t } = useTranslation()

  let location = useLocation()
  let history = useHistory()
  const lastLocation = useLastLocation() || {}

  useEffect(() => {
    const currentLocation = history.location.pathname
    return history.listen(nextLocation => {
      if (nextLocation.search.includes("fromSheet")) {
        const storageName = `${currentLocation}-sheet`
        const sheetData = getLocalStorageObj(storageName) || {}
        sheetData.scrollX = window.pageXOffset
        sheetData.scrollY = window.pageYOffset
        setLocalStorageObj(storageName, sheetData)
      } else {
        removeLocalStorageObj(`${currentLocation}-sheet`)
      }
    })
  }, [history])

  useEffect(() => {
    if (lastLocation.search && lastLocation.search.includes("fromSheet")) {
      const sheetData = getLocalStorageObj(`${history.location.pathname}-sheet`)
      setStorageSheetData(sheetData)
    }
  }, [history.location.pathname, lastLocation.search])

  const getSearchParams = useDeepCompareCallback(
    () => new URLSearchParams(location.search),
    [location.search]
  )

  const hasRequiredFilters = useDeepCompareCallback(() => {
    let hasFilters = true
    const searchParams = getSearchParams().toString()
    requiredFilters.forEach(filter => {
      if (!searchParams.includes(filter)) {
        hasFilters = false
      }
    })
    return hasFilters
  }, [getSearchParams, requiredFilters])

  const fetchResults = useDeepCompareCallback(() => {
    setCollapseRows(true)
    setData([])

    // clear all stored responses from expanded rows on every filter change
    const apiStore = getGlobal().apiStore
    if (apiGETNamespace && apiGETNamespace in apiStore) {
      delete apiStore[apiGETNamespace]
      setGlobal({ apiStore })
    }

    apiGET({
      path: `${endpoint}/${location.search}`,
      onSuccess: response => {
        setData(response)
        setLoading(false)
        setCollapseRows(false)
        if (
          !lastLocation.search ||
          !lastLocation.search.includes("fromSheet")
        ) {
          removeLocalStorageObj(`${history.location.pathname}-sheet`)
        }
      },
    })
  }, [
    apiGETNamespace,
    endpoint,
    location.search,
    history.location.pathname,
    lastLocation.search,
  ])

  useDeepCompareEffect(() => {
    if (location.search && hasRequiredFilters()) {
      setLoading(true)
      setData([])
      fetchResults()
    }
  }, [location.search, hasRequiredFilters, fetchResults])

  useEffect(() => {
    if (
      data.length &&
      (storageSheetData?.scrollX || storageSheetData?.scrollY)
    ) {
      window.scrollTo(
        storageSheetData?.scrollX || 0,
        storageSheetData?.scrollY || 0
      )
    }
  }, [data.length, storageSheetData])

  const addSelectColumn = cols => [
    {
      Header: () => <></>,
      headerStyle: { backgroundColor: "white" },
      columns: [
        {
          Header: () => <></>,
          headerStyle: { backgroundColor: "white" },
          Cell: () => <SelectCell />,
          sortable: false,
          resizable: false,
          width: SELECT_COLUMN_WIDTH,
          className: "rt-select",
          style: { textAlign: "center" },
        },
      ],
    },
    ...cols,
  ]

  const addExpandColumn = cols => [
    ...cols,
    {
      headerClassName: "rt-expand-header",
      width: EXPAND_COLUMN_WIDTH,
      columns: [
        {
          className: "rt-expand",
          headerClassName: "rt-expand-header",
          Cell: row => (showExpander(row) ? <Expander /> : null),
          style: {
            cursor: "pointer",
            fontSize: 25,
            padding: "0",
            textAlign: "center",
            userSelect: "none",
          },
          width: EXPAND_COLUMN_WIDTH,
        },
      ],
    },
  ]

  let { columns: tableColumns } = rest

  if (data.length) {
    tableColumns = addSelectColumn(tableColumns)
  }

  if (SubComponent) {
    tableColumns = addExpandColumn(tableColumns)
  }

  const SheetContainer = formProps !== undefined ? Form : Fragment

  return (
    <>
      <div className="row">
        <div className="col">
          <h1>{title}</h1>
        </div>
      </div>
      <ListFilters items={filters} isSheet />
      {hasRequiredFilters() && (
        <SheetContainer {...(formProps || {})}>
          <div className="mb-3">
            <ReactTable
              manual
              columns={tableColumns}
              minRows={0}
              data={data}
              showPagination={false}
              loading={loading}
              filterable={false}
              resizable={false}
              sortable={false}
              collapseOnDataChange={collapseRows}
              NoDataComponent={() => (
                <div>{!loading ? t("No results found.") : ""}</div>
              )}
              getTableProps={() => ({ style: { overflow: "visible" } })}
              getTbodyProps={() => ({ style: { overflow: "visible" } })}
              getTheadGroupProps={() => ({
                style: {
                  backgroundColor: "white",
                  borderBottom: "0.25rem solid white",
                },
              })}
              getTheadGroupThProps={() => ({ style: { textAlign: "center" } })}
              getTheadThProps={() => ({ style: { textAlign: "center" } })}
              getTdProps={() => ({ style: { textAlign: "center" } })}
              getTrProps={(_, rowInfo) => ({
                rowInfo,
                SubComponent,
              })}
              getTfootTdProps={() => ({ style: { textAlign: "center" } })}
              getTheadTrProps={() => ({ style: { backgroundColor: "white" } })}
              getTheadGroupTrProps={() => ({
                style: { backgroundColor: "white" },
              })}
              TdComponent={TdComponent}
              TrComponent={TrComponent}
              TableComponent={({ children, ...restProps }) => {
                const newChildren = [...children]
                const headers = newChildren.splice(0, headerLevels)

                return (
                  <ReactTableDefaults.TableComponent {...restProps}>
                    <div className="sticky-top">{headers}</div>
                    {newChildren}
                  </ReactTableDefaults.TableComponent>
                )
              }}
              TfootComponent={props =>
                loading ? null : (
                  <ReactTableDefaults.TfootComponent {...props} />
                )
              }
              LoadingComponent={() =>
                loading ? (
                  <div className="mt-2 text-center">
                    <div
                      className="spinner-border"
                      style={{ width: "3rem", height: "3rem" }}
                      role="status"></div>
                  </div>
                ) : null
              }
            />
          </div>
          {formProps !== undefined && (
            <div className="row mt-5">
              <div className="ml-auto col-auto">
                <>{formProps.submitComponent}</>
              </div>
            </div>
          )}
        </SheetContainer>
      )}
    </>
  )
}

Sheet.propTypes = {
  columns: PropTypes.array.isRequired,
  endpoint: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired,
  requiredFilters: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  SubComponent: PropTypes.func,
  headerLevels: PropTypes.number.isRequired,
  apiGETNamespace: PropTypes.string,
  showExpander: PropTypes.func,
  formProps: PropTypes.object,
}

Sheet.defaultProps = {
  requiredFilters: [],
  SubComponent: undefined,
  apiGETNamespace: undefined,
  showExpander: () => true,
  formProps: undefined,
}
