import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Form, FormField } from "components/Form"
import { apiPUT, apiGET } from "utils/api"
import { reverse } from "utils/urls"

export default function RoutingUpdate(props) {
  const [formData, setFormData] = useState({})

  const { t } = useTranslation()

  useEffect(
    () =>
      apiGET({
        path: `/routings/${props.match.params.id}/`,
        onSuccess: response => setFormData(response),
        onError: () => props.history.push(reverse("routingList")),
      }),
    [props.match.params.id, props.history]
  )

  return (
    <div>
      <Form
        endpoint={`/routings/${props.match.params.id}/`}
        apiFunction={apiPUT}
        defaultValues={formData}
        successUrl={reverse("routingDetail", { id: props.match.params.id })}
        successMessage={t("Routing was successfully saved.")}
        title={t("Edit routing")}>
        <div className="row">
          <div className="col-auto">
            <FormField
              name="useAlt"
              fieldType="checkbox"
              label={t("Use alternative routing name")}
            />
          </div>
        </div>
      </Form>
    </div>
  )
}

RoutingUpdate.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string,
    }),
  }).isRequired,
}
