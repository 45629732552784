import React from "react"

class Home extends React.Component {
  render() {
    return (
      <main ref="main" className="container">
        <div className="row p-5">
          <div className="col-12 text-center">
            <p>Welcome to SEDES</p>
          </div>
        </div>
      </main>
    )
  }
}

export default Home
