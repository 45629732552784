import Login from "apps/auth/Login/Login"
import {
  ForgotPassword,
  PasswordReset,
  PasswordChange,
} from "apps/auth/Password"
import {
  ManagePermissions,
  UserCreate,
  UserUpdate,
  UserDelete,
  UserDetail,
  UserList,
} from "apps/auth/User"
import { reverse, route } from "utils/urls"

export const ROUTES = [
  { path: reverse("login"), component: Login, exact: true },
  { path: reverse("forgotPassword"), component: ForgotPassword, exact: true },
  { path: reverse("passwordReset"), component: PasswordReset, exact: true },
]

export const PROTECTED_ROUTES = [
  route("managePermissions", ManagePermissions, ["view_users_user"]),
  route("passwordChange", PasswordChange, ["view_users_user"]),
  route("userCreate", UserCreate, ["add_users_user"]),
  route("userUpdate", UserUpdate, ["change_users_user"]),
  route("userDelete", UserDelete, ["delete_users_user"]),
  route("userDetail", UserDetail, ["view_users_user"]),
  route("userList", UserList, ["view_users_user"]),
]
