import React from "react"
import { useTranslation } from "react-i18next"

import BookingFareForm from "apps/fares/BookingFare/BookingFareForm"
import { reverse } from "utils/urls"

export default function TourOperatorBookingFareCreate(props) {
  const { t } = useTranslation()

  return (
    <BookingFareForm
      {...props}
      actionEndpoint="/fares/tour-operator-fares/"
      label={t("Add Tour Operator booking fare")}
      successMessage={t("Tour Operator Booking Fare was successfully saved.")}
      successUrl={reverse("tourOperatorBookingFareList")}
      isTourOperator
    />
  )
}
