import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

import { getAuthInfo, handleLogout } from "apps/auth/auth"
import Delete from "components/GenericViews/Delete"
import { reverse } from "utils/urls"

export default function UserDelete(props) {
  const { t } = useTranslation()

  return (
    <Delete
      modelName={t("User")}
      endpoint={`/auth/users/${props.match.params.id}/`}
      successRoute={reverse("userList")}
      onSuccess={data => {
        if (data.email === getAuthInfo().email) {
          handleLogout()
        }
      }}
      errorRoute={reverse("userDetail", { id: props.match.params.id })}
    />
  )
}

UserDelete.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
