import {
  TourOperatorInvoiceDetail,
  TourOperatorInvoiceDelete,
  TourOperatorInvoiceSend,
  TourOperatorInvoiceInvertTaxes,
  TourOperatorInvoiceNullify,
  TourOperatorInvoiceNullifyRecreate,
  TourOperatorInvoiceList,
  TourOperatorInvoiceItemDelete,
} from "apps/invoices/TourOperatorInvoice"
import { route } from "utils/urls"

export const PROTECTED_ROUTES = [
  route("tourOperatorInvoiceDeleteInvoiceItem", TourOperatorInvoiceItemDelete, [
    "change_invoices_touroperatorinvoice",
  ]),
  route("tourOperatorInvoiceDelete", TourOperatorInvoiceDelete, [
    "delete_invoices_touroperatorinvoice",
  ]),
  route("tourOperatorInvoiceSend", TourOperatorInvoiceSend, [
    "send_invoices_touroperatorinvoice",
  ]),
  route("tourOperatorInvoiceInvertTaxes", TourOperatorInvoiceInvertTaxes, [
    "change_invoices_touroperatorinvoice",
  ]),
  route("tourOperatorInvoiceNullify", TourOperatorInvoiceNullify, [
    "nullify_invoices_touroperatorinvoice",
  ]),
  route(
    "tourOperatorInvoiceNullifyRecreate",
    TourOperatorInvoiceNullifyRecreate,
    ["nullify_invoices_touroperatorinvoice", "add_invoices_touroperatorinvoice"]
  ),
  route("tourOperatorInvoiceDetail", TourOperatorInvoiceDetail, [
    "view_invoices_touroperatorinvoice",
  ]),
  route("tourOperatorInvoiceList", TourOperatorInvoiceList, [
    "view_invoices_touroperatorinvoice",
  ]),
]
