import React from "react"
import PropTypes from "prop-types"
import { Link as RouterLink } from "react-router-dom"

import "./Icon.scss"

const Icon = ({ name, size, color, className, ...rest }) => {
  return (
    <i
      className={`icon icon-${name} icon-${size} text-${color} ${className}`}
      {...rest}></i>
  )
}

const Link = ({ to, linkClassName, ...rest }) => {
  return to ? (
    <RouterLink to={to} className={`icon-link ${linkClassName}`}>
      <Icon {...rest} />
    </RouterLink>
  ) : (
    <Icon {...rest} />
  )
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
}

Icon.defaultProps = {
  color: "primary",
  size: "md",
  className: "",
}

Link.propTypes = {
  ...Icon.propTypes,
  to: PropTypes.string.isRequired,
  linkClassName: PropTypes.string,
}

Link.defaultProps = { ...Icon.defaultProps, linkClassName: "" }

Icon.Link = Link

export default Icon
