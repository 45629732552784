import React from "react"
import PropTypes from "prop-types"
import { useFormContext, useFieldArray } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { getEnum } from "apps/core/utils"
import Button from "components/Button/Button"
import { FormField } from "components/Form"

export default function CarrierFormSet(props) {
  const { control } = useFormContext()
  const { fields, remove, append } = useFieldArray({
    control,
    name: `${props.namePrefix}.carriers`,
  })

  const CARRIER_TYPE_ENUM = getEnum("carrierType")

  const { t } = useTranslation()

  const getName = (i, name) => {
    return `${props.namePrefix}.carriers[${i}].${name}`
  }

  return (
    <>
      {fields.map((field, k) => (
        <div className="row" key={field.id}>
          <div className="col-10 col-lg-6">
            <div className="row">
              <FormField
                name={getName(k, "carrierType")}
                fieldType="input"
                type="hidden"
                label=""
                defaultValue={
                  k === 0
                    ? CARRIER_TYPE_ENUM.OPERATING
                    : CARRIER_TYPE_ENUM.MARKETING
                }
              />
              <div className="col-5">
                <FormField
                  name={getName(k, "airline")}
                  fieldType="asyncSelect"
                  icon="airplane"
                  label={t("Select carrier")}
                  endpoint="/airlines/"
                  required
                  defaultValue={field.airline}
                  filterOption={option =>
                    !props.carriers
                      .filter(c => c.airline)
                      .map(c => c.airline.id)
                      .includes(option.value)
                  }
                />
              </div>
              <div className="col-4">
                <FormField
                  name={getName(k, "flightNo")}
                  fieldType="flightNo"
                  required
                  defaultValue={field.flightNo}
                />
              </div>
              <div className="col-3 my-auto">
                <p className="mb-0">{`${
                  k === 0 ? t("Operating Carrier") : t("Marketing Carrier")
                }`}</p>
              </div>
            </div>
          </div>
          <div className="col-auto ml-auto my-auto">
            {k === 0 && (
              <Button
                icon="plus"
                type="secondary"
                className="text-nowrap"
                onClick={append}>
                {t("Add carrier")}
              </Button>
            )}
            {k !== 0 && (
              <Button
                icon="remove"
                type="danger"
                className="text-nowrap"
                onClick={() => {
                  remove(k)
                }}>
                {t("Delete carrier")}
              </Button>
            )}
          </div>
        </div>
      ))}
    </>
  )
}

CarrierFormSet.propTypes = {
  namePrefix: PropTypes.string,
  carriers: PropTypes.array,
}

CarrierFormSet.defaultProps = {
  namePrefix: "",
  carriers: [],
}
