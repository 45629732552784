import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useHistory, useRouteMatch } from "react-router"
import { isEmpty } from "lodash"

import { hasPermissions } from "apps/auth/auth"
import { getSeats, getReleaseDate } from "apps/bookings/utils"
import { getEnum } from "apps/core/utils"
import FlightDetails from "apps/flights/FlightDetails/FlightDetails"
import Avatar from "components/Avatar/Avatar"
import Button from "components/Button/Button"
import SectionHeader from "components/SectionHeader/SectionHeader"
import FieldDetail from "components/FieldDetail/FieldDetail"
import Popover from "components/Popover/Popover"
import { apiGET } from "utils/api"
import BookingEvolutionModal from "./BookingEvolutionModal"
import CNXBooking from "./CNXBooking"
import ManageBooking from "./ManageBooking"

export default function BookingDetail({
  isAirline,
  detailEndpoint,
  listRoute,
  changeRoute,
  deleteRoute,
  ...rest
}) {
  const [data, setData] = useState({})

  const { t } = useTranslation()
  let history = useHistory()
  let match = useRouteMatch()

  const BOOKING_TYPE_ENUM = getEnum("bookingType")

  useEffect(
    () =>
      apiGET({
        path: detailEndpoint,
        onSuccess: response => setData(response),
        onError: () => history.push(listRoute),
      }),
    [detailEndpoint, history, listRoute]
  )

  return !isEmpty(data) ? (
    <>
      <div className="row">
        <div className="col">
          <h1>
            {isAirline ? t("Airline") : "TO"} {t("Booking summary")} -{" "}
            <span className="text-magenta">{data.reference}</span>
            {!isAirline ? (
              data.isInvoiced ? (
                <span className="text-danger"> - [{t("Invoiced")}]</span>
              ) : (
                <span className="text-blue-light">
                  {" "}
                  - [{t("Not invoiced")}]
                </span>
              )
            ) : (
              ""
            )}
          </h1>
        </div>
        <div className="col-auto">
          <Button icon="edit" to={changeRoute}>
            {t("Update")}
          </Button>
          {!data.isInvoiced &&
            ((isAirline &&
              hasPermissions(["delete_bookings_airlinebooking"])) ||
              (!isAirline &&
                hasPermissions(["delete_bookings_touroperatorbooking"]))) && (
              <Button
                className="ml-2"
                icon="remove"
                type="danger"
                to={deleteRoute}>
                {t("Delete")}
              </Button>
            )}
          <Button
            className="ml-2"
            type="warning"
            icon="evolution"
            modalTarget="#evolutionModal">
            {t("Evolution")}
          </Button>
          <BookingEvolutionModal data={data} />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-7">
          <FlightDetails data={data.flightDetails} />
        </div>
        <div className="col-5">
          <SectionHeader header={t("Booking info")} />
          <div className="row mt-4">
            <div className="col-6">
              <FieldDetail
                label={t("Received date")}
                value={data.receivedDate}
              />
            </div>
            <div className="col-6">
              <FieldDetail
                label={t("Invoice date")}
                value={
                  <>
                    {data.invoiceDate.date}{" "}
                    <span className="text-magenta">
                      (D-{data.invoiceDate.dateOffset})
                    </span>
                  </>
                }
              />
            </div>
          </div>
          {data.requestReference ? (
            <div className="row">
              <div className="col-6">
                <FieldDetail
                  label={t("Request reference")}
                  value={
                    <a
                      href={data.requestReference}
                      target="_blank"
                      rel="noopener noreferrer">
                      {t("Link")}
                    </a>
                  }
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-7">
          <SectionHeader header={t("Booking details")} />
          <div className="row mt-3">
            <div className="col-6">
              <div className="row mt-4">
                <div className="col-5 text-center">
                  <Avatar
                    image={
                      isAirline ? data.airline.logo : data.tourOperator.logo
                    }
                    title={
                      isAirline
                        ? data.airline.displayName
                        : data.tourOperator.displayName
                    }
                    alt={isAirline ? data.airline.code : data.tourOperator.code}
                    size={80}
                    className="mx-auto"
                  />
                  <small>
                    {isAirline
                      ? data.airline.displayName
                      : data.tourOperator.displayName}
                  </small>
                </div>
                <div className="col-5">
                  <FieldDetail
                    label={t("Booking Type")}
                    value={
                      <b className="text-uppercase text-magenta">
                        {data.type.displayName}
                      </b>
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-3">
                  <FieldDetail label={t("Name")} value={data.name || "-"} />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <FieldDetail label={t("Note")} value={data.note || "-"} />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row mt-4">
                <div className="col">
                  <FieldDetail
                    label={t("Seats")}
                    value={getSeats(data, BOOKING_TYPE_ENUM)}
                  />
                </div>
                {((data.type.id === BOOKING_TYPE_ENUM.ALLOTMENT &&
                  !data.split1Done) ||
                  (data.type.id === BOOKING_TYPE_ENUM.GIR &&
                    !data.split2Done) ||
                  (data.type.id === BOOKING_TYPE_ENUM.GROUP &&
                    data.seats.confirmedSeats > 0 &&
                    !data.finesHavePassed)) &&
                  ((isAirline &&
                    hasPermissions(["change_bookings_airlinebooking"])) ||
                    (!isAirline &&
                      hasPermissions([
                        "change_bookings_touroperatorbooking",
                      ]))) && (
                    <Popover
                      header={t("Manage booking")}
                      contentClassName="py-3 px-2"
                      content={
                        <ManageBooking
                          id={match.params.id}
                          isAirline={isAirline}
                          data={data}
                          setData={setData}
                        />
                      }>
                      <div className="col-auto my-auto">
                        <Button type="info" icon="check-list" />
                      </div>
                    </Popover>
                  )}
                {data.seats.confirmedSeats !== 0 &&
                  ((isAirline &&
                    hasPermissions([
                      "superuser_change_bookings_airlinebooking",
                    ])) ||
                    (!isAirline &&
                      hasPermissions([
                        "superuser_change_bookings_touroperatorbooking",
                      ]))) && (
                    <Popover
                      header={t("Special CNX booking")}
                      contentClassName="py-3 px-2"
                      content={
                        <CNXBooking
                          id={match.params.id}
                          isAirline={isAirline}
                          data={data}
                          setData={setData}
                        />
                      }>
                      <div className="col-auto my-auto">
                        <Button type="warning" icon="calendar-remove" />
                      </div>
                    </Popover>
                  )}
                <div className="col-12">
                  <FieldDetail
                    label={t("Fare")}
                    value={data.totalFare}
                    valueClassName="text-right"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-5">
          {[
            BOOKING_TYPE_ENUM.ALLOTMENT,
            BOOKING_TYPE_ENUM.GIR,
            BOOKING_TYPE_ENUM.GROUP,
          ].includes(data.type.id) && (
            <div className="col-12">
              <SectionHeader header={t("Conditions")} />
              {data.type.id === BOOKING_TYPE_ENUM.ALLOTMENT && (
                <div className="row mt-4">
                  <div className="col-12">
                    <FieldDetail
                      label={t("Block access")}
                      value={
                        data.blockAccess.hasPassed ? (
                          <span className="text-danger">
                            {data.blockAccess.date}{" "}
                            <span className="text-magenta">UTC</span>
                          </span>
                        ) : (
                          <>
                            {data.blockAccess.date}{" "}
                            <span className="text-magenta">UTC</span>
                          </>
                        )
                      }
                    />
                  </div>
                  <div className="col-12">
                    <FieldDetail
                      label={t("Allotment type release date")}
                      value={getReleaseDate(data.releaseDate1)}
                    />
                  </div>
                </div>
              )}
              {data.type.id === BOOKING_TYPE_ENUM.GIR && (
                <>
                  <div className="row mt-4">
                    <div className="col-12">
                      <FieldDetail
                        label={t("Block access")}
                        value={
                          data.blockAccess.hasPassed ? (
                            <span className="text-danger">
                              {data.blockAccess.date}{" "}
                              <span className="text-magenta">UTC</span>
                            </span>
                          ) : (
                            <>
                              {data.blockAccess.date}{" "}
                              <span className="text-magenta">UTC</span>
                            </>
                          )
                        }
                      />
                    </div>
                    <div className="col-6">
                      <FieldDetail
                        label={t("GIR type 1st release date")}
                        value={getReleaseDate(data.releaseDate1)}
                      />
                    </div>
                    <div className="col-6">
                      <FieldDetail
                        label={t("GIR seat release percentage")}
                        value={data.releasePercentage}
                        inputGroupAppend={<span>%</span>}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <FieldDetail
                        label={t("GIR type 2nd release date")}
                        value={getReleaseDate(data.releaseDate2)}
                      />
                    </div>
                  </div>
                </>
              )}
              {data.type.id === BOOKING_TYPE_ENUM.GROUP && (
                <>
                  <div className="row mt-4">
                    <div className="col-6">
                      <span className="px-2">{t("Days")}</span>
                    </div>
                    <div className="col-3">
                      <span className="px-2">{t("Penalty")}</span>
                    </div>
                    <div className="col-3">
                      <span className="px-2">{t("Free CNX")}</span>
                    </div>
                  </div>
                  {data.fines.map(fine => (
                    <div className="row" key={fine.id}>
                      <div className="col-6">
                        <FieldDetail value={fine.applicableDatetime} />
                      </div>
                      <div className="col-3">
                        <FieldDetail value={fine.penalty} />
                      </div>
                      <div className="col-3">
                        <FieldDetail
                          value={`${fine.freeCancelationSeats} ${t("Seats")}`}
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <Button
            icon="chevron-left-double"
            onClick={
              history.location.search.includes("next")
                ? () =>
                    history.push(
                      new URLSearchParams(history.location.search).get("next")
                    )
                : () => history.goBack()
            }>
            {t("Back")}
          </Button>
        </div>
      </div>
    </>
  ) : null
}

BookingDetail.propTypes = {
  detailEndpoint: PropTypes.string.isRequired,
  listRoute: PropTypes.string.isRequired,
  changeRoute: PropTypes.string.isRequired,
  deleteRoute: PropTypes.string.isRequired,
  isAirline: PropTypes.bool,
}

BookingDetail.defaultProps = {
  isAirline: false,
}
