import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { isEmpty } from "lodash"

import { hasPermissions } from "apps/auth/auth"
import Avatar from "components/Avatar/Avatar"
import Button from "components/Button/Button"
import FieldDetail from "components/FieldDetail/FieldDetail"
import Icon from "components/Icon/Icon"
import Tooltip from "components/Tooltip/Tooltip"
import { apiGET } from "utils/api"
import { reverse } from "utils/urls"

export default function FlightSeriesDetail(props) {
  const [data, setData] = useState({})
  const { t } = useTranslation()
  let history = useHistory()

  useEffect(
    () =>
      apiGET({
        path: `/flights/series/${props.match.params.id}`,
        onSuccess: response => setData(response),
        onError: () => history.push(reverse("flightSeriesList")),
      }),
    [history, props.match.params.id]
  )

  return !isEmpty(data) ? (
    <>
      <h1>
        {t("Flight Series Details")} - {data.displayName}
      </h1>
      <h4 className="mt-5">{t("Period")}</h4>
      <div className="row">
        <div className="col-auto">
          <FieldDetail
            label={t("Date from - Date to")}
            value={`${data.periodStart} - ${data.periodEnd}`}
          />
        </div>
        <div className="col-auto">
          <FieldDetail
            label={t("Operating days")}
            value={data.daysOfOperations.join(", ")}
          />
        </div>
      </div>

      <h4 className="mt-5">{t("Leg(s)")}</h4>
      <div className="row">
        {data.flightLegs.map((flightLeg, i) => (
          <Fragment key={i}>
            {i !== 0 && (
              <div className="col-12">
                <hr className="text-primary" />
              </div>
            )}
            <div className="col-12 my-3">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="row">
                    <div className="col">
                      <FieldDetail
                        label={t("Departure airport")}
                        value={flightLeg.departureAirport.displayName}
                        inputGroupPrepend={
                          <div className="mr-2 pt-1">
                            <Icon name="airplane-departure" size="lg" />
                          </div>
                        }
                      />
                    </div>
                    <div className="col-auto">
                      <FieldDetail
                        label={t("Departure time")}
                        value={flightLeg.departureTime}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="row">
                    <div className="col">
                      <FieldDetail
                        label={t("Arrival airport")}
                        value={flightLeg.arrivalAirport.displayName}
                        inputGroupPrepend={
                          <div className="mr-2 pt-1">
                            <Icon name="airplane-arrival" size="lg" />
                          </div>
                        }
                      />
                    </div>
                    <div className="col-auto">
                      <FieldDetail
                        label={t("Arrival time")}
                        value={flightLeg.arrivalTime}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-4">
                  <FieldDetail
                    label={t("Airline")}
                    value={flightLeg.operatingCarrier.airline.displayName}
                    inputGroupPrepend={
                      <div className="mr-2 pt-2">
                        <Avatar
                          image={flightLeg.operatingCarrier.airline.logo}
                          title={flightLeg.operatingCarrier.airline.displayName}
                          alt={flightLeg.operatingCarrier.airline.code}
                        />
                      </div>
                    }
                  />
                </div>
                <div className="col-6 col-lg-2">
                  <FieldDetail
                    label={
                      <>
                        {t("Flight")} N<sup>o</sup>
                      </>
                    }
                    value={flightLeg.operatingCarrier.flightNo}
                  />
                </div>
                <div className="col-6 col-lg-2">
                  <FieldDetail
                    label={t("Carrier type")}
                    value={t("Operating")}
                  />
                </div>
                {flightLeg.marketingCarriers.map((carrier, j) => (
                  <div className="col-12" key={j}>
                    <div className="row">
                      <div className="col-12 col-lg-4">
                        <FieldDetail
                          label={
                            <span className="d-lg-none">{t("Airline")}</span>
                          }
                          value={carrier.airline.displayName}
                          inputGroupPrepend={
                            <div className="mr-2 pt-2">
                              <Avatar
                                image={carrier.airline.logo}
                                title={carrier.airline.displayName}
                                alt={carrier.airline.code}
                              />
                            </div>
                          }
                        />
                      </div>
                      <div className="col-6 col-lg-2">
                        <FieldDetail
                          label={
                            <span className="d-lg-none">
                              <>
                                {t("Flight")} N<sup>o</sup>
                              </>
                            </span>
                          }
                          value={carrier.flightNo}
                        />
                      </div>
                      <div className="col-6 col-lg-2">
                        <FieldDetail
                          label={
                            <span className="d-lg-none">
                              {t("Carrier type")}
                            </span>
                          }
                          value={t("Marketing")}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Fragment>
        ))}
      </div>

      <h4 className="mt-5 mb-3">{t("Segments")}</h4>
      <div className="row">
        {data.flightSegments.map((flightSegment, i) => (
          <div className="row" key={i}>
            <div className="col-auto">
              <b>{flightSegment.route.displayName}</b>
            </div>
            <div className="col-auto">
              {flightSegment.isSaleable ? (
                <Tooltip title={t("Selected for sale")}>
                  <Icon name="check" />
                </Tooltip>
              ) : (
                <Tooltip title={t("Not selected for sale")}>
                  <Icon name="remove" />
                </Tooltip>
              )}
            </div>
          </div>
        ))}
      </div>

      {hasPermissions(["delete_flights_flight"]) && (
        <div className="my-2 mt-4">
          <Button
            icon="remove"
            type="danger"
            to={reverse("flightSeriesDelete", { id: props.match.params.id })}>
            {t("Delete")}
          </Button>
        </div>
      )}
      <Button
        className="mt-2"
        icon="chevron-left-double"
        onClick={() => history.goBack()}>
        {t("Back")}
      </Button>
    </>
  ) : null
}

FlightSeriesDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
