import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation, Trans } from "react-i18next"
import { useHistory } from "react-router-dom"
import { isEmpty } from "lodash"

import Button from "components/Button/Button"
import { apiDELETE, apiGET } from "utils/api"
import { redirectWithNotify } from "utils/notifications"
import { reverse } from "utils/urls"

export default function TicketDefaultDelete(props) {
  const [data, setData] = useState({})
  const { t } = useTranslation()
  let history = useHistory()

  const modelName = t("Ticket Default")
  const { displayName } = data

  const endpoint = `/tickets/ticket-defaults/${props.match.params.id}/`

  useEffect(
    () =>
      apiGET({
        path: endpoint,
        onSuccess: response => setData(response),
        onError: () => history.push(reverse("home")),
      }),
    [endpoint, history]
  )

  const onSubmit = e => {
    e.preventDefault()
    apiDELETE({
      path: endpoint,
      onSuccess: () => {
        const successMessage = (
          <Trans i18nKey="deleteSuccessMessage">
            {{ modelName }} <b>{{ displayName }}</b> was successfully deleted.
          </Trans>
        )
        redirectWithNotify(
          reverse("ticketDefaultList"),
          "success",
          successMessage
        )
      },
      onError: () => {
        const errorMessage = (
          <Trans i18nKey="deleteErrorMessage">
            There were errors while trying to delete {{ modelName }}{" "}
            <b>{{ displayName }}</b>.
          </Trans>
        )
        redirectWithNotify(
          reverse("ticketDefaultDetail", { id: props.match.params.id }),
          "error",
          errorMessage
        )
      },
    })
  }

  return !isEmpty(data) ? (
    <>
      <Trans i18nKey="deleteTitle">
        <h1>Delete {{ modelName }}</h1>
      </Trans>
      <form method="post" onSubmit={onSubmit}>
        <Trans i18nKey="deleteConfirmMessage">
          <p>
            Are you sure you want to delete {{ modelName }}{" "}
            <b>{{ displayName }}</b>?
          </p>
        </Trans>
        {data.subDefaults !== null && (
          <Trans i18nKey="deleteTicketDefaultConfirmMessage">
            <p>
              All of the following related items will be deleted:
              <ul>
                {data.subDefaults.map(sd => (
                  <li>{sd}</li>
                ))}
              </ul>
            </p>
          </Trans>
        )}
        <div className="my-2">
          <Button submit>{t("Delete")}</Button>
        </div>
        <div>
          <Button onClick={() => history.goBack()}>{t("Back")}</Button>
        </div>
      </form>
    </>
  ) : null
}

TicketDefaultDelete.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
