import React from "react"
import { useTranslation } from "react-i18next"

import { hasPermissions } from "apps/auth/auth"
import Button from "components/Button/Button"
import Tooltip from "components/Tooltip/Tooltip"
import Icon from "components/Icon/Icon"
import { actionsColumn, TableWithFilters } from "components/Table"
import { reverse } from "utils/urls"

const getConfig = t => ({
  endpoint: "/airports",
  title: t("Airports"),
  tableName: "airports-table-config",
  columns: [
    {
      Header: t("Name"),
      accessor: "name",
    },
    {
      Header: t("City"),
      id: "city",
      accessor: t => t.city.displayName,
    },
    { Header: t("IATA Code"), accessor: "codeIata" },
    { Header: t("ICAO Code"), accessor: "codeIcao" },
    actionsColumn(obj => (
      <>
        <Tooltip title={t("Airport Details")}>
          <Icon.Link
            name="eye"
            color="gray"
            to={reverse("airportDetail", { id: obj.id })}
          />
        </Tooltip>
        <Tooltip title={t("Airport History")}>
          <Icon.Link
            name="history"
            color="gray"
            to={reverse("history", { id: obj.id, model: "airports" })}
            linkClassName="ml-3"
          />
        </Tooltip>
        <Tooltip title={t("Edit Airport")}>
          <Icon.Link
            name="edit"
            to={reverse("airportUpdate", { id: obj.id })}
            linkClassName="ml-3"
          />
        </Tooltip>
        <Tooltip title={t("Delete Airport")}>
          <Icon.Link
            name="remove"
            color="danger"
            to={reverse("airportDelete", { id: obj.id })}
            linkClassName="ml-3"
          />
        </Tooltip>
      </>
    )),
  ],
  filters: [
    [
      {
        name: "q",
        fieldType: "input",
        type: "text",
        label: t("Name/IATA/ICAO"),
        size: "col-4",
      },
      {
        fieldType: "applyButton",
        size: "col-auto mt-2",
      },
      {
        fieldType: "clearButton",
        size: "col-auto mt-2",
      },
    ],
  ],
  buttons: (
    <>
      {hasPermissions(["add_airports_airport"]) && (
        <Button icon="plus" to={reverse("airportCreate")}>
          {t("Add Airport")}
        </Button>
      )}
    </>
  ),
})

export default function AirportList(props) {
  const { t } = useTranslation()

  return <TableWithFilters config={getConfig(t)} />
}
