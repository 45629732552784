import React, { Fragment, useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { useSegmentSelectionType } from "apps/wizardForms/SegmentSelection"
import Checkbox from "components/Checkbox/Checkbox"
import Expand from "components/Expand/Expand"

import "./GroupedSegments.scss"

export default function GroupedSegments({
  departureAirport,
  arrivalAirport,
  segments,
  defaultValues,
}) {
  const segmentSelectionType = useSegmentSelectionType()
  const { t } = useTranslation()
  const { register, setValue } = useFormContext()
  const [selectedSegments, setSelectedSegments] = useState({})

  const getGroupId = useCallback(
    (i, j, k) =>
      `group-${departureAirport.id}-${arrivalAirport.id}-${i}-${j}-${k}`,
    [departureAirport.id, arrivalAirport.id]
  )

  const getOperatingDayId = day =>
    `operating-${day}-${departureAirport.id}-${arrivalAirport.id}`

  useEffect(() => {
    if (defaultValues) {
      const newSelectedSegments = {}
      Object.values(segments.objects).forEach((flightNumbers, i) => {
        Object.values(flightNumbers).forEach((periods, j) => {
          Object.values(periods).forEach((s, k) => {
            const selected = new Set()
            s.forEach(segment => {
              if (defaultValues[`segment-${segment.id}`] === "true") {
                selected.add(segment.id)
              }
            })
            newSelectedSegments[getGroupId(i, j, k)] = selected
          })
        })
      })
      setSelectedSegments(newSelectedSegments)
    }
  }, [defaultValues, getGroupId, segments.objects])

  return (
    <div className="row">
      <div className="col-12">
        <div className="d-flex px-4 align-items-center segments-header bg-gray-light">
          <div className="row">
            <div className="col">
              <b>
                {`${departureAirport.codeIata} - ${arrivalAirport.codeIata}`}
              </b>
            </div>
            <div className="ml-auto col-auto">
              <b>{`${segments.count} ${t("segments found")}`}</b>
            </div>
          </div>
        </div>
      </div>
      {Object.entries(segments.objects).map(
        ([operatingDay, flightNumbers], i) => (
          <Fragment key={operatingDay}>
            <div className="col-12 my-3">
              <div className="d-flex align-items-center px-2 segments-operating-day">
                <b>{`${t("Operating day")}: `}</b>
                <b className="ml-2 text-uppercase text-magenta">
                  {operatingDay}
                </b>
                <Expand
                  color="magenta"
                  target={`.${getOperatingDayId(operatingDay)}`}
                  defaultExpanded
                />
              </div>
            </div>
            <div className="col-12">
              <div
                className={`row collapse show ${getOperatingDayId(
                  operatingDay
                )}`}>
                {Object.entries(flightNumbers).map(([flightNo, periods], j) => (
                  <Fragment key={flightNo}>
                    {Object.entries(periods).map(
                      ([period, periodSegments], k) => (
                        <Fragment key={period}>
                          <div className="col-12">
                            <div className="px-2">
                              <div className="row">
                                {segmentSelectionType !== "ticket" && (
                                  <div className="col-auto">
                                    <Checkbox
                                      onChange={e => {
                                        const selected = new Set()
                                        e.target.checked
                                          ? periodSegments.forEach(segment =>
                                              selected.add(segment.id)
                                            )
                                          : selected.clear()
                                        periodSegments.forEach(segment =>
                                          setValue(
                                            `segments.segment-${segment.id}`,
                                            e.target.checked
                                          )
                                        )
                                        setSelectedSegments(prevState => ({
                                          ...prevState,
                                          [getGroupId(i, j, k)]: selected,
                                        }))
                                      }}
                                      checked={
                                        selectedSegments[getGroupId(i, j, k)] &&
                                        selectedSegments[getGroupId(i, j, k)]
                                          .size === periodSegments.length
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                )}
                                <div className="col">
                                  <div className="row">
                                    <div className="col-3">{flightNo}</div>
                                    <div className="col-7 text-center">
                                      {period}
                                    </div>
                                    <div className="col text-right">
                                      {selectedSegments[getGroupId(i, j, k)]
                                        ? selectedSegments[getGroupId(i, j, k)]
                                            .size
                                        : 0}
                                      /{periodSegments.length}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-auto">
                                  <Expand
                                    color="magenta"
                                    target={`.${getGroupId(i, j, k)}`}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div
                              className={`row collapse ${getGroupId(i, j, k)}`}>
                              {periodSegments.map((segment, l) => (
                                <div className="col-12" key={segment.id}>
                                  <div
                                    className={`px-5 ${l === 0 ? "mt-2" : ""}`}>
                                    <div className="row">
                                      <div className="col-auto">
                                        <input
                                          name={`segments.segment-${segment.id}`}
                                          type="hidden"
                                          ref={register}
                                        />
                                        <Checkbox
                                          onChange={e => {
                                            const selected = new Set(
                                              selectedSegments[
                                                getGroupId(i, j, k)
                                              ]
                                            )
                                            if (e.target.checked) {
                                              selected.add(segment.id)
                                            } else {
                                              selected.delete(segment.id)
                                            }
                                            setSelectedSegments(prevState => ({
                                              ...prevState,
                                              [getGroupId(i, j, k)]: selected,
                                            }))
                                            setValue(
                                              `segments.segment-${segment.id}`,
                                              e.target.checked
                                            )
                                          }}
                                          checked={
                                            selectedSegments[
                                              getGroupId(i, j, k)
                                            ] &&
                                            selectedSegments[
                                              getGroupId(i, j, k)
                                            ].has(segment.id)
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                      <div className="col-3">
                                        {segment.localDepartureDate}
                                      </div>
                                      <div className="col-4">
                                        {`${segment.localDepartureTime} - ${segment.localArrivalTime}`}
                                      </div>
                                      {l !== periodSegments.length - 1 && (
                                        <div className="col-12">
                                          <hr className="my-2 segments-hr" />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          {j !== Object.keys(flightNumbers).length - 1 && (
                            <div className="col-12">
                              <hr className="my-2 segments-hr" />
                            </div>
                          )}
                        </Fragment>
                      )
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
          </Fragment>
        )
      )}
    </div>
  )
}

GroupedSegments.propTypes = {
  departureAirport: PropTypes.shape({
    id: PropTypes.string.isRequired,
    codeIata: PropTypes.string.isRequired,
  }).isRequired,
  arrivalAirport: PropTypes.shape({
    id: PropTypes.string.isRequired,
    codeIata: PropTypes.string.isRequired,
  }).isRequired,
  segments: PropTypes.exact({
    objects: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  defaultValues: PropTypes.object,
}

GroupedSegments.defaultProps = {
  defaultValues: undefined,
}
