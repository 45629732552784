export const COLUMN_WIDTHS = {
  empty: 50,
  basic: 95,

  optionalTypes: 100,

  weekNo: 80,
  localDepartureDate: 130,
  operatingDay: 50,
  route: 110,
  flightNo: 100,
  etd: 80,
  eta: 80,

  delta: 80,
}

export const emptyColumn = {
  Header: "",
  headerStyle: { backgroundColor: "white" },
  width: COLUMN_WIDTHS.empty,
  columns: [
    {
      Header: "",
      width: COLUMN_WIDTHS.empty,
      headerStyle: { backgroundColor: "white" },
    },
  ],
}
