import React from "react"
import PropTypes from "prop-types"

import AirportForm from "apps/airports/Airport/AirportForm"
import { reverse } from "utils/urls"

export default function AirportUpdate(props) {
  return (
    <AirportForm
      {...props}
      isUpdate
      actionEndpoint={`/airports/${props.match.params.id}/`}
      successUrl={reverse("airportDetail", { id: props.match.params.id })}
    />
  )
}

AirportUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
