import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"

import Avatar from "components/Avatar/Avatar"
import Tooltip from "components/Tooltip/Tooltip"
import Icon from "components/Icon/Icon"
import { actionsColumn, TableWithFilters } from "components/Table"
import { fileGET } from "utils/api"
import { reverse, buildRouteFromLocation } from "utils/urls"
import { hasPermissions } from "apps/auth/auth"

const getConfig = (t, location) => ({
  endpoint: "/invoices/tour-operator-invoices",
  title: t("Tour Operator Invoices"),
  tableName: "tour-operator-invoices-table-config",
  columns: [
    {
      Header: t("Reference"),
      accessor: "reference",
    },
    {
      Header: t("Created"),
      accessor: "created",
    },
    {
      Header: t("TO"),
      id: "tourOperator",
      accessor: b => (
        <Avatar
          image={b.tourOperator.logo}
          title={b.tourOperator.displayName}
          alt={b.tourOperator.code}
        />
      ),
      width: "4.5%",
    },
    {
      Header: t("Total Seats"),
      accessor: "paxCost",
      className: "text-right",
      sortable: false,
    },
    {
      Header: t("Total SUR"),
      accessor: "surCost",
      className: "text-right",
      sortable: false,
    },
    {
      Header: t("Total TAX"),
      accessor: "taxCost",
      className: "text-right",
      sortable: false,
    },
    {
      Header: t("Total INF"),
      accessor: "infCost",
      className: "text-right",
      sortable: false,
    },
    {
      Header: t("Total Ex. VAT"),
      accessor: "totalCost",
      className: "text-right",
      sortable: false,
    },
    {
      Header: t("Total Incl. VAT"),
      accessor: "totalInvoice",
      className: "text-right",
      sortable: false,
    },
    actionsColumn(obj => (
      <>
        <Tooltip title={t("Invoice Details")}>
          <Icon.Link
            name="eye"
            color="gray"
            to={reverse(
              "tourOperatorInvoiceDetail",
              { id: obj.id },
              { next: buildRouteFromLocation(location) }
            )}
          />
        </Tooltip>
        <Tooltip title={t("Download Invoice PDF")}>
          <Icon
            name="pdf"
            onClick={() =>
              fileGET(`/invoices/tour-operator-invoices/${obj.id}/pdf/`)
            }
            type="info"
            style={{ cursor: "pointer" }}
            className="ml-3"
          />
        </Tooltip>
        {!obj.isSent &&
        hasPermissions(["send_invoices_touroperatorinvoice"]) ? (
          <Tooltip title={t("Send Invoice")}>
            <Icon.Link
              name="send"
              to={reverse(
                "tourOperatorInvoiceSend",
                { id: obj.id },
                { next: buildRouteFromLocation(location) }
              )}
              linkClassName="ml-3"
            />
          </Tooltip>
        ) : (
          <span style={{ marginLeft: "2.25rem" }} />
        )}
        {!obj.isNullified &&
        !obj.isSent &&
        hasPermissions(["change_invoices_touroperatorinvoice"]) &&
        // this check actually makes sense because it is possible to create an invoice
        // without bookings. In that case this action is not allowed.
        (obj.hasBookingsInvoicedWithTaxes ||
          obj.hasBookingsInvoicedWithoutTaxes) ? (
          <Tooltip title={t("Invert Taxes")}>
            <Icon.Link
              name="invert"
              to={reverse(
                "tourOperatorInvoiceInvertTaxes",
                { id: obj.id },
                { next: buildRouteFromLocation(location) }
              )}
              linkClassName="ml-3"
            />
          </Tooltip>
        ) : (
          <span style={{ marginLeft: "2.25rem" }} />
        )}
        {!obj.isNullified &&
        !obj.isSent &&
        obj.isLast &&
        hasPermissions(["delete_invoices_touroperatorinvoice"]) ? (
          <Tooltip title={t("Delete Invoice")}>
            <Icon.Link
              name="remove"
              color="danger"
              to={reverse(
                "tourOperatorInvoiceDelete",
                { id: obj.id },
                { next: buildRouteFromLocation(location) }
              )}
              linkClassName="ml-3"
            />
          </Tooltip>
        ) : (
          <span style={{ marginLeft: "2.25rem" }} />
        )}
        {!obj.isNullified &&
        hasPermissions(["nullify_invoices_touroperatorinvoice"]) ? (
          <Tooltip title={t("Nullify Invoice")}>
            <Icon.Link
              name="nullify"
              to={reverse(
                "tourOperatorInvoiceNullify",
                { id: obj.id },
                { next: buildRouteFromLocation(location) }
              )}
              linkClassName="ml-3"
            />
          </Tooltip>
        ) : (
          <span style={{ marginLeft: "2.25rem" }} />
        )}
        {!obj.isNullified &&
        hasPermissions([
          "nullify_invoices_touroperatorinvoice",
          "add_invoices_touroperatorinvoice",
        ]) ? (
          <Tooltip title={t("Nullify and Recreate Invoice")}>
            <Icon.Link
              name="nullify-recreate"
              to={reverse(
                "tourOperatorInvoiceNullifyRecreate",
                { id: obj.id },
                { next: buildRouteFromLocation(location) }
              )}
              linkClassName="ml-3"
            />
          </Tooltip>
        ) : (
          <span style={{ marginLeft: "2.25rem" }} />
        )}
      </>
    )),
  ],
  filters: [
    [
      {
        size: "col",
        children: [
          [
            {
              name: "tourOperator",
              fieldType: "asyncSelect",
              endpoint: "/tour-operators/",
              label: t("Tour Operator"),
              size: "col-4",
            },
            {
              name: "reference",
              fieldType: "input",
              type: "text",
              label: t("Reference"),
              size: "col-4",
            },
            {
              name: "isSent",
              fieldType: "select",
              options: [
                { id: "true", displayName: t("Sent") },
                { id: "false", displayName: t("Not sent") },
              ],
              label: t("Is sent"),
              size: "col-2",
            },
          ],
          [
            {
              fieldType: "date",
              name: "dateFrom",
              label: t("Date from"),
              size: "col-3",
            },
            {
              fieldType: "date",
              name: "dateTo",
              label: t("Date to"),
              size: "col-3",
            },
            {
              name: "isCreditNote",
              fieldType: "select",
              options: [
                { id: "false", displayName: t("Invoice") },
                { id: "true", displayName: t("Credit note") },
              ],
              label: t("Type"),
              size: "col-2",
            },
          ],
        ],
      },
      {
        size: "col-auto",
        children: [
          [
            {
              fieldType: "applyButton",
              size: "col-12 mt-1",
            },
          ],
          [
            {
              fieldType: "clearButton",
              size: "col-12 mt-3",
            },
          ],
        ],
      },
    ],
  ],
})

export default function TourOperatorInvoiceList(props) {
  const { t } = useTranslation()
  let history = useHistory()

  return <TableWithFilters config={getConfig(t, history.location)} />
}
