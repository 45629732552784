import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useForm, FormProvider } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useLastLocation } from "react-router-last-location"
import { isEmpty, isArray, set, isString, isFunction } from "lodash"

import Button from "components/Button/Button"
import { SHOW_DEV_TOOL } from "utils/constants"
import {
  dismissAllNotifications,
  notify,
  redirectWithNotify,
} from "utils/notifications"
import { convertIntegersToStrings, reduceSelectValues } from "utils/forms"
import {
  getLocalStorageObj,
  setLocalStorageObj,
  removeLocalStorageObj,
} from "utils/fs"
import { getPathnameFromString } from "utils/urls"

let DevTool
if (process.env.NODE_ENV === "development" && SHOW_DEV_TOOL) {
  DevTool = require("@hookform/devtools").DevTool
}

export default function Form(props) {
  const { t } = useTranslation()
  let history = useHistory()
  const lastLocation = useLastLocation() || {}

  const formInstance = useForm({ shouldFocusError: false })
  const { reset } = formInstance

  useEffect(() => {
    const currentLocation = history.location.pathname
    return history.listen(nextLocation => {
      if (nextLocation.search.includes("addRelatedField")) {
        setLocalStorageObj(
          `${currentLocation}-savedForm`,
          formInstance.getValues()
        )
      }
    })
  }, [history, formInstance])

  useEffect(() => {
    if (
      lastLocation.search &&
      lastLocation.search.includes("addRelatedField")
    ) {
      const formData = getLocalStorageObj(
        `${history.location.pathname}-savedForm`
      )
      if (formData) {
        reset(convertIntegersToStrings(formData))
      }
    } else if (!isEmpty(props.defaultValues)) {
      reset(convertIntegersToStrings(props.defaultValues))
    }
    removeLocalStorageObj(`${history.location.pathname}-savedForm`)
  }, [
    lastLocation.pathname,
    lastLocation.search,
    history.location.pathname,
    props.defaultValues,
    reset,
  ])

  const onSubmit = data => {
    if (props.processData) {
      data = props.processData(data)
    }

    dismissAllNotifications()

    props.apiFunction({
      path: props.endpoint,
      opts: {
        body: reduceSelectValues(data),
      },
      onSuccess: response => {
        if (props.successUrl) {
          const urlParams = new URLSearchParams(history.location.search)
          let redirectUrl = props.successUrl
          if (urlParams.get("next")) {
            redirectUrl = urlParams.get("next")
            let addRelatedField = urlParams.get("addRelatedField")

            if (addRelatedField) {
              const nextPathname = getPathnameFromString(redirectUrl)
              const formData = getLocalStorageObj(`${nextPathname}-savedForm`)
              if (formData) {
                set(formData, addRelatedField, {
                  id: response.id,
                  displayName: response.displayName,
                })
                setLocalStorageObj(`${nextPathname}-savedForm`, formData)
              }
            }
          }
          props.successFunction(response)

          if (props.successMessage) {
            const message = isFunction(props.successMessage)
              ? props.successMessage(response)
              : props.successMessage
            redirectWithNotify(redirectUrl, "success", message)
          } else {
            history.push(redirectUrl)
          }
        } else {
          props.successFunction(response)

          if (props.successMessage) {
            const message = isFunction(props.successMessage)
              ? props.successMessage(response)
              : props.successMessage
            notify("success", message)
          }
        }
      },
      onError: err => {
        if (err.status >= 400 && err.status <= 499) {
          const detail = err.response.detail
          const nonFieldErrors = err.response.nonFieldErrors

          if (detail) {
            if (nonFieldErrors) {
              err.response.nonFieldErrors.push(detail)
            } else {
              err.response.nonFieldErrors = [detail]
            }
          }

          const formFields = Object.keys(formInstance.control.fieldsRef.current)

          const errors = {}
          Object.entries(err.response).forEach(([key, error]) => {
            if (error) {
              if (formFields.includes(key)) {
                errors[key] = { message: error.join("\n") }
                formInstance.setError(key, { message: error.join("\n") })
              } else {
                if (errors.nonFieldErrors) {
                  errors.nonFieldErrors.message += error.join("\n")
                } else {
                  errors.nonFieldErrors = { message: error.join("\n") }
                }
                formInstance.setError("nonFieldErrors", {
                  message: error.join("\n"),
                })
              }
            }
          })

          onError(errors)
          props.onError(err.response)
        }
      },
    })
  }

  const onError = errors => {
    if (!isEmpty(errors)) {
      dismissAllNotifications()
      notify("warning", t("Please fix errors below."))

      if (errors.nonFieldErrors) {
        let message = errors.nonFieldErrors.message
        if (isString(message)) {
          message = message.split("\n")
        } else if (!isArray(message)) {
          message = [message]
        }

        notify(
          "error",
          <>
            {message.map((msg, i) => (
              <div key={i}>{msg}</div>
            ))}
          </>
        )
      }

      window.scroll({ top: 0, left: 0, behavior: "smooth" })
    }
  }

  return (
    <FormProvider {...formInstance}>
      <form
        onSubmit={formInstance.handleSubmit(
          props.onSubmit || onSubmit,
          onError
        )}>
        <input
          name="nonFieldErrors"
          type="hidden"
          ref={formInstance.register({
            validate: () => {
              dismissAllNotifications()

              const validation = props.validateForm(formInstance.getValues())
              return isArray(validation) ? (
                <ul className="mb-0">
                  {validation.map((err, i) => (
                    <li key={i}>{err}</li>
                  ))}
                </ul>
              ) : (
                validation
              )
            },
          })}
        />
        {(props.title || props.showSubmit) && (
          <div className="row mb-4">
            {props.title && (
              <div className="col">
                <h1>{props.title}</h1>
              </div>
            )}
            {props.showSubmit && (
              <div className="col-auto">
                <Button icon="save" submit>
                  {t("Save")}
                </Button>
              </div>
            )}
          </div>
        )}
        {props.children}
        {props.showBack && (
          <div className="row mt-4">
            {props.showBack && (
              <div className="col">
                <Button
                  icon="chevron-left-double"
                  onClick={() => history.goBack()}>
                  {t("Back")}
                </Button>
              </div>
            )}
          </div>
        )}

        {process.env.NODE_ENV === "development" && SHOW_DEV_TOOL && (
          <DevTool control={formInstance.control} />
        )}
      </form>
    </FormProvider>
  )
}

Form.propTypes = {
  children: PropTypes.node.isRequired,
  defaultValues: PropTypes.object,
  apiFunction: PropTypes.func,
  endpoint: PropTypes.string,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  successUrl: PropTypes.string,
  successFunction: PropTypes.func,
  successMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  showBack: PropTypes.bool,
  showSubmit: PropTypes.bool,
  validateForm: PropTypes.func,
  dataEndpoint: PropTypes.string,
  processData: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

Form.defaultProps = {
  apiFunction: undefined,
  endpoint: undefined,
  successUrl: undefined,
  defaultValues: {},
  onSubmit: undefined,
  onError: () => {},
  successFunction: () => {},
  successMessage: undefined,
  showBack: true,
  showSubmit: true,
  validateForm: () => true,
  dataEndpoint: undefined,
  processData: undefined,
  title: "",
}
