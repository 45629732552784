import React from "react"
import PropTypes from "prop-types"
import { useGlobal } from "reactn"
import { useTranslation } from "react-i18next"
import { useWatch } from "react-hook-form"

import { useSegmentSelectionType } from "apps/wizardForms/SegmentSelection"
import Button from "components/Button/Button"
import Card from "components/Card/Card"
import { FormField } from "components/Form"

export default function FilterForm(props) {
  const segmentSelectionType = useSegmentSelectionType()
  const data = useGlobal(`${segmentSelectionType}FormData`)[0]
  const flightType =
    useGlobal(`${segmentSelectionType}FormFlightType`)[0] || "single"

  const { t } = useTranslation()
  const firstDepartureValue = useWatch({ name: "firstDepartureAirport" })
  const firstArrivalValue = useWatch({ name: "firstArrivalAirport" })

  const secondDepartureValue = useWatch({ name: "secondDepartureAirport" })
  const secondArrivalValue = useWatch({ name: "secondArrivalAirport" })

  return (
    <>
      <Card className="mt-3 p-4">
        <div className="row">
          <div className="col-8">
            <h5>{t("Select route")}</h5>
            {flightType === "multi" && (
              <div className="mt-4">
                <b>{t("Outbound flight")}</b>
              </div>
            )}
            {data && data.airline && (
              <FormField
                fieldType="input"
                type="hidden"
                name="airline"
                label=""
                defaultValue={data.airline.id}
              />
            )}
            <div className="row">
              <div className="col-6">
                <FormField
                  name="firstDepartureAirport"
                  fieldType="airportSelect"
                  label={t("Departure airport")}
                  icon="airplane-departure"
                  required
                  filterOption={
                    firstArrivalValue
                      ? option => option.value !== firstArrivalValue.id
                      : undefined
                  }
                  onChange={() => props.resetSegments()}
                />
              </div>
              <div className="col-6">
                <FormField
                  name="firstArrivalAirport"
                  fieldType="airportSelect"
                  label={t("Arrival airport")}
                  icon="airplane-arrival"
                  required
                  filterOption={
                    firstDepartureValue
                      ? option => option.value !== firstDepartureValue.id
                      : undefined
                  }
                  onChange={() => props.resetSegments()}
                />
              </div>
            </div>
            {flightType === "multi" && (
              <div className="mt-2">
                <b>{t("Return flight")}</b>
                <div className="row">
                  <div className="col-6">
                    <FormField
                      name="secondDepartureAirport"
                      fieldType="airportSelect"
                      label={t("Departure airport")}
                      icon={{ name: "airplane-departure", color: "magenta" }}
                      required
                      filterOption={
                        secondArrivalValue
                          ? option => option.value !== secondArrivalValue.id
                          : undefined
                      }
                      onChange={() => props.resetSegments()}
                    />
                  </div>
                  <div className="col-6">
                    <FormField
                      name="secondArrivalAirport"
                      fieldType="airportSelect"
                      label={t("Arrival airport")}
                      icon={{ name: "airplane-arrival", color: "magenta" }}
                      required
                      filterOption={
                        secondDepartureValue
                          ? option => option.value !== secondDepartureValue.id
                          : undefined
                      }
                      onChange={() => props.resetSegments()}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-12 mt-4">
            <div className="row">
              <div className="col-8">
                <h5>{t("Select date range")}</h5>
                <FormField
                  fieldType="selectDateRange"
                  label={t("Date Range")}
                  required
                  onRangeChange={() => props.resetSegments()}
                  onDateChange={() => props.resetSegments()}
                />
              </div>
              <div className="col-4">
                <h5>{t("Select operating day(s)")}</h5>
                <FormField
                  fieldType="operatingDays"
                  name="operatingDays"
                  defaultValues={data ? data.operatingDays : undefined}
                  onChange={() => props.resetSegments()}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
      <div className="row mt-4">
        <div className="ml-auto col-auto">
          <Button submit>{t("Search")}</Button>
        </div>
      </div>
    </>
  )
}

FilterForm.propTypes = {
  resetSegments: PropTypes.func,
}

FilterForm.defaultProps = {
  resetSegments: () => {},
}
