import React from "react"
import PropTypes from "prop-types"

import UserForm from "apps/auth/User/UserForm"
import { reverse } from "utils/urls"

export default function UserUpdate(props) {
  return (
    <UserForm
      {...props}
      isUpdate
      actionEndpoint={`/auth/users/${props.match.params.id}/`}
      successUrl={reverse("userDetail", { id: props.match.params.id })}
    />
  )
}

UserUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
