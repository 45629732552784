import React from "react"
import { useFormContext, useFieldArray } from "react-hook-form"
import { useTranslation } from "react-i18next"

import Button from "components/Button/Button"
import { FormField } from "components/Form"

export default function TourOperatorsGroupFeeFormSet(props) {
  const { getValues, setValue, control, watch } = useFormContext()
  const { fields, remove } = useFieldArray({
    control,
    name: "tourOperatorsGroupFees",
    keyName: "fieldArrayId",
  })
  const fees = watch("tourOperatorsGroupFees", [])

  const { t } = useTranslation()

  const addFee = () => {
    const newFees = [...(getValues().tourOperatorsGroupFees || [])]
    newFees.push({})
    setValue("tourOperatorsGroupFees", newFees)
  }

  return (
    <>
      {fields.map((field, i) => (
        <div
          className={`row ${i !== 0 ? "mt-3" : ""}`}
          key={field.fieldArrayId}>
          <FormField
            name={`tourOperatorsGroupFees[${i}].fee_id`}
            fieldType="input"
            containerClassName="mb-0 mt-0"
            type="hidden"
            label=""
            defaultValue={field.id || field.fee_id}
          />
          <div className="col-4">
            <FormField
              name={`tourOperatorsGroupFees[${i}].value`}
              currencyName={`tourOperatorsGroupFees[${i}].currency`}
              fieldType="price"
              label={t("Value")}
              defaultValue={field.value}
              currencyDefaultValue={field.currency}
              required
            />
          </div>
          <div className="col-4">
            <FormField
              name={`tourOperatorsGroupFees[${i}].tourOperators`}
              fieldType="asyncSelect"
              label={t("Tour Operators")}
              endpoint="/tour-operators/"
              defaultValue={field.tourOperators}
              filterOption={
                fees.length
                  ? option =>
                      !fees
                        .map(fee => fee.tourOperators)
                        .flat()
                        .filter(tourOperator => tourOperator !== "")
                        .map(tourOperator => tourOperator.id)
                        .includes(option.value)
                  : undefined
              }
              isMulti
              required
            />
          </div>
          <div className="col-auto my-auto">
            <Button
              type="danger"
              outline
              onClick={() => {
                remove(i)
              }}>
              {t("Delete")}
            </Button>
          </div>
          <div className="col-auto my-auto">
            <Button type="success" onClick={addFee}>
              {t("Add")}
            </Button>
          </div>
        </div>
      ))}
      {!fields.length && (
        <div className="row mt-4">
          <div className="col-auto">
            <Button type="success" onClick={addFee}>
              {t("Add")}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}
