import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { startCase, isObject } from "lodash"

import Button from "components/Button/Button"
import Expand from "components/Expand/Expand"
import { apiGET } from "utils/api"
import { reverse } from "utils/urls"

export default function TicketHistory(props) {
  const [history, setHistory] = useState()

  const { t } = useTranslation()

  useEffect(
    () =>
      apiGET({
        path: `/tickets/${props.match.params.id}/history/`,
        onSuccess: response => setHistory(response),
        onError: () =>
          props.history.push(
            reverse("ticketDetail", { id: props.match.params.id })
          ),
      }),
    [props.match.params.id, props.history]
  )

  const ticketColumns = ["created", "isSent", "airlinePnr", "user", "status"]
  const humanPassengerColumns = [
    "created",
    "familyReference",
    "firstName",
    "lastName",
    "gender",
    "dateOfBirth",
    "user",
    "status",
  ]
  const petPassengerColumns = [
    "created",
    "familyReference",
    "petType",
    "weight",
    "description",
    "user",
    "status",
  ]

  const getPassengerColumns = passenger =>
    passenger.passengerType ? humanPassengerColumns : petPassengerColumns

  const getPassengerTitle = passenger =>
    passenger.passengerType
      ? `${passenger.familyReference} - ${passenger.firstName} ${passenger.lastName}`
      : `${passenger.familyReference} - ${passenger.petType}`

  const renderCol = (col, entry) => {
    if (col === "created") {
      return entry.updated
    } else if (col === "user") {
      if (entry[col]) {
        return entry[col].email
      } else {
        return t("System")
      }
    } else if (entry[col] === "True") {
      return t("Yes")
    } else if (entry[col] === "False") {
      return t("No")
    } else if (entry[col] === "None") {
      return "-"
    } else if (isObject(entry[col])) {
      return entry[col].name || "-"
    }

    return entry[col]
  }

  return history ? (
    <div>
      {history.ticket.length || history.passengers.length ? (
        <>
          {history.ticket.length && (
            <div className="my-2">
              <div className="d-flex justify-content-between align-items-center py-2 px-3 bg-gray-light">
                <h5 className="my-1">{t("Ticket History")}</h5>
                <Expand color="magenta" size="xl" target="#ticket-history" />
              </div>
              <div id="ticket-history" className="collapse show">
                <table className="table">
                  <thead>
                    <tr>
                      {ticketColumns.map((col, i) => (
                        <th key={`th-${i}`}>{startCase(col)}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {history.ticket.map((entry, i) => (
                      <tr key={`tr-${i}`}>
                        {ticketColumns.map((col, j) => (
                          <td key={`td-${i}-${j}`}>{renderCol(col, entry)}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {history.passengers.length && (
            <div className="my-4">
              <div className="d-flex justify-content-between align-items-center py-2 px-3 bg-gray-light">
                <h5 className="my-1">{t("Passengers History")}</h5>
                <Expand
                  color="magenta"
                  size="xl"
                  target="#passengers-history"
                />
              </div>
              <div id="passengers-history" className="collapse hide">
                {history.passengers.map((entries, i) => (
                  <Fragment key={i}>
                    <div className="d-flex justify-content-between align-items-center py-2 px-3 border-bottom">
                      <h6 className="my-1">
                        {getPassengerTitle(entries[entries.length - 1])}
                      </h6>
                      <Expand
                        color="magenta"
                        size="xl"
                        target={`#passenger-${i}-history`}
                      />
                    </div>
                    <div
                      id={`passenger-${i}-history`}
                      className="collapse hide">
                      <table className="table">
                        <thead>
                          <tr>
                            {getPassengerColumns(entries[0]).map((col, j) => (
                              <th key={`th-${j}`}>{startCase(col)}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {entries.map((entry, j) => (
                            <tr key={`tr-${j}`}>
                              {getPassengerColumns(entry).map((col, k) => (
                                <td key={`td-${j}-${k}`}>
                                  {renderCol(col, entry)}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Fragment>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <div>{t("No history recorded for selected object.")}</div>
      )}
      <div className="my-2">
        <Button onClick={() => props.history.goBack()}>{t("Back")}</Button>
      </div>
    </div>
  ) : null
}

TicketHistory.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
