import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

import Detail from "components/GenericViews/Detail"
import { reverse } from "utils/urls"

export default function RoutingDetail(props) {
  const { t } = useTranslation()

  return (
    <Detail
      title={t("Routing details")}
      detailEndpoint={`/routings/${props.match.params.id}/`}
      listRoute={reverse("routingList")}
      changePermission={["change_routings_routing"]}
      changeRoute={reverse("routingUpdate", { id: props.match.params.id })}
      deletePermission={["delete_routings_routing"]}
      deleteRoute={reverse("routingDelete", { id: props.match.params.id })}
      historyRoute={reverse("history", {
        id: props.match.params.id,
        model: "routings",
      })}
      fields={[
        [{ label: t("Name"), key: "name", size: "col-4" }],
        [
          {
            label: t("Use alternative routing name"),
            key: "useAlt",
            size: "col-4",
          },
        ],
      ]}
    />
  )
}

RoutingDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
