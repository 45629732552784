import React from "react"

import AirlineForm from "apps/airlines/Airline/AirlineForm"
import { reverse } from "utils/urls"

export default function AirlineCreate(props) {
  return (
    <AirlineForm
      {...props}
      actionEndpoint="/airlines/"
      successUrl={reverse("airlineList")}
    />
  )
}
