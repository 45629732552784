import React from "react"
import PropTypes from "prop-types"
import ReactTable from "react-table-6"

import { getParentColumnWidth } from "./utils"
import ThComponent from "./ThComponent"

import "./Table.scss"
import { isInteger } from "lodash"

class SubTable extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    className: PropTypes.string,
    diagonalColumnWidth: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.number),
    ]),
  }

  static defaultProps = {
    className: "",
    diagonalColumnWidth: 0,
  }

  constructor(props) {
    super(props)
    this.state = {
      columnWidths: this.getColumnWidths(),
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.columns !== this.props.columns) {
      this.setColumnWidths()
    }
  }

  getColumnWidths = () => {
    const columnWidths = {}
    this.props.columns.forEach((col, i) => {
      if (col.parentColumnIndex !== undefined) {
        columnWidths[i] = getParentColumnWidth(col.parentColumnIndex)
      }
    })
    return columnWidths
  }

  setColumnWidths = () =>
    this.setState({ columnWidths: this.getColumnWidths() })

  componentDidMount() {
    window.addEventListener("resize", this.setColumnWidths)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setColumnWidths)
  }

  render() {
    const { data, diagonalColumnWidth } = this.props
    const columns = [
      {
        Header: () => <></>,
        Cell: () => <></>,
        sortable: false,
        resizable: false,
        width: 50,
        className: "rt-sub-select",
        headerClassName: "rt-sub-select-header",
      },
      ...this.props.columns.map((column, i) => {
        column.resizable = false

        if (column.parentColumnIndex !== undefined) {
          column.width = this.state.columnWidths[i]
        }
        return column
      }),
      {
        Header: () => <></>,
        Cell: () => <></>,
        sortable: false,
        resizable: false,
        width: 35,
        className: "rt-sub-expand",
        headerClassName: "rt-sub-expand-header",
      },
    ]

    if (diagonalColumnWidth !== null) {
      columns.splice(1, 0, {
        Header: () => <></>,
        Cell: () => <></>,
        sortable: false,
        resizable: false,
        width: getParentColumnWidth(diagonalColumnWidth),
        className: "rt-sub-empty",
        headerClassName: "rt-sub-empty-header",
        headerStyle: {
          clipPath: `polygon(100% 0, ${
            isInteger(diagonalColumnWidth)
              ? "25"
              : (diagonalColumnWidth.length /
                  (diagonalColumnWidth.length + 1)) *
                100
          }% 0, 100% 100%)`,
        },
      })
    }

    return (
      <ReactTable
        minRows={0}
        showPagination={false}
        {...this.props}
        className={`sub-table ${this.props.className}`}
        data={data}
        pageSize={data.length}
        columns={columns}
        getTheadThProps={(state, rowInfo, column) => ({
          alignment: column.headerAlignment || "justify-content-start",
          sortable: column.sortable,
          renderHeader: column.renderHeader,
          width: column.width,
        })}
        ThComponent={ThComponent}
      />
    )
  }
}

export default SubTable
