import React from "react"
import { useTranslation, Trans } from "react-i18next"
import { get } from "lodash"

import LegFormSet from "apps/flights/Leg/LegFormSet"
import { getDurationDiff } from "apps/flights/utils"
import Card from "components/Card/Card"
import { Form, FormField } from "components/Form"
import { apiPOST } from "utils/api"
import { reverse } from "utils/urls"

export default function FlightCreate(props) {
  const { t } = useTranslation()

  const validateForm = values => {
    const errors = []

    const operatingDays = get(values, "period.days")
    if (!operatingDays) {
      errors.push(
        <Trans i18nKey="operatingDaysValidation">
          At least one operating day must be selected.
        </Trans>
      )
    }

    const legs = values.legs || []
    legs.forEach((leg, i) => {
      const duration = getDurationDiff(values, i)
      if (duration === false) {
        errors.push(
          <Trans
            i18nKey="invalidLegDuration"
            values={{ start: i + 1 }}
            defaults="{{start}}. leg has invalid duration."
          />
        )
      }

      if (legs[i - 1]) {
        const layover = getDurationDiff(values, i - 1, i)

        if (layover === false) {
          errors.push(
            <Trans
              i18nKey="invalidLayoverDuration"
              values={{ start: i, end: i + 1 }}
              defaults="Layover between {{start}}. and {{end}}. leg is invalid."
            />
          )
        }
      }

      if (
        legs[i + 1] &&
        legs[i + 1].departureAirport &&
        legs[i].arrivalAirport &&
        legs[i + 1].departureAirport.id !== legs[i].arrivalAirport.id
      ) {
        errors.push(
          <Trans
            i18nKey="invalidDepartureArrivalAirports"
            values={{ start: i + 1, end: i + 2 }}
            defaults={`Arrival airport of {{start}}. leg and
              departure airport of {{end}}. leg must be the same.`}
          />
        )
      }
    })

    return errors.length ? errors : true
  }

  return (
    <>
      <Form
        apiFunction={apiPOST}
        endpoint={"/flights/"}
        defaultValues={{
          legs: [{ carriers: [{}] }],
        }}
        successUrl={reverse("flightSeriesList")}
        successMessage={t("Flights were successfully saved.")}
        validateForm={values => validateForm(values)}
        title={t("Flight Creation")}>
        <h4 className="mt-5">{t("Period")}</h4>
        <Card bodyClassName="p-4">
          <div className="row">
            <div className="col-12 col-lg-8 my-auto">
              <FormField
                fieldType="selectDateRange"
                required
                namePrefix="period"
              />
            </div>
            <div className="col-12 col-lg-4">
              <FormField fieldType="operatingDays" name="period.days" />
            </div>
          </div>
        </Card>

        <div className="mt-5">
          <h4>{t("Leg(s) creation")}</h4>
          <LegFormSet />
        </div>
      </Form>
    </>
  )
}
