import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import Icon from "components/Icon/Icon"

export default function ThComponent({
  toggleSort,
  className,
  children,
  sortable,
  renderHeader,
  width,
  isResized,
  alignment,
  ...rest
}) {
  const renderSortIcon = () => {
    if (className.includes("desc")) {
      return <Icon name="sort-up" size="sm" />
    } else if (className.includes("asc")) {
      return <Icon name="sort-down" size="sm" />
    }
    return <Icon name="sort-up-down" size="sm" />
  }

  if ((typeof width === "string" || width instanceof String) && !isResized) {
    rest.style = {
      flex: `${width.replace(/\D/g, "")} 0 auto`,
      width,
      maxWidth: width,
    }
  }

  return (
    <div
      className={classnames("rt-th", className)}
      onClick={e => toggleSort && toggleSort(e)}
      role="columnheader"
      tabIndex="-1"
      {...rest}>
      {renderHeader ? (
        renderHeader(children, sortable ? renderSortIcon() : undefined)
      ) : (
        <div className={`d-flex ${alignment}`}>
          {children}
          {sortable && (
            <div className="ml-1 d-flex align-items-center">
              {renderSortIcon()}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

ThComponent.propTypes = {
  toggleSort: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  sortable: PropTypes.bool,
  renderHeader: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isResized: PropTypes.bool,
  alignment: PropTypes.string.isRequired,
}

ThComponent.defaultProps = {
  sortable: true,
  renderHeader: undefined,
  width: undefined,
  isResized: false,
}
