import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { getAuthInfo, isGroupSuperior } from "apps/auth/auth"
import Detail from "components/GenericViews/Detail"
import { reverse } from "utils/urls"

export default function UserDetail(props) {
  const { t } = useTranslation()

  return (
    <Detail
      title={t("User details")}
      detailEndpoint={`/auth/users/${props.match.params.id}/`}
      listRoute={reverse("userList")}
      changePermission={["change_users_user"]}
      changeRoute={reverse("userUpdate", { id: props.match.params.id })}
      changeCheck={data =>
        isGroupSuperior(getAuthInfo().groupType, data.group.id) ||
        getAuthInfo().id === data.id
      }
      deletePermission={["delete_users_user"]}
      deleteRoute={reverse("userDelete", { id: props.match.params.id })}
      deleteCheck={data =>
        isGroupSuperior(getAuthInfo().groupType, data.group.id) ||
        getAuthInfo().id === data.id
      }
      additionalButtons={[
        {
          permission: ["view_users_user"],
          route: reverse("passwordChange"),
          check: data => getAuthInfo().id === data.id,
          className: "btn-primary",
          label: t("Change password"),
        },
        {
          permission: ["view_users_user"],
          route: reverse("managePermissions", { id: props.match.params.id }),
          check: data =>
            isGroupSuperior(getAuthInfo().groupType, data.group.id),
          className: "btn-primary",
          label: t("Manage permissions"),
        },
      ]}
      fields={[
        [
          { label: t("Name"), key: "name", size: "col-4" },
          {
            label: t("Tour Operator"),
            key: "tourOperator",
            type: "singleSelect",
            size: "col-4",
          },
        ],
        [
          { label: t("Email"), key: "email", size: "col-4" },
          {
            label: t("Group"),
            key: "group",
            type: "singleSelect",
            size: "col-4",
          },
        ],
        [
          { label: t("Date joined"), key: "dateJoined", size: "col-4" },
          { label: t("Is active"), key: "isActive", size: "col-4" },
        ],
      ]}
    />
  )
}

UserDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
