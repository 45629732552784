import React from "react"
import { useTranslation } from "react-i18next"

import { Form, FormField } from "components/Form"
import { apiPOST } from "utils/api"
import { reverse } from "utils/urls"

export default function PasswordChange(props) {
  const { t } = useTranslation()

  return (
    <div>
      <Form
        apiFunction={apiPOST}
        endpoint={`/auth/users/password-change/`}
        successUrl={reverse("home")}
        successMessage={t("Password was successfully reset.")}
        title={t("Change password")}>
        <div className="row">
          <div className="col-4">
            <FormField
              name="currentPassword"
              fieldType="input"
              type="password"
              label={t("Current password")}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <FormField
              name="newPassword"
              fieldType="input"
              type="password"
              label={t("New password")}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <FormField
              name="reNewPassword"
              fieldType="input"
              type="password"
              label={t("New password (again)")}
              required
            />
          </div>
        </div>
      </Form>
    </div>
  )
}
