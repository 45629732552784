import {
  TourOperatorCreate,
  TourOperatorUpdate,
  TourOperatorDelete,
  TourOperatorDetail,
  TourOperatorList,
} from "apps/tourOperators/TourOperator"
import { route } from "utils/urls"

export const PROTECTED_ROUTES = [
  route("tourOperatorCreate", TourOperatorCreate, [
    "add_tour_operators_touroperator",
  ]),
  route("tourOperatorUpdate", TourOperatorUpdate, [
    "change_tour_operators_touroperator",
  ]),
  route("tourOperatorDelete", TourOperatorDelete, [
    "delete_tour_operators_touroperator",
  ]),
  route("tourOperatorDetail", TourOperatorDetail, [
    "view_tour_operators_touroperator",
  ]),
  route("tourOperatorList", TourOperatorList, [
    "view_tour_operators_touroperator",
  ]),
]
