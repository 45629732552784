import React from "react"
import { useTranslation } from "react-i18next"

import { hasPermissions } from "apps/auth/auth"
import Button from "components/Button/Button"
import Tooltip from "components/Tooltip/Tooltip"
import Icon from "components/Icon/Icon"
import { actionsColumn, TableWithFilters } from "components/Table"
import { reverse } from "utils/urls"

const getConfig = t => ({
  endpoint: "/cities",
  title: t("Cities"),
  tableName: "cities-table-config",
  columns: [
    {
      Header: t("Name"),
      accessor: "name",
    },
    {
      Header: t("Country"),
      id: "country",
      accessor: c => c.country.displayName,
    },
    { Header: t("Code"), id: "code", accessor: c => c.code || "-" },
    { Header: t("Timezone"), accessor: "timezone" },
    actionsColumn(obj => (
      <>
        <Tooltip title={t("City Details")}>
          <Icon.Link
            name="eye"
            color="gray"
            to={reverse("cityDetail", { id: obj.id })}
          />
        </Tooltip>
        <Tooltip title={t("City History")}>
          <Icon.Link
            name="history"
            color="gray"
            to={reverse("history", { id: obj.id, model: "cities" })}
            linkClassName="ml-3"
          />
        </Tooltip>
        <Tooltip title={t("Edit City")}>
          <Icon.Link
            name="edit"
            to={reverse("cityUpdate", { id: obj.id })}
            linkClassName="ml-3"
          />
        </Tooltip>
        <Tooltip title={t("Delete City")}>
          <Icon.Link
            name="remove"
            color="danger"
            to={reverse("cityDelete", { id: obj.id })}
            linkClassName="ml-3"
          />
        </Tooltip>
      </>
    )),
  ],
  filters: [
    [
      {
        name: "name",
        fieldType: "input",
        type: "text",
        label: t("Name"),
        size: "col-4",
      },
      {
        name: "country",
        fieldType: "asyncSelect",
        endpoint: "/countries/",
        label: t("Country"),
        size: "col-4",
      },
      {
        fieldType: "applyButton",
        size: "col-auto mt-2",
      },
      {
        fieldType: "clearButton",
        size: "col-auto mt-2",
      },
    ],
  ],
  buttons: (
    <>
      {hasPermissions(["add_core_city"]) && (
        <Button icon="plus" to={reverse("cityCreate")}>
          {t("Add City")}
        </Button>
      )}
    </>
  ),
})

export default function CityList(props) {
  const { t } = useTranslation()

  return <TableWithFilters config={getConfig(t)} />
}
