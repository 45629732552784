import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useGlobal } from "reactn"
import { useTranslation, Trans } from "react-i18next"
import { get, isEmpty, isObject } from "lodash"

import FilterForm from "./FilterForm"
import GroupedSegments from "./GroupedSegments"
import { useSegmentSelectionType } from "apps/wizardForms/SegmentSelection"
import Steps from "apps/wizardForms/Steps/Steps"
import Button from "components/Button/Button"
import Avatar from "components/Avatar/Avatar"
import { Form, FormField } from "components/Form"
import { apiGET } from "utils/api"
import { notify } from "utils/notifications"
import { reverse } from "utils/urls"

export default function SegmentSelection(props) {
  const segmentSelectionType = useSegmentSelectionType()
  const [data, setData] = useGlobal(`${segmentSelectionType}FormData`)

  const formFlightType = useGlobal(`${segmentSelectionType}FormFlightType`)
  const flightType = formFlightType[0] || "single"
  const setFlightType = formFlightType[1]

  const formSegments = useGlobal(`${segmentSelectionType}FormSegments`)
  const segments = formSegments[0] || {}
  const setSegments = formSegments[1]

  const [selectedSubject, setSelectedSubject] = useState(
    data?.airline || data?.tourOperator || null
  )

  const { t } = useTranslation()

  const noResultsFound =
    segments.firstLeg !== undefined &&
    parseInt(segments.firstLeg.count) === 0 &&
    ((flightType === "single" && segments.secondLeg === undefined) ||
      (flightType !== "single" &&
        (segments.secondLeg === undefined ||
          (segments.secondLeg !== undefined &&
            parseInt(segments.secondLeg.count) === 0))))

  useEffect(() => {
    return props.history.listen(location => {
      if (location.pathname !== reverse(`${segmentSelectionType}CreateStep2`)) {
        setData(undefined)
        setFlightType(undefined)
        setSegments(undefined)
      }
    })
  }, [props.history, segmentSelectionType, setData, setFlightType, setSegments])

  const resetSegments = () => {
    setSegments(undefined)
  }

  const getSegments = filters => {
    apiGET({
      path: `/flights/segments/grouped-segments/?${filters.toString()}`,
      onSuccess: response => setSegments(response),
      onError: () =>
        notify(
          "error",
          t("There were some errors while trying to filter segments.")
        ),
    })
  }

  const onFilterFormSubmit = d => {
    resetSegments()

    const filters = new URLSearchParams()
    Object.entries(d).forEach(([k, v]) => {
      if (k === "nonFieldErrors") {
        return
      }

      if (isObject(v) && v.id !== undefined) {
        filters.set(k, v.id)
      } else {
        filters.set(k, v)
      }
    })

    if (flightType === "return") {
      filters.set("secondDepartureAirport", filters.get("firstArrivalAirport"))
      filters.set("secondArrivalAirport", filters.get("firstDepartureAirport"))
    }

    getSegments(filters)
    const newData = { ...data, ...d }
    delete newData.segments
    setData(newData)
  }

  const onSegmentsFormSubmit = d => {
    setData({ ...data, ...d })
    props.history.push(reverse(`${segmentSelectionType}CreateStep2`))
  }

  return (
    <>
      <Steps step="step1" />
      <h1>
        {segmentSelectionType === "airlineBooking" &&
          t("Airline Booking creation")}
        {segmentSelectionType === "tourOperatorBooking" &&
          t("Tour Operator Booking creation")}
        {segmentSelectionType === "ticket" && t("Ticket creation")}
      </h1>
      <div className="mt-5 mb-3">
        <Form
          onSubmit={onFilterFormSubmit}
          defaultValues={data || {}}
          showBack={false}
          showSubmit={false}
          validateForm={values => {
            let isValid = true
            const operatingDays = get(values, "operatingDays")
            if (!operatingDays) {
              isValid = (
                <Trans i18nKey="operatingDaysValidation">
                  At least one operating day must be selected.
                </Trans>
              )
            }
            return isValid
          }}>
          <div className="row">
            <div className="col-3">
              <div className="row">
                <div className="col-12">
                  {segmentSelectionType === "airlineBooking" ? (
                    <FormField
                      name="airline"
                      fieldType="asyncSelect"
                      label={t("Select Airline")}
                      endpoint="/airlines/"
                      onChange={selected => {
                        setSelectedSubject(selected)
                        resetSegments()
                      }}
                      required
                    />
                  ) : (
                    <FormField
                      name="tourOperator"
                      fieldType="asyncSelect"
                      label={t("Select Tour Operator")}
                      endpoint="/tour-operators/"
                      onChange={selected => {
                        setSelectedSubject(selected)
                        resetSegments()
                      }}
                      required
                    />
                  )}
                </div>
                <div className="col-12 text-center">
                  {selectedSubject !== null && (
                    <>
                      <Avatar
                        size={80}
                        className="mx-auto mt-5"
                        image={selectedSubject.logo}
                        alt={selectedSubject.code}
                      />
                      <small>{selectedSubject.displayName}</small>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-9">
              <h5>{t("Select flight details")}</h5>
              <div className="row">
                <div className="col-4">
                  <div className="btn-group d-flex" role="group">
                    <Button
                      className="w-100"
                      outline={flightType !== "single"}
                      onClick={() => {
                        resetSegments()
                        setFlightType("single")
                      }}>
                      {t("One way")}
                    </Button>
                    <Button
                      className="w-100"
                      outline={flightType !== "return"}
                      onClick={() => {
                        resetSegments()
                        setFlightType("return")
                      }}>
                      {t("Return")}
                    </Button>
                    <Button
                      className="w-100"
                      outline={flightType !== "multi"}
                      onClick={() => {
                        resetSegments()
                        setFlightType("multi")
                      }}>
                      {t("Multi")}
                    </Button>
                  </div>
                </div>
                <div className="col-12">
                  <FilterForm resetSegments={resetSegments} />
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div className="row">
        <div className="col-12">
          <hr className="bg-primary" />
        </div>
      </div>
      <Form
        onSubmit={onSegmentsFormSubmit}
        defaultValues={data || {}}
        showBack={false}
        showSubmit={false}
        validateForm={values => {
          let isValid = t("At least one segment must be selected.")

          const selectedSegments = Object.entries(values.segments)
            .map(([k, v]) =>
              k.includes("segment") && v === "true" ? k.split("-")[1] : null
            )
            .filter(s => s !== null)

          if (selectedSegments.length !== 0) {
            isValid = true
            const firstLegSelected = segments.firstLeg
              ? selectedSegments.filter(s =>
                  segments.firstLeg.ids.includes(parseInt(s))
                )
              : []

            const secondLegSelected = segments.secondLeg
              ? selectedSegments.filter(s =>
                  segments.secondLeg.ids.includes(parseInt(s))
                )
              : []

            if (flightType !== "single") {
              if (!firstLegSelected.length || !secondLegSelected.length) {
                isValid = t(
                  "Segments from both legs must be selected when `Return` or `Multi` flight " +
                    "types are selected. Otherwise, please choose `One way` flight type."
                )
              }
            }

            if (segmentSelectionType === "ticket") {
              if (flightType === "single" && selectedSegments.length > 1) {
                isValid = t(
                  "Only one segment can be selected when `Single` flight type is selected."
                )
              } else if (
                flightType !== "single" &&
                (firstLegSelected.length > 1 || secondLegSelected.length > 1)
              ) {
                isValid = t(
                  "Only one segment per route can be selected when `Return` or `Multi` flight " +
                    "types are selected."
                )
              }
            }
          }

          return isValid
        }}>
        <>
          {((segments.firstLeg && !isEmpty(segments.firstLeg.objects)) ||
            (segments.secondLeg && !isEmpty(segments.secondLeg.objects))) && (
            <>
              <h5 className="mt-3 mb-2">{t("Select segments")}</h5>
              <div className="row">
                <div className="col-5">
                  {segments.firstLeg ? (
                    <GroupedSegments
                      segments={segments.firstLeg}
                      departureAirport={data.firstDepartureAirport}
                      arrivalAirport={data.firstArrivalAirport}
                      defaultValues={data.segments}
                    />
                  ) : null}
                </div>
                <div className="offset-2 col-5">
                  {flightType !== "single" ? (
                    <GroupedSegments
                      segments={
                        segments.secondLeg || {
                          objects: {},
                          count: 0,
                          ids: [],
                        }
                      }
                      departureAirport={
                        flightType === "return"
                          ? data.firstArrivalAirport
                          : data.secondDepartureAirport
                      }
                      arrivalAirport={
                        flightType === "return"
                          ? data.firstDepartureAirport
                          : data.secondArrivalAirport
                      }
                      defaultValues={data.segments}
                    />
                  ) : null}
                </div>
              </div>
              <div className="row mt-4">
                <div className="ml-auto col-auto">
                  <Button submit>{t("Next step")}</Button>
                </div>
              </div>
            </>
          )}
          {noResultsFound && (
            <>
              <p>{t("No results found.")}</p>
            </>
          )}
        </>
      </Form>
    </>
  )
}

SegmentSelection.propTypes = {
  history: PropTypes.object.isRequired,
}
