import React from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"

import Button from "components/Button/Button"
import { Form, FormField } from "components/Form"
import { apiPOST } from "utils/api"
import { reverse } from "utils/urls"

export default function ForgotPassword(props) {
  const { t } = useTranslation()
  let history = useHistory()

  return (
    <div className="container vh-100 d-flex align-items-center">
      <div className="row h-75">
        <div className="col-12 col-md-6 offset-md-3">
          <h1 className="mb-3">{t("Forgot password")}</h1>
          <Form
            endpoint={`/auth/users/forgot-password/`}
            apiFunction={apiPOST}
            successUrl={reverse("login")}
            successMessage={t(
              "Please check your email and click on the provided link to reset your password."
            )}
            showBack={false}
            showSubmit={false}>
            <div className="my-2">
              <FormField
                name="email"
                fieldType="input"
                type="email"
                label={t("Email")}
                required
              />
            </div>
            <div className="row mt-4">
              <div className="col">
                <Button
                  icon="chevron-left-double"
                  onClick={() => history.goBack()}>
                  {t("Back")}
                </Button>
              </div>
              <div className="col-auto">
                <Button submit>{t("Submit")}</Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}
