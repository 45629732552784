import React from "react"
import PropTypes from "prop-types"

export default function Card({ children, className, bodyClassName, ...rest }) {
  return (
    <div className={`${className} card`} {...rest}>
      <div className={`${bodyClassName} card-body`}>{children}</div>
    </div>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
}

Card.defaultProps = {
  className: "",
  bodyClassName: "",
}
