import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import "./FlightDuration.scss"

export default function FlightDuration({
  type,
  duration,
  className,
  textClassName,
}) {
  const { t } = useTranslation()
  const label = type === "layover" ? t("Layover") : t("Duration")

  return (
    <div className="row">
      <div
        className={`col-12 py-3 duration-container duration-${type} ${className}`}>
        <hr
          className={`${
            duration
              ? type === "layover"
                ? "text-primary"
                : "text-magenta"
              : "text-danger"
          }`}
        />
        <p
          className={`mb-0 p-2 ${
            duration
              ? type === "layover"
                ? "text-magenta"
                : "text-primary"
              : "text-danger"
          } ${textClassName}`}>
          {duration ? (
            <>
              {label}: {duration}
            </>
          ) : (
            <>{`${label}: ${t("invalid")}`}</>
          )}
        </p>
      </div>
    </div>
  )
}

FlightDuration.propTypes = {
  type: PropTypes.oneOf(["layover", "leg"]).isRequired,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  className: PropTypes.string,
  textClassName: PropTypes.string,
}

FlightDuration.defaultProps = {
  className: "",
  textClassName: "",
}
