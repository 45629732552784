const routes = {
  feeCategoryCreate: "/fees/categories/create",
  feeCategoryUpdate: "/fees/categories/:id/update",
  feeCategoryList: "/fees/categories",
  airlineFeeCreate: "/fees/airline-fees/create",
  airlineFeeUpdate: "/fees/airline-fees/:id/update",
  airlineFeeDelete: "/fees/airline-fees/:id/delete",
  airlineFeeList: "/fees/airline-fees",
  tourOperatorFeeCreate: "/fees/tour-operator-fees/create",
  tourOperatorFeeUpdate: "/fees/tour-operator-fees/:id/update",
  tourOperatorFeeDelete: "/fees/tour-operator-fees/:id/delete",
  tourOperatorFeeList: "/fees/tour-operator-fees",
}

export default routes
