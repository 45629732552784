import React, { useContext } from "react"
import PropTypes from "prop-types"
import { ReactTableDefaults } from "react-table-6"

import { TrComponentContext } from "./TrComponent"

export default function TdComponent(props) {
  const trComponentContext = useContext(TrComponentContext)
  let { className } = props

  if (trComponentContext.isSelected) {
    className = `${className} rt-td-selected`
  }

  return <ReactTableDefaults.TdComponent {...props} className={className} />
}

TdComponent.propTypes = {
  className: PropTypes.string,
}

TdComponent.defaultProps = {
  className: "",
}
