import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Form, FormField } from "components/Form"
import { apiPUT, apiGET, apiPOST } from "utils/api"
import { LOGO_CROP_SIZE } from "utils/constants"
import { reverse } from "utils/urls"

export default function TourOperatorForm(props) {
  const [formData, setFormData] = useState({})

  const { t } = useTranslation()

  useEffect(
    () =>
      props.isUpdate
        ? apiGET({
            path: `/tour-operators/${props.match.params.id}/`,
            onSuccess: response => {
              // vat is text field but in DB it can be null so if null cast it to empty string
              response.vat = response.vat === null ? "" : response.vat
              setFormData(response)
            },
            onError: () => props.history.push(reverse("tourOperatorList")),
          })
        : () => {},
    [props.isUpdate, props.match.params.id, props.history]
  )

  return (
    <div>
      <Form
        endpoint={props.actionEndpoint}
        apiFunction={props.isUpdate ? apiPUT : apiPOST}
        defaultValues={formData}
        successUrl={props.successUrl}
        successMessage={t("Tour Operator was successfully saved.")}
        title={
          props.isUpdate ? t("Edit tour operator") : t("Add tour operator")
        }>
        <div className="row">
          <div className="col-4">
            <FormField
              name="name"
              fieldType="input"
              type="text"
              label={t("Name")}
              maxLength={50}
              required
            />
          </div>
          <div className="col-4">
            <FormField
              name="vat"
              fieldType="input"
              type="text"
              label={t("VAT Identification number")}
              maxLength={25}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <FormField
              name="code"
              fieldType="input"
              type="text"
              label={t("Code")}
              maxLength={5}
              required
            />
          </div>
          <div className="col-4">
            <FormField
              name="address"
              fieldType="input"
              type="text"
              label={t("Address")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <FormField
              name="codeAs400"
              fieldType="input"
              type="text"
              label={t("Code AS400")}
              maxLength={7}
              required
            />
          </div>
          <div className="col-4">
            <FormField
              name="city"
              fieldType="selectWithAdd"
              selectFieldType="asyncSelect"
              label={t("City")}
              endpoint="/cities/"
              required
              addRoute="cityCreate"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <FormField
              name="zipCode"
              fieldType="input"
              type="text"
              label={t("Zip Code")}
            />
          </div>
          <div className="col-4">
            <FormField
              name="logoBase64"
              fieldType="image"
              label={t("Logo")}
              cropSize={LOGO_CROP_SIZE}
              defaultValue={formData.logoBase64}
            />
          </div>
        </div>
        <div className="row">
          <div className="offset-4 col-4">
            <FormField
              name="url"
              fieldType="input"
              type="text"
              label={t("Web address")}
            />
          </div>
        </div>
      </Form>
    </div>
  )
}

TourOperatorForm.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string,
    }),
  }).isRequired,
  isUpdate: PropTypes.bool,
  actionEndpoint: PropTypes.string.isRequired,
  successUrl: PropTypes.string.isRequired,
}

TourOperatorForm.defaultProps = {
  isUpdate: false,
}
