import {
  AirlineCreate,
  AirlineUpdate,
  AirlineDelete,
  AirlineDetail,
  AirlineList,
} from "apps/airlines/Airline"
import { route } from "utils/urls"

export const PROTECTED_ROUTES = [
  route("airlineCreate", AirlineCreate, ["add_airlines_airline"]),
  route("airlineUpdate", AirlineUpdate, ["change_airlines_airline"]),
  route("airlineDelete", AirlineDelete, ["delete_airlines_airline"]),
  route("airlineDetail", AirlineDetail, ["view_airlines_airline"]),
  route("airlineList", AirlineList, ["view_airlines_airline"]),
]
