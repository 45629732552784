import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import Avatar from "components/Avatar/Avatar"
import Button from "components/Button/Button"
import SectionHeader from "components/SectionHeader/SectionHeader"

import styles from "./FlightDetails.module.scss"

export default function FlightDetails({ data, header }) {
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)

  const getFlightDetailsAirlines = flightDetails => {
    const airlines = []
    flightDetails.forEach(leg => {
      if (!airlines.map(airline => airline.code).includes(leg.airline.code)) {
        airlines.push(leg.airline)
      }
    })
    return airlines
  }

  const flightDetailsAirlines = getFlightDetailsAirlines(data.flights || [])

  return (
    <>
      <SectionHeader
        header={
          <>
            {header || t("Flight details")}
            {data.flights.length > 1 && (
              <>
                {` - `}
                <span className="text-magenta">{t("Connection flight")}</span>
              </>
            )}
          </>
        }
        body={
          data.flights.length > 1 ? (
            <Button
              icon="eye"
              outline={isExpanded}
              onClick={() => setIsExpanded(!isExpanded)}>
              {t("Details")}
            </Button>
          ) : (
            undefined
          )
        }
      />
      <div className="row my-3">
        <div
          className={`col-auto border-bottom border-left border-teal-light px-3 p-2 ${
            styles["operating-date"]
          }`}>
          <b>
            {data.operatingDate},{" "}
            <span className="text-magenta">
              {data.operatingWeekday.displayName}
            </span>
          </b>
        </div>
      </div>
      {isExpanded ? (
        <div className="row">
          <div className="col-3 pt-2">
            {data.flights.map((leg, i) => (
              <Fragment key={i}>
                <div className={`text-center ${styles["logo-container"]}`}>
                  <Avatar
                    className="mx-auto"
                    image={leg.airline.logo}
                    title={leg.airline.displayName}
                    alt={leg.airline.code}
                    size={80}
                  />
                  <small>{leg.airline.displayName}</small>
                </div>
                {leg.transferDuration && (
                  <div
                    className={styles["transfer-duration-empty-container"]}
                  />
                )}
              </Fragment>
            ))}
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-1">
                <div className="d-flex flex-column align-items-center h-100 py-1">
                  {data.flights.map((leg, i) => (
                    <Fragment key={i}>
                      {i === 0 ? (
                        <div className={styles["trip-empty-dot"]} />
                      ) : (
                        <div className={styles["trip-full-dot"]} />
                      )}
                      <div className={styles["trip-expanded-solid-line"]} />
                      {i === data.flights.length - 1 ? (
                        <div className={styles["trip-empty-dot"]} />
                      ) : (
                        <div className={styles["trip-full-dot"]} />
                      )}
                      {leg.transferDuration && (
                        <div className={styles["trip-dashed-line"]} />
                      )}
                    </Fragment>
                  ))}
                </div>
              </div>
              <div className="col-11">
                {data.flights.map((leg, i) => (
                  <Fragment key={i}>
                    <div className="row">
                      <div className="col-2">
                        {leg.departureTime.time}
                        {leg.departureTime.delta !== 0 && (
                          <sup>{leg.departureTime.delta}</sup>
                        )}
                      </div>
                      <div className="col-5">
                        <b>{leg.departureAirport.displayName}</b>
                      </div>
                      <div className="col-12">
                        <div className="row my-3">
                          <div className="col-7">
                            <small>
                              {t("Flight duration")} {leg.flightDuration.hours}h
                              {leg.flightDuration.minutes !== 0 && (
                                <>{leg.flightDuration.minutes}</>
                              )}
                            </small>
                          </div>
                          <div className="col-5">
                            <b>{leg.flightNo}</b>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2">
                        {leg.arrivalTime.time}
                        {leg.arrivalTime.delta !== 0 && (
                          <sup>{leg.arrivalTime.delta}</sup>
                        )}
                      </div>
                      <div className="col-5">
                        <b>{leg.arrivalAirport.displayName}</b>
                      </div>
                    </div>
                    {leg.transferDuration && (
                      <div
                        className={`d-flex align-items-center ${
                          styles["transfer-duration-container"]
                        }`}>
                        <div>
                          <small className="text-magenta">
                            {t("Transfer duration")}{" "}
                            {leg.transferDuration.hours}h
                            {leg.transferDuration.minutes !== 0 && (
                              <>{leg.transferDuration.minutes}</>
                            )}
                          </small>
                        </div>
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-3">
            <div
              className={`${
                styles[`overlapped-avatars-${flightDetailsAirlines.length}`]
              } ${styles["overlapped-parent"]} pt-3`}>
              {flightDetailsAirlines.map((airline, i) => (
                <Avatar
                  key={i}
                  image={airline.logo}
                  title={airline.displayName}
                  alt={airline.code}
                  size={80}
                  className={`${
                    styles[`overlapped-avatars-${flightDetailsAirlines.length}`]
                  } ${styles[`overlapped-child-${i}`]}`}
                />
              ))}
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-1">
                <div className="d-flex flex-column align-items-center h-100 py-1">
                  <div className={styles["trip-empty-dot"]} />
                  <div className={styles["trip-collapsed-solid-line"]} />
                  {data.flights.length > 1 ? (
                    <div className={styles["trip-transfer-dot"]} />
                  ) : (
                    <div className={styles["trip-collapsed-short-line"]} />
                  )}
                  <div className={styles["trip-collapsed-solid-line"]} />
                  <div className={styles["trip-empty-dot"]} />
                </div>
              </div>
              <div className="col-11">
                <div className="row">
                  <div className="col-2">
                    {data.flights[0].departureTime.time}
                    {data.flights[0].departureTime.delta !== 0 && (
                      <sup>{data.flights[0].departureTime.delta}</sup>
                    )}
                  </div>
                  <div className="col-5">
                    <b>{data.flights[0].departureAirport.displayName}</b>
                  </div>
                  <div className="col-5">
                    <b>{data.flights[0].flightNo}</b>
                  </div>
                </div>
                {data.flights.length > 1 ? (
                  <div className="row my-3">
                    <div className="col-10">
                      <small className="text-magenta">
                        {data.flights.length - 1}x {t("transfer")}
                      </small>
                    </div>
                  </div>
                ) : (
                  <div className={styles["no-transfer-duration-container"]} />
                )}
                <div className="row">
                  <div className="col-2">
                    {data.flights[data.flights.length - 1].arrivalTime.time}
                    {data.flights[data.flights.length - 1].arrivalTime.delta !==
                      0 && (
                      <sup>
                        {
                          data.flights[data.flights.length - 1].arrivalTime
                            .delta
                        }
                      </sup>
                    )}
                  </div>
                  <div className="col-5">
                    <b>
                      {
                        data.flights[data.flights.length - 1].arrivalAirport
                          .displayName
                      }
                    </b>
                  </div>
                  <div className="col-5">
                    <b>{data.flights[data.flights.length - 1].flightNo}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-auto my-auto">
            <div className="text-center">
              <div>{t("Trip duration")}</div>
              <b>
                {data.duration.hours}h
                {data.duration.minutes !== 0 && <>{data.duration.minutes}</>}
              </b>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

FlightDetails.propTypes = {
  data: PropTypes.exact({
    flights: PropTypes.array.isRequired,
    duration: PropTypes.object.isRequired,
    operatingDate: PropTypes.string.isRequired,
    operatingWeekday: PropTypes.object.isRequired,
  }).isRequired,
  header: PropTypes.string,
}

FlightDetails.defaultProps = {
  header: undefined,
}
