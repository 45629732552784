import React from "react"
import { useTranslation } from "react-i18next"

import { hasPermissions } from "apps/auth/auth"
import { getBoolDisplay } from "apps/core/utils"
import Button from "components/Button/Button"
import Tooltip from "components/Tooltip/Tooltip"
import Icon from "components/Icon/Icon"
import { actionsColumn, TableWithFilters } from "components/Table"
import { reverse } from "utils/urls"

const getConfig = t => ({
  endpoint: "/routings",
  title: t("Routings"),
  tableName: "routings-table-config",
  columns: [
    {
      Header: t("Routing"),
      accessor: "name",
    },
    {
      Header: t("Use alternative routing name"),
      id: "useAlt",
      accessor: r => getBoolDisplay(r.useAlt),
    },
    actionsColumn(obj => (
      <>
        <Tooltip title={t("Routing Details")}>
          <Icon.Link
            name="eye"
            color="gray"
            to={reverse("routingDetail", { id: obj.id })}
          />
        </Tooltip>
        <Tooltip title={t("Routing History")}>
          <Icon.Link
            name="history"
            color="gray"
            to={reverse("history", { id: obj.id, model: "routings" })}
            linkClassName="ml-3"
          />
        </Tooltip>
        <Tooltip title={t("Edit Routing")}>
          <Icon.Link
            name="edit"
            linkClassName="ml-3"
            to={reverse("routingUpdate", { id: obj.id })}
          />
        </Tooltip>
        <Tooltip title={t("Delete Routing")}>
          <Icon.Link
            name="remove"
            color="danger"
            linkClassName="ml-3"
            to={reverse("routingDelete", { id: obj.id })}
          />
        </Tooltip>
      </>
    )),
  ],
  filters: [
    [
      {
        name: "id",
        fieldType: "asyncSelect",
        endpoint: "/routings/",
        label: t("Routing"),
        size: "col-4",
      },
      {
        fieldType: "applyButton",
        size: "col-auto mt-2",
      },
      {
        fieldType: "clearButton",
        size: "col-auto mt-2",
      },
    ],
  ],
  buttons: (
    <>
      {hasPermissions(["add_routings_routing"]) && (
        <Button icon="plus" to={reverse("routingCreate")}>
          {t("Add Routing")}
        </Button>
      )}
    </>
  ),
})

export default function RoutingList(props) {
  const { t } = useTranslation()

  return <TableWithFilters config={getConfig(t)} />
}
