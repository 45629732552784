import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import "./Steps.scss"

export default function Steps({ step, ...rest }) {
  const { t } = useTranslation()

  return (
    <div className="row mb-4">
      <div className="col-auto">
        <div className="row">
          <div className="col-auto pr-0">
            <span className="step-circle" />
          </div>
          <div className="col my-auto">
            <b>{t("Step")} 1</b>
          </div>
        </div>
      </div>
      <div className="col">
        <hr
          className={`step-line step-line-${
            step === "step1" ? "inactive" : ""
          }`}
        />
      </div>
      <div className="col-auto">
        <div className="row">
          <div className="col-auto pr-0">
            <span
              className={`step-circle step-circle-${
                step === "step1" ? "inactive" : ""
              }`}
            />
          </div>
          <div className="col my-auto">
            <b className={`step-text-${step === "step1" ? "inactive" : ""}`}>
              {t("Step")} 2
            </b>
          </div>
        </div>
      </div>
    </div>
  )
}

Steps.propTypes = {
  step: PropTypes.oneOf(["step1", "step2"]).isRequired,
}
