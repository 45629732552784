import React from "react"
import { useTranslation } from "react-i18next"

import FeeForm from "apps/fees/Fee/FeeForm"
import { reverse } from "utils/urls"

export default function AirlineFeeCreate(props) {
  const { t } = useTranslation()

  return (
    <FeeForm
      {...props}
      actionEndpoint="/fees/airline-fees/"
      label={t("Add airline fee")}
      successMessage={t("Airline Fee was successfully saved.")}
      successUrl={reverse("airlineFeeList")}
    />
  )
}
