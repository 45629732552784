import React, { useState } from "react"
import PropTypes from "prop-types"
import { useFormContext, Controller, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { get } from "lodash"
import { ConfigProvider, DatePicker } from "antd"
import en_GB from "antd/lib/locale-provider/en_GB"
import moment from "moment"
import "moment/locale/en-gb"

import { getAppInfo } from "apps/core/utils"
import Icon from "components/Icon/Icon"
import { DATE_FORMAT } from "utils/constants"

export default function DateRangeField({
  onChange: onChangeProp,
  className,
  name,
  registerProps,
  defaultValue,
  required,
  ...rest
}) {
  const { errors, control, setValue, getValues } = useFormContext()
  const endDateValue = useWatch({
    control,
    name: name[1],
    defaultValue: defaultValue[1],
  })
  const [focused, setFocused] = useState(false)

  const { t } = useTranslation()

  return (
    <>
      <Controller
        render={({ onChange, onBlur, value, ...restRender }) => {
          const startDateError = get(errors, name[0])
          const endDateError = get(errors, name[1])

          // this value is used because when setting defaultValues prop in related Form,
          // endDateValue is empty
          const endDateGetValue = getValues(name[1])

          return (
            <ConfigProvider
              locale={
                getAppInfo().weekdays[0].id === "MON" ? en_GB : undefined
              }>
              <DatePicker.RangePicker
                format={DATE_FORMAT}
                suffixIcon={<Icon name="calendar" color="magenta" />}
                clearIcon={<Icon name="remove" />}
                separator=""
                placeholder=""
                {...restRender}
                {...rest}
                value={[
                  value ? moment(value, DATE_FORMAT) : null,
                  endDateValue || endDateGetValue
                    ? moment(endDateValue || endDateGetValue, DATE_FORMAT)
                    : null,
                ]}
                className={`${className} date-range-field form-control form-field ${
                  focused || value || endDateValue ? "has-floating-label" : ""
                } ${startDateError || endDateError ? "is-invalid" : ""}`}
                onChange={(dates, dateStrings) => {
                  onChangeProp(dateStrings)
                  if (dates) {
                    onChange(dateStrings[0])
                    setValue(name[1], dateStrings[1])
                  } else {
                    setFocused(false)
                    onChange("")
                    setValue(name[1], "")
                    restRender.ref.current.blur()
                  }
                }}
                onFocus={() => setFocused(true)}
                onBlur={e => {
                  setFocused(false)
                  onBlur(e)
                }}
              />
              {startDateError ? (
                <div className="invalid-feedback d-block">
                  {startDateError.message}
                </div>
              ) : null}
              {endDateError ? (
                <div className="invalid-feedback d-block">
                  {endDateError.message}
                </div>
              ) : null}
            </ConfigProvider>
          )
        }}
        name={name[0]}
        defaultValue={defaultValue[0]}
        control={control}
        rules={{
          ...registerProps[0],
          validate: value => {
            if (required && !value) {
              return t("This field is required.")
            }

            return registerProps[0].validate
              ? registerProps[0].validate(value)
              : true
          },
        }}
      />
      <Controller
        name={name[1]}
        defaultValue={defaultValue[1]}
        control={control}
        rules={registerProps[1]}
        render={() => <></>}
      />
    </>
  )
}

DateRangeField.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.array.isRequired,
  registerProps: PropTypes.array,
  defaultValue: PropTypes.array,
  required: PropTypes.bool.isRequired,
}

DateRangeField.defaultProps = {
  onChange: () => {},
  className: "",
  registerProps: [{}, {}],
  defaultValue: ["", ""],
}
