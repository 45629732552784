import React from "react"

import FeeCategoryForm from "apps/fees/FeeCategory/FeeCategoryForm"
import { reverse } from "utils/urls"

export default function FeeCategoryCreate(props) {
  return (
    <FeeCategoryForm
      {...props}
      actionEndpoint="/fees/categories/"
      successUrl={reverse("feeCategoryList")}
    />
  )
}
