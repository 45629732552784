import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Form, FormField } from "components/Form"
import { apiPUT, apiGET, apiPOST } from "utils/api"
import { reverse } from "utils/urls"

export default function FeeCategoryForm(props) {
  const [formData, setFormData] = useState({})

  const { t } = useTranslation()

  useEffect(
    () =>
      props.isUpdate
        ? apiGET({
            path: `/fees/categories/${props.match.params.id}/`,
            onSuccess: response => setFormData(response),
            onError: () => props.history.push(reverse("feeCategoryList")),
          })
        : () => {},
    [props.isUpdate, props.match.params.id, props.history]
  )

  return (
    <div>
      <Form
        endpoint={props.actionEndpoint}
        apiFunction={props.isUpdate ? apiPUT : apiPOST}
        defaultValues={formData}
        successUrl={props.successUrl}
        successMessage={t("Fee Category was successfully saved.")}
        title={props.isUpdate ? t("Edit fee category") : t("Add fee category")}>
        <div className="row">
          <div className="col-4">
            <FormField
              name="name"
              fieldType="input"
              type="text"
              label={t("Name")}
              maxLength={50}
              required
            />
          </div>
          <div className="col-4">
            <FormField
              name="type"
              fieldType="select"
              label={t("Type")}
              endpoint="/fees/categories/fee-type/"
              required
            />
          </div>
        </div>
      </Form>
    </div>
  )
}

FeeCategoryForm.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string,
    }),
  }).isRequired,
  isUpdate: PropTypes.bool,
  actionEndpoint: PropTypes.string.isRequired,
  successUrl: PropTypes.string.isRequired,
}

FeeCategoryForm.defaultProps = {
  isUpdate: false,
}
