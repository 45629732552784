import React from "react"
import { useTranslation } from "react-i18next"

import BookingFareForm from "apps/fares/BookingFare/BookingFareForm"
import { reverse } from "utils/urls"

export default function AirlineBookingFareCreate(props) {
  const { t } = useTranslation()

  return (
    <BookingFareForm
      {...props}
      actionEndpoint="/fares/airline-fares/"
      label={t("Add Airline booking fare")}
      successMessage={t("Airline Booking Fare was successfully saved.")}
      successUrl={reverse("airlineBookingFareList")}
    />
  )
}
