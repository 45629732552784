import WorkingSheet from "apps/sheets/WorkingSheet/WorkingSheet"
import InvoiceWorkingSheet from "apps/sheets/InvoiceWorkingSheet/InvoiceWorkingSheet"
import { route } from "utils/urls"

export const PROTECTED_ROUTES = [
  route("workingSheet", WorkingSheet, ["view_working_sheet"]),
  route("invoiceWorkingSheet", InvoiceWorkingSheet, [
    "view_invoice_working_sheet",
  ]),
]
