import React from "react"

import DateRangeForm from "apps/core/DateRange/DateRangeForm"
import { reverse } from "utils/urls"

export default function DateRangeCreate(props) {
  return (
    <DateRangeForm
      {...props}
      actionEndpoint="/date-ranges/"
      successUrl={reverse("dateRangeList")}
    />
  )
}
