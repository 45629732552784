import React, { Fragment } from "react"

import StatusDot from "components/StatusDot/StatusDot"
import { getAppInfo } from "apps/core/utils"

export const operatingDaysColumn = daysKey => ({
  id: "operatingDays",
  renderHeader: (children, sortIcon) => {
    return (
      <div className="d-flex justify-content-between">
        {/* Inject flex attributes into child components because setting their className is not available through react-table API*/}
        {children
          .filter(c => c)
          .map((c, i) => (
            <Fragment key={i}>
              {React.cloneElement(c, {
                className: "d-flex flex-grow-1",
              })}
            </Fragment>
          ))}
        <div>{sortIcon}</div>
      </div>
    )
  },
  Header: (
    <>
      {[...Array(7).keys()].map(i => (
        <div key={i} style={{ flex: 1 }}>
          {getAppInfo()["startingWeekdayNo"] === 0 ? i : i + 1}
        </div>
      ))}
    </>
  ),
  Cell: row => (
    <div className="d-flex justify-content-between mt-1">
      <div className="d-flex flex-grow-1">
        {getAppInfo().weekdays.map(day => (
          <div style={{ flex: 1 }} key={day.id}>
            {row.original[daysKey].includes(day.id) ? (
              <StatusDot className="bg-dark" />
            ) : (
              <StatusDot className="bg-blue-light" />
            )}
          </div>
        ))}
      </div>
      {/* This empty div mimics sort arrows space */}
      <div style={{ width: "1.375rem" }}></div>
    </div>
  ),
  accessor: obj => obj[daysKey],
  sortMethod: (a, b, desc) => {
    a = getAppInfo().weekdays.find(weekday => weekday.id === a)
    b = getAppInfo().weekdays.find(weekday => weekday.id === b)

    if (a.dayNumber > b.dayNumber) {
      return 1
    } else if (a.dayNumber < b.dayNumber) {
      return -1
    }

    return 0
  },
  width: 285,
  resizable: false,
})

export const actionsColumn = render => {
  const childrenCount = render({}).props.children.length || 1

  return {
    id: "actions",
    sortable: false,
    className: "py-2 text-center",
    accessor: obj => render(obj),
    // 58 is padding of the cell, 25 is width of the widest icon and 16 is distance between two icons
    width: 58 + childrenCount * 25 + (childrenCount - 1) * 16,
  }
}
