import React from "react"

import { getGlobal } from "reactn"

import Icon from "components/Icon/Icon"
import { APP_INFO_URL } from "utils/constants"

export const getAppInfo = () => getGlobal().apiStore[APP_INFO_URL]

export const getEnum = name => getAppInfo()["enums"][name]

export const getBoolDisplay = val =>
  val ? (
    <Icon name="check" color="success" />
  ) : (
    <Icon name="remove" color="danger" />
  )
