import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useFormContext } from "react-hook-form"
import { useTranslation, Trans } from "react-i18next"
import { useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import moment from "moment-timezone"

import Avatar from "components/Avatar/Avatar"
import Button from "components/Button/Button"
import FieldDetail from "components/FieldDetail/FieldDetail"
import FlightDuration from "components/FlightDuration/FlightDuration"
import Icon from "components/Icon/Icon"
import SectionHeader from "components/SectionHeader/SectionHeader"
import { Form, FormField } from "components/Form"
import { apiGET, apiPUT } from "utils/api"
import { DATE_TIME_FORMAT } from "utils/constants"
import { reverse } from "utils/urls"

function FlightLeg({ data, editingLeg, setEditingLeg }) {
  const formContext = useFormContext()
  const { t } = useTranslation()

  const scheduled = formContext
    ? formContext.watch([
        "scheduledDepartureLocalDatetime",
        "scheduledArrivalLocalDatetime",
      ])
    : null

  const actual = formContext
    ? formContext.watch([
        "actualDepartureLocalDatetime",
        "actualArrivalLocalDatetime",
      ])
    : null

  const renderDuration = () => {
    let departureDatetime, arrivalDatetime
    /*
      The logic for getting departure and the arrival datetimes:
        - if actual datetimes are editable and at least one value is entered, use entered values
        - if scheduled datetimes are editable and at least one value is entered, use entered values
        - if leg has actual datetimes, use those
        - use scheduled datetimes
     */
    if (
      actual &&
      (actual.actualDepartureLocalDatetime || actual.actualArrivalLocalDatetime)
    ) {
      departureDatetime = actual.actualDepartureLocalDatetime
      arrivalDatetime = actual.actualArrivalLocalDatetime
    } else if (
      scheduled &&
      (scheduled.scheduledDepartureLocalDatetime ||
        scheduled.scheduledArrivalLocalDatetime)
    ) {
      departureDatetime = scheduled.scheduledDepartureLocalDatetime
      arrivalDatetime = scheduled.scheduledArrivalLocalDatetime
    } else if (
      data.actualDepartureLocalDatetime &&
      data.actualArrivalLocalDatetime
    ) {
      departureDatetime = data.actualDepartureLocalDatetime
      arrivalDatetime = data.actualArrivalLocalDatetime
    } else {
      departureDatetime = data.scheduledDepartureLocalDatetime
      arrivalDatetime = data.scheduledArrivalLocalDatetime
    }

    const startDateTime = moment.tz(
      departureDatetime,
      DATE_TIME_FORMAT,
      data.departureTimezone
    )
    const endDateTime = moment.tz(
      arrivalDatetime,
      DATE_TIME_FORMAT,
      data.arrivalTimezone
    )

    const hours = endDateTime.diff(startDateTime, "hours")
    const minutes = endDateTime.diff(startDateTime, "minutes") % 60
    return (
      <FlightDuration
        type="leg"
        duration={
          hours > 0 || (hours === 0 && minutes > 1)
            ? `${hours}h${minutes}min`
            : false
        }
        textClassName="bg-white"
      />
    )
  }

  const renderDateTimeDelta = type => {
    let scheduledDateTime = scheduled
      ? scheduled[`scheduled${type}LocalDatetime`]
      : data[`scheduled${type}LocalDatetime`]
    let actualDateTime = actual
      ? actual[`actual${type}LocalDatetime`]
      : data[`actual${type}LocalDatetime`]

    if (!scheduledDateTime || !actualDateTime) {
      return ""
    }

    scheduledDateTime = moment(scheduledDateTime, DATE_TIME_FORMAT)
    actualDateTime = moment(actualDateTime, DATE_TIME_FORMAT)

    const hours = Math.abs(actualDateTime.diff(scheduledDateTime, "hours"))
    const minutes = actualDateTime.diff(scheduledDateTime, "minutes") % 60
    return `${type === "Departure" ? "DD" : "DA"}: ${
      minutes < 0 ? "-" : ""
    }${hours}h${Math.abs(minutes)}min`
  }

  return (
    <div className="row my-5">
      <div className="col-12">
        <SectionHeader
          header={data.displayName}
          body={
            <>
              {editingLeg === null && (
                <Button icon="edit" onClick={() => setEditingLeg(data.id)}>
                  {t("Edit")}
                </Button>
              )}
              {data.id === editingLeg && (
                <>
                  <Button
                    icon="remove"
                    type="warning"
                    onClick={() => setEditingLeg(null)}>
                    {t("Cancel")}
                  </Button>
                  <Button icon="save" submit className="ml-3">
                    {t("Save")}
                  </Button>
                </>
              )}
            </>
          }
        />
      </div>
      <div className="col-12 mt-3">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="row">
              <div className="col-6">
                <FieldDetail
                  label={t("Departure airport")}
                  value={data.flightLeg.departureAirport.displayName}
                  inputGroupPrepend={
                    <div className="mr-2 pt-1">
                      <Icon name="airplane-departure" size="lg" />
                    </div>
                  }
                />
              </div>
              <div className="col-6">
                {data.scheduledDepartureHasPassed || data.id !== editingLeg ? (
                  <FieldDetail
                    label={t("Scheduled departure")}
                    value={data.scheduledDepartureLocalDatetime}
                  />
                ) : (
                  <FormField
                    name="scheduledDepartureLocalDatetime"
                    fieldType="dateTime"
                    label={t("Scheduled departure")}
                    required
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="offset-6 col-6">
                {data.scheduledDepartureHasPassed && data.id === editingLeg ? (
                  <FormField
                    name="actualDepartureLocalDatetime"
                    fieldType="dateTime"
                    label={t("Actual departure")}
                    defaultPickerValue={moment(
                      data.scheduledDepartureLocalDatetime,
                      DATE_TIME_FORMAT
                    )}
                  />
                ) : (
                  <FieldDetail
                    label={t("Actual departure")}
                    value={data.actualDepartureLocalDatetime || "-"}
                  />
                )}
                <div className="text-magenta px-2 mt-2">
                  {renderDateTimeDelta("Departure")}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-3 mt-lg-0">
            <div className="row">
              <div className="col">
                <FieldDetail
                  label={t("Arrival airport")}
                  value={data.flightLeg.arrivalAirport.displayName}
                  inputGroupPrepend={
                    <div className="mr-2 pt-1">
                      <Icon name="airplane-arrival" size="lg" />
                    </div>
                  }
                />
              </div>
              <div className="col">
                {data.scheduledDepartureHasPassed || data.id !== editingLeg ? (
                  <FieldDetail
                    label={t("Scheduled arrival")}
                    value={data.scheduledArrivalLocalDatetime}
                  />
                ) : (
                  <FormField
                    name="scheduledArrivalLocalDatetime"
                    fieldType="dateTime"
                    label={t("Scheduled arrival")}
                    required
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="offset-6 col-6">
                {data.scheduledDepartureHasPassed && data.id === editingLeg ? (
                  <FormField
                    name="actualArrivalLocalDatetime"
                    fieldType="dateTime"
                    label={t("Actual arrival")}
                    defaultPickerValue={moment(
                      data.scheduledArrivalLocalDatetime,
                      DATE_TIME_FORMAT
                    )}
                  />
                ) : (
                  <FieldDetail
                    label={t("Actual arrival")}
                    value={data.actualArrivalLocalDatetime || "-"}
                  />
                )}
                <div className="text-magenta px-2 mt-2">
                  {renderDateTimeDelta("Arrival")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">{renderDuration()}</div>
      <div className="col-12 my-1">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-lg-4">
                <FieldDetail
                  label={t("Airline")}
                  value={data.flightLeg.operatingCarrier.airline.displayName}
                  inputGroupPrepend={
                    <div className="mr-2 pt-2">
                      <Avatar
                        image={data.flightLeg.operatingCarrier.airline.logo}
                        title={
                          data.flightLeg.operatingCarrier.airline.displayName
                        }
                        alt={data.flightLeg.operatingCarrier.airline.code}
                      />
                    </div>
                  }
                />
              </div>
              <div className="col-6 col-lg-2">
                <FieldDetail
                  label={
                    <>
                      {t("Flight")} N<sup>o</sup>
                    </>
                  }
                  value={data.flightLeg.operatingCarrier.flightNo}
                />
              </div>
              <div className="col-6 col-lg-2">
                <FieldDetail label={t("Carrier type")} value={t("Operating")} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {data.flightLeg.marketingCarriers.map((carrier, i) => (
        <div key={i} className="col-12 my-1">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <FieldDetail
                    value={carrier.airline.displayName}
                    label={<span className="d-lg-none">{t("Airline")}</span>}
                    inputGroupPrepend={
                      <div className="mr-2 pt-2">
                        <Avatar
                          image={carrier.airline.logo}
                          title={carrier.airline.displayName}
                          alt={carrier.airline.code}
                        />
                      </div>
                    }
                  />
                </div>
                <div className="col-6 col-lg-2">
                  <FieldDetail
                    label={
                      <span className="d-lg-none">
                        <>
                          {t("Flight")} N<sup>o</sup>
                        </>
                      </span>
                    }
                    value={carrier.flightNo}
                  />
                </div>
                <div className="col-6 col-lg-2">
                  <FieldDetail
                    label={
                      <span className="d-lg-none">{t("Carrier type")}</span>
                    }
                    value={t("Marketing")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

FlightLeg.propTypes = {
  data: PropTypes.object.isRequired,
  editingLeg: PropTypes.number,
  setEditingLeg: PropTypes.func.isRequired,
}

FlightLeg.defaultProps = {
  editingLeg: null,
}

export default function FlightDetail(props) {
  const [data, setData] = useState({})
  const [editingLeg, setEditingLeg] = useState(null)
  const { t } = useTranslation()
  let history = useHistory()

  useEffect(
    () =>
      apiGET({
        path: `/flights/${props.match.params.id}`,
        onSuccess: response => setData(response),
        onError: () => history.push(reverse("flightSeriesList")),
      }),
    [history, props.match.params.id]
  )

  const validateActualDateTimes = values => {
    if (
      (values.actualDepartureLocalDatetime ||
        values.actualArrivalLocalDatetime) &&
      !(
        values.actualDepartureLocalDatetime && values.actualArrivalLocalDatetime
      )
    ) {
      return t("Both departure and arrival datetimes must be defined.")
    }
    return false
  }

  const validatePreviousLegDateTimes = (previousLeg, legIndex, values) => {
    const departureDateTime = moment(
      values.actualDepartureLocalDatetime ||
        values.scheduledDepartureLocalDatetime,
      DATE_TIME_FORMAT
    )

    const previousArrivalDateTime = moment(
      previousLeg.actualArrivalLocalDatetime ||
        previousLeg.scheduledArrivalLocalDatetime,
      DATE_TIME_FORMAT
    )
    const previousDiffHours = departureDateTime.diff(
      previousArrivalDateTime,
      "hours"
    )
    const previousDiffMinutes =
      departureDateTime.diff(previousArrivalDateTime, "minutes") % 60
    if (
      previousDiffHours < 0 ||
      (previousDiffHours === 0 && previousDiffMinutes <= 1)
    ) {
      return (
        <Trans
          i18nKey="invalidLayoverDuration"
          values={{ start: legIndex, end: legIndex + 1 }}
          defaults="Layover between {{start}}. and {{end}}. leg is invalid."
        />
      )
    }
    return false
  }

  const validateNextLegDateTimes = (nextLeg, legIndex, values) => {
    const arrivalDateTime = moment(
      values.actualArrivalLocalDatetime || values.scheduledArrivalLocalDatetime,
      DATE_TIME_FORMAT
    )

    const nextDepartureDateTime = moment(
      nextLeg.actualDepartureLocalDatetime ||
        nextLeg.scheduledDepartureLocalDatetime,
      DATE_TIME_FORMAT
    )
    const nextDiffHours = nextDepartureDateTime.diff(arrivalDateTime, "hours")
    const nextDiffMinutes =
      nextDepartureDateTime.diff(arrivalDateTime, "minutes") % 60
    if (nextDiffHours < 0 || (nextDiffHours === 0 && nextDiffMinutes <= 1)) {
      return (
        <Trans
          i18nKey="invalidLayoverDuration"
          values={{ start: legIndex + 1, end: legIndex + 2 }}
          defaults="Layover between {{start}}. and {{end}}. leg is invalid."
        />
      )
    }
    return false
  }

  return !isEmpty(data) ? (
    <>
      <div className="row">
        <div className="col">
          <h1>
            {t("Flight Details")} -{" "}
            <span className="text-magenta">{data.displayName}</span>
          </h1>
        </div>
      </div>

      {data.legs.map(leg =>
        leg.id === editingLeg ? (
          <Form
            key={leg.id}
            endpoint={`/flights/legs/${leg.id}/`}
            apiFunction={apiPUT}
            defaultValues={{
              scheduledDepartureLocalDatetime:
                leg.scheduledDepartureLocalDatetime,
              scheduledArrivalLocalDatetime: leg.scheduledArrivalLocalDatetime,
              actualDepartureLocalDatetime: leg.actualDepartureLocalDatetime,
              actualArrivalLocalDatetime: leg.actualArrivalLocalDatetime,
            }}
            successUrl={reverse("flightDetail", {
              id: props.match.params.id,
            })}
            successMessage={t("Leg was successfully saved.")}
            successFunction={response => {
              const newData = { ...data }
              const index = newData.legs.findIndex(
                leg => leg.id === response.id
              )
              newData.legs[index] = response
              setEditingLeg(null)
              setData(newData)
            }}
            validateForm={values => {
              // if both of these keys exists in `values` and both are null
              // further validation is not required because that means that user
              // wants to clear actual datetimes
              if (
                values.actualDepartureLocalDatetime === null &&
                values.actualArrivalLocalDatetime === null
              ) {
                return true
              }

              const actualDateTimesValidation = validateActualDateTimes(values)
              if (actualDateTimesValidation) {
                return actualDateTimesValidation
              }

              const errors = []
              const legIndex = data.legs.findIndex(l => l.id === editingLeg)

              const previousLeg = data.legs[legIndex - 1]
              if (previousLeg) {
                const previousLegDateTimesValidation =
                  validatePreviousLegDateTimes(previousLeg, legIndex, values)
                if (previousLegDateTimesValidation) {
                  errors.push(previousLegDateTimesValidation)
                }
              }

              const nextLeg = data.legs[legIndex + 1]
              if (nextLeg) {
                const nextLegDateTimesValidation = validateNextLegDateTimes(
                  nextLeg,
                  legIndex,
                  values
                )
                if (nextLegDateTimesValidation) {
                  errors.push(nextLegDateTimesValidation)
                }
              }

              return errors.length ? errors : true
            }}
            showBack={false}
            showSubmit={false}>
            <FlightLeg
              data={leg}
              editingLeg={editingLeg}
              setEditingLeg={setEditingLeg}
            />
          </Form>
        ) : (
          <Fragment key={leg.id}>
            <FlightLeg
              data={leg}
              editingLeg={editingLeg}
              setEditingLeg={setEditingLeg}
            />
          </Fragment>
        )
      )}
    </>
  ) : null
}

FlightDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
