import React from "react"
import PropTypes from "prop-types"
import { useFormContext, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { Form, FormField } from "components/Form"
import { apiPOST } from "utils/api"
import { reverse } from "utils/urls"

export default function RoutingCreate(props) {
  const { t } = useTranslation()

  return (
    <div>
      <Form
        endpoint="/routings/routes/"
        apiFunction={apiPOST}
        successUrl={reverse("routingList")}
        successMessage={t("Routing was successfully saved.")}
        title={t("Add routing")}>
        <FormFields />
      </Form>
    </div>
  )
}

RoutingCreate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string,
    }),
  }).isRequired,
}

function FormFields(props) {
  const { control } = useFormContext()
  const airports = useWatch({
    control,
    name: ["departureAirport", "arrivalAirport"],
    defaultValue: "",
  })
  const { t } = useTranslation()

  return (
    <div className="row">
      <div className="col-6">
        <FormField
          name="departureAirport"
          fieldType="selectWithAdd"
          selectFieldType="airportSelect"
          label={t("Departure airport")}
          required
          addRoute="airportCreate"
          filterOption={
            airports.arrivalAirport
              ? option => option.value !== airports.arrivalAirport.id
              : undefined
          }
        />
      </div>
      <div className="col-6">
        <FormField
          name="arrivalAirport"
          fieldType="selectWithAdd"
          selectFieldType="airportSelect"
          label={t("Arrival airport")}
          required
          addRoute="airportCreate"
          filterOption={
            airports.departureAirport
              ? option => option.value !== airports.departureAirport.id
              : undefined
          }
        />
      </div>
    </div>
  )
}
