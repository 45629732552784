import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useFormContext, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { isString } from "lodash"

import { getAppInfo } from "apps/core/utils"
import Button from "components/Button/Button"
import { FormField } from "components/Form"
import { hasPermissions } from "apps/auth/auth"

export default function InvoiceWorkingSheetSubmit(props) {
  const [fields, setFields] = useState([])
  const { setValue } = useFormContext()

  const invoiceFields = fields.filter(f => f.includes("invoice"))
  const creditNoteFields = fields.filter(f => f.includes("credit-note"))

  const { t } = useTranslation()
  const { getValues } = useFormContext()

  useEffect(() => {
    const onInvoiceItemSelected = () => {
      setFields(
        Object.values(getValues())
          .filter(item => isString(item) && item.includes("segment-"))
          // it makes sense to join and then split by `,` because each item in itself has
          // data joined by `,` and this will split all items
          .join(",")
          .split(",")
          .filter(item => item !== "")
      )
      setValue("invoiceType", "")
    }

    window.addEventListener("invoiceitemselected", onInvoiceItemSelected)

    return () => {
      window.removeEventListener("invoiceitemselected", onInvoiceItemSelected)
    }
  }, [getValues, setValue])

  return (
    <>
      <Button
        modalTarget="#sheetModal"
        disabled={
          (invoiceFields.length !== 0 && creditNoteFields.length !== 0) ||
          (invoiceFields.length === 0 && creditNoteFields.length === 0)
        }>
        {t("Proceed")}
      </Button>
      {fields.length > 0 && <SubmitModal fields={fields} />}
    </>
  )
}

function SubmitModal(props) {
  const { t } = useTranslation()
  let history = useHistory()

  let isCreditNote
  if (props.fields[0].includes("invoice")) {
    isCreditNote = false
  } else if (props.fields[0].includes("credit-note")) {
    isCreditNote = true
  } else {
    throw new Error("unknown item type")
  }

  const label = isCreditNote ? t("Credit note") : t("Invoice")
  const tourOperator = new URLSearchParams(history.location.search).get(
    "tourOperator"
  )

  const bookingChecked =
    props.fields.filter(field => field.includes("-booking-")).length > 0
  const infantBookingChecked =
    props.fields.filter(field => field.includes("-infant_booking-")).length > 0
  const taxChecked =
    props.fields.filter(field => field.includes("-segment_tax-")).length > 0

  const invoiceTypeValue = useWatch({ name: "invoiceType" })

  return (
    <div className="modal fade" id="sheetModal" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row mb-2">
              <div className="col-12">
                <FormField
                  name="invoiceType"
                  fieldType="select"
                  label={t("Action")}
                  options={[
                    ...(taxChecked ||
                    (!taxChecked && !bookingChecked && infantBookingChecked)
                      ? [
                          {
                            id: "without_taxes",
                            displayName: `${t("Create")} ${label}`,
                          },
                        ]
                      : [
                          {
                            id: "with_taxes",
                            displayName: `${t("Create")} ${label} ${t(
                              "with taxes"
                            )}`,
                          },
                          {
                            id: "without_taxes",
                            displayName: `${t("Create")} ${label} ${t(
                              "without taxes"
                            )}`,
                          },
                        ]),
                    ...(hasPermissions(["change_invoices_touroperatorinvoice"])
                      ? [
                          {
                            id: "add_to_existing",
                            displayName: `${t("Add to existing")} ${label}`,
                          },
                        ]
                      : []),
                  ]}
                  required
                />
              </div>
            </div>
            {invoiceTypeValue?.id === "add_to_existing" && (
              <div className="row">
                <div className="col-12">
                  <FormField
                    name="existingInvoice"
                    fieldType="asyncSelect"
                    label={label}
                    endpoint={`/invoices/tour-operator-invoices/?tourOperator=${tourOperator}&isSent=false&isNullified=false&isCreditNote=${isCreditNote}`}
                    required
                  />
                </div>
              </div>
            )}
            {invoiceTypeValue?.id &&
            invoiceTypeValue?.id !== "add_to_existing" ? (
              <div className="row">
                <div className="col-12">
                  <FormField
                    name="currency"
                    fieldType="select"
                    endpoint="/currencies/"
                    storeChoices
                    label={t("Currency")}
                    defaultValue={getAppInfo().currency}
                    isClearable={false}
                    required
                  />
                </div>
              </div>
            ) : null}
            <div className="row mt-3">
              <div className="ml-auto col-auto">
                <Button submit>{t("Save")}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

SubmitModal.propTypes = {
  fields: PropTypes.array.isRequired,
}
