import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import Button from "components/Button/Button"
import { Form, FormField } from "components/Form"
import { apiPOST } from "utils/api"

export default function CNXBooking({ id, isAirline, data, setData }) {
  const [cnxDefaultValues, setCnxDefaultValues] = useState({})
  const { t } = useTranslation()

  return (
    <div className="row">
      <div className="col-12">
        <Form
          endpoint={
            isAirline
              ? `/bookings/airline-bookings/${id}/superuser-manage-seats/?action=cnx`
              : `/bookings/tour-operator-bookings/${id}/superuser-manage-seats/?action=cnx`
          }
          apiFunction={apiPOST}
          successFunction={response => {
            setData(response)
            setCnxDefaultValues({
              seats: "",
              isForceMajor: false,
            })
            window.scroll({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
          }}
          successMessage={t("Special CNX was successful.")}
          defaultValues={cnxDefaultValues}
          showBack={false}
          showSubmit={false}>
          <div className="row">
            <div className="col-6">
              <b>{t("Total Seats")}:</b>
            </div>
            <div className="col-6">
              <b>{data.seats.totalSeats}</b>
            </div>
          </div>
          <FormField
            name="seats"
            fieldType="input"
            type="number"
            label={t("Special CNX seats")}
            required
            registerProps={{
              validate: value =>
                parseInt(value) > data.seats.confirmedSeats
                  ? t(
                      "Special CNX seats cannot be bigger than the number of confirmed seats."
                    )
                  : true,
            }}
          />
          <div className="mt-3">
            <FormField
              name="isForceMajor"
              fieldType="checkbox"
              label={t("Force Majeure")}
            />
          </div>
          <div className="row mt-4">
            <div className="col-auto ml-auto">
              <Button submit>{t("Confirm")}</Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

CNXBooking.propTypes = {
  id: PropTypes.string.isRequired,
  isAirline: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
}
