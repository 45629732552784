import React from "react"
import PropTypes from "prop-types"

import Fares from "./Fares"
import Card from "components/Card/Card"
import Expand from "components/Expand/Expand"

export default function BookingTypeForm(props) {
  const { fareType, title, legFares, leftChildren, rightChildren } = props

  return (
    <Card className="my-3" bodyClassName="p-0">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center py-2 px-3 bg-gray-light">
            <h5 className="my-1">{title}</h5>
            <Expand color="magenta" size="xl" target={`#${fareType}`} />
          </div>
          <div id={fareType} className="collapse hide py-3 px-4">
            <div className="row">
              <div className="col-7 border-right">
                <div className="row">
                  <div className="col-5">{leftChildren}</div>
                  <div className="col-7">
                    <Fares fareType={fareType} legFares={legFares} />
                  </div>
                </div>
              </div>
              <div className="col-5">{rightChildren}</div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

BookingTypeForm.propTypes = {
  fareType: PropTypes.oneOf(["BLK", "ALT", "GIR", "GRP", "OVF"]).isRequired,
  title: PropTypes.string.isRequired,
  legFares: PropTypes.object,
  leftChildren: PropTypes.node.isRequired,
  rightChildren: PropTypes.node,
}

BookingTypeForm.defaultProps = {
  legFares: {
    firstLeg: [],
    secondLeg: [],
  },
  rightChildren: null,
}
