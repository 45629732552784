import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import { useTranslation, Trans } from "react-i18next"
import { useHistory } from "react-router-dom"

import Button from "components/Button/Button"
import { apiDELETE, apiGET } from "utils/api"
import { redirectWithNotify } from "utils/notifications"
import { reverse } from "utils/urls"

export default function Delete(props) {
  const [data, setData] = useState({})
  const { t } = useTranslation()
  let history = useHistory()

  const { modelName } = props
  const { displayName } = data

  useEffect(
    () =>
      apiGET({
        path: props.endpoint,
        onSuccess: response => setData(response),
        onError: () => history.push(reverse("home")),
      }),
    [props.endpoint, history]
  )

  const onSubmit = e => {
    e.preventDefault()
    apiDELETE({
      path: props.endpoint,
      onSuccess: () => {
        props.onSuccess && props.onSuccess(data)
        const successMessage = props.successMessage || (
          <Trans i18nKey="deleteSuccessMessage">
            {{ modelName }} <b>{{ displayName }}</b> was successfully deleted.
          </Trans>
        )

        const urlParams = new URLSearchParams(history.location.search)
        let redirectUrl = props.successRoute
        if (urlParams.get("next")) {
          redirectUrl = urlParams.get("next")
        }

        redirectWithNotify(redirectUrl, "success", successMessage)
      },
      onError: err => {
        props.onError && props.onError(data)
        const nonFieldErrors = err.response?.nonFieldErrors
        const errorMessage = nonFieldErrors
          ? nonFieldErrors.join("\n")
          : props.errorMessage || (
              <Trans i18nKey="deleteErrorMessage">
                There were errors while trying to delete {{ modelName }}{" "}
                <b>{{ displayName }}</b>.
              </Trans>
            )
        redirectWithNotify(props.errorRoute, "error", errorMessage)
      },
    })
  }

  return !isEmpty(data) ? (
    <>
      <Trans i18nKey="deleteTitle">
        <h1>Delete {{ modelName }}</h1>
      </Trans>
      <form method="post" onSubmit={onSubmit}>
        <Trans i18nKey="deleteConfirmMessage">
          <p>
            Are you sure you want to delete {{ modelName }}{" "}
            <b>{{ displayName }}</b>?
          </p>
        </Trans>
        <div className="my-2">
          <Button submit>{t("Delete")}</Button>
        </div>
        <div>
          <Button onClick={() => history.goBack()}>{t("Back")}</Button>
        </div>
      </form>
    </>
  ) : null
}

Delete.propTypes = {
  endpoint: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  successRoute: PropTypes.string.isRequired,
  successMessage: PropTypes.object,
  onError: PropTypes.func,
  errorRoute: PropTypes.string.isRequired,
  errorMessage: PropTypes.object,
}

Delete.defaultProps = {
  onSuccess: () => {},
  successMessage: undefined,
  onError: () => {},
  errorMessage: undefined,
}
