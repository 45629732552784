import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import Avatar from "components/Avatar/Avatar"
import SectionHeader from "components/SectionHeader/SectionHeader"

export default function BookingInfo({ seats, tourOperator }) {
  const { t } = useTranslation()

  return (
    <>
      <div className="mt-4 mb-3">
        <SectionHeader header={t("Booking info")} />
      </div>
      <div className="d-flex align-items-center py-2 px-3 bg-light my-3">
        <div className="row">
          <div className="col">
            <b>{t("Tour operator")}</b>
          </div>
          <div className="col text-center">
            <b>{t("Confirmed bookings")}</b>
          </div>
          <div className="col text-center">
            <b>{t("Ticketed")}</b>
          </div>
          <div className="col text-center">
            <b>{t("Remaining bookings")}</b>
          </div>
        </div>
      </div>
      <div className="d-flex px-3 pb-2 border-bottom border-light">
        <div className="row">
          <div className="col">
            <Avatar
              image={tourOperator.logo}
              title={tourOperator.displayName}
              alt={tourOperator.code}
            />
          </div>
          <div className="col text-center">{seats.bookingsSeats}</div>
          <div className="col text-center">{seats.passengers}</div>
          <div className="col text-center">{seats.remaining}</div>
        </div>
      </div>
    </>
  )
}

BookingInfo.propTypes = {
  seats: PropTypes.object.isRequired,
  tourOperator: PropTypes.object.isRequired,
}
