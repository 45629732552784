import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { getEnum } from "apps/core/utils"
import Button from "components/Button/Button"
import { Form, FormField } from "components/Form"
import { apiPOST } from "utils/api"

export default function ManageBooking({ id, isAirline, data, setData }) {
  const [manageAction, setManageAction] = useState("")
  const [manageDefaultValues, setManageDefaultValues] = useState({})
  const { t } = useTranslation()

  const BOOKING_TYPE_ENUM = getEnum("bookingType")

  return (
    <div className="row">
      <div className="col-12">
        <Form
          endpoint={
            isAirline
              ? `/bookings/airline-bookings/${id}/manage-seats/?action=${manageAction}`
              : `/bookings/tour-operator-bookings/${id}/manage-seats/?action=${manageAction}`
          }
          apiFunction={apiPOST}
          successFunction={response => {
            setData(response)
            setManageDefaultValues({
              seats: "",
            })
            window.scroll({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
          }}
          successMessage={t("Manage booking was successful.")}
          defaultValues={manageDefaultValues}
          showBack={false}
          showSubmit={false}>
          <div className="row">
            <div className="col-6">
              <b>{t("Total Seats")}:</b>
            </div>
            <div className="col-6">
              <b>{data.seats.totalSeats}</b>
            </div>
          </div>
          {data.type.id !== BOOKING_TYPE_ENUM.GROUP && (
            <div className="row my-3">
              <div className="col-6">
                <b className="text-warning">{t("Optional Seats")}:</b>
              </div>
              <div className="col-6">
                <b className="text-warning">{data.seats.optionalSeats}</b>
              </div>
            </div>
          )}
          <FormField
            name="seats"
            fieldType="input"
            type="number"
            label={
              data.type.id === BOOKING_TYPE_ENUM.GROUP
                ? t("Seats to cancel")
                : t("Confirmed seats")
            }
            required
            registerProps={{
              validate: value => {
                if (data.type.id === BOOKING_TYPE_ENUM.GROUP) {
                  return parseInt(value) > data.seats.confirmedSeats
                    ? t("There is not enough seats to cancel")
                    : true
                } else {
                  return parseInt(value) >
                    data.seats.optionalSeats + data.seats.tempConfirmedSeats
                    ? t(
                        "Confirmed seats cannot be bigger than the number of optional seats."
                      )
                    : true
                }
              },
            }}
          />
          <div className="row mt-4">
            {data.type.id === BOOKING_TYPE_ENUM.GROUP ? (
              <div className="col ml-auto">
                <Button submit onClick={() => setManageAction("cancel")}>
                  {t("Confirm")}
                </Button>
              </div>
            ) : (
              <>
                <div className="col">
                  <Button
                    submit
                    outline
                    onClick={() => setManageAction("temp_confirm")}>
                    {t("Confirm")}
                  </Button>
                </div>
                <div className="col-auto ml-auto">
                  <Button submit onClick={() => setManageAction("release")}>
                    {t("Release")}
                  </Button>
                </div>
              </>
            )}
          </div>
        </Form>
      </div>
    </div>
  )
}

ManageBooking.propTypes = {
  id: PropTypes.string.isRequired,
  isAirline: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
}
