import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { isEmpty } from "lodash"

import { getAuthInfo, getGroups, isGroupSuperior } from "apps/auth/auth"
import { Form, FormField } from "components/Form"
import { apiPUT, apiGET, apiPOST } from "utils/api"
import { reverse } from "utils/urls"

export default function UserForm(props) {
  const [formData, setFormData] = useState({})
  const [selectedGroup, setSelectedGroup] = useState(null)

  const { t } = useTranslation()

  const userGroup = getAuthInfo().groupType
  const groups = getGroups()

  useEffect(
    () =>
      props.isUpdate
        ? apiGET({
            path: `/auth/users/${props.match.params.id}/`,
            onSuccess: response => {
              const authInfo = getAuthInfo()
              if (
                isGroupSuperior(authInfo.groupType, response.group.id) ||
                authInfo.id === response.id
              ) {
                setFormData(response)
              } else {
                props.history.push(reverse("userList"))
              }
            },
            onError: () => props.history.push(reverse("userList")),
          })
        : () => {},
    [props.isUpdate, props.match.params.id, props.history]
  )

  useEffect(() => {
    if (formData.group) {
      setSelectedGroup(formData.group)
    }
  }, [formData.group])

  return (
    <div>
      <Form
        apiFunction={props.isUpdate ? apiPUT : apiPOST}
        endpoint={props.actionEndpoint}
        defaultValues={formData}
        successUrl={props.successUrl}
        successMessage={t("User was successfully saved.")}
        title={props.isUpdate ? t("Edit user") : t("Add user")}>
        <div className="row">
          <div className="col-4">
            <FormField
              name="name"
              fieldType="input"
              type="text"
              label={t("Name")}
              maxLength={255}
            />
          </div>
          {!props.isUpdate &&
            selectedGroup !== null &&
            [groups.tourOperatorSuperuser, groups.tourOperatorUser].includes(
              selectedGroup.id
            ) &&
            [groups.ownerSuperuser, groups.ownerUser].includes(userGroup) && (
              <div className="col-4">
                <FormField
                  name="tourOperator"
                  fieldType="asyncSelect"
                  label={t("Tour Operator")}
                  required
                  endpoint="/tour-operators/"
                />
              </div>
            )}
        </div>
        <div className="row">
          <div className="col-4">
            <FormField
              name="email"
              fieldType="input"
              type="email"
              label={t("Email")}
              maxLength={255}
              required
            />
          </div>
          <div className="col-4">
            <FormField
              name="re_email"
              fieldType="input"
              type="email"
              label={t("Email (again)")}
              maxLength={255}
              required={!props.isUpdate}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <FormField
              name="group"
              fieldType="select"
              label={t("Group")}
              required
              onChange={selected => {
                if (selected !== undefined) {
                  setSelectedGroup(selected)
                }
              }}
              endpoint={`/auth/users/groups/?method=${
                props.isUpdate ? "update" : "create"
              }&id=${props.isUpdate ? props.match.params.id : ""}`}
            />
          </div>
          {(!props.isUpdate ||
            (props.isUpdate &&
              !isEmpty(formData) &&
              isGroupSuperior(userGroup, formData.group.id))) && (
            <div className="col-4">
              <FormField
                name="isActive"
                fieldType="checkbox"
                label={t("Active")}
                helpText={t(
                  "Designates whether this user should be treated as active. Unselect this instead of deleting accounts."
                )}
              />
            </div>
          )}
        </div>
      </Form>
    </div>
  )
}

UserForm.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string,
    }),
  }).isRequired,
  isUpdate: PropTypes.bool,
  actionEndpoint: PropTypes.string.isRequired,
  successUrl: PropTypes.string.isRequired,
}

UserForm.defaultProps = {
  isUpdate: false,
}
