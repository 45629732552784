import React from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { isEqual } from "lodash"

import { FormField } from "components/Form"
import {
  showLoadingOverlay,
  hideLoadingOverlay,
} from "components/LoadingOverlay/LoadingOverlay"
import { csvToArray } from "utils/fs"
import { dismissAllNotifications, notify } from "utils/notifications"

const CSV_HEADERS = [
  "passengerType",
  "familyReference",
  "firstName",
  "lastName",
  "gender",
  "dateOfBirth",
  "petType",
  "weight",
  "description",
]

export default function ImportCSV(props) {
  const { setValue, getValues } = useFormContext()
  const { t } = useTranslation()

  const raiseError = msg => {
    dismissAllNotifications()
    notify("error", msg)
    window.scroll({ top: 0, left: 0, behavior: "smooth" })
  }

  return (
    <FormField
      name="passengersList"
      fieldType="file"
      className="text-center"
      label={t("Import passengers list")}
      inputProps={{ accept: ".csv" }}
      onFileChange={file => {
        const reader = new FileReader()
        showLoadingOverlay()

        reader.onload = function(e) {
          const text = e.target.result
          const data = csvToArray(text.trim())

          const headers = Object.keys(data[0])
          if (!isEqual(headers.sort(), CSV_HEADERS.sort())) {
            raiseError(t("Headers of the uploaded CSV file are not valid."))
          } else {
            let isValid = true

            data.forEach(passenger => {
              if (
                ["Adult", "Child", "Infant"].includes(passenger.passengerType)
              ) {
                if (passenger.gender === "F") {
                  passenger.gender = { id: "F", displayName: t("Female") }
                } else if (passenger.gender === "M") {
                  passenger.gender = { id: "M", displayName: t("Male") }
                } else {
                  isValid = false
                  raiseError(
                    passenger.firstName +
                      passenger.lastName +
                      t("has wrong gender. Valid options are `F` and `M`.")
                  )
                }
              }

              if (passenger.passengerType === "PetC") {
                if (passenger.petType === "Dog") {
                  passenger.petType = { id: "DOG", displayName: t("Dog") }
                } else if (passenger.petType === "Cat") {
                  passenger.petType = { id: "CAT", displayName: t("Cat") }
                } else {
                  isValid = false
                  raiseError(
                    passenger.familyReference +
                      t(
                        "pet has wrong type. Valid options are `DOG` and `CAT`."
                      )
                  )
                }
              }

              passenger.passengerType = passenger.passengerType.toUpperCase()
            })

            if (isValid) {
              setValue("passengers", [
                ...(getValues().passengers || []),
                ...data,
              ])
            }
          }
          hideLoadingOverlay()
        }
        reader.readAsText(file)
      }}
    />
  )
}
