import {
  AirlineBookingSegmentSelection,
  AirlineBookingCreate,
  AirlineBookingUpdate,
  AirlineBookingDelete,
  AirlineBookingDetail,
  AirlineBookingList,
  TourOperatorBookingSegmentSelection,
  TourOperatorBookingCreate,
  TourOperatorBookingUpdate,
  TourOperatorBookingDelete,
  TourOperatorBookingDetail,
  TourOperatorBookingList,
} from "apps/bookings/Booking"
import { route } from "utils/urls"

export const PROTECTED_ROUTES = [
  route("airlineBookingCreateStep1", AirlineBookingSegmentSelection, [
    "add_bookings_airlinebooking",
  ]),
  route("airlineBookingCreateStep2", AirlineBookingCreate, [
    "add_bookings_airlinebooking",
  ]),
  route("airlineBookingUpdate", AirlineBookingUpdate, [
    "change_bookings_airlinebooking",
  ]),
  route("airlineBookingDelete", AirlineBookingDelete, [
    "delete_bookings_airlinebooking",
  ]),
  route("airlineBookingDetail", AirlineBookingDetail, [
    "view_bookings_airlinebooking",
  ]),
  route("airlineBookingList", AirlineBookingList, [
    "view_bookings_airlinebooking",
  ]),
  route("tourOperatorBookingCreateStep1", TourOperatorBookingSegmentSelection, [
    "add_bookings_touroperatorbooking",
  ]),
  route("tourOperatorBookingCreateStep2", TourOperatorBookingCreate, [
    "add_bookings_touroperatorbooking",
  ]),
  route("tourOperatorBookingUpdate", TourOperatorBookingUpdate, [
    "change_bookings_touroperatorbooking",
  ]),
  route("tourOperatorBookingDelete", TourOperatorBookingDelete, [
    "delete_bookings_touroperatorbooking",
  ]),
  route("tourOperatorBookingDetail", TourOperatorBookingDetail, [
    "view_bookings_touroperatorbooking",
  ]),
  route("tourOperatorBookingList", TourOperatorBookingList, [
    "view_bookings_touroperatorbooking",
  ]),
]
