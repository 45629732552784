import React from "react"

import { useTranslation } from "react-i18next"

import Detail from "components/GenericViews/Detail"
import { reverse } from "utils/urls"

export default function AppSettingsDetail(props) {
  const { t } = useTranslation()

  return (
    <Detail
      title={t("App settings")}
      detailEndpoint={`/app-settings/detail/`}
      listRoute={reverse("home")}
      changePermission={["change_core_appsettings"]}
      changeRoute={reverse("appSettingsUpdate")}
      fields={[
        [
          {
            label: t("Currency"),
            key: "currency",
            type: "singleSelect",
            size: "col-8",
          },
        ],
        [
          {
            label: t("Starting Weekday"),
            key: "startingWeekday",
            type: "singleSelect",
            size: "col-4",
          },
          {
            label: t("Starting Weekday No"),
            key: "startingWeekdayNo",
            size: "col-4",
          },
        ],
        [
          {
            label: t("Non Working Days"),
            key: "nonWorkingDays",
            type: "multiSelect",
            size: "col-8",
          },
        ],
        [
          {
            label: t("Booking Block Access Time"),
            key: "bookingBlockAccessTime",
            size: "col-4",
          },
          {
            label: t("Booking Block Access Day Offset"),
            key: "bookingBlockAccessDayOffset",
            size: "col-4",
          },
        ],
        [
          {
            label: t("Mass unit"),
            key: "massUnit",
            type: "singleSelect",
            size: "col-4",
          },
          {
            label: t("Length unit"),
            key: "lengthUnit",
            type: "singleSelect",
            size: "col-4",
          },
        ],
      ]}
    />
  )
}
