import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

import Delete from "components/GenericViews/Delete"
import { reverse } from "utils/urls"

export default function FlightSeriesDelete(props) {
  const { t } = useTranslation()

  return (
    <Delete
      modelName={t("Flight Series")}
      endpoint={`/flights/series/${props.match.params.id}/`}
      successRoute={reverse("flightSeriesList")}
      errorRoute={reverse("flightSeriesDetail", { id: props.match.params.id })}
    />
  )
}

FlightSeriesDelete.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
