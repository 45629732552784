import React from "react"
import { useTranslation } from "react-i18next"

import { hasPermissions } from "apps/auth/auth"
import Tooltip from "components/Tooltip/Tooltip"
import Icon from "components/Icon/Icon"
import Button from "components/Button/Button"
import { actionsColumn, TableWithFilters } from "components/Table"
import { reverse } from "utils/urls"

const getConfig = t => ({
  endpoint: "/airlines",
  title: t("Airlines"),
  tableName: "airlines-table-config",
  columns: [
    {
      Header: t("Name"),
      accessor: "name",
    },
    { Header: t("Code"), accessor: "code" },
    { Header: t("Code AS400"), accessor: "codeAs400" },
    actionsColumn(obj => (
      <>
        <Tooltip title={t("Airline Details")}>
          <Icon.Link
            name="eye"
            color="gray"
            to={reverse("airlineDetail", { id: obj.id })}
          />
        </Tooltip>
        <Tooltip title={t("Airline History")}>
          <Icon.Link
            name="history"
            color="gray"
            to={reverse("history", { id: obj.id, model: "airlines" })}
            linkClassName="ml-3"
          />
        </Tooltip>
        <Tooltip title={t("Edit Airline")}>
          <Icon.Link
            name="edit"
            to={reverse("airlineUpdate", { id: obj.id })}
            linkClassName="ml-3"
          />
        </Tooltip>
        <Tooltip title={t("Delete Airline")}>
          <Icon.Link
            name="remove"
            color="danger"
            to={reverse("airlineDelete", { id: obj.id })}
            linkClassName="ml-3"
          />
        </Tooltip>
      </>
    )),
  ],
  filters: [
    [
      {
        name: "id",
        fieldType: "asyncSelect",
        endpoint: "/airlines/",
        label: t("Airline"),
        size: "col-4",
      },
      {
        fieldType: "applyButton",
        size: "col-auto mt-2",
      },
      {
        fieldType: "clearButton",
        size: "col-auto mt-2",
      },
    ],
  ],
  buttons: (
    <>
      {hasPermissions(["add_airlines_airline"]) && (
        <Button icon="plus" to={reverse("airlineCreate")}>
          {t("Add Airline")}
        </Button>
      )}
    </>
  ),
})

export default function AirlineList(props) {
  const { t } = useTranslation()

  return <TableWithFilters config={getConfig(t)} />
}
