import { getGlobal } from "reactn"
import Cookies from "js-cookie"

import { AUTH_INFO_URL, TOKEN_COOKIE } from "utils/constants"
import { reverse, buildRouteFromLocation } from "utils/urls"
import { initGlobal } from "utils/store"
import history from "utils/history"

export const getAuthCookie = () => Cookies.get(TOKEN_COOKIE)

export const getAuthHeaders = () => {
  const authCookie = getAuthCookie()

  return authCookie
    ? {
        Authorization: `JWT ${getAuthCookie()}`,
      }
    : {}
}

export const handleLogout = () => {
  removeAuthCokie()
  initGlobal() // re-initializes state to default values
  history.push(
    reverse({
      name: "login",
      queryParams: { next: buildRouteFromLocation(history.location) },
    })
  )
}

export const isLoggedIn = () => !!Cookies.get(TOKEN_COOKIE)

export const hasPermissions = (permissions, operation = "every") =>
  isLoggedIn() &&
  permissions[operation](p => getAuthInfo().permissions.includes(p))

export const setAuthCookie = token => {
  Cookies.set(TOKEN_COOKIE, token, { expires: 7 })
}

const removeAuthCokie = () => Cookies.remove(TOKEN_COOKIE)

export const getAuthInfo = () => getGlobal().apiStore[AUTH_INFO_URL]

export const getGroups = () => getAuthInfo().groups

export const isOwner = () => {
  const groups = getGroups()
  return [groups.ownerSuperuser, groups.ownerUser].includes(
    getAuthInfo().groupType
  )
}

export const isGroupSuperior = (group1, group2) => {
  const groups = getGroups()
  return (
    (group1 === groups.ownerSuperuser &&
      [
        groups.ownerUser,
        groups.tourOperatorSuperuser,
        groups.tourOperatorUser,
      ].includes(group2)) ||
    (group1 === groups.ownerUser &&
      [groups.tourOperatorSuperuser, groups.tourOperatorUser].includes(
        group2
      )) ||
    (group1 === groups.tourOperatorSuperuser &&
      group2 === groups.tourOperatorUser)
  )
}
