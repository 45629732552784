import React, { useContext } from "react"
import { useHistory } from "react-router"

import Icon from "components/Icon/Icon"
import { getLocalStorageObj, setLocalStorageObj } from "utils/fs"
import { TrComponentContext } from "./TrComponent"

export default function Expander(props) {
  const { isExpanded, setIsExpanded, rowInfo } = useContext(TrComponentContext)
  let history = useHistory()

  return (
    <div
      className="w-100 h-100"
      onClick={() => {
        const storageName = `${history.location.pathname}-sheet`
        const sheetData = getLocalStorageObj(storageName) || {}
        const expanded = sheetData.expanded || {}
        expanded[rowInfo.original.id] = !isExpanded
        sheetData.expanded = expanded
        setLocalStorageObj(storageName, sheetData)

        setIsExpanded(!isExpanded)
      }}>
      <Icon
        name={isExpanded ? "chevron-up" : "chevron-down"}
        color={isExpanded ? "teal" : "teal-light"}
      />
    </div>
  )
}
