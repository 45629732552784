import React from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"

import { useTranslation } from "react-i18next"

import Icon from "components/Icon/Icon"
import Popover from "components/Popover/Popover"

export default function FeeCell({ total, header, objects, isDetailed }) {
  const { t } = useTranslation()

  return (
    <div className="text-right">
      {total !== null && <span className="mr-2">{total}</span>}
      <Popover
        header={header}
        content={
          <div>
            {!isEmpty(objects) ? (
              isDetailed ? (
                <div className="row">
                  <div className="col-auto">
                    <div className="row">
                      <div className="col-12">&nbsp;</div>
                    </div>
                    {Object.keys(objects).map((k, i) => (
                      <div className="row" key={i}>
                        <div className="col-auto">
                          <b>{k}</b>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="col-auto">
                    <div className="row">
                      <div className="col-auto">
                        <b>{t("Invoiced")}</b>
                      </div>
                    </div>
                    {Object.values(objects).map((v, i) => (
                      <div className="row" key={i}>
                        <div className="col-auto text-right">{v.price}</div>
                      </div>
                    ))}
                  </div>
                  <div className="col-auto">
                    <div className="row">
                      <div className="col-auto">
                        <b>{t("Initial")}</b>
                      </div>
                    </div>
                    {Object.values(objects).map((v, i) => (
                      <div className="row" key={i}>
                        <div className="col-auto text-right">
                          {v.basePrice || ""}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="col-auto">
                    <div className="row">
                      <div className="col-auto">
                        <b>{t("Current")}</b>
                      </div>
                    </div>
                    {Object.values(objects).map((v, i) => (
                      <div className="row" key={i}>
                        <div
                          className={`col-auto text-right ${
                            v.segmentPriceStatus === "no" && "text-danger"
                          } ${
                            v.segmentPriceStatus === "over" && "text-warning"
                          }`}>
                          {v.segmentPrice || ""}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-auto">
                    {Object.keys(objects).map((k, i) => (
                      <div className="row" key={i}>
                        <div className="col-auto">
                          <b>{k}</b>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="col-auto">
                    {Object.values(objects).map((v, i) => (
                      <div className="row" key={i}>
                        <div className="col-12 text-right">
                          {v.price || "-"}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )
            ) : (
              <>{t("No fees defined.")}</>
            )}
          </div>
        }>
        <span>
          <Icon
            name="info-circle"
            color="white"
            className="bg-blue rounded-circle"
            size="sm"
          />
        </span>
      </Popover>
    </div>
  )
}

FeeCell.propTypes = {
  total: PropTypes.string,
  header: PropTypes.string.isRequired,
  objects: PropTypes.object.isRequired,
  isDetailed: PropTypes.bool,
}

FeeCell.defaultProps = {
  total: null,
  isDetailed: false,
}
