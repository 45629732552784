import React from "react"
import PropTypes from "prop-types"

import FeeCategoryForm from "apps/fees/FeeCategory/FeeCategoryForm"
import { reverse } from "utils/urls"

export default function FeeCategoryUpdate(props) {
  return (
    <FeeCategoryForm
      {...props}
      isUpdate
      actionEndpoint={`/fees/categories/${props.match.params.id}/`}
      successUrl={reverse("feeCategoryList")}
    />
  )
}

FeeCategoryUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
