import React from "react"
import PropTypes from "prop-types"
import { useFormContext } from "react-hook-form"
import { get } from "lodash"

import { getAppInfo } from "apps/core/utils"
import { FormField } from "components/Form"

import "../FormField.scss"

export default function PriceField(props) {
  const { errors } = useFormContext()
  const fieldError = get(errors, props.name)
  const currencyError = get(errors, props.currencyName)

  const currency = (
    <FormField
      name={props.currencyName}
      fieldType="select"
      endpoint="/currencies/"
      storeChoices
      label=""
      defaultValue={props.currencyDefaultValue || getAppInfo().currency}
      isClearable={false}
      containerClassName="mt-0 mb-0 currency-field"
    />
  )

  const currencyPosition =
    props.currencyPosition === "prepend"
      ? { inputGroupPrepend: currency }
      : { inputGroupAppend: currency }

  return (
    <>
      <FormField
        fieldType="input"
        type="number"
        step="0.01"
        min={0}
        name={props.name}
        label={props.label}
        required={props.required}
        defaultValue={props.defaultValue}
        showError={false}
        {...currencyPosition}
      />
      {fieldError ? (
        <div className="invalid-feedback d-block text-left">
          {fieldError.message}
        </div>
      ) : null}
      {currencyError ? (
        <div className="invalid-feedback d-block text-left">
          {currencyError.message}
        </div>
      ) : null}
    </>
  )
}

PriceField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  required: PropTypes.bool,
  currencyName: PropTypes.string,
  defaultValue: PropTypes.string,
  currencyDefaultValue: PropTypes.object,
  currencyPosition: PropTypes.oneOf(["prepend", "append"]),
}

PriceField.defaultProps = {
  required: false,
  currencyName: "currency",
  defaultValue: undefined,
  currencyDefaultValue: undefined,
  currencyPosition: "append",
}
