import {
  AirportCreate,
  AirportUpdate,
  AirportDelete,
  AirportDetail,
  AirportList,
} from "apps/airports/Airport"
import { route } from "utils/urls"

export const PROTECTED_ROUTES = [
  route("airportCreate", AirportCreate, ["add_airports_airport"]),
  route("airportUpdate", AirportUpdate, ["change_airports_airport"]),
  route("airportDelete", AirportDelete, ["delete_airports_airport"]),
  route("airportDetail", AirportDetail, ["view_airports_airport"]),
  route("airportList", AirportList, ["view_airports_airport"]),
]
