import React from "react"
import PropTypes from "prop-types"
import { useFormContext, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { FormField } from "components/Form"

export default function FlightNoField({ skipValidation, ...rest }) {
  const { control } = useFormContext()
  const value = useWatch({
    control,
    name: rest.name,
    defaultValue: "",
  })
  const { t } = useTranslation()

  const getMask = () => {
    const startMaskPart = [/[A-Za-z0-9]/, /[A-Za-z0-9]/]
    const endMaskPart = [/[0-9]/, /[0-9]?/, /[0-9]?/, /[0-9]?/, /[A-Za-z]/]

    if (value.length < 3) {
      return [...startMaskPart, /[A-Za-z0-9 ]/]
    }

    if (value[2] === " " || !isNaN(value[2])) {
      return [...startMaskPart, " ", ...endMaskPart]
    }
    return [...startMaskPart, /[A-Za-z]/, " ", ...endMaskPart]
  }

  return (
    <FormField
      maskPlaceholder={null}
      label={
        <>
          {t("Flight")} N<sup>o</sup>
        </>
      }
      {...rest}
      fieldType="masked"
      registerProps={{
        validate: value => {
          if (
            !skipValidation &&
            !value.match(
              /^[A-Za-z0-9]{2}[A-Za-z]{0,1} [0-9]{1}[0-9]{0,3}[A-Za-z]{0,1}$/
            )
          ) {
            return t("This value is not a valid flight number.")
          }
        },
      }}
      mask={getMask()}
      beforeMaskedStateChange={({ nextState }) => {
        let { value } = nextState

        // prevent multiple letters on the end of the flight number (only one is allowed)
        if (
          (value[2] === " " &&
            value.length > 5 &&
            /[a-zA-Z]/.test(value[value.length - 2])) ||
          (value[3] === " " &&
            value.length > 6 &&
            /[a-zA-Z]/.test(value[value.length - 2]))
        ) {
          value = value.slice(0, -1)
        }

        return {
          ...nextState,
          value: value.toUpperCase(),
          selection: { start: value.length, end: value.length },
        }
      }}
    />
  )
}

FlightNoField.propTypes = {
  skipValidation: PropTypes.bool,
}

FlightNoField.defaultProps = {
  skipValidation: false,
}
