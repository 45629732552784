const routes = {
  login: "/users/login",
  forgotPassword: "/users/forgot-password",
  passwordReset: "/users/password-reset/:uid/:token",
  passwordChange: "/users/password-change",
  managePermissions: "/users/:id/manage-permissions",
  userCreate: "/users/create",
  userUpdate: "/users/:id/update",
  userDelete: "/users/:id/delete",
  userDetail: "/users/:id",
  userList: "/users",
}

export default routes
