import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

import Detail from "components/GenericViews/Detail"
import { reverse } from "utils/urls"

export default function AirportDetail(props) {
  const { t } = useTranslation()

  return (
    <Detail
      title={t("Airport details")}
      detailEndpoint={`/airports/${props.match.params.id}/`}
      listRoute={reverse("airportList")}
      changePermission={["change_airports_airport"]}
      changeRoute={reverse("airportUpdate", { id: props.match.params.id })}
      deletePermission={["delete_airports_airport"]}
      deleteRoute={reverse("airportDelete", { id: props.match.params.id })}
      historyRoute={reverse("history", {
        id: props.match.params.id,
        model: "airports",
      })}
      fields={[
        [
          { label: t("Name"), key: "name", size: "col-4" },
          { label: t("IATA Code"), key: "codeIata", size: "col-4" },
        ],
        [
          {
            label: t("City"),
            key: "city",
            type: "singleSelect",
            size: "col-4",
          },
          { label: t("ICAO Code"), key: "codeIcao", size: "col-4" },
        ],
      ]}
    />
  )
}

AirportDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.exact({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
