import React from "react"
import PropTypes from "prop-types"

import Card from "components/Card/Card"

export default function SectionHeader({ header, body }) {
  return (
    <Card bodyClassName="py-2 px-3 bg-gray-light">
      {body === undefined ? (
        <h5 className="mb-0">{header}</h5>
      ) : (
        <div className="row align-items-center">
          <div className="col">
            <h5 className="mb-0">{header}</h5>
          </div>
          <div className="ml-auto col-auto">{body}</div>
        </div>
      )}
    </Card>
  )
}

SectionHeader.propTypes = {
  header: PropTypes.node.isRequired,
  body: PropTypes.node,
}

SectionHeader.defaultProps = {
  body: undefined,
}
