import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useGlobal } from "reactn"
import { useTranslation } from "react-i18next"
import { useLastLocation } from "react-router-last-location"
import { isEmpty } from "lodash"
import moment from "moment"

import {
  BookingInfo,
  ImportCSV,
  Summary,
  TicketInfo,
  ClearAll,
} from "./includes"
import PassengersFormSet from "./Passengers/PassengersFormSet"
import { useSegmentSelectionType } from "apps/wizardForms/SegmentSelection"
import Steps from "apps/wizardForms/Steps/Steps"
import FlightDetails from "apps/flights/FlightDetails/FlightDetails"
import Button from "components/Button/Button"
import SectionHeader from "components/SectionHeader/SectionHeader"
import { Form } from "components/Form"
import { apiGET, apiPOST } from "utils/api"
import { DATE_FORMAT } from "utils/constants"
import { reverse } from "utils/urls"
import { notify } from "utils/notifications"
import { validateTicketForm } from "./utils"

export default function TicketCreate(props) {
  const segmentSelectionType = useSegmentSelectionType()
  const [data, setData] = useGlobal(`${segmentSelectionType}FormData`)

  const formFlightType = useGlobal(`${segmentSelectionType}FormFlightType`)
  const flightType = formFlightType[0] || "single"
  const setFlightType = formFlightType[1]

  const formSegments = useGlobal(`${segmentSelectionType}FormSegments`)
  const setSegments = formSegments[1]

  const [ticketInfo, setTicketInfo] = useState()

  const { t } = useTranslation()

  const lastLocation = useLastLocation()

  useEffect(() => {
    return props.history.listen(location => {
      if (location.pathname !== reverse(`${segmentSelectionType}CreateStep1`)) {
        setData(undefined)
        setFlightType(undefined)
        setSegments(undefined)
      }
    })
  }, [props.history, setData, setFlightType, segmentSelectionType, setSegments])

  useEffect(() => {
    if (isEmpty(data) || !data.segments) {
      props.history.push(reverse(`${segmentSelectionType}CreateStep1`))
    }
  }, [data, props.history, segmentSelectionType])

  const getSelectedSegments = segments =>
    Object.entries(segments || {})
      .map(([k, v]) => (v === "true" ? k.split("-")[1] : null))
      .filter(s => s !== null)

  useEffect(() => {
    if (!ticketInfo && !isEmpty(data)) {
      const selectedSegments = getSelectedSegments(data.segments).join(",")

      apiGET({
        path: `/flights/segments/segments-ticket-info/?segments=${selectedSegments}&tourOperator=${data.tourOperator.id}`,
        onSuccess: response => setTicketInfo(response),
        onError: () =>
          notify(
            "error",
            t("There were some errors while trying to get segments info.")
          ),
      })
    }
  }, [data, t, ticketInfo])

  return (
    <>
      <Steps step="step2" />
      <h1>{t("Ticket creation")}</h1>
      <Form
        showBack={false}
        showSubmit={false}
        apiFunction={apiPOST}
        endpoint="/tickets/"
        successUrl={
          data.source === "sheet"
            ? `${lastLocation.pathname}${lastLocation.search}`
            : reverse("ticketList")
        }
        successMessage={t("Ticket was successfully saved.")}
        processData={d => {
          const newData = { ...data, ...d }

          // there is no need to send passengers list file to API
          delete newData.passengersList
          delete newData.passengersListFilename

          newData.segments = getSelectedSegments(newData.segments)

          return newData
        }}
        validateForm={values => validateTicketForm(values, t)}>
        {ticketInfo ? (
          flightType === "single" ? (
            <div className="row mt-4">
              <div className="col-12 col-xxl">
                <div className="row">
                  <div className="col-12">
                    <FlightDetails data={ticketInfo.flightDetails[0]} />
                  </div>
                  <div className="col-12">
                    <BookingInfo
                      seats={ticketInfo.seats[0]}
                      tourOperator={data.tourOperator}
                    />
                  </div>
                </div>
              </div>
              <div className="col-xxl-auto px-0 border-right border-teal-light" />
              <div className="col-12 mt-3 mt-xxl-0 col-xxl">
                <TicketInfo ticketDefault={ticketInfo.ticketDefault[0]} />
              </div>
            </div>
          ) : (
            <div className="row mt-4">
              {[...Array(2).keys()].map(i => (
                <Fragment key={i}>
                  <div
                    className={`col-12 col-xxl ${
                      i === 1 ? "mt-4 mt-xxl-0" : ""
                    }`}>
                    <div className="row">
                      <div className="col-12">
                        <FlightDetails
                          header={
                            i === 0
                              ? t("Departure flight details")
                              : t("Return flight details")
                          }
                          data={ticketInfo.flightDetails[i]}
                        />
                      </div>
                      <div className="col-12 mt-2">
                        <BookingInfo
                          seats={ticketInfo.seats[i]}
                          tourOperator={data.tourOperator}
                        />
                      </div>
                      <div className="col-12 mt-4">
                        <TicketInfo
                          ticketDefault={ticketInfo.ticketDefault[i]}
                        />
                      </div>
                    </div>
                  </div>
                  {i === 0 && (
                    <div className="col-xxl-auto px-0 border-right border-teal-light" />
                  )}
                </Fragment>
              ))}
            </div>
          )
        ) : null}
        <Summary flightType={flightType} />
        <div className="row mt-4">
          <div className="col-12">
            <SectionHeader header={t("Passenger list")} />
          </div>
          <div className="col-12">
            <div className="row justify-content-center mt-3">
              <div className="col-auto">
                <ImportCSV />
              </div>
            </div>
            <div className="row">
              <div className="ml-auto col-auto">
                <ClearAll />
              </div>
            </div>
          </div>
        </div>
        <div className="row my-2">
          <div className="col-12">
            <hr className="text-primary mb-1" />
          </div>
        </div>
        {ticketInfo !== undefined && (
          <PassengersFormSet
            maxChildAge={Math.min(
              ...ticketInfo.ticketDefault.map(flight =>
                flight !== null ? flight.maxChildAge : 18
              )
            )}
            operatingDates={ticketInfo.flightDetails.map(flight =>
              moment(flight.operatingDate, DATE_FORMAT)
            )}
          />
        )}
        <div className="row mt-5">
          <div className="col-auto">
            <Button
              {...(data.source === "sheet"
                ? { icon: "chevron-left-double" }
                : {})}
              onClick={() => {
                data.source === "sheet"
                  ? // use lastlocation instead of goBack to reset global data
                    props.history.push(
                      `${lastLocation.pathname}${lastLocation.search}`
                    )
                  : props.history.push(
                      reverse(`${segmentSelectionType}CreateStep1`)
                    )
              }}>
              {data.source === "sheet" ? t("Back") : t("Previous step")}
            </Button>
          </div>
          <div className="ml-auto col-auto">
            <Button submit>{t("Finish")}</Button>
          </div>
        </div>
      </Form>
    </>
  )
}

TicketCreate.propTypes = {
  history: PropTypes.object.isRequired,
}
