import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import ReactTable from "react-table-6"
import { debounce, isEqual } from "lodash"

import Button from "components/Button/Button"
import Icon from "components/Icon/Icon"
import Checkbox from "components/Checkbox/Checkbox"
import Pagination from "components/Table/Pagination"
import TdComponent from "components/Table/TdComponent"
import ThComponent from "components/Table/ThComponent"
import { apiGET } from "utils/api"
import { getLocalStorageObj, setLocalStorageObj } from "utils/fs"
import {
  PAGE_QUERY_PARAM,
  PAGE_SIZE_QUERY_PARAM,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  ORDERING_QUERY_PARAM,
} from "utils/constants"

import "./Table.scss"

class Table extends React.Component {
  static propTypes = {
    tableName: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    endpoint: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    showOptionalHeaders: PropTypes.bool.isRequired,
    showSelectColumn: PropTypes.bool.isRequired,
    showExpander: PropTypes.func,
    SubComponent: PropTypes.func,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    SubComponent: undefined,
    showExpander: () => true,
  }

  constructor(props) {
    super(props)

    this.prepareTable(props)

    this.state = {
      data: [],
      pages: null,
      loading: true,
      tableData: {},
      selectedRows: new Set(),
      collapseRows: false,
    }
  }

  prepareTable = props => {
    this.tableConf = getLocalStorageObj(props.tableName) || {}

    if (props.showOptionalHeaders) {
      this.tableConf.headers = this.tableConf.headers || {}

      // when new columns are added, set them to visible in local storage
      props.columns.forEach(col => {
        if (this.tableConf.headers[col.accessor] === undefined) {
          this.tableConf.headers[col.accessor] = true
        }
      })
    }

    setLocalStorageObj(props.tableName, this.tableConf)

    // set page size and page query params if they are not set
    const searchParams = this.getSearchParams()
    let updateParams = false

    if (!searchParams.get(PAGE_QUERY_PARAM)) {
      searchParams.set(PAGE_QUERY_PARAM, 1)
      updateParams = true
    }

    if (!searchParams.get(PAGE_SIZE_QUERY_PARAM)) {
      searchParams.set(
        PAGE_SIZE_QUERY_PARAM,
        this.tableConf.pageSize || DEFAULT_PAGE_SIZE
      )
      updateParams = true
    }

    if (updateParams) {
      props.history.replace("?" + searchParams.toString())
    }
  }

  componentDidMount() {
    if (this.props.location.search) {
      this.setState({ loading: true }, () => this.fetchResults())
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.setState({ loading: true }, () => this.fetchResults())
    }
  }

  getSearchParams = () => new URLSearchParams(this.props.location.search)

  pushToHistory = searchParams => {
    const currentSearchParams = this.getSearchParams()
    if (searchParams.toString() !== currentSearchParams.toString()) {
      this.props.history.push(
        "?" + searchParams.toString() + this.props.location.hash
      )
    }
  }

  fetchResults = () => {
    // trigger this in order to collapse all expanded rows when user filters or sorts
    this.setState({ collapseRows: true })
    apiGET({
      path: `${this.props.endpoint}/${this.props.location.search}`,
      onSuccess: response =>
        this.setState({
          data: response.results,
          pages: response.pageCount,
          loading: false,
          collapseRows: false,
          selectedRows: new Set(),
        }),
    })
  }

  pushTableDataToHistory = data => {
    const searchParams = this.getSearchParams()

    searchParams.set(PAGE_QUERY_PARAM, data.page + 1)
    searchParams.set(PAGE_SIZE_QUERY_PARAM, data.pageSize)

    if (data.sorted.length) {
      searchParams.set(
        ORDERING_QUERY_PARAM,
        data.sorted.map(s => `${s.desc ? "-" : ""}${s.id}`)
      )
    }

    this.pushToHistory(searchParams)
  }

  fetchData = (data, instance) => {
    const tableData = {
      sorted: data.sorted,
      pageSize: data.pageSize,
      page: data.page,
    }

    if (isEqual(this.state.tableData, tableData)) {
      return
    }

    this.setState({ tableData }, () => {
      this.pushTableDataToHistory(data)
    })

    // set page size to local storage
    this.tableConf.pageSize = data.pageSize
    setLocalStorageObj(this.props.tableName, this.tableConf)
  }

  addSelectColumn = columns => {
    const { data, selectedRows } = this.state

    return [
      {
        Header: () => (
          <Checkbox
            id="rt-th-select-all"
            onChange={e => {
              const rows = new Set()
              e.target.checked
                ? data.forEach(d => rows.add(d.id))
                : rows.clear()
              this.setState({ selectedRows: rows })
            }}
            checked={selectedRows.size === data.length}
            style={{ cursor: "pointer" }}
          />
        ),
        Cell: row => (
          <Checkbox
            id={`rt-td-select-row-${row.original.id}`}
            onChange={e => {
              const rows = new Set(selectedRows)
              if (e.target.checked) {
                rows.add(row.original.id)
              } else {
                rows.delete(row.original.id)
              }
              this.setState({ selectedRows: rows })
            }}
            checked={selectedRows.has(row.original.id)}
            style={{ cursor: "pointer" }}
          />
        ),
        sortable: false,
        resizable: false,
        width: 50,
        className: "rt-select",
        headerClassName: "rt-select-all-header",
        style: { textAlign: "center" },
      },
      ...columns,
    ]
  }

  addExpandColumn = columns => [
    ...columns,
    {
      className: "rt-expand",
      headerClassName: "rt-expand-header",
      Expander: ({ isExpanded, ...rest }) =>
        this.props.showExpander(rest) ? (
          <Icon name={isExpanded ? "chevron-up" : "chevron-down"} />
        ) : null,
      style: {
        cursor: "pointer",
        fontSize: 25,
        padding: "0",
        textAlign: "center",
        userSelect: "none",
      },
      resizable: false,
      expander: true,
    },
  ]

  render() {
    const { data, pages, loading, selectedRows, collapseRows } = this.state
    const {
      tableName,
      showOptionalHeaders,
      showSelectColumn,
      t,
      SubComponent,
    } = this.props
    let { columns } = this.props
    const searchParams = this.getSearchParams()
    const defaultPage = searchParams.get(PAGE_QUERY_PARAM)
      ? parseInt(searchParams.get(PAGE_QUERY_PARAM)) - 1
      : undefined

    if (showSelectColumn && data.length) {
      columns = this.addSelectColumn(columns)
    }

    if (SubComponent && data.length) {
      columns = this.addExpandColumn(columns)
    }

    return (
      <>
        {this.tableConf.resized && (
          <Button
            onClick={() => {
              delete this.tableConf.resized
              setLocalStorageObj(tableName, this.tableConf)
              this.forceUpdate()
            }}>
            {t("Reset column widths")}
          </Button>
        )}
        {showOptionalHeaders &&
          Object.entries(this.tableConf.headers).map(([header, checked]) => (
            <Fragment key={`header-${header}`}>
              <input
                type="checkbox"
                name={`header-${header}`}
                id={`header-${header}`}
                defaultChecked={checked}
                onChange={e => {
                  this.tableConf.headers[header] = e.target.checked
                  setLocalStorageObj(tableName, this.tableConf)
                  this.forceUpdate()
                }}
              />
              <label htmlFor={`header-${header}`}>
                {columns.find(col => col.accessor === header).Header}
              </label>
            </Fragment>
          ))}
        <ReactTable
          columns={
            showOptionalHeaders
              ? columns.filter(
                  col => this.tableConf.headers[col.accessor] === true
                )
              : columns
          }
          manual
          collapseOnDataChange={collapseRows}
          showPageJump={false}
          minRows={0}
          data={data}
          pages={pages}
          loading={loading}
          filterable={false}
          onFetchData={this.fetchData}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          defaultPage={defaultPage}
          defaultPageSize={
            parseInt(searchParams.get(PAGE_SIZE_QUERY_PARAM)) ||
            parseInt(this.tableConf.pageSize) ||
            DEFAULT_PAGE_SIZE
          }
          defaultSorted={
            searchParams.get(ORDERING_QUERY_PARAM)
              ? searchParams
                  .get(ORDERING_QUERY_PARAM)
                  .split(",")
                  .map(s => ({
                    id: s.replace("-", ""),
                    desc: s.includes("-"),
                  }))
              : undefined
          }
          onResizedChange={debounce(newResized => {
            this.tableConf.resized = newResized
            setLocalStorageObj(tableName, this.tableConf)
            this.forceUpdate()
          }, 250)}
          defaultResized={this.tableConf.resized}
          NoDataComponent={() => (
            <div>{!loading ? t("No results found.") : ""}</div>
          )}
          showPagination={data.length > 0}
          getTdProps={(state, rowInfo, column) => {
            let className = ""

            if (state.expanded[rowInfo.index]) {
              className = `${className} rt-td-expanded`
            }

            if (showSelectColumn && selectedRows.has(rowInfo.original.id)) {
              className = `${className} rt-td-selected`
            }

            return {
              className,
              width: column.width,
              isResized:
                state.resized.find(
                  r => r.id === column.accessor || r.id === column.id
                ) !== undefined,
            }
          }}
          getTheadThProps={(state, rowInfo, column) => ({
            alignment: column.headerAlignment || "justify-content-start",
            sortable: column.sortable,
            renderHeader: column.renderHeader,
            width: column.width,
            isResized:
              state.resized.find(
                r => r.id === column.accessor || r.id === column.id
              ) !== undefined,
          })}
          SubComponent={SubComponent}
          TdComponent={TdComponent}
          ThComponent={ThComponent}
          PaginationComponent={Pagination}
        />
      </>
    )
  }
}

export default withTranslation()(withRouter(Table))
