import React from "react"
import PropTypes from "prop-types"
import { useFormContext, useFieldArray } from "react-hook-form"
import { useTranslation } from "react-i18next"

import Button from "components/Button/Button"
import { FormField } from "components/Form"

export default function GroupFineFormSet({ name, isUpdate, ...rest }) {
  const { control } = useFormContext()
  const { fields, remove, prepend } = useFieldArray({
    control,
    name: name,
    keyName: "fieldArrayId",
  })
  const { t } = useTranslation()

  return (
    <>
      {fields.map((field, i) => (
        <div
          className={`row ${i !== 0 ? "mt-2" : ""}`}
          key={field.fieldArrayId}>
          {isUpdate && (
            <FormField
              name={`${name}[${i}].fine_id`}
              fieldType="input"
              containerClassName="mb-0 mt-0"
              type="hidden"
              label=""
              defaultValue={field.id || field.fine_id}
            />
          )}
          <div className="col-10">
            <div className="row">
              <div className="col-4">
                <FormField
                  fieldType="input"
                  type="number"
                  name={`${name}[${i}].date`}
                  label={t("Date")}
                  defaultValue={field.date}
                  min={0}
                  disabled={field.date === "0"}
                  required
                  inputGroupPrepend={
                    <span className="input-group-text">D-</span>
                  }
                />
              </div>
              <div className="col-4">
                <FormField
                  fieldType="input"
                  type="number"
                  min={0}
                  step={0.01}
                  name={`${name}[${i}].penalty`}
                  label={t("Penalty")}
                  defaultValue={field.penalty}
                  required
                />
              </div>
              <div className="col-4">
                <FormField
                  fieldType="input"
                  type="number"
                  min={0}
                  step={isUpdate ? 1 : 0.01}
                  name={`${name}[${i}].freeCancelation${
                    isUpdate ? "Seats" : ""
                  }`}
                  label={t("Free Cancelation")}
                  defaultValue={
                    isUpdate
                      ? field.freeCancelationSeats
                      : field.freeCancelation
                  }
                  required
                />
              </div>
            </div>
          </div>
          {field.date !== "0" && (
            <div className="col-2 my-auto">
              <Button
                type="danger"
                outline
                onClick={() => {
                  remove(i)
                }}>
                {t("Delete")}
              </Button>
            </div>
          )}
          {i === fields.length - 1 && (
            <div className="col-2 my-auto">
              <Button type="success" onClick={prepend}>
                {t("Add")}
              </Button>
            </div>
          )}
        </div>
      ))}
      {!fields.length && (
        <div className="row mt-4">
          <div className="col-auto">
            <Button type="success" onClick={prepend}>
              {t("Add Fine")}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

GroupFineFormSet.propTypes = {
  name: PropTypes.string.isRequired,
  isUpdate: PropTypes.bool,
}

GroupFineFormSet.defaultProps = {
  isUpdate: false,
}
